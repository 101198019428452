/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
// Icon
import IconProsesMerchantService from "assets/icons/dashboard/icon-tat-process-merchant-service.svg";
import IconSalesAdmin from "assets/icons/dashboard/icon-tat-process-sales-admin.svg";
import IconSales from "assets/icons/dashboard/icon-tat-process-sales.svg";
import IconProsesTATEndToEnd from "assets/icons/dashboard/icon-tat-process-tat-end-to-end.svg";
import IconProsesTATFMD from "assets/icons/dashboard/icon-tat-process-tat-fmd.svg";
import IconProsessUcug from "assets/icons/dashboard/icon-tat-process-ucug.svg";
import IconTATProcess from "assets/icons/general/icon-configuration.svg";

// Component
import TATSubCard from "components/card/sub-card/tat-process";
import { ServerErrorBoundary } from "components/general";
import CardWrapper from "components/wrapper/wrapper-card";

// Elements
import CardTitle from "elements/card/card-header/card-title";
import LoadingSpinner from "elements/loading/spinner";
// Integrasi
import { CustomSelect } from "elements/select";
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import {
  getDataTatCity,
  getDataTatDevice,
  getDataTatSales,
  getDataTatProcess,
} from "services/api/private-routes/incoming-application/tatProcess";
import { dateUtility } from "utils/helpers/general/date-time";

const useStyles = makeStyles(() => ({
  gridWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    marginBottom: "10px",
  },
  errorTAT: {
    height: "350px",
  },
  filterWrapper: {
    marginLeft: "auto",
    display: "flex",
    gap: "1rem",
    // flexWrap: "wrap",
  },
  selectWraper: {
    display: "flex",
    gap: "15px",
  },
  selectItemSales: {
    textTransform: "uppercase",
  },
  selectSales: {
    "& .value": {
      textTransform: "uppercase",
    },
  },
}));

const INITIAL_STATE = {
  device: "",
  salesName: "",
  salesArea: "",
};

const CardTatProcess = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [date] = useIncomingFilter();

  const translation = useMemo(
    () => ({
      title: t("CARD.TITLE_TAT_PROCESS"),
      placeholder: {
        city: t("PLACEHOLDER.CITY"),
        device: t("PLACEHOLDER.DEVICE"),
        sales: t("PLACEHOLDER.SALES"),
      },
    }),
    [t],
  );

  const [state, setState] = useState(INITIAL_STATE);
  const [toggle] = useState(translation.title);

  const dataTatProcess = useQuery(
    ["data-tat-process", state, date],
    () =>
      getDataTatProcess({
        ...state,
        start_date: dateUtility(date.start).isoSeparator(),
        end_date: dateUtility(date.end).isoSeparator(),
      }),
    {
      enabled: toggle === translation.title,
      retry: 0,
      keepPreviousData: true,
    },
  );

  const dataTatCity = useQuery(["data-tat-city"], () => getDataTatCity(), {
    retry: 0,
  });

  const dataTatDevice = useQuery(["data-tat-device"], () => getDataTatDevice(), {
    retry: 0,
  });

  const dataTatSales = useQuery(["data-tat-sales"], () => getDataTatSales(), {
    retry: 0,
  });

  const getTatDetailImage = useCallback(item => {
    return (
      {
        merchant_service: IconProsesMerchantService,
        tat_end_to_end: IconProsesTATEndToEnd,
        fmd: IconProsesTATFMD,
        sales: IconSales,
        sales_admin: IconSalesAdmin,
        ucug: IconProsessUcug,
      }[String(item)?.toLowerCase()] || IconSales
    );
  }, []);

  const getTatDetailTitle = useCallback(
    status => {
      return (
        {
          sales: t("BOX.TAT_PROCESS_SALES"),
          sales_admin: t("BOX.TAT_PROCESS_SALES_ADMIN"),
          ucug: t("BOX.TAT_PROCESS_UCUG"),
          merchant_service: t("BOX.TAT_PROCESS_MERCHANT_SERVICE"),
          tat_end_to_end: t("BOX.TAT_PROCESS_TAT_End_To_End"),
          fmd: t("BOX.TAT_PROCESS_TAT_FMD"),
        }[String(status)?.toLowerCase()] || status
      );
    },
    [t],
  );

  const activeQuery = useMemo(() => {
    return {
      [translation.title]: dataTatProcess,
    }[toggle];
  }, [dataTatProcess, toggle, translation]);

  const CONTENT = useMemo(() => {
    const isTAT = {
      sales: false,
      sales_admin: false,
      ucug: false,
      merchant_service: false,
      tat_end_to_end: true,
      fmd: true,
    };
    if (activeQuery?.isFetching) return <LoadingSpinner height={348} />;
    if (activeQuery?.isError)
      return <ServerErrorBoundary onClick={activeQuery?.refetch} title={activeQuery?.error?.message} />;
    if (!activeQuery?.isFetching)
      return (
        <>
          {activeQuery?.data?.parser.map(item => (
            <Grid item xs={4} key={item.title}>
              <TATSubCard
                titleSales={getTatDetailTitle(item.title)}
                days={item.contents.target}
                completedPercent={item.contents.actual_completed}
                iconSrc={getTatDetailImage(item.title)}
                isTat={isTAT[item.title]}
              />
            </Grid>
          ))}
        </>
      );
    return null;
  }, [activeQuery, getTatDetailImage, getTatDetailTitle]);

  return (
    <CardWrapper>
      <Grid container className={classes.gridWrapper}>
        <Grid item xs={4}>
          <CardTitle iconSrc={IconTATProcess} title={translation.title} />
        </Grid>
        <Grid item xs={8}>
          <div className={classes.gridWrapper}>
            <div className={classes.filterWrapper}>
              <div>
                <CustomSelect
                  className={classes.selectDropdown}
                  placeholder={dataTatCity.isFetching ? "Loading..." : translation.placeholder.city}
                  data={dataTatCity?.data?.response ?? []}
                  disabled={dataTatCity.isFetching}
                  setValue={val => setState(x => ({ ...x, salesArea: val }))}
                  renderItem={val => val.name}
                  renderValue={val => val.name}
                  value={state.salesArea}
                />
              </div>
              <div>
                {dataTatDevice.isError && (
                  <ServerErrorBoundary onClick={() => dataTatDevice.refetch()} title={dataTatDevice.error?.message} />
                )}
                {!dataTatDevice.isError && (
                  <CustomSelect
                    className={classes.selectDropdown}
                    placeholder={dataTatDevice.isFetching ? "Loading..." : translation.placeholder.device}
                    data={dataTatDevice?.data?.data ?? []}
                    disabled={dataTatDevice.isFetching}
                    setValue={val => setState(x => ({ ...x, device: val }))}
                    renderItem={val => val.name}
                    renderValue={val => val.name}
                    value={state.device}
                  />
                )}
              </div>
              <div>
                {dataTatSales.isError && <ServerErrorBoundary onClick={() => dataTatSales.refetch()} />}
                {!dataTatSales.isError && (
                  <CustomSelect
                    className={clsx(classes.selectDropdown, classes.selectSales)}
                    placeholder={dataTatSales.isFetching ? "Loading..." : translation.placeholder.sales}
                    data={dataTatSales?.data?.responseSalesName ?? []}
                    disabled={dataTatSales.isFetching}
                    setValue={val => setState(x => ({ ...x, salesName: val }))}
                    renderItem={val => val.name}
                    renderValue={val => val.name}
                    value={state.salesName}
                    classNameItem={classes.selectItemSales}
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {CONTENT}
      </Grid>
    </CardWrapper>
  );
};
export default CardTatProcess;
