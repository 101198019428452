import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const useStyles = makeStyles(() => ({
  tableContainer: {
    "&::-webkit-scrollbar": {
      width: "1px",
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: COLORS.GRAY_MEDIUM,
      borderRadius: 10,
    },
  },
  tableRowMain: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableRowBody: {
    backgroundColor: COLORS.WHITE,
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  cellSizeOther: {
    fontWeight: 400,
    fontSize: SIZES.REGULAR_10,
    minWidth: 115,
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
  },
  cellSizeOtherLeft: {
    fontWeight: 400,
    fontSize: SIZES.REGULAR_10,
    minWidth: 115,
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
    position: "sticky",
    left: 0,
    backgroundColor: "inherit",
  },
  cellSizeMain: {
    fontWeight: 600,
    fontSize: SIZES.REGULAR_14,
    minWidth: 250,
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
    position: "sticky",
    left: 0,
    backgroundColor: "inherit",
  },
  cellSizeFooterFirst: {
    fontWeight: 600,
    fontSize: SIZES.REGULAR_10,
    minWidth: 250,
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
    position: "sticky",
    left: 0,
    backgroundColor: "inherit",
  },
  cellSizeFooter: {
    fontWeight: 600,
    fontSize: SIZES.REGULAR_10,
    minWidth: 250,
    height: 32,
    paddingTop: 4,
    paddingBottom: 4,
  },
}));
