import { useQuery } from "@tanstack/react-query";
import { listSalesName } from "services/api/private-routes/general";

const KEY_SALES_NAME = "list-sales-name";

const useListSales = () => {
  return useQuery([KEY_SALES_NAME], listSalesName, {
    retry: 3,
    refetchOnMount: false,
  });
};

export { useListSales, KEY_SALES_NAME };
