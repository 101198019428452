import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const IncomingIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C4.92339 0 0 4.92339 0 11C0 17.0766 4.92339 22 11 22C17.0766 22 22 17.0766 22 11C22 4.92339 17.0766 0 11 0ZM11 19.871C6.09879 19.871 2.12903 15.9012 2.12903 11C2.12903 6.09879 6.09879 2.12903 11 2.12903C15.9012 2.12903 19.871 6.09879 19.871 11C19.871 15.9012 15.9012 19.871 11 19.871ZM16.7617 10.7294L15.8923 9.86008C15.6794 9.64718 15.3379 9.65161 15.1294 9.86895L12.1532 12.9871V5.85484C12.1532 5.5621 11.9137 5.32258 11.621 5.32258H10.379C10.0863 5.32258 9.84677 5.5621 9.84677 5.85484V12.9871L6.87056 9.86895C6.6621 9.65161 6.32056 9.64718 6.10766 9.86008L5.23831 10.7294C5.02984 10.9379 5.02984 11.275 5.23831 11.4835L10.623 16.8681C10.8315 17.0766 11.1685 17.0766 11.377 16.8681L16.7617 11.4835C16.9702 11.275 16.9702 10.9379 16.7617 10.7294V10.7294Z"
      fill={fill}
    />
  </SvgIcon>
);

IncomingIcon.defaultProp = {
  fill: COLORS.WHITE,
};

IncomingIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IncomingIcon;
