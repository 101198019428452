const selectMerchant = ["Tokopedia", "Bukalapak"];

const selectPeriod = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const bySales = ["Sales"];

const toggleButton = ["Area", "Sales"];

const toggleButtonDetailProfitibility = ["Branding", "Merchant"];

const toogleFilterProfitibility = ["MTD", "YTD"];

const toogleCategoryProfitibility = ["Profit", "MCC"];

export {
  selectMerchant,
  selectPeriod,
  bySales,
  toggleButton,
  toogleFilterProfitibility,
  toogleCategoryProfitibility,
  toggleButtonDetailProfitibility,
};
