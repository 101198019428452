import { Box, IconButton, makeStyles, Modal } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import IconClose from "assets/icons/general/icon-close.svg";
import { ServerErrorBoundary } from "components/general";
import { Button } from "elements/button";
import LoadingSpinner from "elements/loading/spinner";
import Title from "elements/title";
import { getListDownloadable } from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import CardFile from "./card-file";
import { DOWNLOAD_FEATURES } from ".";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "max-content",
  },
  boxStyle: {
    maxWidth: "95vw",
    minWidth: "70vw",
    padding: "26px 20px 15px 20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
  },
  iconButtonBoxStyle: {
    height: "25px",
    width: "25px",
    alignSelf: "end",
  },
  iconStyle: {
    marginRight: "7px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 0",
  },
  listFileWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "40px 0 40px 0",
    minHeight: "380px",
    maxHeight: "70vh",
    overflowX: "auto",
  },
  button: {
    marginLeft: "auto",
  },
}));

const PopupDownload = ({ isOpen, onClose, onSubmit, title, feature, disabledSubmit, showBtnPrint }) => {
  const classes = useStyles();

  const queryDownload = useQuery(["download", feature], () => getListDownloadable({ feature }), {
    retry: 0,
    enabled: isOpen,
    refetchInterval: 1_000 * 2, // 2 Seconds
    keepPreviousData: false,
  });

  const content = useMemo(() => {
    if (queryDownload.isLoading) return <LoadingSpinner />;
    if (queryDownload.isError) return <ServerErrorBoundary />;
    const isSharedable = feature === DOWNLOAD_FEATURES.CALCULATION_PROFIT_AND_LOSS;
    return (
      queryDownload.data?.data.map(val => (
        <CardFile
          key={val.created_date}
          status={val.status}
          fileName={val.filename}
          userName={val.user_name}
          date={val.created_date}
          url={val.url_path ?? undefined}
          printUrl={val.url_print}
          shareUrl={isSharedable && val.url_path}
          downloadId={val.id_download}
          showBtnPrint={showBtnPrint}
        />
      )) ?? null
    );
  }, [queryDownload, feature, showBtnPrint]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.boxStyle}>
        {onClose && (
          <IconButton onClick={onClose} className={classes.iconButtonBoxStyle}>
            <img src={IconClose} alt="icon-close" />
          </IconButton>
        )}
        <Title title={title} align="center" fontWeight={600} />
        <div className={classes.listFileWrapper}>{content}</div>
        <div className={classes.buttonStyle}>
          <Button
            onClick={onSubmit}
            className={classes.button}
            children="Submit"
            size="large"
            disabled={disabledSubmit}
          />
        </div>
      </Box>
    </Modal>
  );
};

PopupDownload.defaultProps = {
  isOpen: false,
  title: "Download File List",
  disabledSubmit: false,
  showBtnPrint: true,
};

PopupDownload.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  feature: PropTypes.string.isRequired,
  disabledSubmit: PropTypes.bool,
  showBtnPrint: PropTypes.bool,
};

export { PopupDownload };
