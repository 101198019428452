import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const TypographyLegend = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_12,
});

const TextLegend = ({ text }) => {
  return <TypographyLegend>{text}</TypographyLegend>;
};

TextLegend.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextLegend;
