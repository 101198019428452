import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const CasaIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.88889 12.2221C4.21399 12.2221 3.66667 12.7694 3.66667 13.4443C3.66667 14.1192 4.21399 14.6666 4.88889 14.6666C5.56378 14.6666 6.11111 14.1192 6.11111 13.4443C6.11111 12.7694 5.56378 12.2221 4.88889 12.2221ZM10.7957 8.5142L11.4159 6.65375C11.4602 6.52198 11.5374 6.41121 11.6039 6.29357C11.4232 6.18816 11.2242 6.111 11 6.111C10.3251 6.111 9.77778 6.65833 9.77778 7.33322C9.77778 7.93593 10.222 8.41375 10.7957 8.5142ZM6.72222 7.94434C6.04733 7.94434 5.5 8.49166 5.5 9.16656C5.5 9.84145 6.04733 10.3888 6.72222 10.3888C7.39712 10.3888 7.94444 9.84145 7.94444 9.16656C7.94444 8.49166 7.39712 7.94434 6.72222 7.94434ZM11 2.44434C4.92479 2.44434 0 7.36913 0 13.4443C0 15.461 0.544271 17.3501 1.49188 18.9749C1.70615 19.3423 2.11444 19.5554 2.53993 19.5554H19.4601C19.8856 19.5554 20.2939 19.3423 20.5081 18.9749C21.4557 17.3501 22 15.461 22 13.4443C22 7.36913 17.0752 2.44434 11 2.44434ZM19.1075 17.7221H2.89247C2.19847 16.4094 1.83333 14.9385 1.83333 13.4443C1.83333 8.38968 5.94535 4.27767 11 4.27767C16.0547 4.27767 20.1667 8.38968 20.1667 13.4443C20.1667 14.9385 19.8015 16.4094 19.1075 17.7221ZM15.8889 13.4443C15.8889 14.1192 16.4362 14.6666 17.1111 14.6666C17.786 14.6666 18.3333 14.1192 18.3333 13.4443C18.3333 12.7694 17.786 12.2221 17.1111 12.2221C16.4362 12.2221 15.8889 12.7694 15.8889 13.4443ZM13.7343 6.46354C13.2485 6.30197 12.7352 6.56361 12.574 7.04295L10.8423 12.2378C9.56733 12.3207 8.55556 13.3702 8.55556 14.6666C8.55556 15.1142 8.68465 15.5278 8.89472 15.8888H13.1057C13.3157 15.5278 13.4448 15.1142 13.4448 14.6666C13.4448 13.9237 13.1064 13.2663 12.5835 12.8179L14.3153 7.62312C14.4745 7.14302 14.2144 6.62357 13.7343 6.46354ZM16.5 9.16656C16.5 8.56309 16.0547 8.08451 15.4794 7.9852C15.4768 7.99323 15.4768 8.00163 15.4741 8.01003L14.7293 10.244C14.8962 10.3296 15.0773 10.3892 15.2778 10.3892C15.9527 10.3888 16.5 9.84145 16.5 9.16656Z"
      fill={fill}
    />
  </SvgIcon>
);

CasaIcon.defaultProp = {
  fill: COLORS.WHITE,
};

CasaIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default CasaIcon;
