export const responseDataBE = {
  response_code: "200",
  response_message: "Success",
  data: {
    sales_volume: {
      on_us: {
        sum: [
          {
            period: "2022-01",
            value: 3817158,
          },
          {
            period: "2022-02",
            value: 11500,
          },
          {
            period: "2022-03",
            value: 31022838,
          },
          {
            period: "2022-04",
            value: 3384399,
          },
          {
            period: "2022-05",
            value: 5429120,
          },
          {
            period: "2022-06",
            value: 82961780,
          },
          {
            period: "2022-07",
            value: 203808310001,
          },
        ],
        credit: [
          {
            period: "2022-01",
            value: 3817158,
          },
          {
            period: "2022-02",
            value: 11500,
          },
          {
            period: "2022-03",
            value: 31022838,
          },
          {
            period: "2022-04",
            value: 3384399,
          },
          {
            period: "2022-05",
            value: 5429120,
          },
          {
            period: "2022-06",
            value: 82604447,
          },
          {
            period: "2022-07",
            value: 190266141753,
          },
        ],
        debit: [
          {
            period: "2022-06",
            value: 357333,
          },
          {
            period: "2022-07",
            value: 13542168248,
          },
        ],
      },
      off_us: {
        sum: [
          {
            period: "2022-02",
            value: 7108362,
          },
          {
            period: "2022-04",
            value: 7790099,
          },
          {
            period: "2022-05",
            value: 73682119,
          },
          {
            period: "2022-06",
            value: 469908751,
          },
          {
            period: "2022-07",
            value: 253931957093,
          },
        ],
        debit_lokal_npg: [
          {
            period: "2022-04",
            value: 11,
          },
          {
            period: "2022-05",
            value: 1983911,
          },
          {
            period: "2022-06",
            value: 18879833,
          },
          {
            period: "2022-07",
            value: 64817283707,
          },
        ],
        others: {
          sum: [
            {
              period: "2022-02",
              value: 7108362,
            },
            {
              period: "2022-04",
              value: 7790088,
            },
            {
              period: "2022-05",
              value: 71698208,
            },
            {
              period: "2022-06",
              value: 451028918,
            },
            {
              period: "2022-07",
              value: 189114673386,
            },
          ],
          lokal: [
            {
              period: "2022-04",
              value: 2201088,
            },
            {
              period: "2022-05",
              value: 16726774,
            },
            {
              period: "2022-06",
              value: 364208387,
            },
            {
              period: "2022-07",
              value: 137070515691,
            },
          ],
          overseas: [
            {
              period: "2022-02",
              value: 7108362,
            },
            {
              period: "2022-04",
              value: 5589000,
            },
            {
              period: "2022-05",
              value: 54971434,
            },
            {
              period: "2022-06",
              value: 86820531,
            },
            {
              period: "2022-07",
              value: 52044157695,
            },
          ],
        },
      },
      qr: {
        sum: [],
        on_us: [],
        off_us: [],
      },
      sum: [
        {
          period: "2022-01",
          value: 3817158,
        },
        {
          period: "2022-02",
          value: 7119862,
        },
        {
          period: "2022-03",
          value: 31022838,
        },
        {
          period: "2022-04",
          value: 11174498,
        },
        {
          period: "2022-05",
          value: 79111239,
        },
        {
          period: "2022-06",
          value: 552870531,
        },
        {
          period: "2022-07",
          value: 457740267094,
        },
      ],
    },
    non_ecomm: {
      sum: [
        {
          period: "2022-02",
          value: 7108362,
        },
        {
          period: "2022-03",
          value: 30784300,
        },
        {
          period: "2022-04",
          value: 5589099,
        },
        {
          period: "2022-05",
          value: 72502984,
        },
        {
          period: "2022-06",
          value: 66452433,
        },
        {
          period: "2022-07",
          value: 239230094324,
        },
      ],
      on_us: {
        sum: [
          {
            period: "2022-03",
            value: 30784300,
          },
          {
            period: "2022-05",
            value: 4114220,
          },
          {
            period: "2022-06",
            value: 1017427,
          },
          {
            period: "2022-07",
            value: 55289617390,
          },
        ],
        debit: [
          {
            period: "2022-06",
            value: 357333,
          },
          {
            period: "2022-07",
            value: 11665589499,
          },
        ],
        credit: [
          {
            period: "2022-03",
            value: 30784300,
          },
          {
            period: "2022-05",
            value: 4114220,
          },
          {
            period: "2022-06",
            value: 660094,
          },
          {
            period: "2022-07",
            value: 43624027891,
          },
        ],
        regular: [
          {
            period: "2022-03",
            value: 30784300,
          },
          {
            period: "2022-05",
            value: 4114220,
          },
          {
            period: "2022-06",
            value: 660094,
          },
          {
            period: "2022-07",
            value: 43624027891,
          },
        ],
        instalment: [],
      },
      off_us: {
        sum: [
          {
            period: "2022-02",
            value: 7108362,
          },
          {
            period: "2022-04",
            value: 5589099,
          },
          {
            period: "2022-05",
            value: 68388764,
          },
          {
            period: "2022-06",
            value: 65435006,
          },
          {
            period: "2022-07",
            value: 183940476934,
          },
        ],
        debit_lokal_npg: [
          {
            period: "2022-04",
            value: 11,
          },
          {
            period: "2022-05",
            value: 85011,
          },
          {
            period: "2022-06",
            value: 674033,
          },
          {
            period: "2022-07",
            value: 60540837551,
          },
        ],
        others: {
          sum: [
            {
              period: "2022-02",
              value: 7108362,
            },
            {
              period: "2022-04",
              value: 5589088,
            },
            {
              period: "2022-05",
              value: 68303753,
            },
            {
              period: "2022-06",
              value: 64760973,
            },
            {
              period: "2022-07",
              value: 123399639383,
            },
          ],
          lokal: [
            {
              period: "2022-04",
              value: 88,
            },
            {
              period: "2022-05",
              value: 15101489,
            },
            {
              period: "2022-06",
              value: 46420722,
            },
            {
              period: "2022-07",
              value: 82374418375,
            },
          ],
          overseas: [
            {
              period: "2022-02",
              value: 7108362,
            },
            {
              period: "2022-04",
              value: 5589000,
            },
            {
              period: "2022-05",
              value: 53202264,
            },
            {
              period: "2022-06",
              value: 18340251,
            },
            {
              period: "2022-07",
              value: 41025221008,
            },
          ],
        },
      },
    },
    ecomm: {
      sum: [
        {
          period: "2022-01",
          value: 3817158,
        },
        {
          period: "2022-02",
          value: 11500,
        },
        {
          period: "2022-03",
          value: 238538,
        },
        {
          period: "2022-04",
          value: 5585399,
        },
        {
          period: "2022-05",
          value: 6608255,
        },
        {
          period: "2022-06",
          value: 486418098,
        },
        {
          period: "2022-07",
          value: 218510172770,
        },
      ],
      on_us: {
        sum: [
          {
            period: "2022-01",
            value: 3817158,
          },
          {
            period: "2022-02",
            value: 11500,
          },
          {
            period: "2022-03",
            value: 238538,
          },
          {
            period: "2022-04",
            value: 3384399,
          },
          {
            period: "2022-05",
            value: 1314900,
          },
          {
            period: "2022-06",
            value: 81944353,
          },
          {
            period: "2022-07",
            value: 148518692611,
          },
        ],
        debit: [
          {
            period: "2022-07",
            value: 1876578749,
          },
        ],
        credit: [
          {
            period: "2022-01",
            value: 3817158,
          },
          {
            period: "2022-02",
            value: 11500,
          },
          {
            period: "2022-03",
            value: 238538,
          },
          {
            period: "2022-04",
            value: 3384399,
          },
          {
            period: "2022-05",
            value: 1314900,
          },
          {
            period: "2022-06",
            value: 81944353,
          },
          {
            period: "2022-07",
            value: 146642113862,
          },
        ],
        regular: [
          {
            period: "2022-01",
            value: 3817158,
          },
          {
            period: "2022-02",
            value: 11500,
          },
          {
            period: "2022-03",
            value: 238538,
          },
          {
            period: "2022-04",
            value: 3384399,
          },
          {
            period: "2022-05",
            value: 1314900,
          },
          {
            period: "2022-06",
            value: 81944353,
          },
          {
            period: "2022-07",
            value: 896787302,
          },
          {
            period: "2022-07",
            value: 145745326560,
          },
        ],
        instalment: [],
      },
      off_us: {
        sum: [
          {
            period: "2022-04",
            value: 2201000,
          },
          {
            period: "2022-05",
            value: 5293355,
          },
          {
            period: "2022-06",
            value: 404473745,
          },
          {
            period: "2022-07",
            value: 69991480159,
          },
        ],
        debit_lokal_npg: [
          {
            period: "2022-05",
            value: 1898900,
          },
          {
            period: "2022-06",
            value: 18205800,
          },
          {
            period: "2022-07",
            value: 4276446156,
          },
        ],
        others: {
          sum: [
            {
              period: "2022-04",
              value: 2201000,
            },
            {
              period: "2022-05",
              value: 3394455,
            },
            {
              period: "2022-06",
              value: 386267945,
            },
            {
              period: "2022-07",
              value: 65715034003,
            },
          ],
          lokal: [
            {
              period: "2022-04",
              value: 2201000,
            },
            {
              period: "2022-05",
              value: 1625285,
            },
            {
              period: "2022-06",
              value: 317787665,
            },
            {
              period: "2022-07",
              value: 54696097316,
            },
          ],
          overseas: [
            {
              period: "2022-05",
              value: 1769170,
            },
            {
              period: "2022-06",
              value: 68480280,
            },
            {
              period: "2022-07",
              value: 11018936687,
            },
          ],
        },
      },
    },
    trx: {
      sum: [
        {
          period: "2022-01",
          value: 3,
        },
        {
          period: "2022-02",
          value: 2,
        },
        {
          period: "2022-03",
          value: 4,
        },
        {
          period: "2022-04",
          value: 7,
        },
        {
          period: "2022-05",
          value: 30,
        },
        {
          period: "2022-06",
          value: 520,
        },
        {
          period: "2022-07",
          value: 844978,
        },
      ],
      non_ecomm: [
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-03",
          value: 1,
        },
        {
          period: "2022-04",
          value: 2,
        },
        {
          period: "2022-05",
          value: 20,
        },
        {
          period: "2022-06",
          value: 34,
        },
        {
          period: "2022-07",
          value: 234764,
        },
      ],
      ecomm: [
        {
          period: "2022-01",
          value: 3,
        },
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-03",
          value: 3,
        },
        {
          period: "2022-04",
          value: 5,
        },
        {
          period: "2022-05",
          value: 10,
        },
        {
          period: "2022-06",
          value: 486,
        },
        {
          period: "2022-07",
          value: 610214,
        },
      ],
      qr: {
        sum: [],
        on_us: [],
        off_us: [],
      },
    },
    trx_on_us: {
      sum: [
        {
          period: "2022-01",
          value: 3,
        },
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-03",
          value: 4,
        },
        {
          period: "2022-04",
          value: 3,
        },
        {
          period: "2022-05",
          value: 8,
        },
        {
          period: "2022-06",
          value: 81,
        },
        {
          period: "2022-07",
          value: 191587,
        },
      ],
      credit: [
        {
          period: "2022-01",
          value: 3,
        },
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-03",
          value: 4,
        },
        {
          period: "2022-04",
          value: 3,
        },
        {
          period: "2022-05",
          value: 8,
        },
        {
          period: "2022-06",
          value: 75,
        },
        {
          period: "2022-07",
          value: 166465,
        },
      ],
      debit: [
        {
          period: "2022-06",
          value: 6,
        },
        {
          period: "2022-07",
          value: 25122,
        },
      ],
    },
    trx_off_us: {
      sum: [
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-04",
          value: 5,
        },
        {
          period: "2022-05",
          value: 24,
        },
        {
          period: "2022-06",
          value: 448,
        },
        {
          period: "2022-07",
          value: 779616,
        },
      ],
      debit_lokal_npg: [
        {
          period: "2022-04",
          value: 1,
        },
        {
          period: "2022-05",
          value: 3,
        },
        {
          period: "2022-06",
          value: 29,
        },
        {
          period: "2022-07",
          value: 226027,
        },
      ],
      others: {
        sum: [
          {
            period: "2022-02",
            value: 1,
          },
          {
            period: "2022-04",
            value: 4,
          },
          {
            period: "2022-05",
            value: 21,
          },
          {
            period: "2022-06",
            value: 419,
          },
          {
            period: "2022-07",
            value: 553589,
          },
        ],
        lokal: [
          {
            period: "2022-04",
            value: 3,
          },
          {
            period: "2022-05",
            value: 12,
          },
          {
            period: "2022-06",
            value: 342,
          },
          {
            period: "2022-07",
            value: 440335,
          },
        ],
        overseas: [
          {
            period: "2022-02",
            value: 1,
          },
          {
            period: "2022-04",
            value: 1,
          },
          {
            period: "2022-05",
            value: 9,
          },
          {
            period: "2022-06",
            value: 77,
          },
          {
            period: "2022-07",
            value: 113254,
          },
        ],
      },
    },
    trx_on_us_off_us_percentage: {
      on_us: [
        {
          period: "2022-01",
          value: 3,
        },
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-03",
          value: 4,
        },
        {
          period: "2022-04",
          value: 3,
        },
        {
          period: "2022-05",
          value: 8,
        },
        {
          period: "2022-06",
          value: 81,
        },
        {
          period: "2022-07",
          value: 191587,
        },
      ],
      off_us: [
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-04",
          value: 5,
        },
        {
          period: "2022-05",
          value: 24,
        },
        {
          period: "2022-06",
          value: 448,
        },
        {
          period: "2022-07",
          value: 779616,
        },
      ],
      on_us_percentage: [
        {
          period: "2022-01",
          value: 1,
        },
        {
          period: "2022-02",
          value: 0.5,
        },
        {
          period: "2022-03",
          value: 1,
        },
        {
          period: "2022-04",
          value: 0.43,
        },
        {
          period: "2022-05",
          value: 0.27,
        },
        {
          period: "2022-06",
          value: 0.16,
        },
        {
          period: "2022-07",
          value: 0.23,
        },
      ],
      off_us_percentage: [
        {
          period: "2022-02",
          value: 0.5,
        },
        {
          period: "2022-04",
          value: 0.71,
        },
        {
          period: "2022-05",
          value: 0.8,
        },
        {
          period: "2022-06",
          value: 0.86,
        },
        {
          period: "2022-07",
          value: 0.92,
        },
      ],
    },
    sales_volume_principal: {
      sum: [
        {
          period: "2022-01",
          value: 3817158,
        },
        {
          period: "2022-02",
          value: 7119862,
        },
        {
          period: "2022-03",
          value: 31022838,
        },
        {
          period: "2022-04",
          value: 11174498,
        },
        {
          period: "2022-05",
          value: 79196239,
        },
        {
          period: "2022-06",
          value: 558355453,
        },
        {
          period: "2022-07",
          value: 475926032274,
        },
      ],
      visa: [
        {
          period: "2022-01",
          value: 3687358,
        },
        {
          period: "2022-02",
          value: 7108362,
        },
        {
          period: "2022-04",
          value: 7738700,
        },
        {
          period: "2022-05",
          value: 18616149,
        },
        {
          period: "2022-06",
          value: 344464653,
        },
        {
          period: "2022-07",
          value: 143243917590,
        },
      ],
      visa_npg: [
        {
          period: "2022-05",
          value: 1898911,
        },
        {
          period: "2022-06",
          value: 9287800,
        },
        {
          period: "2022-07",
          value: 23336513208,
        },
      ],
      mastercard: [
        {
          period: "2022-01",
          value: 129800,
        },
        {
          period: "2022-02",
          value: 11500,
        },
        {
          period: "2022-03",
          value: 31022838,
        },
        {
          period: "2022-04",
          value: 3384399,
        },
        {
          period: "2022-05",
          value: 56741921,
        },
        {
          period: "2022-06",
          value: 188619994,
        },
        {
          period: "2022-07",
          value: 172636883077,
        },
      ],
      mastercard_npg: [
        {
          period: "2022-04",
          value: 11,
        },
        {
          period: "2022-05",
          value: 170000,
        },
        {
          period: "2022-06",
          value: 15076955,
        },
        {
          period: "2022-07",
          value: 59666535679,
        },
      ],
      jcb: [
        {
          period: "2022-04",
          value: 51388,
        },
        {
          period: "2022-05",
          value: 1769258,
        },
        {
          period: "2022-06",
          value: 906051,
        },
        {
          period: "2022-07",
          value: 77042182720,
        },
      ],
      jcb_npg: [],
    },
    no_of_trx_principal: {
      sum: [
        {
          period: "2022-01",
          value: 3,
        },
        {
          period: "2022-02",
          value: 2,
        },
        {
          period: "2022-03",
          value: 4,
        },
        {
          period: "2022-04",
          value: 8,
        },
        {
          period: "2022-05",
          value: 33,
        },
        {
          period: "2022-06",
          value: 539,
        },
        {
          period: "2022-07",
          value: 1047985,
        },
      ],
      visa: [
        {
          period: "2022-01",
          value: 2,
        },
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-04",
          value: 2,
        },
        {
          period: "2022-05",
          value: 13,
        },
        {
          period: "2022-06",
          value: 326,
        },
        {
          period: "2022-07",
          value: 359700,
        },
      ],
      visa_npg: [
        {
          period: "2022-05",
          value: 2,
        },
        {
          period: "2022-06",
          value: 15,
        },
        {
          period: "2022-07",
          value: 56408,
        },
      ],
      mastercard: [
        {
          period: "2022-01",
          value: 1,
        },
        {
          period: "2022-02",
          value: 1,
        },
        {
          period: "2022-03",
          value: 4,
        },
        {
          period: "2022-04",
          value: 3,
        },
        {
          period: "2022-05",
          value: 14,
        },
        {
          period: "2022-06",
          value: 164,
        },
        {
          period: "2022-07",
          value: 370180,
        },
      ],
      mastercard_npg: [
        {
          period: "2022-04",
          value: 1,
        },
        {
          period: "2022-05",
          value: 2,
        },
        {
          period: "2022-06",
          value: 24,
        },
        {
          period: "2022-07",
          value: 246401,
        },
      ],
      jcb: [
        {
          period: "2022-04",
          value: 2,
        },
        {
          period: "2022-05",
          value: 2,
        },
        {
          period: "2022-06",
          value: 10,
        },
        {
          period: "2022-07",
          value: 15296,
        },
      ],
      jcb_npg: [],
    },
    net_revenue: {
      sum: [
        {
          period: "2022-01",
          value: 45805.89,
        },
        {
          period: "2022-02",
          value: 106763.43,
        },
        {
          period: "2022-03",
          value: 49038.9,
        },
        {
          period: "2022-04",
          value: 172839.45,
        },
        {
          period: "2022-05",
          value: 1107132.28,
        },
        {
          period: "2022-06",
          value: 9230964.88,
        },
        {
          period: "2022-07",
          value: 7070150690.71,
        },
      ],
      non_ecomm: [
        {
          period: "2022-02",
          value: 106625.43,
        },
        {
          period: "2022-03",
          value: 46176.45,
        },
        {
          period: "2022-04",
          value: 95014.87,
        },
        {
          period: "2022-05",
          value: 1011829.67,
        },
        {
          period: "2022-06",
          value: 1005271.86,
        },
        {
          period: "2022-07",
          value: 3554689246.95,
        },
      ],
      ecomm: [
        {
          period: "2022-01",
          value: 45805.89,
        },
        {
          period: "2022-02",
          value: 138,
        },
        {
          period: "2022-03",
          value: 2862.45,
        },
        {
          period: "2022-04",
          value: 77824.58,
        },
        {
          period: "2022-05",
          value: 95302.61,
        },
        {
          period: "2022-06",
          value: 8225693.02,
        },
        {
          period: "2022-07",
          value: 3515461443.76,
        },
      ],
    },
    casa: {
      ending_balance: [
        {
          period: "2022-10",
          value: 1500000,
        },
      ],
      avg_balance: [
        {
          period: "2022-10",
          value: 100000,
        },
      ],
    },
    casa_non_ecomm: {
      ending_balance: [],
      avg_balance: [],
    },
    casa_ecomm: {
      ending_balance: [
        {
          period: "2022-10",
          value: 1500000,
        },
      ],
      avg_balance: [
        {
          period: "2022-10",
          value: 100000,
        },
      ],
    },
    shadow_nii: {
      sum: [
        {
          period: "2022-10",
          value: 200000000,
        },
      ],
      ecomm: [
        {
          period: "2022-10",
          value: 200000000,
        },
      ],
      non_ecomm: [],
    },
    tid: {
      sum: [
        {
          period: "2022-09",
          value: 19370000,
        },
      ],
      edc: [
        {
          period: "2022-09",
          value: 190000,
        },
      ],
      qr: [
        {
          period: "2022-09",
          value: 180000,
        },
      ],
      ecom: [
        {
          period: "2022-09",
          value: 19000000,
        },
      ],
    },
    merchant: {
      sum: [
        {
          period: "2022-09",
          value: 56800000,
        },
      ],
      non_ecom: [
        {
          period: "2022-09",
          value: 18000000,
        },
      ],
      qr: [
        {
          period: "2022-09",
          value: 18000000,
        },
      ],
      bukalapak: [
        {
          period: "2022-09",
          value: 19000000,
        },
      ],
      ecom: [
        {
          period: "2022-09",
          value: 1800000,
        },
      ],
    },
    noii: {
      mdr_revenue_before_contra: [
        {
          period: "2022-09",
          value: 1900000,
        },
      ],
      mdr_revenue_carry_forward: [
        {
          period: "2022-09",
          value: 1000000,
        },
      ],
      contra_revenue: [
        {
          period: "2022-09",
          value: -34100000,
        },
      ],
      mdr_revenue_cognos: [
        {
          period: "2022-09",
          value: 18000000,
        },
      ],
      mdr_npg: [
        {
          period: "2022-09",
          value: 18000000,
        },
      ],
      other_revenue: [
        {
          period: "2022-09",
          value: 1800987,
        },
      ],
      jadit: [
        {
          period: "2022-09",
          value: 1888888,
        },
      ],
      interchange: [
        {
          period: "2022-09",
          value: 18800000,
        },
      ],
      visa: [
        {
          period: "2022-09",
          value: 17900000,
        },
      ],
      mcbs: [],
      migs: [
        {
          period: "2022-09",
          value: 17000000,
        },
      ],
      migs_trx: [
        {
          period: "2022-09",
          value: 17600000,
        },
      ],
      jcb: [
        {
          period: "2022-09",
          value: 16888888,
        },
      ],
      revenue_hardcash_cognos: [
        {
          period: "2022-09",
          value: 93278763,
        },
      ],
      revenue_real_hardcash: [
        {
          period: "2022-09",
          value: 17688888,
        },
      ],
      accrual_current_year: {
        sum: [
          {
            period: "2022-09",
            value: 2084657777,
          },
        ],
        cf_cost: [
          {
            period: "2022-09",
            value: 17777777,
          },
        ],
        accrual: [
          {
            period: "2022-09",
            value: 1888880000,
          },
        ],
        acc_mov: [
          {
            period: "2022-09",
            value: 178000000,
          },
        ],
      },
      accrual_last_year: [],
      total_acc: [
        {
          period: "2022-09",
          value: 2084657777,
        },
      ],
    },
    revenue_after_shadow: [
      {
        period: "2022-09",
        value: 93278763,
      },
      {
        period: "2022-10",
        value: 200000000,
      },
    ],
    opex: {
      communication_and_utilities: [
        {
          period: "2022-09",
          value: 74356590,
        },
      ],
      deprecation_and_amortization: [
        {
          period: "2022-09",
          value: 317733015,
        },
      ],
      insurance: [
        {
          period: "2022-09",
          value: 0,
        },
      ],
      legal_and_professional_fee: [
        {
          period: "2022-09",
          value: 0,
        },
      ],
      office_activity: [
        {
          period: "2022-09",
          value: 1156200,
        },
      ],
      office_stationary_and_supply: [
        {
          period: "2022-09",
          value: 30714177,
        },
      ],
      others: [
        {
          period: "2022-09",
          value: 0,
        },
      ],
      out_sourcing: [
        {
          period: "2022-09",
          value: 407307194,
        },
      ],
      rental: [
        {
          period: "2022-09",
          value: 0,
        },
      ],
      repair_and_maintenance: [
        {
          period: "2022-09",
          value: 1128609668,
        },
      ],
      tax: [
        {
          period: "2022-09",
          value: 0,
        },
      ],
      travel_and_transportation: [
        {
          period: "2022-09",
          value: 22626990,
        },
      ],
      employee_activity: [
        {
          period: "2022-09",
          value: 1780779,
        },
      ],
      it_related: [
        {
          period: "2022-09",
          value: 7956510435,
        },
      ],
      dakom: [
        {
          period: "2022-09",
          value: 1679412650,
        },
      ],
      it_maintenance: [
        {
          period: "2022-09",
          value: 6277097785,
        },
      ],
    },
    total_ga: [
      {
        period: "2022-09",
        value: 9940795048,
      },
    ],
    personal_expenses: [
      {
        period: "2022-09",
        value: 2392863032,
      },
    ],
    promotion_expenses: [
      {
        period: "2022-09",
        value: 218494080,
      },
    ],
    total_opex: [
      {
        period: "2022-09",
        value: 12552152160,
      },
    ],
  },
};

export const filterListDropdown = [
  {
    description: "Sales Volume",
    id: 1,
    name: "Sales Volume",
  },
  {
    description: "Non Ecomm",
    id: 2,
    name: "Non Ecomm",
  },
  {
    description: "Ecomm",
    id: 3,
    name: "Ecomm",
  },
  {
    description: "tid",
    id: 4,
    name: "#TID",
  },
  {
    description: "Trx",
    id: 5,
    name: "Trx",
  },
  {
    description: "Trx On Us",
    id: 6,
    name: "Trx On Us",
  },
  {
    description: "Trx Off Us",
    id: 7,
    name: "Trx Off Us",
  },
  {
    description: "#Merchant",
    id: 8,
    name: "#Merchant",
  },
  {
    description: "Trx On Us Off Us %",
    id: 9,
    name: "Trx On Us Off Us %",
  },
  {
    description: "Sales Volume by Principal",
    id: 10,
    name: "Sales Volume by Principal",
  },
  {
    description: "No Of TRX by Principal",
    id: 11,
    name: "No Of TRX by Principal",
  },
  {
    description: "Net Revenue",
    id: 12,
    name: "Net Revenue",
  },
  {
    description: "NOII",
    id: 13,
    name: "NOII",
  },
  {
    description: "CASA",
    id: 14,
    name: "CASA",
  },
  {
    description: "CASA Non Ecomm",
    id: 15,
    name: "CASA Non Ecomm",
  },
  {
    description: "CASA Ecomm",
    id: 16,
    name: "CASA Ecomm",
  },
  {
    description: "Shadow Nii",
    id: 17,
    name: "Shadow Nii",
  },
  {
    description: "Revenue After Shadow",
    id: 18,
    name: "Revenue After Shadow",
  },
  {
    description: "Opex",
    id: 19,
    name: "Opex",
  },
  {
    description: "Total GA",
    id: 20,
    name: "Total GA",
  },
  {
    description: "Total Opex",
    id: 21,
    name: "Total Opex",
  },
];
