/* eslint-disable react/jsx-no-bind */
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "assets/icons/general/icon-download.svg";
import DatePickerComp from "components/datepicker";
import { PopupDownload, DOWNLOAD_FEATURES } from "components/popup/popup-download";
import { Button } from "elements/button";
import { CardBase } from "elements/card";

const CardFilterProfitabilityByMerchant = ({ filter, onChange, onSubmitExcelDownload }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dateNow = new Date();
  const yearAgo = useMemo(() => new Date().setFullYear(new Date().getFullYear() - 1), []);
  const [count, setCount] = useState(0);

  const [popupStatus, setPopupStatus] = useState(false);

  function togglePopup() {
    setPopupStatus(prev => !prev);
  }

  function handleChange(e) {
    setCount(prev => prev + 1);
    if (count > 0) {
      onChange(e);
      setCount(0);
    }
  }

  return (
    <CardBase className={classes.root}>
      <DatePickerComp
        onChange={handleChange}
        value={filter.monthly}
        minDate={yearAgo}
        maxDate={dateNow}
        views={["year", "month"]}
        format="MM/YYYY"
        placeholder="MM/YYYY"
        onClose={() => setCount(0)}
      />
      <Button variant="primary" onClick={() => togglePopup()} defaultButton>
        <img width={16} height={16} src={DownloadIcon} alt={`${DownloadIcon}`} />
        <span style={{ marginLeft: 8 }}>{t("GENERAL.DOWNLOAD_EXCEL")}</span>
      </Button>
      <PopupDownload
        isOpen={popupStatus}
        onClose={() => togglePopup()}
        feature={DOWNLOAD_FEATURES.DETAIL_BRANDING}
        onSubmit={onSubmitExcelDownload}
        showBtnPrint={false}
      />
    </CardBase>
  );
};

CardFilterProfitabilityByMerchant.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmitExcelDownload: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default CardFilterProfitabilityByMerchant;
