const headerText = [
  { text: "Incoming Application", url: "dashboard-incoming-application" },
  {
    text: "Portfolio Management Dashboard",
    url: "dashboard-portofolio",
  },
  {
    text: "Sales Volume Portfolio Dashboard",
    url: "dashboard-portofolio-sales-volume",
  },
  { text: "Casa Portfolio Dashboard", url: "dashboard-portofolio-by-casa" },
  { text: "Better Worse Merchant", url: "dashboard-worse-better-merchant" },
  { text: "Merchant Transaction Detail", url: "dashboard-detail-transaction" },
  {
    text: "Revenue Detail by Branding",
    url: "dashboard-detail-revenue-by-branding",
  },
  {
    text: "EDC Profitability",
    url: "dashboard-profitability",
  },
  {
    text: "EDC Profitability",
    url: "terminology",
  },
  {
    text: "EDC Profitability",
    url: "dashboard-profitability-detail",
  },
  {
    text: "EDC Profitability",
    url: "dashboard-profitability-by-merchant",
  },
  { text: "Executive Summary", url: "dashboard-executive-summary" },
  { text: "Calculation Profit Loss", url: "calculation-profit-loss" },
  { text: "User Management", url: "user-management" },
];

const contentsPieChart = {
  siteCode: "40 Application",
  title: "Application",
  textPercent: "50.5%",
  valuePercent: 0.5,
};

const dataPercentPie = [
  { type: "Completed", percent: 0.705 },
  { type: "Uncompleted", percent: 0.295 },
];

const legendChartLine = {
  incoming: 47,
  process: 40,
  approve: 5,
  deploy: 2,
};

const dataChartLine = [
  {
    day: "1",
    incoming: 80,
    approve: 100,
    process: 120,
    deploy: 170,
    marked: false,
  },
  {
    day: "2",
    incoming: 100,
    approve: 120,
    process: 150,
    deploy: 200,
    marked: true,
  },
  {
    day: "3",
    incoming: 130,
    approve: 150,
    process: 160,
    deploy: 210,
    marked: false,
  },
  {
    day: "4",
    incoming: 150,
    approve: 150,
    process: 200,
    deploy: 250,
    marked: false,
  },
  {
    day: "5",
    incoming: 170,
    approve: 170,
    process: 220,
    deploy: 270,
    marked: true,
  },
  {
    day: "6",
    incoming: 190,
    approve: 200,
    process: 240,
    deploy: 290,
    marked: false,
  },
  {
    day: "7",
    incoming: 210,
    approve: 190,
    process: 220,
    deploy: 270,
    marked: true,
  },
  {
    day: "8",
    incoming: 230,
    approve: 230,
    process: 240,
    deploy: 290,
    marked: true,
  },
  {
    day: "9",
    incoming: 190,
    approve: 180,
    process: 210,
    deploy: 250,
    marked: false,
  },
  {
    day: "10",
    incoming: 200,
    approve: 210,
    process: 220,
    deploy: 260,
    marked: false,
  },
  {
    day: "11",
    incoming: 210,
    approve: 220,
    process: 230,
    deploy: 280,
    marked: true,
  },
  {
    day: "12",
    incoming: 220,
    approve: 230,
    process: 240,
    deploy: 290,
    marked: false,
  },
  {
    day: "13",
    incoming: 230,
    approve: 250,
    process: 260,
    deploy: 300,
    marked: false,
  },
  {
    day: "14",
    incoming: 250,
    approve: 270,
    process: 280,
    deploy: 310,
    marked: true,
  },
  {
    day: "15",
    incoming: 260,
    approve: 280,
    process: 300,
    deploy: 320,
    marked: false,
  },
  {
    day: "16",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "17",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "18",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "19",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "20",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "21",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "22",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "23",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "24",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "25",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "26",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "27",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "28",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "29",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "30",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
  {
    day: "31",
    incoming: 270,
    approve: 280,
    process: 290,
    deploy: 330,
    marked: true,
  },
];

const barChartColumn = [
  { name: "Mohammad Reza Y", value: 50, marked: 1 },
  { name: "Ahmad Wahyu", value: 45, marked: 1 },
  { name: "Winda A", value: 43, marked: 1 },
  { name: "Desy Annisa", value: 35, marked: 1 },
  { name: "Wahyu Habiyyu", value: 30, marked: 2 },
  { name: "Anfika Sigma", value: 25, marked: 2 },
  { name: "Aditya Mahavira", value: 26, marked: 2 },
  { name: "Rizky Dasa M", value: 24, marked: 3 },
  { name: "Helfah A", value: 20, marked: 3 },
  { name: "Wahyudi", value: 10, marked: 3 },
];

const barChartColumn2 = [
  { name: "Mohammad Reza Y 2", value: 50, marked: 1 },
  { name: "Ahmad Wahyu", value: 45, marked: 1 },
  { name: "Winda A", value: 43, marked: 1 },
  { name: "Desy Annisa", value: 35, marked: 1 },
  { name: "Wahyu Habiyyu", value: 30, marked: 2 },
  { name: "Anfika Sigma", value: 25, marked: 2 },
  { name: "Aditya Mahavira", value: 26, marked: 2 },
  { name: "Rizky Dasa M", value: 24, marked: 3 },
  { name: "Helfah A", value: 20, marked: 3 },
  { name: "Wahyudi 2", value: 10, marked: 3 },
];
const portfolioChartBar = [
  { name: "Current Month RR", value: 50, marked: 1 },
  { name: "Top Division", value: 30, marked: 2 },
  { name: "Top Area Coordinator", value: 24, marked: 3 },
];
const portfolioChartBar2 = [
  { name: "Jan 22", total: 50, marked: 1 },
  { name: "Feb 22", total: 30, marked: 2 },
  { name: "Mar 22", total: 24, marked: 3 },
];

const dataListTopIncoming = [
  { id: 1, name: "Mohammad Reza Yazir", sum: "800" },
  { id: 2, name: "Dimpos Simbagariang", sum: "700" },
  { id: 3, name: "Dimpos Simbagariang", sum: "700" },
  { id: 4, name: "Dimpos Simbagariang", sum: "700" },
  { id: 5, name: "Dimpos Simbagariang", sum: "700" },
];

const dataListBackToSales = [
  { id: 1, name: "KTP", sum: "799" },
  { id: 2, name: "NPWP", sum: "531" },
  { id: 3, name: "Negatif BI Checking", sum: "286" },
  { id: 4, name: "Negatif AKKI Checking", sum: "250" },
  { id: 5, name: "Negatif", sum: "117" },
];

const dataListTopArea = [
  {
    id: 1,
    name: "Mohammad Reza Yazir",
    percen: "97,89%",
    sum: "799",
    percenNum: 1,
  },
  {
    id: 2,
    name: "Dimpos Simbagariang",
    percen: "97,89%",
    sum: "531",
    percenNum: 0.8,
  },
  {
    id: 3,
    name: "Mohammad Reza Yazir",
    percen: "97,89%",
    sum: "286",
    percenNum: 0.9,
  },
  {
    id: 4,
    name: "Dimpos Simbagariang",
    percen: "97,89%",
    sum: "250",
    percenNum: 0.8,
  },
  {
    id: 5,
    name: "Dimpos Simbagariang",
    percen: "97,89%",
    sum: "117",
    percenNum: 0.8,
  },
];

const dummySelectSales = [
  {
    key: "Linda Pasodi",
    value: "Linda Pasodi",
    label: "Linda Pasodi",
  },
  {
    key: "Linda Pasodim",
    value: "Linda Pasodim",
    label: "Linda Pasodim",
  },
  {
    key: "Posdim Linda",
    value: "Posdim Linda",
    label: "Posdim Linda",
  },
];

const dummySelectTATCity = [
  {
    key: "city1",
    value: "Jakarta",
    label: "Jakarta",
  },
  {
    key: "city2",
    value: "Jakarta 2",
    label: "Jakarta 2",
  },
  {
    key: "city3",
    value: "Jakarta 3",
    label: "Jakarta 3",
  },
  {
    key: "city4",
    value: "Jakarta 4",
    label: "Jakarta 4 ",
  },
];

const dummySelectTATDevice = [
  {
    key: "edc",
    value: "EDC",
    label: "EDC",
  },
  {
    key: "qr",
    value: "QR",
    label: "QR",
  },
];

const dummySelectTATSales = [
  {
    key: "sales_1",
    value: "Sales 1",
    label: "Sales 1",
  },
  {
    key: "sales_2",
    value: "Sales 2",
    label: "Sales 2",
  },
];

const dummySelectArea = [
  {
    key: "BA001 - JAKARTA",
    value: "BA001 - JAKARTA",
    label: "BA001 - JAKARTA",
  },
  {
    key: "BA002 - JAKARTA 1",
    value: "BA002 - JAKARTA 1",
    label: "BA002 - JAKARTA 1",
  },
  {
    key: "BA003 - JAKARTA 2",
    value: "BA003 - JAKARTA 2",
    label: "BA003 - JAKARTA 2",
  },
];

const dummySelectBussinesType = [
  {
    key: "5812 - Eating places and Restaurants1",
    value: "5812 - Eating places and Restaurants 1",
    label: "5812 - Eating places and Restaurants 1",
  },
  {
    key: "5813 - Hotel",
    value: "5813 - Hotel",
    label: "5813 - Hotel",
  },
  {
    key: "5813 - Swalayan",
    value: "5813 - Swalayan",
    label: "5813 - Swalayan",
  },
];

const dummySelectStatus = [
  {
    key: "Incoming",
    value: "Incoming",
    label: "Incoming",
  },
  {
    key: "Approve",
    value: "Approve",
    label: "Approve",
  },
  {
    key: "Process",
    value: "Process",
    label: "Process",
  },
  {
    key: "Delpoy",
    value: "Delpoy",
    label: "Delpoy",
  },
];

const dataProcessOnboardingMerchant = {
  dataOnboarding: [
    {
      label: "Incoming",
      amount: 55000,
    },
    {
      label: "Process",
      amount: 55000,
    },
    {
      label: "Approve",
      amount: 55000,
    },
    {
      label: "Deploy",
      amount: 55000,
    },
    {
      label: "Reject",
      amount: 55000,
    },
    {
      label: "Back To Sales",
      amount: 55000,
    },
  ],
  dataChart: {
    pieProcess: {
      siteCode: "5",
      title: "Application",
      textPercent: 25.6,
      valuePercent: 0.256,
    },
    pieApprove: {
      siteCode: "40",
      title: "Application",
      textPercent: 70.5,
      valuePercent: 0.7,
    },
    pieDeploy: {
      siteCode: "2",
      title: "Application",
      textPercent: 5.8,
      valuePercent: 0.058,
    },
  },
};

const dataChartMonthly = [
  {
    dates: "1",
    status: "Incoming",
    amounts: 7,
  },
  {
    dates: "1",
    status: "Approve",
    amounts: 3.9,
  },
  {
    dates: "1",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "1",
    status: "Deploy",
    amounts: 6.9,
  },
  {
    dates: "2",
    status: "Incoming",
    amounts: 4.2,
  },
  {
    dates: "2",
    status: "Approve",
    amounts: 10,
  },
  {
    dates: "2",
    status: "Process",
    amounts: 4.2,
  },
  {
    dates: "2",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "3",
    status: "Incoming",
    amounts: 9.5,
  },
  {
    dates: "3",
    status: "Approve",
    amounts: 9.5,
  },
  {
    dates: "3",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "3",
    status: "Deploy",
    amounts: 5.9,
  },
  {
    dates: "4",
    status: "Incoming",
    amounts: 14.5,
  },
  {
    dates: "4",
    status: "Approve",
    amounts: 8.5,
  },
  {
    dates: "4",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "4",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "5",
    status: "Incoming",
    amounts: 18.4,
  },
  {
    dates: "5",
    status: "Approve",
    amounts: 11.9,
  },
  {
    dates: "5",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "5",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "6",
    status: "Incoming",
    amounts: 21.5,
  },
  {
    dates: "6",
    status: "Approve",
    amounts: 15.2,
  },
  {
    dates: "6",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "6",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "7",
    status: "Incoming",
    amounts: 25.2,
  },
  {
    dates: "7",
    status: "Approve",
    amounts: 17,
  },
  {
    dates: "7",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "7",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "8",
    status: "Incoming",
    amounts: 26.5,
  },
  {
    dates: "8",
    status: "Approve",
    amounts: 16.6,
  },
  {
    dates: "8",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "8",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "9",
    status: "Incoming",
    amounts: 23.3,
  },
  {
    dates: "9",
    status: "Approve",
    amounts: 14.2,
  },
  {
    dates: "9",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "9",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "10",
    status: "Incoming",
    amounts: 18.3,
  },
  {
    dates: "10",
    status: "Approve",
    amounts: 10.3,
  },
  {
    dates: "10",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "10",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "11",
    status: "Incoming",
    amounts: 13.9,
  },
  {
    dates: "11",
    status: "Approve",
    amounts: 6.6,
  },
  {
    dates: "11",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "11",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "12",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "12",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "12",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "12",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "13",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "13",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "13",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "13",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "14",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "14",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "14",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "14",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "15",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "15",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "15",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "15",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "16",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "16",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "16",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "16",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "17",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "17",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "17",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "17",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "18",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "18",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "18",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "18",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "19",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "19",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "19",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "19",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "20",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "20",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "20",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "20",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "21",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "21",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "21",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "21",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "22",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "22",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "22",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "22",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "23",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "23",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "23",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "23",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "24",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "24",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "24",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "24",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "25",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "25",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "25",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "25",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "26",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "26",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "26",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "26",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "27",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "27",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "27",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "27",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "28",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "28",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "28",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "28",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "29",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "29",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "29",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "29",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "30",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "30",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "30",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "30",
    status: "Deploy",
    amounts: 19,
  },
  {
    dates: "31",
    status: "Incoming",
    amounts: 9.6,
  },
  {
    dates: "31",
    status: "Approve",
    amounts: 4.8,
  },
  {
    dates: "31",
    status: "Process",
    amounts: 10,
  },
  {
    dates: "31",
    status: "Deploy",
    amounts: 19,
  },
];

const tatProcessDummy = [
  {
    title: "sales",
    day: 1,
    percentage: 30,
  },
  {
    title: "sales_admin",
    day: 1,
    percentage: 90,
  },
  {
    title: "ucug",
    day: 3,
    percentage: 20,
  },
  {
    title: "merchant_service",
    day: 2,
    percentage: 90,
  },
  {
    title: "tat_end_to_end",
    day: 7,
    percentage: 90,
  },
  {
    title: "tat_mfd",
    day: 7,
    percentage: 90,
  },
];

export {
  headerText,
  contentsPieChart,
  dataPercentPie,
  legendChartLine,
  dataChartLine,
  barChartColumn,
  barChartColumn2,
  portfolioChartBar,
  portfolioChartBar2,
  dataListBackToSales,
  dataListTopArea,
  dummySelectSales,
  dummySelectTATCity,
  dummySelectTATDevice,
  dummySelectTATSales,
  dummySelectArea,
  dummySelectBussinesType,
  dummySelectStatus,
  dataProcessOnboardingMerchant,
  dataChartMonthly,
  tatProcessDummy,
  dataListTopIncoming,
};
