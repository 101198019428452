/* eslint-disable no-plusplus */
export const getListDropdownYear = async () => {
  const arrYear = [];
  let yearNow = new Date().getFullYear();
  for (let i = 3; i >= 1; i--) {
    arrYear.push(yearNow);
    yearNow -= 1;
  }
  return { data: arrYear };
};
