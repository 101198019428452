import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDown } from "assets/icons/dashboard/icon-table-down.svg";
import { ReactComponent as IconUp } from "assets/icons/dashboard/icon-table-up.svg";
import { ReactComponent as IconStripe } from "assets/icons/general/icon-stripe.svg";

import { useStyles } from "./styled";

const TableSalesVolume = ({ headers, mainData, totalData, mainHead }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const iconPicker = number => {
    if (number >= 100) return <IconUp />;
    else if (number >= 90) return <IconStripe />;
    else return <IconDown />;
  };

  const tableHeadContent = useMemo(() => {
    return (
      <TableHead>
        <TableRow className={classes.tableRowMain}>
          <TableCell className={classes.cellSizeMain}>{mainHead ?? "-"}</TableCell>
          {headers?.map(name => (
            <TableCell className={classes.cellSizeOther} align="center" key={name}>
              {name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }, [headers, classes, mainHead]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        {tableHeadContent}
        <TableBody>
          {mainData.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow className={classes.tableRowBody} key={item?.name + i}>
              {Object.entries(item).map(([key, val]) => {
                const className = key === "name" ? classes.cellSizeOtherLeft : classes.cellSizeOther;
                const align = key === "name" ? "left" : "center";
                return (
                  <TableCell className={className} align={align} key={key}>
                    {["run_rate_percent", "yearly_run_rate_percent"].includes(key) ? (
                      <>
                        {iconPicker(Number(val))} <span>{val} %</span>
                      </>
                    ) : (
                      val
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}

          {/* Total */}
          <TableRow className={classes.tableRowMain}>
            {Object.entries(totalData).map(([key, val]) => {
              if (key === "name")
                return (
                  <TableCell className={classes.cellSizeFooterFirst} align="left" key={key}>
                    {t("SALES_VOLUME.TOTAL")}
                  </TableCell>
                );
              return (
                <TableCell className={classes.cellSizeOther} align="center" key={key}>
                  {["run_rate_percent", "yearly_run_rate_percent"].includes(key) ? (
                    <>
                      {iconPicker(Number(val))} <span>{val} %</span>
                    </>
                  ) : (
                    val
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
        <TableBody />
      </Table>
    </TableContainer>
  );
};

TableSalesVolume.defaultProps = {
  headers: [],
  mainData: [],
  totalData: {},
  mainHead: null,
};

TableSalesVolume.propTypes = {
  mainHead: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  mainData: PropTypes.array,
  totalData: PropTypes.object,
};

export default TableSalesVolume;
