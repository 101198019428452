export const getListYearHeader = async year => {
  const years = year.toString().substring(2);
  const tableHead = [
    `Jan-${years}`,
    `Feb-${years}`,
    `Mar-${years}`,
    `Apr-${years}`,
    `May-${years}`,
    `Jun-${years}`,
    `Jul-${years}`,
    `Aug-${years}`,
    `Sep-${years}`,
    `Oct-${years}`,
    `Nov-${years}`,
    `Dec-${years}`,
    `Total-${years}`,
  ];
  return { data: tableHead };
};
