/* eslint-disable react/prop-types */
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

// Elements
import TextLegend from "elements/typhography/text-legend";

const ImageWrapperLegend = styled("div")({
  marginRight: "20px",
  display: "flex",
});

const LegendWrapper = styled("div")({
  display: "flex",
});

const LegendChartBarLineText = ({ icon, text, dataTestId }) => (
  <LegendWrapper data-testid={dataTestId}>
    <ImageWrapperLegend>
      <img alt="incoming" src={icon} />
    </ImageWrapperLegend>
    <TextLegend text={text} />
  </LegendWrapper>
);

LegendChartBarLineText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default LegendChartBarLineText;
