import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "./axiosPrivate";

const fetchUser = async id => {
  const { data } = await axiosPrivate.get(`${ENDPOINT.BASEURL}/auth/usermgmt/user/${id}`);
  return data.data;
};

const editUser = async ({ id, payload }) => {
  const data = {
    ...payload,
    role_user: payload.roleUser,
  };
  delete data.roleUser;
  const { status } = await axiosPrivate.post(`${ENDPOINT.BASEURL}/auth/usermgmt/update/${id}`, data);
  if (status !== 200) throw new Error("");
  return { status };
};

export { fetchUser, editUser };
