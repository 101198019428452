import DataSet from "@antv/data-set";
import { makeStyles } from "@material-ui/core";
import { Annotation, Axis, Chart, Coordinate, Interval, Legend, Tooltip, View } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
import IconElipseBlue from "assets/icons/general/icon-ellipse-blue.svg";
import IconElipseRed from "assets/icons/general/icon-ellipse-red.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "12px",
    display: "flex",
    gap: 10,
  },
  wrapperImg: {
    marginRight: "10px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
}));

const ChartQRType = ({ dataChart, total, colors }) => {
  const classes = useStyles();
  const data = [];

  // Render Data to Ring
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 24; i++) {
    const item = {};
    item.type = `${i}`;
    item.value = 10;
    data.push(item);
  }

  const { DataView: MyDataView } = DataSet;
  const dv = new MyDataView();
  dv.source(data).transform({
    type: "percent",
    field: "value",
    dimension: "type",
    as: "percent",
  });

  // data is here
  const userData = dataChart;

  // Formater data value
  const userDv = new MyDataView();
  userDv.source(userData).transform({
    type: "percent",
    field: "value",
    dimension: "type",
    as: "percent",
  });

  const parseCurrency = number => {
    const formattedNumber = new Intl.NumberFormat("id-ID").format(number);
    return formattedNumber || "0";
  };

  const IMG = value => {
    if (value === "On Us" || value === "We Chat") {
      return IconElipseBlue;
    } else {
      return IconElipseRed;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Chart placeholder={false} height={150} padding={0} autoFit>
          <Tooltip position="bottom" showCrosshairs={false}>
            {(title, items) => {
              return (
                <div className={classes.wrapper}>
                  <div className={classes.wrapperImg}>
                    <img src={IMG(items[0].data.type)} alt="haha" />
                  </div>
                  <div>
                    <div className={classes.datesWrapper}>{items[0].data.type ?? "-"}</div>
                    <div className={classes.valueWrapper}>{parseCurrency(items[0].data.total) ?? "-"}</div>
                  </div>
                </div>
              );
            }}
          </Tooltip>
          <Legend visible={false} />
          <View data={dv.rows}>
            <Legend visible={false} />
            {/* <Tooltip shared showTitle="false" /> */}
            <Coordinate type="theta" innerRadius={0.9} />
            <Annotation.Text
              position={["50%", "50%"]}
              content={parseCurrency(total)}
              style={{
                lineHeight: "26px",
                fontSize: "18",
                fill: COLORS.DARK_HARD,
                textAlign: "center",
              }}
            />
          </View>
          <View data={data}>
            <Axis visible={false} />
            <Coordinate type="polar" innerRadius={0.9} />
          </View>
          <View
            data={userDv.rows}
            scale={{
              percent: {
                formatter: val => {
                  return `${(val * 100).toFixed(2)}`;
                },
              },
            }}
          >
            <Coordinate type="theta" innerRadius={0.75} />
            <Interval position="total" adjust="stack" color={colors} />
          </View>
        </Chart>
        <div
          style={{
            height: 100,
            width: 100,
            borderRadius: "80%",
            border: "1px solid #C6D6FE",
            position: "absolute",
            top: 25,
          }}
        />
      </div>
    </div>
  );
};

ChartQRType.defaultProps = {
  dataChart: [],
  total: 0,
  colors: [],
};

ChartQRType.propTypes = {
  dataChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  total: PropTypes.number,
  colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ChartQRType;
