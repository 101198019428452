import { TableCell } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const parseCurrency = number => {
  const formattedNumber = new Intl.NumberFormat("id-ID").format(number);
  return formattedNumber || "0";
};

const TableRowDetails = ({ data = [], currencyType, ...rest }) => {
  if (!Array.isArray(data)) {
    if (process.env.NODE_ENV === "development") throw new Error("props data TableRowDetails must be array of object");
    else return null;
  }
  return (
    data?.map((x, i) => (
      <TableCellByGroupTransaction data={x} currencyType={currencyType} key={x?.id ?? i} {...rest} />
    )) ?? []
  );
};

TableRowDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  currencyType: PropTypes.string.isRequired,
};

const TableCellByGroupTransaction = ({ data, currencyType, ...rest }) => {
  if (typeof data !== "object" || Array.isArray(data)) {
    if (process.env.NODE_ENV === "development")
      throw new Error("props data TableCellByGroupTransaction must be object");
    else return null;
  }

  return Object.entries(data).map(([key, val]) =>
    key === "grand_total" ? (
      <TableCell key={key} {...rest}>
        {parseCurrency(val)}
      </TableCell>
    ) : (
      <TableCell key={key} {...rest}>
        {currencyType === "percentage" ? `${val ?? 0}%` : parseCurrency(val)}
      </TableCell>
    ),
  );
};

TableCellByGroupTransaction.propTypes = {
  data: PropTypes.object.isRequired,
  currencyType: PropTypes.string.isRequired,
};

export { TableCellByGroupTransaction, TableRowDetails };
