import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
// Assets
import IconChartLine from "assets/icons/chart/icon-chart-line.svg";

// Component
import ChartLineCustom from "components/chart/chart-line";
import { ServerErrorBoundary } from "components/general";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
// Elements
import { Button } from "elements/button";
import CardTitle from "elements/card/card-header/card-title";
import LoadingSpinner from "elements/loading/spinner";
import { CustomSelect } from "elements/select";
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import {
  getDailyChart,
  getSelectSalesName,
  getListBusinessType,
  getSelectSalesArea,
  getSeletStatus,
} from "services/api/private-routes/incoming-application";

import COLORS from "utils/constants/colors";
import { dateUtility } from "utils/helpers/general/date-time";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  filterWrapper: {
    marginLeft: "auto",
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
  },
  selectWraper: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      width: "100%",
    },
  },
  error: {
    height: "570px",
    justifyContent: "center",
  },
  selectDropdownSalesArea: {
    "& .value": {
      textTransform: "uppercase",
    },
  },
  selectItemDropdownSalesArea: {
    textTransform: "uppercase",
  },
  selectDropdown: {
    maxWidth: "3rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const CHART_CONFIG = {
  SCALE_CHART: {
    amounts: {
      min: 0,
      nice: true,
    },
    dates: {
      type: "time",
      range: [0, 1],
      mask: "DD-MM-YYYY",
    },
  },
  HEIGHT_CHART: 500,
  COLOR: ["status", [COLORS.BLUE_MEDIUM, COLORS.GREEN_HARD, COLORS.SECONDARY_MEDIUM, COLORS.PURPLE_HARD]],
};

const INITIAL_FILTER = {
  sales: "",
  salesArea: "",
  businessType: "",
  status: "",
};

const CardDailyChartApplication = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [localFilter, setLocalFilter] = useState(INITIAL_FILTER);
  const [filter, setFilter] = useState(localFilter);
  const [date] = useIncomingFilter();

  const translation = useMemo(
    () => ({
      placeholder: {
        sales: t("PLACEHOLDER.SALES"),
        area: t("PLACEHOLDER.AREA"),
        businessType: t("PLACEHOLDER.BUSSINESS_TYPE"),
        status: t("PLACEHOLDER.STATUS"),
      },
    }),
    [t],
  );

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const dailyChartApp = useQuery(["chart-daily-app", { ...date, ...filter }], () =>
    getDailyChart({
      startDate: dateUtility(date.start).isoSeparator(),
      endDate: dateUtility(date.end).isoSeparator(),
      sales: filter.sales?.code || filter.sales,
      salesArea: filter.salesArea?.code,
      businessType: filter.businessType?.code || filter.businessType,
      status: String(filter.status?.id || filter.status).toLowerCase(),
    }),
  );

  const listSalesName = useQuery(["list-sales-name"], () => getSelectSalesName());
  const listBusinessType = useQuery(["list-business-type"], () => getListBusinessType());
  const listSalesArea = useQuery(["list-sales-area"], () => getSelectSalesArea());
  const listStatusName = useQuery(["list-status-name"], () => getSeletStatus());

  const dailyChartLineContent = useMemo(() => {
    if (dailyChartApp.isError)
      return (
        <ServerErrorBoundary
          className={classes.error}
          onClick={() => dailyChartApp.refetch()}
          title={dailyChartApp.error?.message}
        />
      );
    if (dailyChartApp.isLoading)
      return (
        <WrapperOutlineChart>
          <LoadingSpinner height={520} />
        </WrapperOutlineChart>
      );
    if (dailyChartApp.isSuccess)
      return (
        <WrapperOutlineChart>
          <ChartLineCustom
            dataChart={dailyChartApp?.data?.chart}
            legends={dailyChartApp?.data?.legends}
            scaleChart={CHART_CONFIG.SCALE_CHART}
            heightChart={CHART_CONFIG.HEIGHT_CHART}
            colorChart={CHART_CONFIG.COLOR}
          />
        </WrapperOutlineChart>
      );

    return null;
  }, [dailyChartApp, classes]);

  return (
    <CardWrapper>
      <Grid container className={classes.gridWrapper}>
        <CardTitle iconSrc={IconChartLine} title={t("CARD.TITLE_DAILY_CHART_APP")} />
        <div className={classes.filterWrapper}>
          <div className={classes.selectWraper}>
            <CustomSelect
              className={clsx(classes.selectDropdown, classes.selectDropdownSalesArea)}
              placeholder={listSalesName.isFetching ? "Loading..." : translation.placeholder.sales}
              data={listSalesName?.data?.responseSalesName ?? []}
              disabled={listSalesName.isFetching}
              setValue={val => setLocalFilter(x => ({ ...x, sales: val }))}
              renderItem={val => val.name}
              renderValue={val => val.name}
              value={localFilter.sales}
              classNameItem={classes.selectItemDropdownSalesArea}
            />
            <CustomSelect
              className={classes.selectDropdown}
              placeholder={listSalesArea.isFetching ? "Loading..." : translation.placeholder.area}
              data={listSalesArea?.data?.resBusinessArea ?? []}
              disabled={listSalesArea.isFetching}
              setValue={val => setLocalFilter(x => ({ ...x, salesArea: val }))}
              renderItem={val => val.name}
              renderValue={val => val.name}
              value={localFilter.salesArea}
            />
            <CustomSelect
              className={classes.selectDropdown}
              placeholder={listBusinessType.isFetching ? "Loading..." : translation.placeholder.businessType}
              data={listBusinessType.data?.response ?? []}
              disabled={listBusinessType.isFetching}
              setValue={val => setLocalFilter(x => ({ ...x, businessType: val }))}
              renderItem={val => val.name}
              renderValue={val => val.name}
              value={localFilter.businessType}
            />
            <CustomSelect
              className={classes.selectDropdown}
              placeholder={listStatusName.isFetching ? "Loading..." : translation.placeholder.status}
              data={listStatusName.data?.resStatusName ?? []}
              disabled={listStatusName.isFetching}
              setValue={val => setLocalFilter(x => ({ ...x, status: val }))}
              renderItem={val => val.name}
              renderValue={val => val.name}
              value={localFilter.status}
            />
          </div>
          <Button
            disabled={dailyChartApp.isLoading}
            onClick={() => setFilter(localFilter)}
            style={{ height: "max-content" }}
          >
            {t("GENERAL.APPLY")}
          </Button>
        </div>
      </Grid>
      {dailyChartLineContent}
    </CardWrapper>
  );
};

export default CardDailyChartApplication;
