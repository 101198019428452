import { createRefresh } from "react-auth-kit";

export function useRefreshToken() {
  return createRefresh({
    interval: 1,
    refreshApiCallback: async param => {
      return {
        // isSuccess: true,
        // newAuthToken: param.authToken,
        // newAuthTokenExpireIn: 10,
        // newRefreshTokenExpiresIn: 60,
        ...param,
      };
    },
  });
}
