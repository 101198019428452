import React from "react";
import { useTranslation } from "react-i18next";
// Assets
import IconCard from "assets/icons/general/icon-house-leave.svg";

// Component
import DataOnboardingMerchant from "components/card/sub-card/data-onboarding-merchant";
import CardWrapper from "components/wrapper/wrapper-card";
import CardTitle from "elements/card/card-header/card-title";

const ProsesOnboardingMerchantCard = () => {
  const { t } = useTranslation();

  return (
    <CardWrapper>
      <CardTitle iconSrc={IconCard} title={t("CARD.TITLE_PROCESS_ONBOARDING_MERCHANT")} />
      <DataOnboardingMerchant />
    </CardWrapper>
  );
};

ProsesOnboardingMerchantCard.propTypes = {};

export default ProsesOnboardingMerchantCard;
