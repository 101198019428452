/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import * as core from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo, Fragment, useState, useEffect, useCallback } from "react";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down.svg";
import { ReactComponent as IconChevronUp } from "assets/icons/general/icon-chevron-up.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { parseCurrency } from "utils/helpers/useFormater";
import { RESOLVER_TEXT } from "./resolver";

const StickyTableCell = core.withStyles(() => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 10,
  },
  body: {
    zIndex: 10,
    left: "55px",
    position: "sticky",
    backgroundColor: "inherit",
  },
}))(core.TableCell);

const useStyles = core.makeStyles(() => ({
  tableContainer: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderLeft: "0px",
  },
  tableRow: {
    height: 24,
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableCellChild: {
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: COLORS.WHITE,
  },
  tableCell: {
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
  },
  tableRowIcon: {
    zIndex: 10,
    left: 0,
    position: "sticky",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    maxWidth: "40px",
  },
  tableRowBlank: {
    zIndex: 10,
    left: 0,
    position: "sticky",
    backgroundColor: COLORS.WHITE,
    width: "55px",
  },
  tableRowMainItem: {
    height: 24,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
  },
  headerTableCell: {
    fontWeight: 700,
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: SIZES.REGULAR_14,
    minWidth: 200,
    height: 24,
  },
  blankHeadTableCell: {
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    minWidth: 335,
    height: 24,
    zIndex: 10,
  },
  typographyMain: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    color: COLORS.BLACK,
  },
  typographyItemMain: {
    color: COLORS.GRAY_PRIMARY,
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
  },
  typographyItemChild: {
    marginLeft: "25px",
    fontSize: SIZES.REGULAR_14,
  },
  typographyItemChild2: {
    marginLeft: "50px",
    fontSize: SIZES.REGULAR_14,
  },
  stickyHeader: {
    zIndex: 10,
    right: 0,
    position: "sticky",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    whiteSpace: "nowrap",
  },
  sticky: {
    zIndex: 10,
    right: 0,
    position: "sticky",
    backgroundColor: "inherit",
  },
  stickyChild: {
    zIndex: 10,
    right: 0,
    position: "sticky",
    backgroundColor: COLORS.WHITE,
  },
}));

const ExecutiveSummaryTable = ({ filterData, tableHead, data, year }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState([]);
  const [, setFiltering] = useState([]);

  const RESOLVER = {
    ...RESOLVER_TEXT,
    accrual_current_year: `ACCRUAL ${year * 1}`,
    accrual_last_year: `ACCRUAL ${year * 1 - 1}`,
  };

  useEffect(() => {
    if (filterData.length < 1) return data;
    else {
      const filtered = filterData.filter(value => {
        return Object.entries(RESOLVER).filter(key => (key[1] === value ? key[0] : null));
      });
      const filteringData = Object.entries(data?.data || {}).filter(([key]) =>
        filtered.includes(Object.keys(RESOLVER[key])),
      );

      setFiltering(prevstate => {
        return [...prevstate, filteringData];
      });
    }
  }, [filterData, opened, RESOLVER, data]);

  const percentage = useCallback(value => {
    const percenNumber = (value * 100).toFixed(2);
    if (value) return `${percenNumber}%`;
    else return "-";
  }, []);

  const CHILD_COLUMN = ({ value, classeName, formated }) => {
    return value.map((values, index) => (
      <core.TableCell align="right" key={index} className={index === 13 ? classeName : null}>
        {formated === "thousand_format" && <>{values ? parseCurrency(values) : "-"}</>}
        {formated === "percentage" && <>{percentage(values)}</>}
      </core.TableCell>
    ));
  };

  const STICKY_TABLE_CELL = ({ value, classStickyTable, classesTypography, TableCell }) => {
    return (
      <>
        {TableCell && <core.TableCell className={classes.tableRowBlank} />}
        <StickyTableCell className={classStickyTable}>
          <core.Typography className={classesTypography}>{RESOLVER[value] || value}</core.Typography>
        </StickyTableCell>
      </>
    );
  };

  const DATA_TABLE = useCallback(
    (value, index) => {
      const datas = value[1];
      return (
        <core.TableBody key={JSON.stringify(value)}>
          <core.TableRow className={classes.tableRowMainItem}>
            <core.TableCell className={classes.tableRowIcon}>
              {!Array.isArray(value[1]) ? (
                <core.IconButton size="small">
                  {opened.includes(index) ? (
                    <IconChevronUp
                      onClick={() => {
                        const indexData = opened.filter(idx => idx !== index);
                        setOpened(indexData);
                      }}
                    />
                  ) : (
                    <IconChevronDown
                      onClick={() => {
                        setOpened(prevstate => {
                          return [...prevstate, index];
                        });
                      }}
                    />
                  )}
                </core.IconButton>
              ) : null}
            </core.TableCell>
            <STICKY_TABLE_CELL
              TableCell={false}
              value={value[0]}
              classStickyTable={classes.tableCell}
              classesTypography={classes.typographyMain}
            />
            {Array.isArray(value[1]) && (
              <CHILD_COLUMN value={value[1]} formated="thousand_format" classeName={classes.sticky} />
            )}
            {datas?.sum && <CHILD_COLUMN value={datas?.sum} formated="thousand_format" classeName={classes.sticky} />}
            {!datas?.sum &&
              !Array.isArray(value[1]) &&
              new Array(13).fill(null).map((value, index) => {
                return (
                  <core.TableCell align="right" key={index} className={index === 12 ? classes.sticky : null}>
                    {!value && ""}
                  </core.TableCell>
                );
              })}
          </core.TableRow>
          {typeof datas === "object" &&
            Object.entries(datas)
              .filter(value => value[0] !== "sum")
              .map(
                (value, idx) =>
                  opened.includes(index) && (
                    <Fragment key={idx}>
                      <core.TableRow>
                        <STICKY_TABLE_CELL
                          TableCell
                          value={value[0]}
                          classStickyTable={classes.tableCellChild}
                          classesTypography={classes.typographyItemMain}
                        />
                        {Array.isArray(value[1]) && (
                          <CHILD_COLUMN value={value[1]} formated="thousand_format" classeName={classes.stickyChild} />
                        )}
                        {value[1]?.sum && (
                          <CHILD_COLUMN
                            value={value[1]?.sum}
                            formated="thousand_format"
                            classeName={classes.stickyChild}
                          />
                        )}
                      </core.TableRow>
                      {typeof value[1] === "object" &&
                        Object.entries(value[1])
                          .filter(value => value[0] !== "sum")
                          .map((value, idx) => {
                            return (
                              <Fragment key={idx}>
                                <core.TableRow>
                                  {isNaN(value[0]) && (
                                    <>
                                      <STICKY_TABLE_CELL
                                        TableCell
                                        value={value[0]}
                                        classStickyTable={classes.tableCellChild}
                                        classesTypography={classes.typographyItemChild}
                                      />
                                      {value[1].sum && (
                                        <CHILD_COLUMN
                                          value={value[1]?.sum}
                                          formated="thousand_format"
                                          classeName={classes.stickyChild}
                                        />
                                      )}
                                    </>
                                  )}
                                  {value[0].includes("percentage") && Array.isArray(value[1]) && (
                                    <CHILD_COLUMN
                                      value={value[1]}
                                      formated="percentage"
                                      classeName={classes.stickyChild}
                                    />
                                  )}
                                  {!value[0].includes("percentage") && Array.isArray(value[1]) && (
                                    <CHILD_COLUMN
                                      value={value[1]}
                                      formated="thousand_format"
                                      classeName={classes.stickyChild}
                                    />
                                  )}
                                </core.TableRow>

                                {!Array.isArray(value[1] || {}) &&
                                  typeof value[1] === "object" &&
                                  Object.entries(value[1] || {})
                                    .filter(value => value[0] !== "sum")
                                    .map((value, idx) => {
                                      return (
                                        <Fragment key={idx}>
                                          <core.TableRow>
                                            {isNaN(value[0]) && (
                                              <STICKY_TABLE_CELL
                                                TableCell
                                                value={value[0]}
                                                classStickyTable={classes.tableCellChild}
                                                classesTypography={classes.typographyItemChild2}
                                              />
                                            )}
                                            {Array.isArray(value[1]) && (
                                              <CHILD_COLUMN
                                                value={value[1]}
                                                formated="thousand_format"
                                                classeName={classes.stickyChild}
                                              />
                                            )}
                                          </core.TableRow>
                                        </Fragment>
                                      );
                                    })}
                              </Fragment>
                            );
                          })}
                    </Fragment>
                  ),
              )}
        </core.TableBody>
      );
    },
    [classes, opened],
  );

  const NON_FILTERED_DATA = useMemo(() => {
    if (filterData.length < 1 || (filterData.includes("Choose All") && data)) {
      return Object.entries(data?.data || {}).map((items, index) => DATA_TABLE(items, index));
    }
  }, [filterData, data, DATA_TABLE]);

  const FILTERED_DATA = useMemo(() => {
    if (filterData.length > 0 && !filterData.includes("Choose All") && data) {
      return Object.entries(data?.data || {})
        .filter(([key]) => filterData.includes(RESOLVER[key]))
        .map((items, index) => DATA_TABLE(items, index));
    }
  }, [filterData, data, RESOLVER, DATA_TABLE]);

  return (
    <core.TableContainer component={core.Paper} className={classes.tableContainer}>
      <core.Table stickyHeader>
        <core.TableHead>
          <core.TableRow className={classes.tableRow}>
            <core.TableCell colSpan={2} className={classes.blankHeadTableCell} />
            {tableHead &&
              tableHead.map(content => (
                <core.TableCell
                  className={!content.includes("Total") ? classes.headerTableCell : classes.stickyHeader}
                  align="right"
                  key={content}
                >
                  {content}
                </core.TableCell>
              ))}
          </core.TableRow>
        </core.TableHead>
        {/* NOT FILTER / FILTER CHOOSE ALL */}
        {NON_FILTERED_DATA}
        {/* FILTER CHOOSE */}
        {FILTERED_DATA}
      </core.Table>
    </core.TableContainer>
  );
};

ExecutiveSummaryTable.propTypes = {
  filterData: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  year: PropTypes.string.isRequired,
};

export default ExecutiveSummaryTable;
