import React from "react";
import { useParams } from "react-router-dom";
import IconChartLine from "assets/icons/chart/icon-chart-line.svg";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";
import CobaChart from "../card-profitability-coba-chart-by-merchant";
import CombaChart from "../card-profitability-comba-chart-by-merchant";
import EmmergingChart from "../card-profitability-emmerge-chart-by-merchant";

const CardProfitabilityThreeBarChart = () => {
  const { branding } = useParams()
  return (
    <CardWrapper>
      <TitleChart icon={IconChartLine} title={`Customer Level TRB & Revenue (${branding})`} />
      <CobaChart />
      <div style={{ marginTop: 30 }} />
      <CombaChart />
      <div style={{ marginTop: 30 }} />
      <EmmergingChart />
    </CardWrapper>
  );
};

export default CardProfitabilityThreeBarChart;
