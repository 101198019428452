import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  boxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  boxImg: {
    marginRight: "1rem",
  },
  title: {
    fontSize: SIZES.REGULAR_18,
    fontWeight: "500",
    color: COLORS.DARK_HARD,
  },
}));

const CardTitle = ({ iconSrc, title }) => {
  const classes = useStyles();
  const variant = "h6";
  return (
    <Box className={classes.boxWrapper}>
      <Box className={classes.boxImg}>
        <img src={iconSrc} alt="icon" />
      </Box>
      <Typography variant={variant} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

CardTitle.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardTitle;
