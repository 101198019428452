import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import IconCrown from "assets/icons/general/icon-crown.svg";
import IconChartSales from "assets/icons/general/icon-mobile.svg";
import { CardPortofolioByCasa } from "components/card/card-portofolio-by-casa";
import CardPortfolioTableCASA from "components/card/card-portofolio-by-casa/card-portfolio-casa-table";
import LegendBarSalesVolume from "components/legend/legend-portofolio-sales-bar";
// import GeneralMap from "components/map";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";

// Elements
import BarChartCustom from "elements/chart/bar-chart-vertikal";
import CustomSelectDropdown from "elements/input/select-custom";
// import UnderMaintenance from "elements/under-maintenance";
// import { useAbortController } from "hooks/useAbortController";
import {
  // getCasaMap,
  getOverviewCasa,
  getSalesByMonthCASA,
  getSelectByLoginCasa,
  // getCasaMapSalesType,
} from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { useStyles } from "./styled";

const DashboardPortofolioByCasa = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const { signal } = useAbortController();

  const translation = React.useMemo(
    () => ({
      toggle: {
        division: t("CASA.DIVISION"),
        coordinator: t("PORTOFOLIO.AREA_COORDINATOR"),
        sales: t("BOX.TAT_PROCESS_SALES"),
      },
      label: {
        current_month: t("PORTOFOLIO.CURRENT_MONTH_RR"),
        higgest_division: t("PORTOFOLIO.TOP_DIVISION_HEAD"),
        top_area_coordinator: t("PORTOFOLIO.TOP_AREA_COORDINATOR"),
        idrBio: t("PORTOFOLIO.IDR_BIO"),
        titleChart: t("PORTOFOLIO.OVERVIEW_CASA"),
      },
    }),
    [t],
  );

  const intervalPositionOverviewCASA = "title*total_bio";
  const intervalPositionCASA = "month_name*total_bio";
  const axisNameCASAOverview = "title";
  const axisNameSalesByMonth = "month_name";
  const [selectSalesAreaLogin, setSelectAreaLogin] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const salesAreaLogin = useQuery(["sales-area-login-casa"], () => {
    return getSelectByLoginCasa();
  });

  const overviewCasa = useQuery(["overview-casa"], () => getOverviewCasa());

  const diagramSalesByMonthCASA = useQuery(
    ["diagram-month-casa", selectSalesAreaLogin],
    () => getSalesByMonthCASA(selectSalesAreaLogin?.id ?? ""),
    {
      retry: 0,
    },
  );

  // const {
  //   data: locationData,
  //   isLoading: loadMap,
  //   // isError: errorMap,
  //   refetch: refetchMap,
  // } = useQuery(["casa-map"], () => getCasaMap(signal), {
  //   retry: 0,
  // });
  // const {
  //   data: summaryData,
  //   isLoading: loadType,
  //   // isError: errorMapSalesType,
  //   refetch: refetchMapSalesType,
  // } = useQuery(["casa-map-sales-type"], () => getCasaMapSalesType(signal), {
  //   retry: 0,
  // });

  const colorOverviewCASA = [
    "title*total",
    name => {
      let colorUse = "";
      if (name === "total_volume") {
        colorUse = COLORS.GREEN_HARD;
      }
      if (name === "higgest_division") {
        colorUse = COLORS.PURPLE_HARD;
      }
      if (name === "higgest_arco") {
        colorUse = COLORS.GRAY_HARD;
      }
      return colorUse;
    },
  ];

  const labelOverviewCASA = {
    formatter(items) {
      const { label } = translation;
      const labelCode = {
        total_volume: label.current_month,
        higgest_division: label.higgest_division,
        higgest_arco: label.top_area_coordinator,
      };
      return labelCode[items];
    },
  };

  const colorCASA = [
    "id",
    id => {
      const colorCode = {
        0: COLORS.BLUE_MEDIUM,
        1: COLORS.GREEN_HARD,
        2: COLORS.SECONDARY_MEDIUM,
      };
      return colorCode[id] ?? COLORS.GREEN_HARD;
    },
  ];

  return (
    <div>
      <Grid container direction="column" spacing={4}>
        {/* <Grid item>
          <UnderMaintenance>
            <GeneralMap
              locationData={locationData ?? []}
              summaryData={summaryData ?? []}
              isLoading={loadMap || loadType}
              // isError={errorMap || errorMapSalesType}
              refetch={refetchMap || refetchMapSalesType}
            />
          </UnderMaintenance>
        </Grid> */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CardWrapper>
                <TitleChart
                  icon={IconChartSales}
                  title={translation.label.titleChart}
                  titleIdr={translation.label.idrBio}
                />
                <BarChartCustom
                  data={overviewCasa}
                  legendView={false}
                  heightChart={350}
                  colors={colorOverviewCASA}
                  labelCustom={labelOverviewCASA}
                  positionName={intervalPositionOverviewCASA}
                  axisName={axisNameCASAOverview}
                />
                <div className={classes.legendSalesVolumeWrapper}>
                  {overviewCasa.isSuccess ? (
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={4}>
                        <LegendBarSalesVolume title={overviewCasa?.data[0].month_name || "-"} marginSize="60px" />
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.legendSingleWrapper}>
                          <LegendBarSalesVolume title={overviewCasa?.data[1].name || "-"} icon={IconCrown} />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.legendSingleWrapper}>
                          <LegendBarSalesVolume title={overviewCasa?.data[2].name || "-"} icon={IconCrown} />
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
              </CardWrapper>
            </Grid>
            <Grid item xs={6}>
              <CardWrapper>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <TitleChart icon={IconChartSales} title={t("SIDEBAR.CASA")} titleIdr={t("PORTOFOLIO.IDR_BIO")} />
                  <CustomSelectDropdown
                    placeholder={salesAreaLogin.isFetching ? "Loading..." : "Sales Area"}
                    arrayData={salesAreaLogin?.data?.data ?? []}
                    disabled={salesAreaLogin.isFetching}
                    onChange={e => setSelectAreaLogin(e.target.value)}
                    renderValue={val => val.name}
                    data={selectSalesAreaLogin}
                  />
                </div>
                <BarChartCustom
                  data={diagramSalesByMonthCASA}
                  legendView={false}
                  heightChart={360}
                  colors={colorCASA}
                  positionName={intervalPositionCASA}
                  axisName={axisNameSalesByMonth}
                />
              </CardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CardPortofolioByCasa enable={salesAreaLogin.isSuccess} selectSalesAreaLogin={selectSalesAreaLogin} />
        </Grid>
      </Grid>
      <CardPortfolioTableCASA classes={classes} />
    </div>
  );
};

export default DashboardPortofolioByCasa;
