import { Checkbox, ListItemText, makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as IconCheck } from "assets/icons/general/icon-check.svg";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down25.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const DropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  style: {
    maxHeight: "520px",
    marginTop: "5px",
    marginLeft: "-10px",
    borderRadius: "10px",
  },
  getContentAnchorEl: null,
  disableScrollLock: true,
};

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
    paddingRight: 10,
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
  },
  root: {
    displayEmpty: true,
    disableUnderline: true,
    width: "200px",
    borderRadius: "10px",
    borderColor: COLORS.GRAY_ULTRASOFT,
    fontSize: SIZES.REGULAR_14,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    alignItems: "center",
    marginRight: "5px",
    height: "40px",
    "& .MuiSelect-select": {
      borderRadius: "10px",
      paddingLeft: "10px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      paddingTop: 0,
      paddingBottom: 0,
      height: "100%",
    },
  },
  menuItem: {
    backgroundColor: "transparent",
    "&.Mui-selected": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    height: "52px",
  },
  placeholder: {
    color: COLORS.GRAY_HARD,
  },
}));

const MultipleSelectCheckbox = ({ isChooseAll, content, onChange, onChooseAll, arrayData, chipData }) => {
  const classes = useStyles();

  const renderDefaultValue = () => {
    return <span className={classes.placeholder}>{content}</span>;
  };

  return (
    <Select
      multiple
      displayEmpty
      value={[]}
      MenuProps={DropdownProps}
      IconComponent={IconChevronDown}
      disableUnderline
      renderValue={() => renderDefaultValue()}
      className={classes.root}
    >
      {isChooseAll && (
        <MenuItem className={classes.menuItem}>
          <Checkbox
            onChange={onChooseAll}
            checked={arrayData?.length === chipData?.length}
            icon={<IconCheck />}
            className={classes.checkbox}
          />
          <ListItemText primary="Choose All" />
        </MenuItem>
      )}
      {arrayData?.map(val => {
        return (
          <MenuItem className={classes.menuItem} key={val.id} value={val.id}>
            <Checkbox
              onChange={() => onChange(val)}
              checked={chipData.indexOf(val.name) > -1}
              icon={<IconCheck />}
              className={classes.checkbox}
            />
            <ListItemText primary={val.name} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

MultipleSelectCheckbox.defaultProps = {
  onChooseAll: () => {},
  isChooseAll: false,
};

MultipleSelectCheckbox.propTypes = {
  isChooseAll: PropTypes.bool,
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChooseAll: PropTypes.func,
  arrayData: PropTypes.arrayOf(PropTypes.object).isRequired,
  chipData: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MultipleSelectCheckbox;
