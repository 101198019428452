import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getDataListMonthOctoMerchant = async signal => {
  const { data: dataResponse, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.LIST_MONTH, {
    signal,
  });
  if (status !== 200) throw new Error(`[getDataListMonthOctoMerchant] ${status}`);
  const { data } = dataResponse;

  const parsedData = Object.entries(data || {}).reduce(
    (items, [, val]) => [
      ...items,
      {
        value: val.month_name,
        label: val.month_name,
      },
    ],
    [],
  );

  return parsedData;
};

export { getDataListMonthOctoMerchant };
