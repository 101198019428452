import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  inputLabel: {
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_14,
    marginBottom: "5px",
  },
  select: {
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    width: "100%",
    size: "14px",
    borderRadius: "10px",
    border: ({ isInvalid }) => `1px solid ${isInvalid ? COLORS.PRIMARY_DARK : COLORS.GRAY_ULTRASOFT}`,
    lineHeight: "20px",
    "& .MuiSelect-select": {
      borderRadius: "10px",
      paddingLeft: "10px",
      lineHeight: "20px",
      height: "27px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-icon": {
      marginRight: "10px",
      marginTop: "4px",
      alignItems: "center",
    },
  },
  selectItem: {
    backgroundColor: "transparent",
    height: "52px",
    "&.Mui-selected": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    textTransform: ({ isUpperCase }) => `${isUpperCase ? "uppercase" : "none"}`,
  },
});

export { useStyles };
