import { styled, TextField as MTextField, makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "assets/icons/general/icon-search.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

import { useQueryListSalesByLogin } from "./use-query-list-sales-by-login";

const KEY_SELECT_ALL = {
  name: "Select All",
};

const TextField = styled(MTextField)({
  minWidth: "200px",
  width: "10rem",
  borderRadius: "10px",
  borderColor: COLORS.GRAY_ULTRASOFT,
  fontSize: SIZES.REGULAR_14,
  backgroundColor: COLORS.GRAY_ULTRASOFT,
  alignItems: "center",
});

const useStyles = makeStyles({
  textField: {
    fontSize: SIZES.REGULAR_14,
    color: COLORS.DARK_HARD,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    borderRadius: 10,
    height: "40px",
  },
  input: {
    fontSize: "inherit",
    color: "inherit",
    padding: "0 1em",
    height: "100%",
    width: "100%",
  },
});

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();
const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

const DropdownListSalesByLogin = ({ onChange }) => {
  const classes = useStyles();

  const queryListSalesByLogin = useQueryListSalesByLogin();

  return (
    <Autocomplete
      options={
        Array.isArray(queryListSalesByLogin.data?.data) ? [KEY_SELECT_ALL, ...queryListSalesByLogin.data.data] : []
      }
      disabled={!queryListSalesByLogin.isSuccess}
      limitTags={5}
      onChange={(e, val) => onChange && onChange(e, val)}
      filterOptions={filterOptions}
      style={{
        width: "max-content",
        display: "inline",
      }}
      getOptionLabel={option => option?.name || ""}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={queryListSalesByLogin.isSuccess ? "Sales" : "Loading..."}
          disabled={!queryListSalesByLogin.isSuccess}
          className={classes.textField}
          SelectProps={{ native: true }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <img src={SearchIcon} alt="search icon" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

DropdownListSalesByLogin.defaultProps = {
  onChange: undefined,
};

DropdownListSalesByLogin.propTypes = {
  onChange: PropTypes.func,
};

export { DropdownListSalesByLogin };
