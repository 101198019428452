import { MenuItem as MMenuItem, styled } from "@material-ui/core";
import COLORS from "utils/constants/colors";

const MenuItem = styled(MMenuItem)({
  backgroundColor: "transparent",
  textTransform: "inherit",
  minHeight: "3rem",
  "&.Mui-selected, &:hover": {
    backgroundColor: COLORS.PRIMARY_ULTRASOFT,
  },
});

export { MenuItem };
