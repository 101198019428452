import { Box, Button, IconButton, makeStyles, Modal, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import IconRemove from "assets/icons/general/icon-close.svg";
import { ReactComponent as IconFileGrey } from "assets/icons/user-management/icon-file-grey.svg";
import { ReactComponent as IconFile } from "assets/icons/user-management/icon-file-text.svg";
import ButtonContained from "elements/button/button-contained";
import ButtonOutlined from "elements/button/button-outlined";
import Description from "elements/description";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxStyle: {
    width: "530px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
    position: "relative"
  },
  iconButtonBoxStyle: {
    height: "24px",
    width: "24px",
    alignSelf: "end",
  },
  descStyle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginBottom: "25px",
  },
  labelWrapper: {
    marginTop: "50px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    marginBottom: "55px",
  },
  fileNameWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    width: "370px",
    height: "40px",
    marginRight: "11px",
    borderRadius: "10px",
    border: `1px solid ${COLORS.PRIMARY_DARK}`,
  },
  fileNameStyle: {
    display: "flex",
    alignItems: "center",
    color: COLORS.DARK_HARD,
    fonstSize: SIZES.REGULAR_14,
    fontWeight: 400,
  },
  removeIconStyle: {
    alignSelf: "center",
    border: `1.5px solid ${COLORS.PRIMARY_DARK}`,
    height: "15px",
    width: "15px",
    padding: "11px",
    "& img": {
      height: "15px",
      width: "15px",
    },
  },
  placeholderStyle: {
    display: "flex",
    alignItems: "center",
    borderStyle: "none",
    width: "370px",
    height: "40px",
    marginRight: "11px",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    color: COLORS.GRAY_HARD,
    padding: "10px",
    borderRadius: "10px",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fonstSize: SIZES.REGULAR_14,
    fontWeight: 400,
  },
  iconStyle: {
    marginRight: "7px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonBrowseFile: {
    border: `1.5px solid ${COLORS.PRIMARY_DARK}`,
    color: COLORS.PRIMARY_DARK,
    borderRadius: "10px",
    padding: "8px 18px",
    gap: "10px",
    height: "40px",
    boxShadow: "0px 3px 10px rgba(84, 163, 216, 0.25)",
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    width: "100px",
    "&:focus": {
      border: `1px solid ${COLORS.PRIMARY_DARK}`,
    },
    "&:hover": {
      color: COLORS.PRIMARY_DARK,
      border: `1.5px solid ${COLORS.PRIMARY_DARK}`,
    },
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  errorStyles: {
    color: COLORS.PRIMARY_DARK,
    position: "absolute",
    bottom: 90,
  },
}));

const PopupUpload = ({
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  onUpload,
  onRemoveFile,
  fileName,
  title,
  description,
  placeholder,
  submitButtonText,
  uploadButtonText,
  cancelButtonText,
  value,
  acceptFile = ".xlsx, .xls, .pdf",
  errorMessage,
  isLoading,
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.boxStyle}>
        <Title title={title} align="center" fontWeight={600} />
        <div className={classes.labelWrapper}>
          <Description description={description} align="left" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
        </div>
        <div className={classes.inputWrapper}>
          {!fileName && (
            <Typography className={classes.placeholderStyle}>
              <IconFileGrey className={classes.iconStyle} />
              {placeholder}
            </Typography>
          )}
          {fileName && (
            <div className={classes.fileNameWrapper}>
              <Typography className={classes.fileNameStyle}>
                <IconFile className={classes.iconStyle} />
                {fileName}
              </Typography>
              <IconButton onClick={onRemoveFile} className={classes.removeIconStyle}>
                <img src={IconRemove} alt="icon-remove" />
              </IconButton>
            </div>
          )}
          <Button variant="outlined" component="label" content={uploadButtonText} className={classes.buttonBrowseFile}>
            {uploadButtonText}
            <input
              type="file"
              value={value}
              ref={inputRef}
              hidden
              accept={acceptFile}
              onChange={event => onUpload(event)}
            />
          </Button>
        </div>
        {errorMessage ? <div className={classes.errorStyles}>{errorMessage}</div> : null}
        <div className={classes.buttonStyle}>
          <ButtonOutlined content={cancelButtonText} width="79px" onClick={onCancel} />
          <ButtonContained content={submitButtonText} width="81px" onClick={onSubmit} disabled={!fileName || isLoading} />
        </div>
      </Box>
    </Modal>
  );
};

PopupUpload.defaultProps = {
  isOpen: false,
  fileName: "",
  value: null,
  errorMessage: "",
  isLoading: false
};

PopupUpload.propTypes = {
  isOpen: PropTypes.bool,
  acceptFile: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  uploadButtonText: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool
};

export default PopupUpload;
