import { makeStyles } from "@material-ui/core";
import { Axis, Chart, Interaction, Interval, Legend, Point, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
import { ServerErrorBoundary } from "components/general";
import LegendChartBar from "components/legend/legend-chart-bar";
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { thousandFormat, thousandFormatWithComma } from "utils/helpers/useFormater";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
  error: {
    height: "360px",
  },
  wrapperTooltip: {
    height: "350px",
    width: "500px",
  },
}));

const BarChartTooltip = ({
  data,
  legendView,
  heightChart,
  colors,
  labelCustom,
  positionName,
  axisName,
  pointLabel,
}) => {
  const classes = useStyles();

  const labelDefault = {
    formatter(items) {
      return items;
    },
  };

  if (data.isLoading) {
    return <LoadingSpinner height={350} />;
  }

  if (data.isError) {
    return <ServerErrorBoundary className={classes.error} onClick={() => data.refetch()} title={data.error?.message} />;
  }

  const formatter = {
    total_bio: {
      min: 0,
      formatter(val) {
        return thousandFormat(val);
      },
      type: "linear-strict",
    },
    nice: true,
    type: "linear",
  };

  return (
    <div>
      <Chart
        padding={[30, 20, 50, 70]}
        autoFit
        scale={formatter}
        height={heightChart}
        data={data.data}
        theme={{ maxColumnWidth: 40 }}
      >
        <Interaction type="element-active" />
        <Axis
          name="total_bio"
          grid={{
            align: "center",
            line: {
              type: "line",
              style: {
                lineWidth: 0,
              },
            },
          }}
        />
        <Axis name={axisName} label={data?.length !== 0 ? labelCustom : labelDefault} />
        <Interval position={positionName} color={data.length !== 0 ? colors : ""} />
        <Legend visible={false} />
        {pointLabel ? (
          <Point
            position={positionName}
            label={[
              pointLabel,
              val => ({
                content: thousandFormatWithComma(val),
              }),
            ]}
            size={0}
          />
        ) : null}
        <Tooltip position="top" showMarkers>
          {(_, items) => {
            return <div style={{ padding: 20 }}>{thousandFormatWithComma(items[0].data.total_bio)}</div>;
          }}
        </Tooltip>
      </Chart>
      {legendView === true ? <LegendChartBar /> : ""}
    </div>

    // <Chart
    //   height={300}
    //   padding="auto"
    //   data={data}
    //   scale={{ value: { min: 0, type: "linear-strict" }, nice: true, type: "linear" }}
    //   autoFit
    // >
    //   <Axis name={axisName} label={data?.length !== 0 ? labelCustom : labelDefault} />
    //   <Interval
    //     adjust={[
    //       {
    //         type: "dodge",
    //         marginRatio: 0,
    //       },
    //     ]}
    //     color={colors}
    //     position="month*value"
    //   />
    //   <Tooltip shared />
    //   <Legend visible={false} />
    // </Chart>
  );
};

BarChartTooltip.defaultProps = {
  data: [],
  legendView: true,
  heightChart: 500,
  colors: [COLORS.GREEN_MEDIUM, COLORS.SECONDARY_MEDIUM, COLORS.PRIMARY_HARD],
  labelCustom: {},
  positionName: "",
  axisName: "",
  isLoading: false,
  isError: false,
  pointLabel: false,
};

BarChartTooltip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  legendView: PropTypes.bool,
  heightChart: PropTypes.number,
  colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelCustom: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  positionName: PropTypes.string,
  axisName: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  pointLabel: PropTypes.string,
};

export default BarChartTooltip;
