import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconChartSales from "assets/icons/general/icon-mobile.svg";
import { ServerErrorBoundary } from "components/general";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";
import BarChartCustom from "elements/chart/bar-chart-tooltip-chart";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import {
  getDataChartMccHaveProfit,
  getDataChartMccHaveNotProfit,
  getDataChartMccZeroProfit,
  getDataChartMccZeroNotProfit,
} from "services/api/private-routes/dashboard-profitibility";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import CardWrapperLine from "../card-wrapper-line";

const useStyles = makeStyles({
  topRightTextChart: {
    padding: "6px 12px",
    backgroundColor: "transparent",
    border: `1px solid ${COLORS.GRAY_SOFT}`,
    borderRadius: "30px",

    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_16,
    fontWeight: "500",
    lineHeight: "20px",
  },
  wrapper: {
    width: "49.25%",
    height: "49.25%",
  },
});

const CardTrbMtdChart = ({ dataFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signal } = useAbortController();

  const year = dataFilter?.date.getFullYear();
  const month = (dataFilter?.date.getMonth() + 1).toString().padStart(2, "0");
  const formattedDate = `${year}-${month}`;

  const newFilter = {
    toggle: dataFilter?.toggle?.toLowerCase(),
    date: formattedDate,
  };

  const dataChartBarHaveProfit = useQuery(["chartbar-mcc-have-profit", dataFilter], () =>
    getDataChartMccHaveProfit({ newFilter }),
  );
  const dataChartBarHaveNotProfit = useQuery(["chartbar-mcc-have-not-profit", dataFilter], () =>
    getDataChartMccHaveNotProfit({ newFilter }),
  );
  const dataChartBarZeroProfit = useQuery(["chartbar-mcc-zero-profit", dataFilter], () =>
    getDataChartMccZeroProfit({ newFilter }, signal),
  );
  const dataChartBarZeroNonProfit = useQuery(["chartbar-mcc-zero-not-profit", dataFilter], () =>
    getDataChartMccZeroNotProfit({ newFilter }),
  );

  //   translation
  const translation = useMemo(
    () => ({
      title: {
        zero_trb: t("PROFITABILITY.ZERO_TRB"),
        have_trb: t("PROFITABILITY.HAVE_TRB"),
        profit: t("PROFITABILITY.PROFIT"),
        not_profit: t("PROFITABILITY.NOT_PROFIT"),
      },
      label: {
        zero_trb: t("PROFITABILITY.ZERO_TRB"),
        have_trb: t("PROFITABILITY.HAVE_TRB"),
        profit: t("PROFITABILITY.PROFIT"),
        not_profit: t("PROFITABILITY.NOT_PROFIT"),
      },
    }),
    [t],
  );
  // label line
  const labelLeft = [translation.label.have_trb, translation.label.zero_trb];
  const labelBottom = [translation.label.not_profit, translation.label.profit];
  //   position
  const intervalPosition = "mcc_group_name*total";
  const intervalPositionDetail = "month_name*total";
  //   axisName
  const axisName = "mcc_group_name";
  const axisNameDetail = "month_name";
  //   colors
  const colorHaveTrbLeft = COLORS.SECONDARY_MEDIUM;
  const colorHaveTrbRight = COLORS.BLUE_MEDIUM;
  const colorZeroTrbLeft = COLORS.RED_MEDIUM_HARD;
  const colorZeroTrbRight = COLORS.GREEN_MEDIUM;
  //   label custom
  const labelAxisCustom = {
    formatter(text) {
      return text.substr(0, 3);
    },
  };

  const showDataChartBarHaveNotProfit = useMemo(() => {
    if (dataChartBarHaveNotProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartBarHaveNotProfit.isError) {
      return <ServerErrorBoundary onClick={dataChartBarHaveNotProfit.refetch} />;
    }
    return (
      <>
        <BarChartCustom
          data={dataChartBarHaveNotProfit}
          legendView={false}
          heightChart={350}
          colors={colorHaveTrbLeft}
          positionName={intervalPosition}
          axisName={axisName}
          labelCustom={labelAxisCustom}
          positionNameDetail={intervalPositionDetail}
          axisNameDetail={axisNameDetail}
          pointValue="total"
        />
      </>
    );
  }, [dataChartBarHaveNotProfit, axisName, labelAxisCustom, colorHaveTrbLeft]);

  const showDataChartBarHaveProfit = useMemo(() => {
    if (dataChartBarHaveProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartBarHaveProfit.isError) {
      return <ServerErrorBoundary onClick={dataChartBarHaveProfit.refetch} />;
    }
    return (
      <>
        <BarChartCustom
          data={dataChartBarHaveProfit}
          legendView={false}
          heightChart={350}
          colors={colorHaveTrbRight}
          positionName={intervalPosition}
          axisName={axisName}
          labelCustom={labelAxisCustom}
          positionNameDetail={intervalPositionDetail}
          axisNameDetail={axisNameDetail}
          pointValue="total"
        />
      </>
    );
  }, [dataChartBarHaveProfit, axisName, labelAxisCustom, colorHaveTrbRight]);

  const showDataChartBarZeroNotProfit = useMemo(() => {
    if (dataChartBarZeroNonProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartBarZeroNonProfit?.isError) {
      return <ServerErrorBoundary onClick={dataChartBarZeroNonProfit?.refetch} />;
    }
    return (
      <>
        <BarChartCustom
          data={dataChartBarZeroNonProfit}
          legendView={false}
          heightChart={350}
          colors={colorZeroTrbLeft}
          positionName={intervalPosition}
          axisName={axisName}
          labelCustom={labelAxisCustom}
          positionNameDetail={intervalPositionDetail}
          axisNameDetail={axisNameDetail}
          pointValue="total"
        />
      </>
    );
  }, [dataChartBarZeroNonProfit, axisName, labelAxisCustom, colorZeroTrbLeft]);

  const showDataChartBarZeroProfit = useMemo(() => {
    if (dataChartBarZeroProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartBarZeroProfit.isError) {
      return <ServerErrorBoundary onClick={dataChartBarZeroProfit.refetch} />;
    }
    return (
      <>
        <BarChartCustom
          data={dataChartBarZeroProfit}
          legendView={false}
          heightChart={350}
          colors={colorZeroTrbRight}
          positionName={intervalPosition}
          axisName={axisName}
          labelCustom={labelAxisCustom}
          positionNameDetail={intervalPositionDetail}
          axisNameDetail={axisNameDetail}
          pointValue="total"
        />
      </>
    );
  }, [dataChartBarZeroProfit, axisName, labelAxisCustom, colorZeroTrbRight]);

  return (
    <CardWrapperLine labelLeft={labelLeft} labelBottom={labelBottom}>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.title.have_trb} />
            <Typography className={classes.topRightTextChart}>{translation.title.not_profit}</Typography>
          </Grid>
          {showDataChartBarHaveNotProfit}
        </CardWrapper>
      </div>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.title.have_trb} />
            <Typography className={classes.topRightTextChart}>{translation.title.profit}</Typography>
          </Grid>
          {showDataChartBarHaveProfit}
        </CardWrapper>
      </div>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.title.zero_trb} />
            <Typography className={classes.topRightTextChart}>{translation.title.not_profit}</Typography>
          </Grid>
          {showDataChartBarZeroNotProfit}
        </CardWrapper>
      </div>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.title.zero_trb} />
            <Typography className={classes.topRightTextChart}>{translation.title.profit}</Typography>
          </Grid>
          {showDataChartBarZeroProfit}
        </CardWrapper>
      </div>
    </CardWrapperLine>
  );
};

CardTrbMtdChart.propTypes = {
  dataFilter: PropTypes.object.isRequired,
};

export default CardTrbMtdChart;
