import { Grid } from "@material-ui/core";
import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import IconUnderMaintenance from "assets/icons/general/icon-under-maintenance.svg";
import Description from "elements/description";
import COLORS from "utils/constants/colors";
import { useStyles } from "./styled";

const DevelopmentPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const translation = useMemo(
    () => ({
      title: t("GENERAL.FEATURE_UNDER_DEVELOPMENT"),
      description: t("GENERAL.FEATURE_UNDER_DEVELOPMENT_DESCRIPTION"),
    }),
    [t],
  );

  return (
    <Grid container className={classes.container}>
      <img src={IconUnderMaintenance} alt="icon-under-maintenance" />
      <Grid className={classes.textWrapper}>
        <Description description={translation.title} color={COLORS.DARK_HARD} size="40px" align="center" isBold />
        <Description
          description={translation.description}
          color={COLORS.GRAY_PRIMARY}
          size="18px"
          align="center"
          width="55%"
        />
      </Grid>
    </Grid>
  );
};

export default DevelopmentPage;
