const parse = (filename, extIdx = filename.lastIndexOf(".")) => ({
  name: filename.substring(0, extIdx),
  extension: filename.substring(extIdx + 1),
});

export const trimFileName = (
  filename,
  size = 28,
  fill = "...",
  file = parse(filename),
  head = file.name.substring(0, size),
) => (file.name.length >= size ? `${head}${fill}${file.extension}` : filename);
