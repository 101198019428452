import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: "500",
    lineHeight: "14px",
    color: COLORS.DARK_HARD,
    marginRight: "5px",
  },
  titleIdr: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: "500",
    lineHeight: "14px",
    color: COLORS.GRAY_PRIMARY,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "20px",
  },
}));

const TitleWithIdr = ({ title, titleIdr }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="h6" className={classes.titleIdr}>
        {titleIdr}
      </Typography>
    </div>
  );
};

TitleWithIdr.defaultProps = {};

TitleWithIdr.propTypes = {
  title: PropTypes.string.isRequired,
  titleIdr: PropTypes.string.isRequired,
  // titleSize: PropTypes.string,
  // titleColor: PropTypes.string,
};

export default TitleWithIdr;
