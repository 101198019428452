/**
 *
 * @param {object} obj
 * @param {string[]} keys
 * @returns {string[]}
 */
export const orderingKeys = (obj = {}, keys) =>
  keys.reduce((prev, curr) => ({ ...prev, [curr]: obj[curr] ?? null }), {});

/**
 *
 * @param {object} obj
 * @param {any} value
 * @returns {string}
 */
export const getObjKey = (obj, value) => {
  return Object.keys(obj).find(key => obj[key] === value);
};

/**
 *
 * @param {object} obj
 * @returns {[]}
 */
export const objectToArray = obj => {
  return Object.entries(obj).reduce((prev, [key, val]) => [...prev, { key, val }], []);
};
