/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ListCommon from "components/list/list-common";
import TitleChart from "components/title/title-chart";
import COLORS from "utils/constants/colors";

const CardMiniTopMerchantStyle = styled("div")(({ marginLeft, marginRight }) => ({
  backgroundColor: COLORS.WHITE,
  padding: "20px",
  borderRadius: "15px",
  border: "1px solid #E9F0FF",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  marginLeft,
  marginRight,
}));

const SpaceStyle = styled("div")({
  height: "20px",
});

const CardMiniTopMerchant = ({
  icon,
  title,
  list,
  marginLeft,
  marginRight,
  onMouseOver,
  onMouseOut,
  titleIdr,
  totalBio,
}) => {
  const listMerchants = list;

  //

  return (
    <CardMiniTopMerchantStyle marginLeft={marginLeft} marginRight={marginRight}>
      <TitleChart icon={icon} title={title} titleIdr={titleIdr} />
      <SpaceStyle />
      {listMerchants.map((listMerchant, index) => (
        <ListCommon
          key={index + 1}
          number={index}
          name={listMerchant?.branding_name}
          sum={totalBio ? listMerchant?.total_bio : listMerchant?.total}
          borderBottomSize={index === 0 ? "none" : "1px solid #E9F0FF"}
          height={listMerchant?.branding_name?.length > 20 ? 60 : listMerchant?.branding_name?.length > 40 ? 81 : 39}
          onMouseOver={e => {
            if (index !== 0) return onMouseOver(e, listMerchant);
          }}
          onMouseOut={onMouseOut}
        />
      ))}
    </CardMiniTopMerchantStyle>
  );
};

CardMiniTopMerchant.propTypes = {
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
};

CardMiniTopMerchant.defaultProps = {
  list: [],
  marginLeft: "0px",
  marginRight: "0px",
  totalBio: false,
};

CardMiniTopMerchant.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string.isRequired,
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  titleIdr: PropTypes.string,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  totalBio: PropTypes.bool,
};

export default CardMiniTopMerchant;
