import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";
import { orderingKeys, VALUE_REVENUE_ALIASES } from "./util";

const getListDetailRevenueByBranding = async (
  { page, filterSalesArea, annualFilter, search, typeSearch, sorts },
  signal,
) => {
  const { data: responseData, status } = await axiosPrivate.post(
    `${ENDPOINT.BASEURL}/dashboard/revenue-branding`,
    {
      filters: {
        sales_area: filterSalesArea || [],
        type: annualFilter.toUpperCase() || "YEARLY",
      },
      search: search || "",
      type_search: typeSearch,
      sorts,
      page: page || 1,
      size: 15,
    },
    { signal },
  );

  if (status !== 200) throw new Error(`[getListDetailRevenueByBranding] ${status}`);

  const { data } = responseData;

  const transactionsParser = transactions =>
    transactions
      .reduce((prev, curr) => {
        return [...prev, { ...curr, nominal: orderingKeys(curr.nominal) }];
      }, [])
      .sort((a, b) => {
        if (["ONGOING", "YEARLY"].includes(String(annualFilter || "YEARLY"))) return Number(a.year) - Number(b.year);
        else return 1;
      });

  const parsedData = data.data.reduce((prev, curr) => {
    const { merchant, transactions } = curr;
    return [
      ...prev,
      {
        id: merchant.branding_id,
        name: merchant.branding_name,
        transactions: transactionsParser(transactions),
      },
    ];
  }, []);

  const heads =
    parsedData[0]?.transactions?.reduce((prev, curr) => {
      const head =
        String(annualFilter).toUpperCase() === "ONGOING" || String(annualFilter).toUpperCase() === "MONTHLY"
          ? `${curr.month} - ${curr.year}`
          : curr.year;
      return [...prev, head];
    }, []) ?? [];

  return {
    data: parsedData,
    subHeaders: Object.values(VALUE_REVENUE_ALIASES),
    heads,
    page: {
      dataCount: data.row_count,
      totalPage: data.total_page,
      offset: data.page.offset,
      number: data.page.pageNumber,
      size: data.page.pageSize,
    },
    // filter: {
    //   salesArea: data?.linked_sort_pagination_dto?.search_request?.filters?.sales_area ?? [],
    //   type: data?.linked_sort_pagination_dto?.search_request?.filters?.type ?? "Monthly",
    // },
  };
};

const downloadListRevenueBranding = async ({ page, filterSalesArea, annualFilter, search, typeSearch, sorts }) => {
  const { status, data: responseBody } = await axiosPrivate.post("/dashboard/revenue-branding/download", {
    filters: {
      sales_area: filterSalesArea || [],
      type: annualFilter.toUpperCase() || "YEARLY",
    },
    search: search || "",
    type_search: typeSearch,
    sorts,
    page: page || 1,
    size: 15,
  });

  if (status !== 200) throw new Error("downloadListRevenueBranding");

  return { payload: responseBody };
};

export { getListDetailRevenueByBranding, downloadListRevenueBranding };
