import { axiosPrivate } from "../axiosPrivate";

const getDataIncomingApplicationBackToSales = async ({ startDate, endDate }) => {
  const response = await axiosPrivate.get(
    `/dashboard/incoming/application/top-back-to-sales?startDate=${startDate}&endDate=${endDate}`,
  );
  if (response.status !== 200) throw new Error(`[getDataIncomingApplicationBackToSales] ${response.status}`);
  const dataResponse = response?.data?.data?.map((items, idx) => {
    return {
      id: idx + 1,
      name: items.name,
      sum: items.sum,
    };
  });

  return { data: dataResponse };
};

export { getDataIncomingApplicationBackToSales };
