import PropTypes from "prop-types";
import React from "react";
import { CustomSelect } from "elements/select";
import { useQuerySalesAreaByLogin } from "./use-query-sales-area";

const KEY_SELECT_ALL = {
  name: "Select All",
};

const DropdwownSalesAreaUser = ({ value, setValue }) => {
  const salesArea = useQuerySalesAreaByLogin();

  return (
    <CustomSelect
      data={Array.isArray(salesArea.data?.data) ? [KEY_SELECT_ALL, ...salesArea.data.data] : []}
      renderItem={item => item.name}
      renderValue={item => item.name}
      value={value}
      setValue={setValue}
      placeholder="Sales Area"
      disabled={!salesArea.isSuccess}
    />
  );
};

DropdwownSalesAreaUser.defaultProps = {
  value: undefined,
  setValue: undefined,
};
DropdwownSalesAreaUser.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export { DropdwownSalesAreaUser };
