import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDelete } from "assets/icons/general/icon-close.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { Chip } from "./chip-item";

const useStyles = makeStyles({
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: ".5rem",
    alignItems: "center",
  },
  chipReset: {
    borderColor: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    border: "2px solid",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },
});

const Chips = ({ value, onDelete, onReset, renderItem }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const resetContent = useMemo(() => {
    if (!onReset || !Array.isArray(value) || value.length === 0) return null;
    else return <Chip onClick={onReset} label={t("GENERAL.RESET_DEFAULT")} className={classes.chipReset} />;
  }, [onReset, value, classes, t]);

  const content = useMemo(() => {
    return value.map(item => {
      const label = renderItem ? renderItem(item) : String(item);
      return <Chip key={label} label={label} onDelete={() => onDelete && onDelete(item)} deleteIcon={<IconDelete />} />;
    });
  }, [value, renderItem, onDelete]);

  return (
    <div className={classes.chipContainer}>
      {content}
      {resetContent}
    </div>
  );
};

Chips.defaultProps = {
  value: [],
  onDelete: undefined,
  renderItem: undefined,
  onReset: undefined,
};

Chips.propTypes = {
  value: PropTypes.array,
  onDelete: PropTypes.func,
  renderItem: PropTypes.func,
  onReset: PropTypes.func,
};

export { Chips };
