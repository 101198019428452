import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const Wrapper = styled("div")(({ heightsize, flexsize }) => ({
  padding: "20px",
  backgroundColor: `${COLORS.WHITE}`,
  borderRadius: "15px",
  border: `1px solid ${COLORS.GRAY_SOFT}`,
  boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  height: heightsize ?? "auto",
  flex: flexsize,
}));

const CardWrapper = ({ children, heightSize, flexSize }) => {
  return (
    <Wrapper heightsize={heightSize} flexsize={flexSize}>
      {children}
    </Wrapper>
  );
};

CardWrapper.defaultProps = {
  heightSize: "",
  flexSize: 0,
};

CardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  heightSize: PropTypes.string,
  flexSize: PropTypes.number,
};

export default CardWrapper;
