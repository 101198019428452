import { zodResolver } from "@hookform/resolvers/zod";
import { Box, styled } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import Textfield from "components/input/text-field";
import TextfieldPrivate from "components/input/text-field-private";
import { useCIMBLoading } from "components/loading/cimb-loading";
import ButtonContained from "elements/button/button-contained";
import ButtonOutlined from "elements/button/button-outlined";
import ButtonText from "elements/button/button-text";
import FootNote from "elements/foot-note";
import { useAuth } from "hooks/useAuth";
import { postLogin } from "services/auth-api";
import COLORS from "utils/constants/colors";
import { routes } from "utils/constants/paths";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignSelf: "center",
  width: "400px",
  height: "auto",
  padding: "30px",
  backgroundColor: COLORS.WHITE,
});

const InputWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const ForgotWrapper = styled("div")(({ top }) => ({
  display: "flex",
  alignSelf: "end",
  marginBottom: "35px",
  marginTop: top,
}));

const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledGeneral = styled("div")(({ bottom }) => ({
  marginBottom: bottom,
}));

const FormLogin = ({ onRegister, onForgot }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoginData } = useAuth();
  const { setActive } = useCIMBLoading();

  const signInSchema = z.object({
    email: z.string().email(t("FOOTNOTE.INVALID_EMAIL")),
    password: z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(signInSchema),
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const mutation = useMutation(
    async ({ email, password }) => {
      const result = await postLogin({ email, password });
      if (result.success) setLoginData({ email, token: result.headers.authorization, userData: result.userData });
      return result;
    },
    {
      onMutate: () => setActive(true),
      onSuccess: data => {
        data.success && navigate(routes.DASHBOARD.PARSE_PREFIX(routes.DASHBOARD.INCOMING_APPLICATION));
        data.invalid && setError("password", { message: data.msg, type: "validate" });
      },
      onSettled: () => setActive(false),
    },
  );

  const onSubmit = handleSubmit(data => mutation.mutate(data));

  return (
    <FormContainer>
      <form onSubmit={onSubmit} data-testid="login-form">
        <div>
          {/* ******************** CHANGE REQUEST CIMB ******************** */}
          {/* <StyledGeneral bottom="5px">
            <Description description={t("LOGIN.WELCOME_TO")} />
          </StyledGeneral> */}

          {/* <StyledGeneral bottom="20px">
            <Title title={t("LOGIN.CIMB_SiMB")} size={SIZES.MEDIUM_22} color={COLORS.DARK_HARD} />
          </StyledGeneral> */}
          {/* ******************** END CHANGE REQUEST CIMB ******************** */}

          <InputWrapper>
            <StyledGeneral bottom="20px">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Textfield
                    {...field}
                    data-testid="email"
                    label={t("GENERAL.EMAIL")}
                    placeholder={t("PLACEHOLDER.INPUT_EMAIL")}
                    isInvalid={!!errors?.email?.message}
                  />
                )}
              />
              {!!errors?.email?.message && <FootNote content={errors?.email?.message} />}
            </StyledGeneral>

            <StyledGeneral>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextfieldPrivate
                    {...field}
                    data-testid="password"
                    label={t("GENERAL.PASSWORD")}
                    placeholder={t("PLACEHOLDER.INPUT_PASSWORD")}
                    isInvalid={!!errors?.password?.message}
                  />
                )}
              />
              {!!errors?.password?.message && <FootNote content={errors?.password?.message} />}
            </StyledGeneral>

            <ForgotWrapper top={mutation.data?.invalid ? "20px" : "5px"}>
              <ButtonText content={t("GENERAL.FORGOT_PASSWORD")} width="max-content" onClick={onForgot} />
            </ForgotWrapper>
          </InputWrapper>
        </div>

        <ButtonWrapper>
          <ButtonOutlined content={t("GENERAL.REGISTER")} onClick={onRegister} />
          <ButtonContained content={t("GENERAL.LOGIN")} type="submit" />
        </ButtonWrapper>
      </form>
    </FormContainer>
  );
};

FormLogin.propTypes = {
  onRegister: PropTypes.func.isRequired,
  onForgot: PropTypes.func.isRequired,
};

export default FormLogin;
