/* eslint-disable react/prop-types */
import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import IconChartLine from "assets/icons/chart/icon-chart-line.svg";
import ChartPortofolioLine from "components/chart/chart-portofolio-line";
import { ServerErrorBoundary } from "components/general";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
import TextToggleButton from "elements/button/button-toggle-text";
import CardTitle from "elements/card/card-header/card-title";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { getTrendChartMtdByMerchant } from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { toogleFilterProfitibility } from "utils/constants/mocks/calculation-profit-loss";
import QUERY_NAME from "utils/constants/query";
import { thousandFormat } from "utils/helpers/useFormater";
import useStyles from "./styles";

const CardProfitabilityTrendChart = ({ monthly }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [toggle, setToggle] = useState(toogleFilterProfitibility[0]);
  const axisName = "month_name";
  const axisLabel = {
    formatter(text) {
      return text;
    },
  };
  const position = "month_name*total_bio";
  const tooltip = (_, items) => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.datesWrapper}>{items[0]?.data?.month_name ?? "-"}</div>
        <div className={classes.valueWrapper}>{thousandFormat(items[0]?.data.total_bio) ?? "-"}</div>
      </div>
    );
  };

  const { signal } = useAbortController();
  const dataChart = useQuery([QUERY_NAME.TREND_CHART_MTD_BY_MERCHANT, toggle, monthly], () =>
    getTrendChartMtdByMerchant(signal, id, toggle.toLowerCase(), monthly),
  );

  function clickToggle(val) {
    setToggle(val);
  }

  const chartContent = useMemo(() => {
    if (dataChart?.isFetching) {
      return <LoadingSpinner height={500} />;
    }

    if (dataChart?.isError) {
      return <ServerErrorBoundary className={classes.minHCard} onClick={dataChart.refetch} />;
    }

    if (dataChart.isSuccess) {
      const convertedDataChart = dataChart?.data?.map((item, index) => ({
        month_name: item.month_name,
        date: `${index + 1}/01/2023`,
        total_bio: item.total_bio,
      }));

      return (
        <ChartPortofolioLine
          data={convertedDataChart}
          axisname={axisName}
          axislabel={axisLabel}
          position={position}
          colors={COLORS.SECONDARY_MEDIUM}
          customTooltip={tooltip}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChart.isFetching]);

  return (
    <CardWrapper>
      <WrapperOutlineChart>
        <Grid container className={classes.gridWrapper}>
          <Grid item xs={3}>
            <CardTitle iconSrc={IconChartLine} title="Profitability Trend" />
          </Grid>
          <Grid item xs={3}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextToggleButton
                toggleData={toogleFilterProfitibility}
                selected={toggle}
                onChange={val => clickToggle(val)}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div />
          </Grid>
        </Grid>
        <div className={classes.mt20} />
        {chartContent}
      </WrapperOutlineChart>
    </CardWrapper>
  );
};

export default CardProfitabilityTrendChart;
