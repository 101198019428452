import qs from "qs";
import ENDPOINT from "utils/constants/endpoint";
import { orderingKeys } from "utils/helpers/general";
import { axiosPrivate } from "../axiosPrivate";

// GET SALES VOLUME MAP : as popup
const getSalesVolumeMap = async signal => {
  const { data, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME_MAP, { signal });
  if (status !== 200) throw new Error("Failed to load map data");
  const parse = {
    data: data?.data?.map(item => {
      return {
        data: item.data.reverse(),
        key: item.key,
        latitude: item.latitude,
        location: item.location,
        longitude: item.longitude,
      };
    }),
  };
  return parse.data;
};

// GET SALES VOLUME TYPE : as card
const getSalesVolumeType = async signal => {
  const { data, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME_TYPE, { signal });
  if (status !== 200) throw new Error("Failed to load map data");
  const parse = {
    data: data?.data?.map(item => {
      return {
        data: item.additional_data,
        key: item.key,
        label: item.label,
        title: item.title,
      };
    }),
  };
  return parse.data;
};

// GET SALES VOLUME OVERVIEW
const getSalesVolumeOverView = async () => {
  const { data, status } = await axiosPrivate.get(`${ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME}/overview`);
  if (status !== 200) throw new Error(`[getSalesVolumeOverView] ${status}`);
  const getData = Object.entries(data?.data).reduce((prev, [key, val]) => [...prev, { title: key, ...val }], []);
  return getData;
};

// Diagram batang by Month
const getSalesByMonth = async salesAreaId => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME}/diagram-month?salesArea=${salesAreaId}`,
  );
  if (status !== 200) throw new Error(`[getSalesByMonth] ${status}`);
  const dataParse = data?.data?.map((item, id) => ({ ...item, id }));
  // console.info(dataParse, "data get Parse")
  return dataParse;
};

//  >>>>    GET Line Chart   <<<<
const getSalesLineChart = async (salesAreaId, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME}-chart/filter?salesArea=${salesAreaId}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getSalesLineChart] ${status}`);
  const dataChart = data?.data?.data_chart?.sort((a, b) => {
    const parseDate = date => new Date(String(date).split("/").reverse().join("-"));
    const [left, right] = [parseDate(a.date), parseDate(b.date)];
    return new Date(left) - new Date(right);
  });

  return { ...data?.data, dataSalesLine: data?.data, dataChart };
};

//  >>>>    GET Table   <<<<

const MONTH = {
  jan: "Jan",
  feb: "Feb",
  mar: "Mar",
  apr: "Apr",
  may: "May",
  jun: "Jun",
  jul: "Jul",
  aug: "Aug",
  sept: "Sept",
  oct: "Oct",
  nov: "Nov",
  dec: "Dec",
};

const RRHeader = (month, year) => {
  const yearSubfix = String(year).substring(2);
  return {
    run_rate: `RR ${month} ${year}`,
    month_target: `${month} ${year} (T)`,
    year_target: `YTD ${year} (T)`,
    run_rate_percent: `RR ${month} ${yearSubfix} (%)`,
    yearly_run_rate_percent: `YTD ${month} ${yearSubfix} (%)`,
  };
};

const KeysSalesVolumeHeaders = ["name", ...Object.keys(MONTH), ...Object.keys(RRHeader())];
const orderingSalesVolume = obj => orderingKeys(obj, KeysSalesVolumeHeaders);

const getTableDivision = async ({ year = new Date().getFullYear(), target = "SV" }, signal = undefined) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME}/division?year=${year}&target=${target}`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getTableDivision] ${status}`);

  const yearResponse = data?.data?.year || new Date().getFullYear();
  const month = Object.values(MONTH)[new Date().getMonth()];
  const subfix = String(yearResponse).substring(2);
  const RRHeaders = Object.values(RRHeader(month, subfix));
  const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

  const mainData = data?.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
  const totalData = orderingSalesVolume(data?.data?.total_data ?? {});

  return { data: data?.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
};

const getTableArco = async ({ year = "", userCode = "", target }, signal) => {
  const query = qs.stringify({
    year,
    value: userCode,
    target,
  });

  const { data, status } = await axiosPrivate.get(`${ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME}/arco?${query}`, {
    signal,
  });

  if (status !== 200) throw new Error(`[getTableArco] ${status}`);

  const yearResponse = data?.data?.year || new Date().getFullYear();
  const month = Object.values(MONTH)[new Date().getMonth()];
  const subfix = String(yearResponse).substring(2);
  const RRHeaders = Object.values(RRHeader(month, subfix));
  const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

  const mainData = data?.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
  const totalData = orderingSalesVolume(data?.data?.total_data ?? {});

  return { data: data?.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
};

const getSalesTable = async ({ year = "", userCode = "", target = "" }, signal) => {
  const query = qs.stringify({
    year,
    value: userCode,
    target,
  });

  const { data, status } = await axiosPrivate.get(`${ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME}/sales?${query}`, {
    signal,
  });

  if (status !== 200) throw new Error(`[getSalesTable] ${status}`);

  const yearResponse = data?.data?.year || new Date().getFullYear();
  const month = Object.values(MONTH)[new Date().getMonth()];
  const subfix = String(yearResponse).substring(2);
  const RRHeaders = Object.values(RRHeader(month, subfix));
  const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

  const mainData = data?.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
  const totalData = orderingSalesVolume(data.data?.total_data ?? {});

  return { data: data?.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
};

const getSelectByLogin = async () => {
  const { data: response } = await axiosPrivate.get(`/auth/usermgmt/sales-area-user`);

  const { data } = response;

  const getIdByName = name => data?.find(x => x.name === name) || "";
  const getList = () => data?.reduce((prev, curr) => [...prev, curr.name], []);

  return { dataSales: data, data, getIdByName, getList };
};

const getSelectDivHeadSalesVolume = async ({ year }) => {
  const { data: responseDivHead } = await axiosPrivate.get(
    `/dashboard/transaction/portfolio/sales-volume/division/list?year=${year}`,
  );
  return responseDivHead;
};
const getSelectArcoHeadSalesVolume = async ({ year }) => {
  const { data: response } = await axiosPrivate.get(
    `/dashboard/transaction/portfolio/sales-volume/arco/list?year=${year}`,
  );
  return response;
};

const getSelectSalesTable = async () => {
  const { data: response } = await axiosPrivate.get(`/dashboard/transaction/portfolio/sales-volume/sales/list`);
  return response;
};

const getCategorySalesVolumeList = async (year, signal) => {
  const { data, status } = await axiosPrivate.get(
    `/dashboard/transaction/portfolio/sales-volume/target/list?year=${year}`,
    {
      signal,
    },
  );
  if (status !== 200) throw new Error(`[getCategorySalesVolumeList] ${status}`);
  return data?.data;
};

const downloadExcel = async (filter = { year: new Date().getFullYear(), filterType: "DIV_HEAD" }) => {
  const { data: response, status } = await axiosPrivate.post(
    "/dashboard/transaction/portfolio/sales-volume/table/download",
    {
      year: filter.year,
      filter_type: filter.filterType,
    },
  );

  if (status !== 200) throw new Error(`[downloadExcel] ${status}`);
  if (status === 200) {
    fetch(response.data.url)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const dates = new Date();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const month = dates.getMonth() + 1 < 10 ? `0${dates.getMonth() + 1}` : dates.getMonth() + 1;
        const day = dates.getDate() < 10 ? `0${dates.getDate()}` : dates.getDate();
        const filename = `SALES_VOLUME_${filter.filterType}_${filter.year}_(${dates.getFullYear()}${month}${day}).xlsx`;
        a.href = url;
        a.download = filename;
        a.click();
      });
  }
};

export {
  getSalesVolumeMap,
  getSalesVolumeType,
  getSalesVolumeOverView,
  getSalesByMonth,
  getSalesLineChart,
  getTableDivision,
  getTableArco,
  getSalesTable,
  getSelectByLogin,
  getSelectArcoHeadSalesVolume,
  getSelectSalesTable,
  getSelectDivHeadSalesVolume,
  getCategorySalesVolumeList,
  downloadExcel,
};
