import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

// Component
import WrapperSubCard from "components/wrapper/wrapper-sub-card";

import COLORS from "utils/constants/colors";

const TextSalesTitle = styled(Typography)({
  marginBottom: "15px",
  fontWeight: "400",
  color: COLORS.GRAY_PRIMARY,
});

const IconImg = styled("img")({
  width: "58px",
  height: "58px",
  marginRight: "15px",
});

const useStyles = makeStyles(() => ({
  secondaryText: {
    display: "flex",
    flexDirection: "column",
    "&.Mui-selected": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.PRIMARY_DARK,
    },
  },
  listItemStyle: {
    "&.MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

const TATSubCard = ({ titleSales, days, completedPercent, iconSrc, isTat }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [percentColor, setPercentColor] = useState(COLORS.RED_HARD);

  // Change color
  const changeColorPercent = useCallback(() => {
    const percent = completedPercent;
    if (percent >= 49) {
      setPercentColor(COLORS.GREEN_HARD);
    } else {
      setPercentColor(COLORS.RED_HARD);
    }
  }, [setPercentColor, completedPercent]);

  // Cheking day and days or workdays
  const renderDays = () => {
    const status = isTat;
    const day = days;
    if (status === false && day < 2) {
      return (
        <span>
          {days} {t("GENERAL.DAY")}
        </span>
      );
    }
    if (status === false && day > 1) {
      return (
        <span>
          {days} {t("GENERAL.DAYS")}
        </span>
      );
    }
    return (
      <span>
        {days} {t("GENERAL.WORKDAYS")}
      </span>
    );
  };

  const WrapperTyphographyPercent = styled(Typography)({
    color: percentColor,
  });

  useEffect(() => {
    changeColorPercent();
  }, [percentColor, changeColorPercent]);

  return (
    <WrapperSubCard>
      <List>
        <ListItem className={classes.listItemStyle} alignItems="center">
          <ListItemAvatar>
            <IconImg src={iconSrc} alt="sdasd" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <TextSalesTitle component="span" variant="body2">
                {titleSales}
              </TextSalesTitle>
            }
            secondary={
              <>
                <span className={classes.secondaryText}>
                  <Typography component="span" variant="h5" color="textPrimary">
                    {days === null ? "-" : renderDays()}
                  </Typography>
                  <WrapperTyphographyPercent component="span" variant="body2">
                    {completedPercent}% Completed
                  </WrapperTyphographyPercent>
                </span>
              </>
            }
          />
        </ListItem>
      </List>
    </WrapperSubCard>
  );
};

TATSubCard.defaultProps = {
  days: 0,
  isTat: false,
};

TATSubCard.propTypes = {
  titleSales: PropTypes.string.isRequired,
  days: PropTypes.number,
  completedPercent: PropTypes.number.isRequired,
  iconSrc: PropTypes.string.isRequired,
  isTat: PropTypes.bool,
};

export default TATSubCard;
