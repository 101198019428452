import { AxiosError } from "axios";
import ENDPOINT from "utils/constants/endpoint";
import { axiosInstance } from "./axios";

const postLogin = async ({ email, password }) => {
  const { data, status, headers } = await axiosInstance
    .post(ENDPOINT.API_AUTH.LOGIN, {
      email,
      password,
    })
    .catch(err => {
      if (err instanceof AxiosError) return err.response;
    });

  if (status === 401) return { invalid: true, msg: data.response_message };
  if (status === 401) return { invalid: true };
  if (status === 200) {
    const { userData } = await getUserData({ token: headers.authorization });
    return { data, headers, success: true, userData };
  }
};

const postRegister = async payload => {
  const { data, status } = await axiosInstance.post(ENDPOINT.API_AUTH.USERS_RECORD, payload);

  if (status !== 200) throw new Error(data?.response_message);

  return data;
};

const refreshForgotPassword = async ({ token }) => {
  const { status } = await axiosInstance.post(
    ENDPOINT.API_AUTH.REFRESH_FORGOT_PASSWORD,
    {},
    { headers: { Authorization: token } },
  );

  if (status === 401) return { invalid: true };

  return status;
};

const postForgotPassword = async ({ email }) => {
  const { status } = await axiosInstance
    .post(ENDPOINT.API_AUTH.FORGOT_PASSWORD, {
      email,
    })
    .catch(err => {
      if (err instanceof AxiosError) {
        return err.response;
      }
    });

  if (status === 404) return { emailNotRegistered: true };
  if (status === 401) return { emailLocked: true };

  return { success: true };
};

const postEmailForgotPassword = async ({ email }) => {
  const response = await axiosInstance.post(ENDPOINT.API_AUTH.FORGOT_PASSWORD, {
    email,
  });
  if (response.status !== 200) throw new Error("");
  return response.data;
};

const validatePageForgotPassword = async ({ token }) => {
  const { status, data } = await axiosInstance
    .post(ENDPOINT.API_AUTH.VALIDATE_PAGE, {}, { headers: { Authorization: token } })
    .then(val => val)
    .catch(err => (err instanceof AxiosError ? err.response : Promise.reject(err)));

  if (status === 401 || status === 400) {
    return { expired: true };
  }

  return { state: data.data?.state, success: true };
};

const submitNewPassword = async ({ password, passwordConfirmation, token }) => {
  const { data, status } = await axiosInstance.post(
    ENDPOINT.API_AUTH.NEW_PASSWORD,
    { password, passwordConfirmation },
    { headers: { Authorization: token } },
  );
  if (status !== 200) throw new Error(data);
  return data;
};

const validatePageOTP = async ({ token, dateTime }) => {
  const { data } = await axiosInstance.post(
    ENDPOINT.API_AUTH.VALIDATE_PAGE_OTP,
    {
      time_start: dateTime,
    },
    { headers: { Authorization: token } },
  );
  const timeStart = new Date(data.data.time_start);
  const timeNow = new Date(data.data.time_now);

  // Get expired tmime
  const timeExpired = new Date(data.data.time_start);

  timeExpired.setMinutes(timeStart.getMinutes() + 5); // RULES

  const isExpired = timeExpired.getTime() <= timeNow.getTime();

  return { timeExpired, timeStart, timeNow, isExpired, email: data.data.email, success: true };
};

const submitOTP = async ({ otp, token }) => {
  const { status } = await axiosInstance.post(
    ENDPOINT.API_AUTH.OTP_VALIDATE,
    { otp },
    { headers: { Authorization: token } },
  );

  if (status === 200) return { success: true };
  return { invalid: true };
};

const refreshNewOTP = async ({ email, token, timeStart }) => {
  const { data, status } = await axiosInstance
    .post(ENDPOINT.API_AUTH.OTP_REFRESH, { email, time_start: timeStart }, { headers: { Authorization: token } })
    .catch(err => {
      if (err instanceof AxiosError) {
        return err.response;
      }
    });

  if (status === 401) return { limit: true };
  const { remaining_request } = data.data;
  return { remaining_request, success: true };
};

const getUserData = async ({ token }) => {
  const { data } = await axiosInstance.get("/auth/user", {
    headers: { Authorization: token },
  });

  return { userData: data.data };
};

export {
  postLogin,
  postEmailForgotPassword,
  postRegister,
  postForgotPassword,
  validatePageForgotPassword,
  submitNewPassword,
  validatePageOTP,
  submitOTP,
  refreshNewOTP,
  refreshForgotPassword,
  getUserData,
};
