/* eslint-disable no-param-reassign */
import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

// Array Data
const colsDatas = cols => {
  if (cols.length === 0) {
    const nullDataArray = new Array(13).fill(null);
    return nullDataArray;
  } else {
    const nullDataArray = new Array(12).fill(null);
    const datas = cols.reduce((prev, curr) => {
      const idx = curr.period.substring(curr.period.length - 2) === "13" ? 13 : new Date(curr.period).getMonth();
      prev[idx] = curr.value;
      return prev;
    }, nullDataArray);

    return datas;
  }
};

// Object Data
const valuesObjData = cols => {
  const datas = Object.entries(cols).reduce((items, [key, value]) => {
    if (value) {
      return {
        ...items,
        [key]: !Array.isArray(value) ? valuesObjData(value) : colsDatas(value),
      };
    } else return items;
  }, {});
  return datas;
};

export const getListDataTableExecutiveSummary = async (payload, signal) => {
  const { data: dataResponse, status } = await axiosPrivate.post(
    `${ENDPOINT.API_DASHBOARD.EXECUTIVE_SUMMARY.EXECUTIVE_SUMMARY}`,
    payload,
    {
      signal,
    },
  );
  if (status !== 200) throw new Error([`getListDataTableExecutiveSummary] ${status}`]);

  const { data } = dataResponse;
  const parsedData = Object.entries(data).reduce(
    (items, [key, value]) => ({
      ...items,
      [key]: !Array.isArray(value) ? valuesObjData(value) : colsDatas(value),
    }),
    {},
  );

  return parsedData;
};
