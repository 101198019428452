/* eslint-disable react/no-array-index-key */
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down.svg";
import COLORS from "utils/constants/colors";
import { dummyPositions } from "utils/constants/mocks/register";
import { useStyles } from "./styled";

const DropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  style: {
    marginTop: "5px",
    marginLeft: "-10px",
    borderRadius: "10px",
  },
  getContentAnchorEl: null,
  disableScrollLock: true,
};

const SelectInput = forwardRef(({ label, onChange, placeholder, isInvalid, isUpperCase, dataSelect, ...rest }, ref) => {
  const classes = useStyles({ isInvalid, isUpperCase });
  const renderDefaultValue = value =>
    !value || String(value)?.trim().length === 0 ? (
      <span style={{ color: COLORS.GRAY_HARD }}>{placeholder}</span>
    ) : (
      <span>{helper(value)}</span>
    );

  const helper = value => {
    const search = dataSelect.map(item => item.id).indexOf(value);
    if (search > -1) {
      return dataSelect[search].name;
    }
    return false;
  };

  return (
    <>
      <InputLabel className={classes.inputLabel}>{label} :</InputLabel>
      <Select
        className={classes.select}
        displayEmpty
        onChange={onChange}
        variant="standard"
        disableUnderline
        IconComponent={IconChevronDown}
        MenuProps={DropdownProps}
        fullWidth
        renderValue={value => renderDefaultValue(value)}
        ref={ref}
        {...rest}
      >
        {dataSelect.map((item, index) => (
          <MenuItem className={classes.selectItem} key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
});

SelectInput.defaultProps = {
  isInvalid: false,
  dataSelect: dummyPositions,
  isUpperCase: false,
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  dataSelect: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isUpperCase: PropTypes.bool,
};

export default SelectInput;
