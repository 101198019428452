import { createTheme } from "@material-ui/core";
import COLORS from "utils/constants/colors";

export const customTheme = createTheme({
  overrides: {
    MuiInput: {
      input: {
        "&::placeholder": {
          color: COLORS.GRAY_HARD,
        },
      },
    },
  },
  palette: {
    primary: {
      main: COLORS.PRIMARY_DARK,
      dark: COLORS.DARK_HARD,
      light: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.GRAY_ULTRASOFT,
    },
    background: {
      default: COLORS.GRAY_ULTRASOFT,
      main: COLORS.GRAY_ULTRASOFT,
    },
    text: {
      main: COLORS.GRAY_ULTRASOFT,
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
});
