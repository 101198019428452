import { nominalyDigit } from "utils/constants/general";

const thousandFormat = value => {
  if (value === null) {
    return "-";
  }
  if (value === undefined) {
    return "-";
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const thousandFormatWithComma = value => {
  if (!value) {
    return "-";
  }

  return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(value).replace("Rp", "");
};

const percentageFormatter = (val, decimalPlace = 2) => {
  const percentVal = `${parseFloat(val).toFixed(decimalPlace)}%`;
  return percentVal;
};

const convertNominalToInternationalSystem = (value, decimal = 0) => {
  const valueNumber = Number(value);
  if (valueNumber >= nominalyDigit.TRILLION) {
    return `${(valueNumber / nominalyDigit.TRILLION).toFixed(decimal)} T`;
  }
  if (valueNumber >= nominalyDigit.BILLION) {
    return `${(valueNumber / nominalyDigit.BILLION).toFixed(decimal)} B`;
  }
  if (valueNumber >= nominalyDigit.MILLION) {
    return `${(valueNumber / nominalyDigit.MILLION).toFixed(decimal)} M`;
  }
  if (valueNumber >= nominalyDigit.THOUSAND) {
    return `${(valueNumber / nominalyDigit.THOUSAND).toFixed(decimal)} K`;
  }
  return `${valueNumber}`;
};

const convertToStringMonth = (value, t) => {
  switch (Number(value)) {
    case 1:
      return t("GENERAL.JANUARY");
    case 2:
      return t("GENERAL.FEBRUARY");
    case 3:
      return t("GENERAL.MARCH");
    case 4:
      return t("GENERAL.APRIL");
    case 5:
      return t("GENERAL.MAY");
    case 6:
      return t("GENERAL.JUNE");
    case 7:
      return t("GENERAL.JULY");
    case 8:
      return t("GENERAL.AUGUST");
    case 9:
      return t("GENERAL.SEPTEMBER");
    case 10:
      return t("GENERAL.OCTOBER");
    case 11:
      return t("GENERAL.NOVEMBER");
    case 12:
      return t("GENERAL.DECEMBER");
    default:
      return value;
  }
};

const getCertainString = (value, start, end) => {
  if (start === undefined || end === undefined) {
    return String(value).trim();
  }
  return String(value).trim().substring(start, end);
};

// Remove Kode and cappitalize
const helperCappitalize = item => {
  const clearCode = String(item).replace(/\d+.\d+./g, "");
  return clearCode[0].toUpperCase() + clearCode.slice(1).toLowerCase();
};

const REGEX_DECIMALS = /[-+]?\d*\.?\d+([eE][-+]?\d+)?/;

/**
 *
 * @param {string|number} input
 * @returns {number}
 */
const toNumber = input => {
  const digit = String(input).match(REGEX_DECIMALS)[0];
  return Number(digit);
};

const formatCurrency = new Intl.NumberFormat("en-EN");
const toCurrency = input => {
  const num = toNumber(input);
  return formatCurrency.format(num);
};

const parseCurrency = (number, options = {}) => {
  const { maximumFractionDigits = 0 } = options;
  const formattedNumber = new Intl.NumberFormat("id-ID", {
    maximumFractionDigits,
  }).format(number);
  return formattedNumber || "0";
};

export {
  thousandFormat,
  percentageFormatter,
  convertNominalToInternationalSystem,
  convertToStringMonth,
  getCertainString,
  helperCappitalize,
  toNumber,
  toCurrency,
  parseCurrency,
  thousandFormatWithComma,
};
