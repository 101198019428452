import { Grid } from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import IconMailSuccess from "assets/icons/popup/icon-mail-success.svg";
import IconStateFailed from "assets/icons/popup/icon-state-failed.svg";
import IconStateSuccess from "assets/icons/popup/icon-state-success.svg";
import FormOtp from "components/form/form-otp";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupInformation from "components/popup/popup-information";
import DescOTP from "elements/description/desc-otp";
import { refreshNewOTP, submitOTP, validatePageOTP, validatePageForgotPassword } from "services/auth-api";
import { SIZES } from "utils/constants/fonts";
import { routes } from "utils/constants/paths";

const OtpPage = () => {
  const queryClient = useQueryClient();
  const token = useSearchParams()[0].get("tokenReset");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActive } = useCIMBLoading();
  const [counter, setCounter] = useState(0);
  const [openRequest, setOpenRequest] = useState(false);
  const [openRequestLimit, setOpenRequestLimit] = useState(false);

  /**
   * Check valid token & state position
   */
  const validatingToken = useQuery(["validate-page-password"], () => validatePageForgotPassword({ token }), {
    retry: 0,
    onSuccess: res => {
      if (res.state === "otp") return;
      if (res.state === "password" || res.expired || res.invalid)
        return navigate(`${routes.AUTH.PARSE_PREFIX(routes.AUTH.NEW_PASSWORD)}?tokenReset=${token}`);
      else return navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN));
    },
  });

  /**
   * Validate page otp sync time between client and server
   */
  const validatePage = useQuery(["validate-otp-page"], () => validatePageOTP({ token }), {
    retry: 0,
    enabled: validatingToken.data?.state === "otp",
    onError: () => navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)),
  });

  /**
   * Submit OTP
   */
  const otpSubmission = useMutation(submitOTP, {
    onMutate: () => setActive(true),
    onSettled: () => setActive(false),
    onSuccess: res => {
      if (res.invalid) {
        validatingToken.refetch();
        validatePage.refetch();
      }
    },
    onError: () => {
      validatingToken.refetch();
      validatePage.refetch();
    },
  });

  const requestNewOTP = useMutation(refreshNewOTP, {
    onMutate: () => setActive(true),
    onSettled: () => {
      setActive(false);
    },
    onSuccess: data => {
      if (data.success) {
        setCounter(3 - data.remaining_request);
        setOpenRequest(true);
      }
      if (!data.limit) {
        validatingToken.refetch();
        validatePage.refetch();
      } else {
        setOpenRequestLimit(true);
      }
    },
  });

  const handleTimeOut = () => validatePage.refetch();
  const handleClose = () => navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN));

  const handleCloseRequest = () => {
    queryClient.invalidateQueries(["validate-page-password", "validate-otp-page"]);
    validatingToken.refetch();
    validatePage.refetch();
    setOpenRequest(false);
  };

  const handleCloseRequestLimit = () => setOpenRequestLimit(false);

  // Throw to login page if user doesn't store token
  if (!token) return <Navigate to={routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)} />;

  return (
    <Grid container spacing={0} style={{ paddingBottom: "30px" }}>
      <Grid item xs={5}>
        {validatePage.data?.success && (
          <FormOtp
            timeExpired={validatePage.data.timeExpired}
            timeNow={validatePage.data.timeNow}
            onRequestNewOTP={() => requestNewOTP.mutate({ token, timeStart: Date.now() })}
            email={validatePage.data.email}
            onTimeOut={handleTimeOut}
            isInvalidOTP={!!otpSubmission.data?.invalid}
            onSubmit={({ otp }) => otpSubmission.mutate({ otp, token })}
            limitOTP={requestNewOTP?.data?.limit} // default false
            isExpired={validatePage.data.isExpired}
          />
        )}
        <PopupInformation
          isOpen={openRequest}
          onClose={handleCloseRequest}
          onSubmit={handleCloseRequest}
          icon={IconStateSuccess}
          title={t("OTP.TITLE_REQUEST_SEND")}
          description={
            <DescOTP
              description={`${t("OTP.REQUEST_BEEN_SEND")}(${counter}/3).${t("OTP.CHECK_EMAIL")}`}
              align="center"
              size={SIZES.REGULAR_14}
              maxHit={openRequestLimit}
            />
          }
          buttonText={t("GENERAL.CLOSE")}
        />
        <PopupInformation
          isOpen={openRequestLimit}
          onClose={handleCloseRequestLimit}
          onSubmit={handleCloseRequestLimit}
          icon={IconStateFailed}
          title={t("OTP.TITLE_REQUEST_LIMIT")}
          description={
            <DescOTP
              description={`${t("OTP.REQUEST_LIMIT")}.${t("OTP.TRY_AGAIN")}`}
              align="center"
              size={SIZES.REGULAR_14}
              maxHit={openRequestLimit}
            />
          }
          buttonText={t("GENERAL.CLOSE")}
          maxHit={openRequestLimit}
        />
        <PopupInformation
          isOpen={otpSubmission.data?.success}
          onClose={handleClose}
          onSubmit={handleClose}
          icon={IconMailSuccess}
          title={t("DESCRIPTION.PASSWORD_RESET_SUCCESS")}
          description={t("DESCRIPTION.LOGIN_NEW_PASSWORD")}
          buttonText={t("GENERAL.LOGIN")}
        />
      </Grid>
    </Grid>
  );
};

export default OtpPage;
