import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "elements/button";
import TextToggleButton from "elements/button/button-toggle-text";
import { CardBase } from "elements/card";
import { CustomSelect } from "elements/select";
import { DropdownListBranding } from "features/list-branding";
import { DropdownListSalesByLogin } from "features/list-sales-by-login";
import { DropdwownSalesAreaUser } from "features/sales-area";
import { useFilterCalculation } from "pages/dashboard/dashboard-calculation-loss-profit/use-calculation-filter";
import { toggleButton } from "utils/constants/mocks/calculation-profit-loss";
import { useQueryExistingCalculation } from "../card-existing-calculation-pnl/use-query-existing-calculation";

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: ".8rem",
  },
  divider: {
    height: "20px",
  },
  button: {
    height: "auto",
    marginLeft: "auto",
  },
  sales: {
    minWidth: "20rem",
  },
}));

function getMonthsForLocale(locale = "en-EN") {
  const format = new Intl.DateTimeFormat(locale, { month: "long" });
  return Array(12)
    .fill("")
    .map((x, i) => format.format(new Date(Date.UTC(2000, i, 1, 0, 0, 0))));
}

const MONTH = getMonthsForLocale();

const MONTH_VALUE = MONTH.reduce((prev, curr, i) => {
  const year = new Date().getFullYear();
  const month = String(i + 1).padStart(2, "0");
  const value = `${year}-${month}`;

  return [...prev, { value, render: curr }];
}, []);

const INITIAL_FILTER = {
  period: null,
  brandingId: null,
  toggle: toggleButton[0],
  sales: null,
  area: null,
};

const CardFilterPNL = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryExistingCalculation = useQueryExistingCalculation();
  const [, setFilterCalculation] = useFilterCalculation();
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const handleOnApply = useCallback(() => {
    setFilterCalculation({
      ...filter,
      period: filter.period?.value,
      area: filter.area?.id,
      sales: filter.sales?.user_code,
    });
  }, [filter, setFilterCalculation]);

  return (
    <CardBase className={classes.filterContainer}>
      <DropdownListBranding onChange={(e, val) => setFilter(old => ({ ...old, brandingId: val?.id || null }))} />
      <CustomSelect
        data={MONTH_VALUE}
        renderItem={item => item.render}
        renderValue={item => item.render}
        placeholder="Period"
        setValue={val => setFilter(old => ({ ...old, period: val }))}
        value={filter.period}
      />
      <Divider orientation="vertical" light className={classes.divider} />
      <TextToggleButton
        toggleData={toggleButton}
        onChange={val => setFilter(old => ({ ...old, toggle: val, sales: null, area: null }))}
        selected={filter.toggle}
      />
      <span>:</span>
      {filter.toggle === toggleButton[0] ? (
        // <DropdwownSalesArea value={filter.area} setValue={val => setFilter(old => ({ ...old, area: val }))} />
        <DropdwownSalesAreaUser value={filter.area} setValue={val => setFilter(old => ({ ...old, area: val }))} />
      ) : (
        <DropdownListSalesByLogin onChange={(e, val) => setFilter(old => ({ ...old, sales: val }))} />
      )}
      <Button
        disabled={![filter.brandingId, filter.period].every(Boolean) || queryExistingCalculation.isFetching}
        onClick={handleOnApply}
        className={classes.button}
      >
        {t("GENERAL.APPLY")}
      </Button>
    </CardBase>
  );
};

export { CardFilterPNL };
