import { makeStyles } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const useStyles = makeStyles({
  page: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  buttonContained: {
    borderRadius: "10px",
    gap: "10px",
    fontWeight: 500,
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    padding: "10px 18px 10px 14px",
    textTransform: "none",
  },
  buttonOutlined: {
    borderRadius: "10px",
    gap: "10px",
    fontWeight: 500,
    border: `2px solid ${COLORS.PRIMARY_DARK}`,
    color: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    padding: "10px 18px 10px 14px",
    textTransform: "none",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "40px",
  },
  margin20: {},
  centerWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchWrapper: {
    marginTop: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "15px",
    border: `1px solid ${COLORS.GRAY_SOFT}`,
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  },
  dividerWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableWrapper: {
    borderRadius: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "20px",
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    display: "flex",
    flexDirection: "column",
  },
  tableHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tableTitle: {
    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_18,
    fontWeight: 500,
  },
  tableTitleIcon: {
    width: "24px",
    height: "19px",
  },
  titleMedium18: {
    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_18,
    fontWeight: 500,
  },
  subtitleGray12: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    lineHeight: "18px",
    color: COLORS.GRAY_DARK,
  },
  radioLabel: {
    marginRight: "0px",
  },
  radio: {
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
  },
  rowsPerPage: {
    fontWeight: 400,
    fontSize: SIZES.REGULAR_16,
    lineHeight: "24px",
    color: COLORS.GRAY_SECONDARY,
  },
  summaryOutletWrapper: {
    marginBottom: "30px",
  },
  summaryOutletTitle: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  summaryOutletIcon: {
    marginRight: "13px",
  },
  boxChip: {
    display: "flex",
    flexWrap: "wrap",
    gap: ".5rem",
    alignItems: "center",
    marginTop: "10px",
  },
  buttonReset: {
    width: "130px",
    height: "32px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "30px",
    borderColor: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    border: "2px solid",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },
  tableErrorBoundary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "2rem",
    "& p": {
      fontSize: SIZES.REGULAR_16,
      fontWeight: 800,
    },
  },
});
