import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getDataTopIncomingContribution = async ({ startDate, endDate }) => {
  const response = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/dashboard/incoming/application/top-incoming-contribution?startDate=${startDate}&endDate=${endDate}`,
  );

  if (response.status !== 200) throw new Error(`[getDataTopAreaCoordinatorContribution] ${response.status}`);

  const dataResponse = response?.data?.data?.map((items, idx) => {
    return {
      id: idx + 1,
      name: items.name,
      sum: `${items.sum}`,
    };
  });

  return { data: dataResponse };
};

export { getDataTopIncomingContribution };
