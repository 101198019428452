import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

// Component
import TextLegendNumber from "elements/typhography/text-legend-number";

const ImageWrapperLegend = styled("div")({
  marginRight: "10px",
  display: "flex",
});

const LegendWrapper = styled("div")({
  display: "flex",
});

const LegendChartLineText = ({ icon, text, number }) => (
  <LegendWrapper>
    <ImageWrapperLegend>
      <img alt="incoming" src={icon} />
    </ImageWrapperLegend>
    <TextLegendNumber text={text} number={number} />
  </LegendWrapper>
);

LegendChartLineText.defaultProps = {
  number: undefined,
};

LegendChartLineText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  number: PropTypes.number,
};

export default LegendChartLineText;
