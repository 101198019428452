import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconSummary from "assets/icons/dashboard/icon-summary.svg";
import { CardBase } from "elements/card";
import LoadingSpinner from "elements/loading/spinner";
import { Title } from "elements/typhography/title";
import { useAbortController } from "hooks/useAbortController";
import { getSummaryOutlet } from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(theme => ({
  summaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  contentWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: ".5rem",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  cardContainer: {
    flex: "1 1 auto",
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  reguler12px: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    lineHeight: "18px",
    color: COLORS.GRAY_DARK,
    textAlign: "left",
  },
  semiBold16px: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    lineHeight: "24px",
  },
  semiBold14px: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    lineHeight: "20px",
    color: COLORS.PRIMARY_DARK,
  },
  loadingContainer: {
    gridColumn: "1 / -1",
  },
}));

const CardSummaryOutlet = () => {
  const classes = useStyles();
  const { signal } = useAbortController();
  const { t } = useTranslation();

  const summaryOutlet = useQuery(["summary-outlet"], () => getSummaryOutlet(signal), {
    retry: 0,
    refetchOnMount: false,
  });

  const content = useMemo(() => {
    if (summaryOutlet.isLoading) return <LoadingSpinner className={classes.loadingContainer} height={200} />;
    if (!summaryOutlet.data?.parsedData) return null;
    return summaryOutlet.data.parsedData.map(value => (
      <div className={classes.cardContainer}>
        <img src={value.category.icon} alt={`icon ${value.category.icon}`} />
        <div className={classes.cardContent}>
          <Typography className={classes.reguler12px}>{value.category.title}</Typography>
          <Typography className={classes.semiBold16px}>{value.data.outletName}</Typography>
          <Typography className={classes.semiBold14px}>{value.data.nominal}</Typography>
        </div>
      </div>
    ));
  }, [summaryOutlet, classes]);

  return (
    <CardBase className={classes.summaryContainer}>
      <Title
        title={t("GENERAL.SUMMARY_OUTLET")}
        subTitle={t("GENERAL.IDR_BIO")}
        icon={<img src={IconSummary} alt="icon summary outlet" />}
      />
      <div className={classes.contentWrapper}>{content}</div>
    </CardBase>
  );
};

export default CardSummaryOutlet;
