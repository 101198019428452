const ENDPOINT = Object.freeze({
  BASEURL: String(process.env.REACT_APP_API_BASE_URL),
  API_AUTH: {
    LOGIN: String(process.env.REACT_APP_ENDPOINT_LOGIN),
    USERS_RECORD: String(process.env.REACT_APP_ENDPOINT_USERS_RECORD),
    FORGOT_PASSWORD: String(process.env.REACT_APP_ENDPOINT_FORGOT_PASSWORD),
    NEW_PASSWORD: String(process.env.REACT_APP_ENDPOINT_NEW_PASSWORD),
    VALIDATE_PAGE: String(process.env.REACT_APP_ENDPOINT_VALIDATE_PAGE),
    OTP_REFRESH: String(process.env.REACT_APP_ENDPOINT_OTP_REFRESH),
    OTP_VALIDATE: String(process.env.REACT_APP_ENDPOINT_OTP_VALIDATE),
    VALIDATE_PAGE_OTP: String(process.env.REACT_APP_ENDPOINT_VALIDATE_PAGE_OTP),
    REFRESH_FORGOT_PASSWORD: String(process.env.REACT_APP_ENDPOINT_REFRESH_FORGOT_PASSWORD),
  },
  API_DASHBOARD: {
    LIST_SELECT: {
      SALES_AREA: String(process.env.REACT_APP_ENDPOINT_SALES_AREA_USER),
      BUSINESS_TYPE: String(process.env.REACT_APP_ENDPOINT_BUSINESS_TYPE),
      INCOMING_SALES_NAME: String(process.env.REACT_APP_ENDPOINT_INCOMING_SALES_NAME),
      INCOMING_BUSINESS_AREA: String(process.env.REACT_APP_ENDPOINT_INCOMING_BUSINESS_AREA),
      INCOMING_STATUS_NAME: String(process.env.REACT_APP_ENDPOINT_INCOMING_STATUS_NAME),
      DIV_HEAD_LIST: String(process.env.REACT_APP_ENDPOINT_DIV_HEAD_LIST),
      ARCO_HEAD_LIST: String(process.env.REACT_APP_ENDPOINT_ARCO_HEAD_LIST),
      SALES_NAME_LIST: String(process.env.REACT_APP_ENDPOINT_SALES_NAME_LIST),
    },
    INCOMING: {
      DAILY_CHART: String(process.env.REACT_APP_ENDPOINT_INCOMING_APP_DAILY_CHART),
      AVERAGE_INCOMING: String(process.env.REACT_APP_ENDPOINT_AVERAGE_APPLICATION_INCOMING),
      AVERAGE_DEPLOY: String(process.env.REACT_APP_ENDPOINT_AVERAGE_APPLICATION_DEPLOY),
    },
    PORTOFOLIO: {
      SALES_VOLUME: String(process.env.REACT_APP_ENDPOINT_PORTFOLIO_SALES_VOLUME),
      SALES_VOLUME_MAP: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_SALES_VOLUME_MAPS),
      SALES_VOLUME_TYPE: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_SALES_VOLUME_MAPS_SALES_TYPE),
      CASA_MAP: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_CASA_MAPS),
      CASA_MAP_SALES_TYPE: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_CASA_MAPS_SALES_TYPE),
      CASA_OVERVIEW: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_CASA_OVERVIEW),
      CASA_DIAGRAM_MONTH: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_CASA_BY_SALES_AREA),
      CASA_CHART_LINE: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_CASA_CHART),
      CASA_TABLE: String(process.env.REACT_APP_ENDPOINT_PORTOFOLIO_CASA_TABLE),
      PORTOFOLIO_CHART: String(process.env.REACT_APP_ENDPOINT_PORTFOLIO_MANAGEMENT_CHART),
      CASA_TYPE: String(process.env.REACT_APP_ENDPOINT_PORTFOLIO_MANAGEMENT_CHART_CASA_TYPE),
      SALES_VOLUME_TRANSACTION_TYPE: String(
        process.env.REACT_APP_ENDPOINT_PORTFOLIO_MANAGEMENT_CHART_SALES_VOLUME_TRANSACTION_TYPE,
      ),
      LIST_MONTH: String(process.env.REACT_APP_ENDPOINT_PORTFOLIO_MANAGEMENT_LIST_MONTH),
      STATISTIC_BY_DIVISION: String(process.env.REACT_APP_ENDPOINT_PORTFOLIO_STATISTIC_BY_DIVISION),
      TOP_MERCHANT: String(process.env.REACT_APP_ENDPOINT_PORTFOLIO_TOP_MERCHANT),
    },
    EXECUTIVE_SUMMARY: {
      EXECUTIVE_SUMMARY: String(process.env.REACT_APP_ENDPOINT_EXECUTIVE_SUMMARY),
      EXECUTIVE_SUMMARY_DOWNLOAD: String(process.env.REACT_APP_ENDPOINT_EXECUTIVE_SUMMARY_DOWNLOAD),
    },
  },
});

export default ENDPOINT;
