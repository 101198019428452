import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  tableContainer: {
    borderRadius: "8px",
  },
  table: {
    width: "100%",
    borderColor: "transparent",
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableRow: {
    padding: "4px 10px",
  },
  tableCellHead: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 600,
    lineHeight: SIZES.REGULAR_18,
  },
  tableCellBodySemiBold: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    lineHeight: SIZES.REGULAR_14,
  },
  tableCellBodyReguler: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 400,
    lineHeight: SIZES.REGULAR_14,
  },
});

const InvalidDataTable = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow classame={classes.tableRow}>
            <TableCell className={classes.tableCellHead}>No</TableCell>
            <TableCell className={classes.tableCellHead}>Row</TableCell>
            <TableCell className={classes.tableCellHead}>Detail Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row}>
              <TableCell className={classes.tableCellBodySemiBold}>{index + 1}</TableCell>
              <TableCell className={classes.tableCellBodySemiBold}>{row.row}</TableCell>
              <TableCell className={classes.tableCellBodyReguler}>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InvalidDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InvalidDataTable;
