import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const Title = ({ title, align, size, color, fontWeight }) => (
  <Typography
    gutterBottom
    component="div"
    align={align}
    style={{
      margin: 0,
      color,
      fontSize: size,
      fontWeight,
    }}
  >
    {title}
  </Typography>
);

Title.defaultProps = {
  title: undefined,
  align: "left",
  size: SIZES.MEDIUM_22,
  color: COLORS.DARK_MEDIUM,
  fontWeight: 500,
};

Title.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
};

export default Title;
