import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import IconUserConfirm from "assets/icons/popup/icon-user-multiple-confirm.svg";
import IconUserFailed from "assets/icons/popup/icon-user-multiple-failed.svg";
import IconUserSuccess from "assets/icons/popup/icon-user-multiple-success.svg";
import FormEditUserManagement from "components/form/form-edit-user-management";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupConfirmation from "components/popup/popup-confirmation";
import PopupInformation from "components/popup/popup-information";
import { fetchUser, editUser } from "services/api/private-routes";
import { routes } from "utils/constants/paths";

const EditUserManagementPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dataUser = useQuery(["user", id], () => fetchUser(id), { retry: 0, refetchOnMount: true });
  const { setActive } = useCIMBLoading();

  const [dataRequest, setDataRequest] = useState({});
  const [openInfo, setOpenInfo] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const mutation = useMutation(editUser, {
    onSuccess: () => {
      setOpenConfirm(false);
      setOpenInfo(true);
    },
    onError: () => {
      setOpenError(true);
    },
    onMutate: () => setActive(true),
    onSettled: () => {
      setDataRequest({});
      setOpenConfirm(false);
      setActive(false);
    },
  });

  const handleCloseInfo = () => {
    setOpenInfo(false);
    navigate(routes.DASHBOARD.PARSE_PREFIX(routes.DASHBOARD.USER_MANAGEMENT));
  };

  const handleCloseConfirm = () => setOpenConfirm(false);
  const handleSubmitConfirm = () => {
    mutation.mutate({ id, payload: { ...dataRequest, supervisor_code: dataRequest.supervisor_code?.trim() } });
  };

  const bactToUserManagement = () => {
    navigate(routes.DASHBOARD.PARSE_PREFIX(routes.DASHBOARD.USER_MANAGEMENT));
  };

  return (
    <div>
      {dataUser.isSuccess && dataUser.isFetchedAfterMount && (
        <FormEditUserManagement
          userData={dataUser.data}
          submitCancel={bactToUserManagement}
          onSubmit={data => {
            setOpenConfirm(true);
            setDataRequest(data);
          }}
        />
      )}
      <PopupInformation
        isOpen={openInfo}
        onSubmit={handleCloseInfo}
        icon={IconUserSuccess}
        title={t("DESCRIPTION.CHANGE")}
        description={t("DESCRIPTION.SUCCESS_SAVE")}
        buttonText={t("GENERAL.CLOSE")}
      />

      <PopupInformation
        isOpen={openError}
        onSubmit={handleCloseInfo}
        icon={IconUserFailed}
        title={t("DESCRIPTION.FAILED_CHANGE")}
        description={t("DESCRIPTION.FAILED_SAVE")}
        buttonText={t("GENERAL.CLOSE")}
      />

      <PopupConfirmation
        isOpen={openConfirm}
        isClose
        onClose={handleCloseConfirm}
        onCancel={handleCloseConfirm}
        onSubmit={handleSubmitConfirm}
        icon={IconUserConfirm}
        title={t("GENERAL.CONFIRMATION")}
        subtitle={t("DESCRIPTION.CONFIRM_CHANGE")}
        description={t("DESCRIPTION.INFORMATION")}
        submitButtonText={t("GENERAL.CONFIRM")}
        cancelButtonText={t("GENERAL.CANCEL")}
      />
    </div>
  );
};

export default EditUserManagementPage;
