// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from "@ant-design/plots";
import React, { memo } from "react";
import IconHouse from "assets/icons/general/icon-house.svg";
import { Chips } from "components/chip";
import { CustomSelect } from "elements/select";
import { Title } from "elements/typhography/title";

// import { DropdownListBranding } from "features/list-branding";
// import { DropdownSales } from "features/list-sales";
// import { DropdwownSalesArea } from "features/sales-area/dropdown-sales-area";

const data = [
  { year: "1991", value: 3 },
  { year: "1992", value: 4 },
  { year: "1993", value: 3.5 },
  { year: "1994", value: 5 },
  { year: "1995", value: 4.9 },
  { year: "1996", value: 6 },
  { year: "1997", value: 7 },
  { year: "1998", value: 9 },
];

const ChartSample = () => {
  const config = {
    data,
    padding: "auto",
    xField: "year",
    yField: "value",
    xAxis: {
      tickCount: 5,
    },
  };
  return (
    <Column
      {...config}
      renderer="canvas"
      seriesField="year"
      columnWidthRatio={0.8}
      columnStyle={{
        fill: "red",
        fillOpacity: 0.5,
        stroke: "black",
        lineWidth: 1,
        lineDash: [4, 5],
        strokeOpacity: 0.7,
        shadowColor: "black",
        shadowBlur: 10,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        cursor: "pointer",
      }}
      interactions={[{ type: "element-active" }]}
      state={{
        active: {
          style: {
            lineWidth: 2,
            stroke: "#000",
          },
        },
      }}
      columnBackground={{
        style: {
          fill: "#000",
          fillOpacity: 0.25,
        },
      }}
      meta={{
        year: {
          alias: "asd",
        },
      }}
      padding={40}
      color={({ year }) => {
        return year < 1997 ? "red" : "green";
      }}
      pattern={{
        type: "square",
        cfg: {
          size: 10,
          padding: 0,
          rotation: 0,
          fill: "#FFF",
          isStagger: true,
        },
      }}
      tooltip={{
        customContent: () => {
          return (
            // <div
            //   style={{
            //     width: "100%",
            //   }}
            // >
            <Column {...config} padding={50} />
            // </div>
          );
        },
      }}
    />
  );
};

const MemoizeChart = memo(ChartSample);

export const RNDPages = () => {
  const [value, setValue] = React.useState("");
  const [chips, setChips] = React.useState(["a", "basdasjkdkjasd", "safdsf"]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      {/* <CardFilterDetailsTransaction /> */}
      {/* <DropdownSalesArea /> */}

      <CustomSelect
        data={[{ a: 1 }, { b: 2 }, { c: 3 }]}
        renderItem={item => Object.values(item).join(",")}
        placeholder="place"
        value={value}
        setValue={setValue}
        renderValue={val => Object.values(val).join("")}
      />
      <Chips value={chips} onDelete={item => setChips(x => x.filter(y => y !== item))} onReset={() => setChips([])} />
      <Title title="Title" icon={<img src={IconHouse} alt="icon house" />} />
      <MemoizeChart />
      {/* <DropdownListBranding />
      <DropdwownSalesArea />
      <DropdownSales /> */}
    </div>
  );
};
