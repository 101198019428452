import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ChartBar from "elements/chart/chart-bar";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const ChartListBarStyle = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: "1px solid #E9F0FF",
  height: 50,
});

const Flex1Style = styled("div")(({ flex1 }) => ({
  flex: flex1,
}));

const Flex2Style = styled("div")(({ flex2 }) => ({
  display: "flex",
  flex: flex2,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
}));

const Flex2InStyle = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyleTypography1 = styled(Typography)({
  color: COLORS.DARK_HARD,
  fontSize: SIZES.REGULAR_12,
  fontWeight: 400,
});

const StyleTypography2 = styled(Typography)({
  color: COLORS.DARK_HARD,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 600,
  minWidth: "90px",
});

const StyleTypography3 = styled(Typography)(({ margin }) => ({
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 600,
  margin,
}));

const StyleTypography4 = styled(Typography)({
  color: COLORS.DARK_HARD,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 600,
  minWidth: "40px",
});
const ChartListBar = ({ name, percen, sum, percenNum, flex1, flex2 }) => (
  <ChartListBarStyle>
    <Flex1Style flex1={flex1}>
      <StyleTypography1 variant="subtitle1" component="div" align="left">
        {name}
      </StyleTypography1>
    </Flex1Style>
    <Flex2Style flex2={flex2}>
      <ChartBar percenNum={percenNum} />
      <Flex2InStyle>
        <StyleTypography2 variant="subtitle1" component="div" align="center">
          {percen}
        </StyleTypography2>
        <StyleTypography3 variant="subtitle1" component="div" align="center" margin="8px">
          |
        </StyleTypography3>
        <StyleTypography4 variant="subtitle1" component="div" align="center">
          {sum}
        </StyleTypography4>
      </Flex2InStyle>
    </Flex2Style>
  </ChartListBarStyle>
);

StyleTypography3.defaultProps = { margin: "0px" };

StyleTypography3.propTypes = {
  margin: PropTypes.string,
};

Flex1Style.propTypes = {
  flex1: PropTypes.number,
};

Flex1Style.propTypes = {
  flex2: PropTypes.number,
};

ChartListBar.defaultProps = {
  flex1: 0.45,
  flex2: 0.55,
};

ChartListBar.propTypes = {
  name: PropTypes.string.isRequired,
  percen: PropTypes.string.isRequired, // exp: (percen: "50%")
  sum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percenNum: PropTypes.number.isRequired, // exp: (percenNum: 0.5) value 0.1, 0.2, 0.3, dst
  flex1: PropTypes.number,
  flex2: PropTypes.number,
};

export default ChartListBar;
