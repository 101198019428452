import { useSignIn, useSignOut, useIsAuthenticated, useAuthUser } from "react-auth-kit";

export const ROLE = Object.freeze({
  ADMINISTRATOR: 1,
  SUPERVISOR: 2,
  SALES: 3,
  SUPPORT_SERVICE: 4,
  INQUIRY: 5,
  ARCO: 6,
});

export function useAuth() {
  const authSignIn = useSignIn();
  const userAuth = useAuthUser();
  const signOut = useSignOut();

  /* ------------------------------ AUTHORIZATION ----------------------------- */
  const setLoginData = ({ email, token, userData }) => {
    const role = String(userData?.role?.name).toLowerCase();
    const roleId = Number(userData?.role?.id);
    const isAuthenticatingSuccess = authSignIn({
      authState: { email, role, roleId, userData },
      tokenType: "cookie",
      token,
      expiresIn: 60 * 60,
      refreshToken: `refresh_token ${email}`,
      refreshTokenExpireIn: 60,
    });

    if (!isAuthenticatingSuccess) throw new Error("Failed Authenticating");
  };

  const isAdmin = () => userAuth().role === "administrator";

  const userData = () => {
    const data = userAuth()?.userData;
    // Signout if cookie value is not valid
    if (typeof data !== "object") {
      signOut();
      return {};
    }
    return data;
  };

  const getRole = () => {
    const roleId = userAuth()?.roleId;
    return {
      role: roleId,
      isAdmin: roleId === ROLE.ADMINISTRATOR,
      isInquiry: roleId === ROLE.INQUIRY,
    };
  };

  return {
    setLoginData,
    doSignOut: useSignOut(),
    isAuthenticated: useIsAuthenticated(),
    authData: userAuth,
    isAdmin,
    userData,
    getRole,
  };
}
