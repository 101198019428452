import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

// Colors
import COLORS from "utils/constants/colors";

const WrapperOutline = styled("div")({
  marginTop: "15px",
  border: `1px solid ${COLORS.GRAY_SOFT}`,
  borderRadius: "10px",
  padding: "20px",
  paddingTop: "20px",
  paddingBottom: "20px",
});

const WrapperOutlineChart = ({ children }) => {
  return <WrapperOutline>{children}</WrapperOutline>;
};

WrapperOutlineChart.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WrapperOutlineChart;
