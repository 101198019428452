import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const DotIcon = ({ fill, size }) => (
  <SvgIcon
    width="1"
    height="1"
    viewBox="0 0 4 4"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "block", margin: "auto" }}
  >
    <circle cx={size} cy={size} r={size} fill={fill} />
  </SvgIcon>
);

DotIcon.defaultProps = {
  fill: "white",
  size: 2,
};

DotIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default DotIcon;
