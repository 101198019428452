import { TableCell } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const TableRowDetailsRevenue = ({ data = [], ...rest }) => {
  if (!Array.isArray(data)) {
    if (process.env.NODE_ENV === "development")
      throw new Error("props data TableRowDetailsRevenue must be array of object");
    else return null;
  }
  return data?.map((x, i) => <TableCellByGroupRevenue data={x} key={x?.id ?? i} {...rest} />) ?? [];
};

TableRowDetailsRevenue.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const parseCurrency = number => {
  const formattedNumber = new Intl.NumberFormat("id-ID").format(number);
  return formattedNumber || "0";
};

const TableCellByGroupRevenue = ({ data, ...rest }) => {
  if (typeof data !== "object" || Array.isArray(data)) {
    if (process.env.NODE_ENV === "development") throw new Error("props data TableCellByGroupRevenue must be object");
    else return null;
  }
  return Object.entries(data).map(([key, val]) => (
    <TableCell key={key} {...rest}>
      {parseCurrency(val)}
    </TableCell>
  ));
};

TableCellByGroupRevenue.propTypes = {
  data: PropTypes.object.isRequired,
};

export { TableCellByGroupRevenue, TableRowDetailsRevenue };
