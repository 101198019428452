import qs from "qs";
import ENDPOINT from "utils/constants/endpoint";
import { dummyYears } from "utils/constants/mocks/dashboard/portofolio-casa";
import { orderingKeys } from "utils/helpers/general";
import { axiosPrivate } from "../axiosPrivate";

// Select Area
const getSelectByLoginCasa = async () => {
  const { data, status } = await axiosPrivate.get(`${ENDPOINT.API_DASHBOARD.LIST_SELECT.SALES_AREA}`);
  if (status !== 200) throw new Error("Select By Login Error");
  return { ...data, dataSales: data?.data };
};

const getYears = async () => {
  const { data } = dummyYears;
  const delay = () => new Promise(res => setTimeout(res, 1500));
  await delay();
  return data;
};

// Get Maps : as popup
const getCasaMap = async signal => {
  const { data, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_MAP, { signal });
  if (status !== 200) throw new Error("Failed to load map data");
  const parse = {
    data: data?.data.map(item => {
      return {
        data: item.data?.reverse() || [],
        key: item.key,
        latitude: item.latitude,
        location: item.location,
        longitude: item.longitude,
      };
    }),
  };
  return parse.data;
};

// Get Sales Type : as card
const getCasaMapSalesType = async signal => {
  const { data, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_MAP_SALES_TYPE, { signal });
  if (status !== 200) throw new Error("Failed to load map data");
  const parse = {
    data: data?.data?.map(item => {
      return {
        data: item.data?.reverse() || [],
        key: item.key,
        label: item.label,
        title: item.title,
      };
    }),
  };
  return parse.data;
};

// Overview CASA
const getOverviewCasa = async signal => {
  const { data, status } = await axiosPrivate.get(`${ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_OVERVIEW}`, { signal });
  if (status !== 200) throw new Error("Failed to load Overview CASA");
  // const parsedData = {
  //   total_volume: {
  //     month_name: data.data.total_volume?.month_name,
  //     total: data.data.total_volume?.total,
  //     total_bio: data.data.total_volume?.total_bio,
  //   },
  //   higgest_division: {
  //     name: data.data.higgest_division?.division_name,
  //     total: data.data.higgest_division?.total,
  //     total_bio: data.data.higgest_division?.total_bio,
  //   },
  //   higgest_arco: {
  //     name: data.data.higgest_arco?.depart_name,
  //     total: data.data.higgest_arco?.total,
  //     total_bio: data.data.higgest_arco?.total_bio,
  //   },
  // };
  // const getData = Object.entries(parsedData).reduce((prev, [key, val]) => [...prev, { title: key, ...val }], []);
  // return getData;
  const getData = Object.entries(data.data).reduce((prev, [key, val]) => [...prev, { title: key, ...val }], []);
  return getData;
};

// CASA By Month Sales Area
const getSalesByMonthCASA = async (salesAreaId = "") => {
  const { data: dataByMonth, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_DIAGRAM_MONTH}?salesArea=${salesAreaId}`,
  );
  if (status !== 200) throw new Error("Failed to load Data CASA By Month ");
  const addId = dataByMonth?.data?.map((item, id) => ({ ...item, id }));
  return addId;
};

// CASA Line Diagram
const getCASALine = async (salesAreaId = "") => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_CHART_LINE}?salesArea=${salesAreaId}`,
  );
  if (status !== 200) throw new Error(`[getCASALineChart] ${status}`);

  const dataChart = data?.data?.data_chart?.sort((a, b) => {
    const parseDate = date => new Date(String(date).split("/").reverse().join("-"));
    const [left, right] = [parseDate(a.date), parseDate(b.date)];
    return new Date(left) - new Date(right);
  });

  return { ...data.data, dataSalesLine: data.data, dataChart };
};

//  >>>>    GET Table   <<<<

const MONTH = {
  jan: "Jan",
  feb: "Feb",
  mar: "Mar",
  apr: "Apr",
  may: "May",
  jun: "Jun",
  jul: "Jul",
  aug: "Aug",
  sept: "Sept",
  oct: "Oct",
  nov: "Nov",
  dec: "Dec",
};

// Default Value Dropdown list target empty
const DEFAULT_VALUE_FILTER_DROPDOWN_CASA = [
  {
    id: "6",
    target_code: "AVB",
    target_name: "CASA",
  },
];

const RRHeader = (month, year) => {
  const yearSubfix = String(year).substring(2);
  return {
    run_rate: `RR ${month} ${year}`,
    month_target: `${month} ${year} (T)`,
    year_target: `YTD ${year} (T)`,
    run_rate_percent: `RR ${month} ${yearSubfix} (%)`,
    yearly_run_rate_percent: `YTD ${month} ${yearSubfix} (%)`,
  };
};

const KeysSalesVolumeHeaders = ["name", ...Object.keys(MONTH), ...Object.keys(RRHeader())];

const orderingSalesVolume = obj => orderingKeys(obj, KeysSalesVolumeHeaders);

// const getTableCASA = async (
//   filter = { year: new Date().getFullYear(), filterType: "DIV_HEAD", nameHead: { user_code: null }, target: {} },
// ) => {
//   const setYear = filter.year;
//   const setSales = filter?.nameHead?.user_code;
//   const setToggle = filter?.filterType;
//   const targets = filter.target.target_code ? filter.target.target_code : "AVB";
//   const { data, status } = await axiosPrivate.post(`${ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_TABLE}`, {
//     year: setYear,
//     filter_type: setToggle,
//     search_filter: setSales,
//     target: targets,
//   });
//   if (status !== 200) throw new Error(`[getTableDivision] ${status}`);

//   const yearResponse = data.data?.year || new Date().getFullYear();

//   const month = Object.values(MONTH)[new Date().getMonth()];
//   const subfix = String(yearResponse).substring(2);
//   const RRHeaders = Object.values(RRHeader(month, subfix));
//   const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

//   const mainData = data.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
//   const totalData = orderingSalesVolume(data.data?.total_data ?? {});

//   return { data: data.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
// };

const getDataSelectDivHeadCASA = async ({ target = "", year = "" }) => {
  const query = qs.stringify({
    target,
    year,
  });
  const { data, status } = await axiosPrivate.get(`/dashboard/transaction/portfolio/casa/division?${query}`);
  if (status !== 200) throw new Error(`[getTableDivision] ${status}`);

  const yearResponse = data?.data?.year || new Date().getFullYear();
  const month = Object.values(MONTH)[new Date().getMonth()];
  const subfix = String(yearResponse).substring(2);
  const RRHeaders = Object.values(RRHeader(month, subfix));
  const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

  const mainData = data?.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
  const totalData = orderingSalesVolume(data?.data?.total_data ?? {});

  return { data: data?.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
};

const getDataSelectArco = async ({ positionCode = "", target = "", year = "" }) => {
  const query = qs.stringify({
    divisionCode: positionCode,
    target,
    year,
  });
  const { data, status } = await axiosPrivate.get(`/dashboard/transaction/portfolio/casa/arco?${query}`);

  if (status !== 200) throw new Error(`[getDataSelectArco] ${status}`);

  const yearResponse = data?.data?.year || new Date().getFullYear();
  const month = Object.values(MONTH)[new Date().getMonth()];
  const subfix = String(yearResponse).substring(2);
  const RRHeaders = Object.values(RRHeader(month, subfix));
  const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

  const mainData = data?.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
  const totalData = orderingSalesVolume(data?.data?.total_data ?? {});

  return { data: data?.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
};

const getDataSelectSales = async ({ positionCode = "", target = "", year = "" }) => {
  const query = qs.stringify({
    arcoCode: positionCode,
    target,
    year,
  });
  const { data, status } = await axiosPrivate.get(`/dashboard/transaction/portfolio/casa/sales?${query}`);
  if (status !== 200) throw new Error(`[getDataSelectSales] ${status}`);

  const yearResponse = data?.data?.year || new Date().getFullYear();
  const month = Object.values(MONTH)[new Date().getMonth()];
  const subfix = String(yearResponse).substring(2);
  const RRHeaders = Object.values(RRHeader(month, subfix));
  const headers = Object.values(MONTH).reduce((prev, curr) => [...prev, `${curr} ${subfix}`], []);

  const mainData = data?.data?.main_data?.reduce((prev, curr) => [...prev, orderingSalesVolume(curr)], []) || [];
  const totalData = orderingSalesVolume(data?.data?.total_data ?? {});

  return { data: data?.data, headers: [...headers, ...RRHeaders], mainData, totalData, yearResponse };
};

const getSelectDivHeadCASA = async ({ year }) => {
  const { data: responseDivHead } = await axiosPrivate.get(
    `/dashboard/transaction/portfolio/casa/division/list?year=${year}`,
  );
  return responseDivHead;
};

const getSelectArcoHeadCASA = async ({ year }) => {
  const { data: response } = await axiosPrivate.get(`/dashboard/transaction/portfolio/casa/arco/list?year=${year}`);
  return response;
};

const getCategoryCasaList = async (year, signal) => {
  const { data, status } = await axiosPrivate.get(`/dashboard/transaction/portfolio/casa/target/list?year=${year}`, {
    signal,
  });
  if (status !== 200) throw new Error(`[getCategoryCasaList] ${status}`);
  return data?.data ? data.data : DEFAULT_VALUE_FILTER_DROPDOWN_CASA;
};

const downloadExcelCasa = async (filter = { year: new Date().getFullYear(), filterType: "DIV_HEAD" }) => {
  const { data: response, status } = await axiosPrivate.post("/dashboard/transaction/portfolio/casa/table/download", {
    year: filter.year,
    filter_type: filter.filterType,
  });

  if (status !== 200) throw new Error(`[downloadExcelCasa] ${status}`);
  if (status === 200) {
    fetch(response?.data?.url)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const dates = new Date();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const formattedMonth = (dates.getMonth() + 1 < 10 ? "0" : "") + (dates.getMonth() + 1);
        const formattedDate = (dates.getDate() < 10 ? "0" : "") + dates.getDate();
        const fileName = `CASA_${filter.filterType}_${
          filter.year
        }_(${dates.getFullYear()}${formattedMonth}${formattedDate}).xlsx`;
        a.href = url;
        a.download = fileName;
        a.click();
      });
  }
};

const getSelectSalesHead = async () => {
  const { data: response } = await axiosPrivate.get(`/dashboard/transaction/portfolio/casa/sales/list`);
  return response;
};

export {
  getSelectByLoginCasa,
  getYears,
  getOverviewCasa,
  getSalesByMonthCASA,
  getCASALine,
  // getTableCASA,
  getCasaMap,
  getCasaMapSalesType,
  getSelectArcoHeadCASA,
  getSelectSalesHead,
  getSelectDivHeadCASA,
  getCategoryCasaList,
  downloadExcelCasa,
  getDataSelectDivHeadCASA,
  getDataSelectArco,
  getDataSelectSales,
};
