import { makeStyles, styled } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const useStyles = makeStyles(theme => ({
  card: {
    padding: "30px 30px 30px 30px",
    borderRadius: "15px",
    borderColor: COLORS.GRAY_SOFT,
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  cardBottom: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: 90,
    zIndex: 120,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  boxMarginBottom: {
    marginBottom: "10px",
    width: "100%",
  },
  boxMinHeight26px: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "26px",
  },
  boxMinHeight30: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    minHeight: "30px",
  },
  boxPadding50px: {
    display: "flex",
    flexDirection: "row",
    padding: "50px 0px 0px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "30px",
  },
  boxPadding40px: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  boxPadding40pxGap50px: {
    display: "flex",
    flexDirection: "row",
    padding: "40px 0px 0px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "50px",
  },
  boxPadding20px: {
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    display: "auto",
    flexDirection: "column",
    minWidth: "330px",
    minHeight: "280px",
    width: "100%",
  },
  boxBorderDashed: {
    width: "100%",
    minHeight: "90px",
    border: "1px dashed",
    borderRadius: "15px",
    borderColor: COLORS.GRAY_MEDIUM,
    position: "relative",
    zIndex: "100",
  },
  boxAbsoluteLeft: {
    position: "absolute",
    zIndex: "100",
    top: -9,
    left: -12,
  },
  boxAbsoluteLeft119: {
    display: "auto",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    zIndex: "100",
    top: 15,
    left: 119,
  },
  boxNormal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "50px",
  },
  boxNormalColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: "15px",
    paddingBottom: "15px",
    alignItems: "center",
    width: "100%",
    gap: "25px",
  },
  typography18px: {
    color: COLORS.BLACK,
    fontSize: SIZES.REGULAR_18,
    fontWeight: 600,
  },
  typography18pxGrayHard: {
    color: COLORS.GRAY_HARD,
    fontSize: SIZES.REGULAR_18,
    fontWeight: 600,
  },
  typography14px: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.BLACK,
    width: "50%",
  },
  typography14px25: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.BLACK,
    width: "25%",
  },
  typography14px27: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.BLACK,
    width: "27%",
  },
  typography14pxNormal: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.BLACK,
    width: "none",
    marginRight: "5px",
  },
  typography14pxBold: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    color: COLORS.BLACK,
    width: "71%",
  },
  typography14pxBold25: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    color: COLORS.BLACK,
    width: "25%",
  },
  typography14pxBoldNormal: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    color: COLORS.BLACK,
    width: "none",
  },
  typography14pxGrayHard: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.GRAY_HARD,
  },
  typography24pxPrimaryDark: {
    fontSize: SIZES.MEDIUM_24,
    fontWeight: 700,
    color: COLORS.PRIMARY_DARK,
  },
  buttonContained: {
    marginRight: "30px",
    width: "171px",
    height: 40,
    borderRadius: "10px",
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    textTransform: "initial",
  },
  grid: {
    display: "grid",
    gap: "3rem",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  cols: {
    display: "flex",
    flexDirection: "column",
  },
  underlined: {
    height: "3rem",
    borderBottom: "1px dashed #97A5C9",
    marginBottom: "1.5rem",
  },
  flexTitle: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "1rem",
    "& .MuiTextField-root": {
      width: "65%",
    },
    "& .MuiTypography-root": {
      fontSize: SIZES.REGULAR_14,
      display: "flex",
      justifyContent: "space-between",
      paddingRight: ".5rem",
      flex: "0 0 9rem",
    },
  },
  btnReset: {
    width: "max-content",
  },
}));

export const StyledDiv = styled("div")(
  ({ setwidth, setjustify, setpaddingbottom, setborderbottom, setpaddingtop, height }) => ({
    display: "flex",
    justifyContent: setjustify || "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: setpaddingbottom || "10px",
    paddingTop: setpaddingtop || "0px",
    borderBottom: setborderbottom || "1px dashed #97A5C9",
    width: setwidth || "100%",
    marginBottom: "10px",
    height: height || "20px",
  }),
);
