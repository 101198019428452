import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import TextfieldPrivate from "components/input/text-field-private";
import ButtonContained from "elements/button/button-contained";
import FootNote from "elements/foot-note";
import Title from "elements/title";
import COLORS from "utils/constants/colors";

const FormNewPasswordStyle = styled("form")({
  width: 400,
  display: "flex",
  flexDirection: "column",
  padding: "30px",
  backgroundColor: COLORS.WHITE,
  boxShadow: "0px 8px 12px rgba(188, 200, 231, 0.4)",
  borderRadius: "15px",
});

const Space40Div = styled("div")({
  height: "40px",
});
const Space20Div = styled("div")({
  height: "20px",
});

const DivButtonStyle = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: "40px",
});

const FormNewPassword = ({ onSubmit }) => {
  const { t } = useTranslation();
  const passwordSchema = z
    .object({
      password: z
        .string()
        .min(1, t("FOOTNOTE.REQUIRED_FIELD"))
        .min(5, "Password must be at least 5 characters")
        .transform((password, ctx) => {
          const passConfirm = getValues("passwordConfirmation");

          if (passConfirm && passConfirm !== password)
            setError("passwordConfirmation", {
              type: "pattern",
              message: t("FOOTNOTE.PASSWORD_CONFIRM_UNMATCH"),
            });
          else clearErrors("passwordConfirmation");

          if (password.length < 5) ctx.addIssue({ code: "custom", message: "Password must be at least 5 characters" });
          else return password;
        }),
      passwordConfirmation: z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")),
    })
    .transform((arg, ctx) => {
      if (arg.password !== arg.passwordConfirmation)
        ctx.addIssue({
          code: "custom",
          path: ["passwordConfirmation"],
          message: t("FOOTNOTE.PASSWORD_CONFIRM_UNMATCH"),
        });

      return arg;
    });

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const handleOnSubmit = handleSubmit(data => onSubmit(data));

  return (
    <FormNewPasswordStyle onSubmit={handleOnSubmit}>
      <Title title={t("GENERAL.NEW_PASSWORD")} color={COLORS.DARK_HARD} />
      <Space40Div />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextfieldPrivate
            label={t("GENERAL.NEW_PASSWORD")}
            placeholder={t("PLACEHOLDER.INPUT_NEW_PASSWORD")}
            isInvalid={!!errors?.password?.message}
            {...field}
          />
        )}
      />
      {!!errors?.password?.message && <FootNote content={errors?.password?.message} />}

      <Space20Div />
      <Controller
        control={control}
        name="passwordConfirmation"
        render={({ field }) => (
          <TextfieldPrivate
            label={t("GENERAL.CONFIRM_NEW_PASSWORD")}
            placeholder={t("PLACEHOLDER.INPUT_NEW_PASSWORD_CONFIRM")}
            isInvalid={!!errors?.passwordConfirmation?.message}
            {...field}
          />
        )}
      />
      {!!errors?.passwordConfirmation?.message && <FootNote content={errors?.passwordConfirmation?.message} />}
      <DivButtonStyle>
        <ButtonContained content={t("GENERAL.RESET_PASSWORD")} type="submit" />
      </DivButtonStyle>
    </FormNewPasswordStyle>
  );
};

FormNewPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FormNewPassword;
