import qs from "qs";
import { axiosPrivate } from "../axiosPrivate";

const getListDownloadable = async ({ feature = "ExecutiveSummary" }) => {
  const query = qs.stringify({ feature });
  const { data, status } = await axiosPrivate.get(`/dashboard/download/user?${query}`);

  if (status !== 200) throw new Error("");
  return { data: data.data };
};

export { getListDownloadable };
