import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    textAlign: "center",
  },
  subHeader: {
    textAlign: "center",
  },
}));

const PlaceholderChart = ({ title, subTitle, height, className, style }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.wrapper, className)} style={{ height, ...style }}>
      {typeof title === "string" ? <h1 className={classes.header}>{t("PLACEHOLDER.CHART_EMPTY")}</h1> : title}
      {typeof subTitle === "string" ? (
        <p className={classes.subHeader}>{t("PLACEHOLDER.CHART_CHOOSE_ANOTHER")}</p>
      ) : (
        subTitle
      )}
    </div>
  );
};

PlaceholderChart.defaultProps = {
  title: "Empty Data",
  subTitle: null,
  height: 350,
  className: undefined,
  style: {},
};

PlaceholderChart.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  height: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default PlaceholderChart;
