import { axiosPrivate } from "../axiosPrivate";

const getDataTopAreaCoordinatorContribution = async ({ startDate, endDate }) => {
  const { status, data: responseBody } = await axiosPrivate.get(
    `/dashboard/incoming/application/top-arco-contribution?startDate=${startDate}&endDate=${endDate}`,
  );

  if (status !== 200) throw new Error(`[getDataTopAreaCoordinatorContribution] ${status}`);

  const { data } = responseBody;
  const dataResponse = data?.map((items, idx) => {
    return {
      id: idx + 1,
      name: items.name,
      percen: `${items.avgPercen}%`,
      sum: items.sum,
      percenNum: items.avgPercen / 10,
    };
  });

  return { data: dataResponse };
};

export { getDataTopAreaCoordinatorContribution };
