import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down25.svg";
import { Select } from "elements/select";

const MultipleDropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

/**
 * @type React.ForwardRefRenderFunction<React.HTMLButtonElement, Props>
 */
const SelectMultiple = forwardRef(({ value, setValue, disabled, renderValue, placeholder, children }, ref) => {
  return (
    <Select
      ref={ref}
      multiple
      value={value}
      disableUnderline
      displayEmpty
      MenuProps={MultipleDropdownProps}
      renderValue={val => renderValue?.(val, placeholder) ?? placeholder}
      IconComponent={IconChevronDown}
      onChange={e => setValue?.(e.target.value)}
      disabled={disabled}
    >
      {children}
    </Select>
  );
});

SelectMultiple.defaultProps = {
  value: null,
  renderValue: null,
  disabled: false,
  placeholder: "Placeholder",
};

const Props = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func.isRequired,
  renderValue: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
};

SelectMultiple.propTypes = Props;

export { SelectMultiple };
