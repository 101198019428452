import { makeStyles, styled, Typography, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IconNumber from "elements/dynamic-icon/icon-number";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const ListCommonStyle = styled("div")(({ borderstyle, height }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: borderstyle,
  height,
}));

const Flex1Style = styled("div")(({ flex1 }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: flex1,
}));

const Flex2Style = styled("div")(({ flex2 }) => ({
  display: "flex",
  flex: flex2,
  justifyContent: "flex-end",
}));

const StyleTypography = styled(Typography)(({ fontSize, fontWeight }) => ({
  marginLeft: "10px",
  color: COLORS.DARK_HARD,
  fontSize,
  fontWeight,
}));

const useStyles = makeStyles(() => ({
  textWrapper: {
    display: "-webkit-box",
    lineClamp: 1,
    boxOrient: "vertical",
    overflow: "hidden",
    marginLeft: "10px",
    color: COLORS.DARK_HARD,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "10px",
    width: "18vw",
  },
  tootipStyle: {
    margin: "2px",
    maxWidth: 500,
    backgroundColor: "#FFF",
    color: COLORS.DARK_HARD,
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    fontSize: 12,
  },
}));

const ListCommonPopover = ({ number, name, sum, fontWeight, borderBottomSize, height, fontSize, flex1, flex2 }) => {
  const classes = useStyles();

  return (
    <ListCommonStyle borderstyle={borderBottomSize} height={height}>
      <Flex1Style flex1={flex1}>
        <IconNumber number={number} />
        {name?.length <= 30 ? (
          <Typography noWrap className={classes.textWrapper}>
            {name}
          </Typography>
        ) : (
          <Tooltip placement="bottom-start" title={name} classes={{ tooltip: classes.tootipStyle }}>
            <Typography
              className={classes.textWrapper}
              variant="subtitle1"
              component="div"
              align="left"
              fontSize={fontSize}
            >
              {name}
            </Typography>
          </Tooltip>
        )}
      </Flex1Style>
      <Flex2Style flex2={flex2}>
        <StyleTypography variant="subtitle1" component="div" fontSize={fontSize} fontWeight={fontWeight}>
          {sum}
        </StyleTypography>
      </Flex2Style>
    </ListCommonStyle>
  );
};

StyleTypography.defaultProps = {
  fontWeight: 400,
  fontSize: SIZES.REGULAR_14,
};

StyleTypography.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
};

Flex1Style.propTypes = {
  flex1: PropTypes.number,
};

Flex1Style.propTypes = {
  flex2: PropTypes.number,
};

ListCommonStyle.propTypes = {
  borderStyle: PropTypes.string,
  height: PropTypes.number,
};

ListCommonPopover.defaultProps = {
  fontWeight: 400,
  borderBottomSize: "1px solid #E9F0FF",
  height: 50,
  fontSize: SIZES.REGULAR_14,
  flex1: 0.7,
  flex2: 0.3,
};

ListCommonPopover.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fontWeight: PropTypes.number,
  borderBottomSize: PropTypes.string,
  height: PropTypes.number,
  fontSize: PropTypes.string,
  flex1: PropTypes.number,
  flex2: PropTypes.number,
};

export default ListCommonPopover;
