import { useQuery } from "@tanstack/react-query";
import { getDataTopIncomingContribution } from "services/api/private-routes/incoming-application/topIncomingContribution";
import { dateUtility } from "utils/helpers/general/date-time";

export const useFetchDataTopIncomingContribution = date => {
  const { data, isLoading, isError, isSuccess, refetch, error } = useQuery(
    ["data-top-incoming-contribution", { ...date }],
    () =>
      getDataTopIncomingContribution({
        startDate: dateUtility(date.start).isoSeparator(),
        endDate: dateUtility(date.end).isoSeparator(),
      }),
  );

  return { data, isLoading, isError, isSuccess, refetch, error };
};
