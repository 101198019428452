import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const NEGATIVE_CASE = [
  {
    type: "Coba",
    value: 0,
    num: 0,
  },
  {
    type: "Comba",
    value: 0,
    num: 0,
  },
  {
    type: "Consumer",
    value: 0,
    num: 0,
  },
];

const getDataChartCasaPortfolioTypeChart = async signal => {
  const { data: responseBody, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.CASA_TYPE, {
    signal,
  });
  if (status !== 200) throw new Error(`[getDataChartCasaPortfolioTypeChart] ${status}`);
  const { data } = responseBody;

  const parsedData = {
    percentage: data?.percentage ? data?.percentage : 0,
    data: data
      ? [
          {
            type: "Coba",
            value: data.coba_total ? data.coba_total : 0,
            num: data.coba ? data.coba : 0,
          },
          {
            type: "Comba",
            value: data.comba_total ? data.comba_total : 0,
            num: data.comba ? data.comba : 0,
          },
          {
            type: "Consumer",
            value: data.consumer_total ? data.consumer_total : 0,
            num: data.consumer ? data.consumer : 0,
          },
        ]
      : NEGATIVE_CASE,
  };

  return parsedData;
};

export { getDataChartCasaPortfolioTypeChart };
