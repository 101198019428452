import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import iconList from "assets/icons/general/icon-list.svg";
import ListCommon from "components/list/list-common";
import TitleChart from "components/title/title-chart";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
import { parseCurrency } from "utils/helpers/useFormater";

const SpaceStyle = styled("div")({
  height: "10px",
});

const SubTopMerchantSalesVolume = ({ list, title }) => {
  return (
    <WrapperOutlineChart>
      <TitleChart icon={iconList} title={title} />
      <SpaceStyle />
      {list.map((listSale, index) => (
        <ListCommon
          number={index + 1}
          name={listSale.name}
          sum={parseCurrency(listSale.sum, { maximumFractionDigits: 10 })}
          fontWeight={600}
          key={JSON.stringify(index)}
        />
      ))}
    </WrapperOutlineChart>
  );
};

SubTopMerchantSalesVolume.defaultProps = {
  list: [],
};

SubTopMerchantSalesVolume.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string.isRequired,
};

export default SubTopMerchantSalesVolume;
