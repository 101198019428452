import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconUserFailed from "assets/icons/popup/icon-user-failed.svg";
import IconUserSuccess from "assets/icons/popup/icon-user-success.svg";
import FormRegister from "components/form/form-register";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupInformation from "components/popup/popup-information";
import useTimeOut from "hooks/useTimeOut";
import { postRegister } from "services/auth-api";
import { routes } from "utils/constants/paths";

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActive } = useCIMBLoading();
  const [openWaiting, setOpenWaiting] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [msgFailed, setMsgFailed] = useState(t("REGISTER.POPUP_REGISTER_FAILED_DESC"));
  const autoRedirectTimer = useRef(null);

  const mutation = useMutation(postRegister, {
    onSuccess: () => setOpenWaiting(true),
    onError: res => {
      setOpenFailed(true);
      const res_error = res.toString();
      const search_text = res_error.includes("Error: ");
      if (search_text === true) {
        setMsgFailed(res_error.replace("Error: ", ""));
      } else {
        setMsgFailed(res_error);
      }
    },
    onMutate: () => setActive(true),
    onSettled: () => setActive(false),
  });

  useTimeOut({
    enable: openWaiting,
    onTimeOut: () => setOpenWaiting(false),
  });

  useTimeOut({
    enable: openFailed,
    onTimeOut: () => {
      setOpenFailed(false);
      setMsgFailed(t("REGISTER.POPUP_REGISTER_FAILED_DESC"));
    },
  });

  const handleCloseWaiting = useCallback(() => navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)), [navigate]);

  const handleCloseFailed = () => {
    setOpenFailed(false);
    setMsgFailed(t("REGISTER.POPUP_REGISTER_FAILED_DESC"));
  };

  const handleCancel = useCallback(() => navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)), [navigate]);

  useEffect(() => {
    if (openWaiting) autoRedirectTimer.current = window.setTimeout(handleCloseWaiting, 5_000);
    return () => {
      if (autoRedirectTimer.current) window.clearTimeout(autoRedirectTimer.current);
    };
  }, [openWaiting, handleCloseWaiting]);

  return (
    <div>
      <FormRegister submitHandler={data => mutation.mutate(data)} cancelHandler={handleCancel} />
      <PopupInformation
        isOpen={openWaiting}
        onClose={handleCloseWaiting}
        onSubmit={handleCloseWaiting}
        icon={IconUserSuccess}
        title={t("REGISTER.POPUP_WAITING_FOR_APPROVAL")}
        description={t("REGISTER.POPUP_REGISTER_SUCCESS")}
        buttonText={t("GENERAL.CLOSE")}
      />
      <PopupInformation
        isOpen={openFailed}
        onClose={handleCloseFailed}
        onSubmit={handleCloseFailed}
        icon={IconUserFailed}
        title={t("REGISTER.POPUP_REGISTER_FAILED")}
        description={msgFailed}
        buttonText={t("GENERAL.CLOSE")}
      />
    </div>
  );
};

export default RegisterPage;
