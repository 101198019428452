import { Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { ReactComponent as IconShare } from "assets/icons/general/icon-share.svg";
import { Chips } from "components/chip";
import { Button } from "elements/button";
import { CardBase } from "elements/card";
import { Select, MenuItem, CustomSelect, SelectMultiple } from "elements/select";
import { Title } from "elements/typhography/title";
import { DropdownListMonthByYear } from "features/list-month-year";
import { DropdownSales } from "features/list-sales";
import { DropdwownSalesArea, MultipleDropdownSalesArea } from "features/sales-area";

const DesignSystemPages = () => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        textAlign: "center",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      <Typography style={{ width: "100vw" }}>Button</Typography>
      <Button>Primary</Button>
      <Button startIcon={<IconDownload />}>Primary</Button>
      <Button variant="primary">Outlined</Button>
      <Button disabled variant="primary">
        Primary disable
      </Button>
      <Button disabled variant="outlined">
        Outlined disable
      </Button>
      <Typography style={{ width: "100vw" }}>Select</Typography>
      <div style={{ backgroundColor: "white", padding: "2rem 3rem", display: "flex", gap: "5rem" }}>
        <Select disableUnderline placeholder="Native select" displayEmpty value={null}>
          <MenuItem>sad</MenuItem>
        </Select>
        <CustomSelect data={["a", "b"]} />
      </div>

      <Typography style={{ width: "100vw" }}>Card</Typography>
      <CardBase>
        Card
        <Typography>Child</Typography>
      </CardBase>
      <CardBase>
        <Title>Title</Title>
      </CardBase>
      <CardBase>
        <Title icon={<IconShare />}>Title</Title>
      </CardBase>
      <CardBase>
        <Title icon={<IconShare />} subTitle="Subtitle">
          Title
        </Title>
      </CardBase>
      <CardBase>
        <Title icon={<IconShare />} subTitle="Subtitle">
          Title
        </Title>
      </CardBase>
      <CardBase variant="small">
        <Title icon={<IconShare />} subTitle="Small padding">
          Small
        </Title>
      </CardBase>

      <Typography style={{ width: "100vw" }}>Filter</Typography>
      <CardBase
        variant="small"
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <Select disableUnderline placeholder="Native select" displayEmpty value={null}>
          <MenuItem>sad</MenuItem>
        </Select>
        <CustomSelect data={["a", "b"]} />
        <Button
          style={{
            marginLeft: "auto",
          }}
        >
          Apply
        </Button>
      </CardBase>

      <Typography style={{ width: "100vw" }}>Chips</Typography>
      <CardBase style={{ backgroundColor: "transparent" }}>
        <Chips value={["a", "b", "c"]} />
      </CardBase>

      <Typography style={{ width: "100vw" }}>Featured</Typography>
      <Typography variant="caption" style={{ width: "100vw" }}>
        Components with query
      </Typography>
      {/* <CardBase><DropdownListBranding /></CardBase> */}
      <CardBase>
        <DropdownSales />
      </CardBase>
      <CardBase>
        <MultipleDropdownSalesArea />
      </CardBase>
      <CardBase>
        <DropdwownSalesArea />
      </CardBase>
      <CardBase>
        <DropdownListMonthByYear />
      </CardBase>
      <CardBase>
        <Typography>Select Multiple</Typography>
        <SelectMultiple />
      </CardBase>
    </div>
  );
};

export { DesignSystemPages };
