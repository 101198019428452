import { Box, styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const Wrapper = styled(Box)({
  border: `1px solid ${COLORS.GRAY_MEDIUM}`,
  borderRadius: "15px",
  paddingTop: "15px",
  paddingBottom: "15px",
  minWidth: "50%",
});

const WrapperSubCard = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

WrapperSubCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WrapperSubCard;
