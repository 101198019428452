import { useQuery } from "@tanstack/react-query";
import { getListSalesArea, getSelectByLogin } from "services/api/private-routes";

const KEY_SALES_AREA = "sales-area";

const useQuerySalesArea = () => {
  return useQuery([KEY_SALES_AREA], getListSalesArea, {
    retry: 0,
    refetchOnMount: false,
    placeholderData: {
      data: [],
      parsedData: [],
      names: [],
    },
  });
};

const KEY_SALES_AREA_LOGIN = "sales-area-login";

const useQuerySalesAreaByLogin = () => {
  return useQuery([KEY_SALES_AREA_LOGIN], getSelectByLogin);
};

export { useQuerySalesArea, useQuerySalesAreaByLogin, KEY_SALES_AREA_LOGIN, KEY_SALES_AREA };
