import { makeStyles, Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { createContext, useContext, useRef, useState } from "react";
import Lottie from "react-lottie";
import CIMBLoadingLottie from "assets/lottie/loading/cimb-loading.json";

const CIMBLoadingContext = createContext(null);

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const CIMBLoadingProvider = ({ children }) => {
  const classes = useStyles();
  const [active, setActiveLoading] = useState(false);
  const firstRender = useRef(true);

  const options = {
    loop: true,
    autoplay: true,
    animationData: CIMBLoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const setActive = isActive => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setActiveLoading(isActive);
    }
  };

  return (
    <CIMBLoadingContext.Provider value={{ active, setActive }}>
      <Modal className={classes.root} open={active}>
        <Lottie options={options} height={100} width={100} />
      </Modal>
      {children}
    </CIMBLoadingContext.Provider>
  );
};

CIMBLoadingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCIMBLoading = () => {
  const ctx = useContext(CIMBLoadingContext);
  if (!ctx) {
    throw new Error("useCIMBLoading must be used within the CIMBLoadingProvider");
  }
  const { active, setActive } = ctx;
  return { active, setActive };
};

export { CIMBLoadingProvider, useCIMBLoading };
