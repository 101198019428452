import IconTopDebit from "assets/icons/dashboard/icon-top-debit.svg";
import IconTopOutlet from "assets/icons/dashboard/icon-top-outlet.svg";
import IconTopQR from "assets/icons/dashboard/icon-top-QR.svg";
import { orderingKeys } from "utils/helpers/general";

export const TRANSACTIONS_ALIASES = {
  credit_off_us: "Credit off Us",
  credit_on_us: "Credit on Us",
  debit_off_us: "Debit off Us",
  debit_on_us: "Debit on Us",
  qr: "QR",
  grand_total: "Grand Total",
};

export const KEYS_TRANSACTIONS_ALIASES = Object.keys(TRANSACTIONS_ALIASES);
export const VALUES_TRANSACTIONS_ALIASES = Object.values(TRANSACTIONS_ALIASES);

/**
 * Ordering keys transaction
 * @param {object} obj
 */
export const orderingKeysTransaction = obj => {
  return orderingKeys(obj, KEYS_TRANSACTIONS_ALIASES);
};

export const SUMMARY_OUTLET_ALIASES = {
  top_outlet: { title: "Top Outlet", icon: IconTopOutlet },
  top_debit_npg: { title: "Top Debit NPG", icon: IconTopDebit },
  top_qr: { title: "Top QR", icon: IconTopQR },
};

export const KEYS_SUMMARY_OUTLET_ALIASES = Object.keys(SUMMARY_OUTLET_ALIASES);

/**
 * Ordering keys transaction
 * @param {object} obj
 */
export const orderingKeysSummary = obj => {
  return orderingKeys(obj, KEYS_SUMMARY_OUTLET_ALIASES);
};
