import { Button, IconButton, Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import IconUsers from "assets/icons/user-management/icon-users.svg";
import IconClose from "assets/icons/user-management/icon-x-close-outline-white.svg";
import ButtonOutlined from "elements/button/button-outlined";
import { useUserManagement } from "pages/user-management/user-management-context";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  snackbarStyle: {
    backgroundColor: COLORS.WHITE,
    width: "950px",
    height: "70px",
    borderRadius: "10px",
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "15px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  buttonApprove: {
    backgroundColor: COLORS.GREEN_HARD,
    borderRadius: "10px",
    marginLeft: "20px",
    textTransform: "none",
    color: COLORS.WHITE,
  },
  iconImageStyle: {
    marginRight: "15px",
  },
  iconCloseStyle: {
    top: "-30px",
    right: "-30px",
  },
  semiBold18: {
    fontWeight: 500,
    fontSize: SIZES.REGULAR_18,
  },
});

const ToasterUserManagement = ({ isOpen, onClose }) => {
  const { checkbox, setSidePopupData, popoverConfirmationRejectUser } = useUserManagement();
  const { t } = useTranslation();
  const classes = useStyles();

  const onReject = () =>
    popoverConfirmationRejectUser({
      data: Object.keys(checkbox.checked),
    });

  const onApprove = () =>
    setSidePopupData({
      data: Object.entries(checkbox.checked).reduce((prev, [key, val]) => [...prev, { id: key, ...val }], []),
      open: true,
    });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={isOpen}
      className={classes.snackbarStyle}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.rowWrapper}>
          <img src={IconUsers} alt="icon user" className={classes.iconImageStyle} />
          <Typography className={classes.semiBold18}>
            {Object.keys(checkbox?.checked ?? {}).length} {t("USERMANAGEMENT.USER_SELECTED")}
          </Typography>
        </div>
        <div>
          <ButtonOutlined content={t("GENERAL.REJECT")} width="76px" onClick={onReject} />
          <Button variant="contained" className={classes.buttonApprove} onClick={onApprove}>
            {t("GENERAL.APPROVE")}
          </Button>
          <IconButton onClick={onClose} className={classes.iconCloseStyle}>
            <img src={IconClose} alt="icon-close" />
          </IconButton>
        </div>
      </div>
    </Snackbar>
  );
};

ToasterUserManagement.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ToasterUserManagement;
