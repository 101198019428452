export const SIZES = {
  LARGE_32: "32px",
  MEDIUM_24: "24px",
  MEDIUM_22: "22px",
  REGULAR_18: "18px",
  REGULAR_16: "16px",
  REGULAR_13: "13px",
  REGULAR_14: "14px",
  REGULAR_12: "12px",
  REGULAR_11: "11px",
  REGULAR_10: "10px",
  SMALL_8: "8px",
};
