/* eslint-disable no-console */
import MomentUtils from "@date-io/moment";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { AuthProvider } from "react-auth-kit";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CIMBLoadingProvider } from "components/loading/cimb-loading";
import { customTheme } from "utils/theme/index";
import { useRefreshToken } from "./hooks/useRefreshToken";
import Routes from "./routes";
import "assets/styles/index.style.css";
import "utils/i18n/index";
import "utils/constants/endpoint";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://950c5ae4229e4e349ddc9feb583b6218@o4504116794949632.ingest.sentry.io/4504116797177856",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.5,
  });
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  logger: {
    log: (...args) => console.log("[LOG]", ...args),
    warn: (...args) => console.log("[WARN]", ...args),
    error: (...args) => console.log("[ERROR]", ...args),
  },
});

const Render = () => {
  const refreshToken = useRefreshToken();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {/* Only SIT and dev */}
        {(process.env.NODE_ENV === "development" || window.location.host.includes("edcweb.mylab-siab.com")) && (
          <ReactQueryDevtools />
        )}
        <AuthProvider authName="_auth" authType="cookie" refresh={refreshToken}>
          <ThemeProvider theme={customTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <CssBaseline />
              <CIMBLoadingProvider>
                <Routes />
              </CIMBLoadingProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Render />
  </React.StrictMode>,
  document.getElementById("root"),
);
