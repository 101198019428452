import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const RbbIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.17266 12.5633L8.93125 9.83052C9.02148 9.74028 9.02148 9.59419 8.93125 9.50395L8.38984 8.95825C8.29961 8.86802 8.15352 8.86802 8.06328 8.95825L6.01797 10.9864L5.13281 10.0883C5.04258 9.9981 4.89648 9.9981 4.80625 10.0883L4.26055 10.6297C4.17031 10.72 4.17031 10.8661 4.26055 10.9563L5.85469 12.5633C5.93633 12.6536 6.08242 12.6536 6.17266 12.5633ZM15.1875 3.00708H11.75C11.75 1.49028 10.5168 0.25708 9 0.25708C7.4832 0.25708 6.25 1.49028 6.25 3.00708H2.8125C1.67383 3.00708 0.75 3.93091 0.75 5.06958V20.1946C0.75 21.3333 1.67383 22.2571 2.8125 22.2571H15.1875C16.3262 22.2571 17.25 21.3333 17.25 20.1946V5.06958C17.25 3.93091 16.3262 3.00708 15.1875 3.00708ZM9 2.31958C9.37813 2.31958 9.6875 2.62896 9.6875 3.00708C9.6875 3.38521 9.37813 3.69458 9 3.69458C8.62187 3.69458 8.3125 3.38521 8.3125 3.00708C8.3125 2.62896 8.62187 2.31958 9 2.31958ZM15.1875 19.8508C15.1875 20.0399 15.0328 20.1946 14.8438 20.1946H3.15625C2.96719 20.1946 2.8125 20.0399 2.8125 19.8508V5.41333C2.8125 5.22427 2.96719 5.06958 3.15625 5.06958H4.875V6.44458C4.875 6.82271 5.18437 7.13208 5.5625 7.13208H12.4375C12.8156 7.13208 13.125 6.82271 13.125 6.44458V5.06958H14.8438C15.0328 5.06958 15.1875 5.22427 15.1875 5.41333V19.8508ZM5.5625 14.3508C4.99102 14.3508 4.53125 14.8106 4.53125 15.3821C4.53125 15.9536 4.99102 16.4133 5.5625 16.4133C6.13398 16.4133 6.59375 15.9536 6.59375 15.3821C6.59375 14.8106 6.13398 14.3508 5.5625 14.3508ZM12.7812 10.5696H10.0613C10.0055 10.6469 9.97109 10.7372 9.90234 10.8059L8.75078 11.9446H12.7812C12.9703 11.9446 13.125 11.7899 13.125 11.6008V10.9133C13.125 10.7243 12.9703 10.5696 12.7812 10.5696ZM12.7812 14.6946H7.96875C7.77969 14.6946 7.625 14.8493 7.625 15.0383V15.7258C7.625 15.9149 7.77969 16.0696 7.96875 16.0696H12.7812C12.9703 16.0696 13.125 15.9149 13.125 15.7258V15.0383C13.125 14.8493 12.9703 14.6946 12.7812 14.6946Z"
      fill={fill}
    />
  </SvgIcon>
);

RbbIcon.defaultProp = {
  fill: COLORS.WHITE,
};

RbbIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default RbbIcon;
