import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const TitleTableStyle = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px",
});

const StyleTypography1 = styled(Typography)(({ flex1 }) => ({
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 500,
  flex: flex1,
}));

const StyleTypography2 = styled(Typography)(({ flex2 }) => ({
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 500,
  flex: flex2,
}));

const TitleTable = ({ title1, title2, align, flex1, flex2 }) => (
  <TitleTableStyle>
    <StyleTypography1 variant="subtitle1" component="div" align="left" flex1={flex1}>
      {title1}
    </StyleTypography1>
    <StyleTypography2 variant="subtitle2" component="div" align={align} flex2={flex2}>
      {title2}
    </StyleTypography2>
  </TitleTableStyle>
);

StyleTypography1.propTypes = {
  flex1: PropTypes.number,
};

StyleTypography2.propTypes = {
  flex2: PropTypes.number,
};

TitleTable.defaultProps = {
  align: "right",
  flex1: 0.5,
  flex2: 0.5,
  title2: undefined,
};

TitleTable.propTypes = {
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string,
  align: PropTypes.string,
  flex1: PropTypes.number,
  flex2: PropTypes.number,
};

export default TitleTable;
