/* eslint-disable react/no-array-index-key */
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import IconSortDown from "assets/icons/general/icon-sort-asc.svg";
import IconSortUp from "assets/icons/general/icon-sort-desc.svg";
import ButtonContained from "elements/button/button-contained";
import COLORS from "utils/constants/colors";
import { thousandFormatWithComma } from "utils/helpers/useFormater";
import { useStyles } from "./styled";

const stickyColumn = ["Branding", "Merchant Name"];

const DetailProfitibility = ({ data, headers, subHeaders, onSort, monthly }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const headerTable = ["Branding", ...subHeaders];

  function handleSort({ key, direction }) {
    onSort({ key, direction });
  }

  const handleToDetail = (id, branding) => {
    navigate(`/dashboard-profitability-by-merchant/${monthly}/${id}/${branding}`);
  };

  const mainHeadTableContent = headerTable.map((items, index) => {
    const mLeft = 200 * index;
    const stickyCondition = stickyColumn && stickyColumn.includes(items);

    return (
      <TableCell
        align="left"
        style={{ left: stickyCondition && mLeft }}
        className={
          stickyCondition
            ? [classes.stickyHeader, classes.tableCellReguler11]
            : [classes.tableCellReguler11, classes.tableHead]
        }
        key={index}
      >
        <div className={classes.subHeaderWrapper}>
          {items || ""}
          {items !== "Action" && (
            <div className={classes.iconSortStyle}>
              <IconButton
                className={classes.iconButtonStyle}
                onClick={() => handleSort({ key: headers[index], direction: "ASC" })}
              >
                <img src={IconSortUp} alt="icon-sort-up" width={11} />
              </IconButton>
              <IconButton
                className={classes.iconButtonStyle}
                onClick={() => handleSort({ key: headers[index], direction: "DESC" })}
              >
                <img src={IconSortDown} alt="icon-sort-down" width={11} />
              </IconButton>
            </div>
          )}
        </div>
      </TableCell>
    );
  });

  const detailsContent = data.map((items, index) => {
    const evenIndex = (index + 1) % 2 === 1;

    return (
      <TableRow className={classes.tableRow} key={index}>
        <TableCell
          align="left"
          style={{ background: evenIndex && COLORS.WHITE }}
          className={stickyColumn ? [classes.stickyColumn, classes.tableCellReguler10] : classes.tableCellReguler10}
        >
          {items.name}
        </TableCell>
        <TableCell
          align="left"
          style={{ left: stickyColumn && 200, background: evenIndex && COLORS.WHITE }}
          className={stickyColumn ? [classes.stickyColumn, classes.tableCellReguler10] : classes.tableCellReguler10}
        >
          {items.transactions.merchant_name}
        </TableCell>
        <TableCell align="left" className={classes.styleCell}>
          {items.transactions.profit}
        </TableCell>
        <TableCell align="left">{items.transactions.trb}</TableCell>
        <TableCell align="left">{items.transactions.status_edc}</TableCell>
        <TableCell align="left">{items.transactions.sales}</TableCell>
        <TableCell align="left">{thousandFormatWithComma(items.transactions.mdr_revenue)}</TableCell>
        <TableCell align="left">{thousandFormatWithComma(items.transactions.interchange_fee)}</TableCell>
        <TableCell align="left">{thousandFormatWithComma(items.transactions.edc_cost)}</TableCell>
        <TableCell align="left">{thousandFormatWithComma(items.transactions.total_profit)}</TableCell>
        <TableCell align="left">{items.transactions.mcc_group}</TableCell>
        <TableCell align="left">
          <ButtonContained content="Detail" width="72px" onClick={() => handleToDetail(items.id, items.name)} />
        </TableCell>
      </TableRow>
    );
  });

  if (data.length === 0) return null;
  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>{mainHeadTableContent}</TableRow>
        </TableHead>
        <TableBody>{detailsContent}</TableBody>
      </Table>
    </TableContainer>
  );
};

DetailProfitibility.defaultProps = {
  onSort: () => {},
};

DetailProfitibility.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  subHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  monthly: PropTypes.string.isRequired,
  onSort: PropTypes.func,
};

export default DetailProfitibility;
