import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import IconEmailFail from "assets/icons/popup/icon-email-fail.svg";
import IconMailSuccess from "assets/icons/popup/icon-mail-success.svg";
import IconStateFailed from "assets/icons/popup/icon-state-failed.svg";
import CardVerification from "components/card/card-verification";
import FormNewPassword from "components/form/form-new-password";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupInformation from "components/popup/popup-information";
import useTimeOut from "hooks/useTimeOut";
import { submitNewPassword, validatePageForgotPassword, refreshForgotPassword } from "services/auth-api";
import { routes } from "utils/constants/paths";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const token = useSearchParams()[0].get("tokenReset");
  const [refreshLinkStatus, setRefreshLinkStatus] = useState(null); // "invalid" | "success"
  const { setActive } = useCIMBLoading();
  const { t } = useTranslation();

  const validatingToken = useQuery(["validate-page-password"], () => validatePageForgotPassword({ token }), {
    retry: 0,
    cacheTime: 0,
    onSuccess: res => {
      res.state === "otp" && navigate(`${routes.AUTH.PARSE_PREFIX(routes.AUTH.OTP)}?tokenReset=${token}`);
    },
  });

  const newPasswordSubmission = useMutation(submitNewPassword, {
    onMutate: () => setActive(true),
    onSuccess: () => navigate(`${routes.AUTH.PARSE_PREFIX(routes.AUTH.OTP)}?tokenReset=${token}`),
    onSettled: () => setActive(false),
  });

  const refreshExpiredLinkForgotPassword = useMutation(() => refreshForgotPassword({ token }), {
    onSuccess: res => {
      if (res.invalid) setRefreshLinkStatus("invalid");
      else setRefreshLinkStatus("success");
    },
  });

  const submitHandler = ({ password, passwordConfirmation }) =>
    newPasswordSubmission.mutate({ password, passwordConfirmation, token });

  const onCloseModalInformation = useCallback(() => navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)), [navigate]);

  useTimeOut({
    enable: !!refreshLinkStatus,
    onTimeOut: () => onCloseModalInformation(),
  });

  // Throw user to login page if user doesnt have token
  if (!token) return <Navigate to={routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)} />;

  // Waiting until token has validated
  if (!validatingToken.data) return null;

  return (
    <div>
      <PopupInformation
        isOpen={refreshLinkStatus === "success"}
        onClose={onCloseModalInformation}
        onSubmit={onCloseModalInformation}
        icon={IconMailSuccess}
        title={t("DESCRIPTION.SUCCESSFULLY_SENT")}
        description={t("DESCRIPTION.PASSWORD_RESET_LINK_EMAIL")}
        buttonText={t("GENERAL.CLOSE")}
      />
      <PopupInformation
        isOpen={refreshLinkStatus === "invalid"}
        onClose={onCloseModalInformation}
        onSubmit={onCloseModalInformation}
        icon={IconStateFailed}
        title="Link expired"
        description="Your link is expired, please request new reset password link."
        buttonText={t("GENERAL.CLOSE")}
      />
      {validatingToken.data?.expired && (
        <CardVerification
          title={t("FORGOT_PASSWORD.EXPIRE")}
          description={t("FORGOT_PASSWORD.EXPIRE_DESC")}
          icon={IconEmailFail}
          buttonText={t("FORGOT_PASSWORD.REQUEST_NEW")}
          onSubmit={refreshExpiredLinkForgotPassword.mutate}
        />
      )}
      {validatingToken.data?.success && <FormNewPassword onSubmit={submitHandler} />}
    </div>
  );
};

export default NewPasswordPage;
