import { Button, styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const StyledButton = styled(Button)(({ fontSize, fontWeight, width }) => ({
  color: COLORS.PRIMARY_DARK,
  lineHeight: SIZES.REGULAR_18,
  textTransform: "none",
  cursor: "pointer",
  fontSize,
  fontWeight,
  width,
  margin: 0,
  padding: 0,
}));

const ButtonText = ({ content, onClick, fontSize, fontWeight, width, disabled, ...rest }) => (
  <StyledButton
    {...rest}
    onClick={onClick}
    fontSize={fontSize}
    fontWeight={fontWeight}
    width={width}
    disabled={disabled}
  >
    {content}
  </StyledButton>
);

ButtonText.defaultProps = {
  fontSize: SIZES.REGULAR_12,
  fontWeight: 500,
  width: "150px",
  disabled: false,
};

ButtonText.propTypes = {
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonText;
