import { styled, TextField as MTextField, makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useState } from "react";
import SearchIcon from "assets/icons/general/icon-search.svg";
import { getListBranding } from "services/api/private-routes/calculation-profit-and-loss";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const TextField = styled(MTextField)({
  minWidth: "200px",
  width: "10rem",
  borderRadius: "10px",
  borderColor: COLORS.GRAY_ULTRASOFT,
  fontSize: SIZES.REGULAR_14,
  backgroundColor: COLORS.GRAY_ULTRASOFT,
  alignItems: "center",
});

const useStyles = makeStyles({
  textField: {
    fontSize: SIZES.REGULAR_14,
    color: COLORS.DARK_HARD,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    borderRadius: 10,
    height: "40px",
  },
  input: {
    fontSize: "inherit",
    color: "inherit",
    padding: "0 1em",
    height: "100%",
    width: "100%",
  },
});

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();
const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

const DropdownListBranding = ({ onChange }) => {
  const [filterText, setFilterText] = useState("");
  const classes = useStyles();

  const listBranding = useQuery(["list-branding", filterText], () => getListBranding(filterText), {
    enabled: filterText?.length > 3,
    refetchOnMount: filterText?.length > 3,
  });

  return (
    <Autocomplete
      options={listBranding.data?.data || []}
      limitTags={5}
      onChange={(e, val) => onChange && onChange(e, val)}
      filterOptions={filterOptions}
      style={{
        width: "max-content",
        display: "inline",
      }}
      getOptionLabel={option => option?.branding_name || ""}
      renderInput={params => (
        <TextField
          {...params}
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
          placeholder="Branding Id"
          disabled={!listBranding.isSuccess}
          className={classes.textField}
          SelectProps={{ native: true }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <img src={SearchIcon} alt="search icon" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

DropdownListBranding.defaultProps = {
  onChange: undefined,
};

DropdownListBranding.propTypes = {
  onChange: PropTypes.func,
};

export { DropdownListBranding };
