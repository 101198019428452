import { AxiosError } from "axios";
import { downloadBlobObject } from "utils/helpers/general";
import { axiosPrivate } from "./axiosPrivate";

const getListPosition = async () => {
  const { data } = await axiosPrivate.get("/auth/getListPosition");
  return { parsedData: data.data, ...data };
};

const getListRole = async () => {
  const { data: responseBody } = await axiosPrivate.get("/auth/usermgmt/getRole");

  /**
   * @typedef {object} Roles
   * @property {object[]} data
   */
  /** @type {Roles} */
  const { data } = responseBody;

  /** @type {Array} */
  const names = data?.reduce((prev, curr) => [...prev, curr.name], []);

  const findByName = name => {
    return data.find(role => role.name === name);
  };

  return { parsedData: data, data, names, findByName };
};

const getListLocation = async () => {
  const { data } = await axiosPrivate.get("/auth/usermgmt/getListLocation");
  return { parsedData: data.data, ...data };
};

const getListSupervisor = async () => {
  const { data } = await axiosPrivate.get("/auth/usermgmt/getListSupervisor");

  if (!Array.isArray(data?.data)) throw new Error(`[Invalid response] Expected array receive ${typeof data?.data}`);
  const parsedData = data.data.map(item => ({
    ...item,
    id: item.user_code,
    name: item.full_name,
  }));
  delete data.data;

  const findByUserCode = userCode => parsedData.find(x => x.id === userCode);

  return { parsedData, findByUserCode };
};

const getListSalesArea = async () => {
  const { data: responseBody } = await axiosPrivate.get("/auth/usermgmt/getSalesArea");
  const { data } = responseBody;

  /** @type {String[]} */
  const names = data?.map(x => x.name);

  const findByName = name => data.find(x => x.name === name);

  return { parsedData: data, data, names, findByName };
};

const USER_STATUS = {
  DEFAULT: null,
  N: "Non-Active",
  A: "Active",
  S: "Submission",
  R: "Rejected",
};

const getListUser = async ({ currentPage = 0, fieldName = "fullName", filterName = "", filterStatus = "" }) => {
  const filter = Object.keys(USER_STATUS).find(x => USER_STATUS[x] === filterStatus) || "";

  const { data } = await axiosPrivate.get(
    `/auth/usermgmt/getAllUserPaginated?page=${currentPage}&size=15&filterField=${fieldName}&filterValue=${filterName}&status=${filter}`,
  );

  const parsedData = data?.data.map(item => ({
    ...item,
    salesAreaUsers: {
      id: item.salesAreaUsers.map(val => val.id) || [],
      value: item.salesAreaUsers.map(val => val.name).join(", ") || null,
    },
    status: USER_STATUS[item.status || "DEFAULT"],
  }));

  delete data.data;
  return { ...data, users: parsedData };
};

const acceptUser = async payload => {
  const body = payload.map(x => ({
    ...x,
    role: x.role_id,
    role_user: [x.role_id],
  }));
  const { status, data } = await axiosPrivate.post(`/auth/usermgmt/submission/approval`, {
    data: body,
  });

  if (status !== 200) throw new Error("[acceptUser]");

  const { total_data, total_failed } = data.data;
  return { total_data, total_failed };
};

const rejectUser = async ({ id, rejectionReason }) => {
  const { data, status } = await axiosPrivate.post(`/auth/usermgmt/submission/reject`, {
    data: id,
    reason_reject: rejectionReason,
  });
  if (status !== 200) throw new Error(data);
  return { data, status, id };
};

const resetOtpUser = async ({ id }) => {
  const { data, status } = await axiosPrivate.post(`/auth/usermgmt/otp/reset`, {
    id,
    email: "deswitaMahesayu@gmail.com", // harusnya ID
  });
  if (status !== 200) throw new Error(data);
  return status;
};

const updateStatusUser = async ({ id, userStatus }) => {
  const { data, status } = await axiosPrivate.post(`/auth/usermgmt/updateStatus`, {
    id,
    status: userStatus,
  });
  if (status !== 200) throw new Error(data);
  return status;
};

const downloadExcelTemplate = async () => {
  const { data } = await axiosPrivate.get("/auth/usermgmt/download/excel/template", {
    responseType: "blob",
  });

  downloadBlobObject(data, "template.xlsx");
};

const uploadExcel = async ({ formData }) => {
  const { data, status } = await axiosPrivate
    .post("/auth/usermgmt/upload", formData, {
      "Content-Type": "multipart/form-data",
    })
    .catch(reason => {
      if (reason instanceof AxiosError) {
        return { data: reason.response.data, status: reason.response.status };
      }
    });

  if (status !== 200) {
    return { invalid: true, data };
  }
  return { data };
};

const downloadUserData = async () => {
  const { data: responseBody, status } = await axiosPrivate.get("/auth/usermgmt/download/excel", {
    responseType: "blob",
  });

  if (status !== 200) throw new Error(`[downloadUserData] ${status}`);
  downloadBlobObject(responseBody, "user-management.xlsx");
};

export {
  getListPosition,
  getListLocation,
  getListSupervisor,
  getListRole,
  getListUser,
  getListSalesArea,
  updateStatusUser,
  acceptUser,
  rejectUser,
  resetOtpUser,
  downloadExcelTemplate,
  uploadExcel,
  downloadUserData,
};
