import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  gridWrapper: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  mt20: {
    marginTop: 20,
  },
  wrapper: {
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "5px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_13,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_13,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
    textAlign: "center"
  },
  minHCard: {
    minHeight: 500
  }
});

export default useStyles;
