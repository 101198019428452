/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconTitle from "assets/icons/chart/icon-chart-pie.svg";
import IconElipseBlue from "assets/icons/general/icon-ellipse-blue.svg";
import IconElipseRed from "assets/icons/general/icon-ellipse-red.svg";
import ChartQRType from "components/chart/chart-qr-type";
import { ServerErrorBoundary } from "components/general";
import LegendRingChart from "components/legend/legend-ring-chart";
import TitleWithIdr from "components/title/title-with-idr";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
import CardTitle from "elements/card/card-header/card-title";
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";
import CardPortfolioMerchantName from "../card-portfolio-merchant-name";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  chartWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      overflow: "hidden",
      width: "100%",
      height: "100%",
    },
  },

  gridStyles: {
    overflow: "hidden",
    width: "100%",
  },

  dataQRListWrapper: {
    overflow: "hidden",
    width: "100%",
  },

  cardQR: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },

  firstQRCard: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      paddingRight: 20,
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 20,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
      marginBottom: 15,
    },
  },

  wrapperLegend: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  errorWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CardQRType = ({ dataQRList, dataChartType }) => {
  const classes = useStyles();

  /* translations */
  const { t } = useTranslation();

  const translations = useMemo(
    () => ({
      cardTitle: t("CARD.TITLE.QR_TYPE"),
      titleWithIdr: {
        idrBio: t("PORTOFOLIO.IDR_BIO"),
        idrMio: t("PORTOFOLIO.IDR_MIO"),
        generalQr: t("GENERAL.QR"),
        weChatB2b: t("PORTOFOLIO.WECHAT_AND_B2B"),
      },
      legendRingChart: {
        onUs: t("PORTOFOLIO.ON_US"),
        offUs: t("PORTOFOLIO.OFF_US"),
        weChat: t("PORTOFOLIO.WECHAT"),
        b2b: t("PORTOFOLIO.B2B"),
      },
    }),
    [t],
  );

  /* memo fetching */
  const DATA_CHART_TYPE_QR = useMemo(() => {
    if (dataChartType.isError) {
      return <ServerErrorBoundary onClick={() => dataChartType.refetch()} title={dataChartType.error?.message} />;
    }
    if (dataChartType.isLoading) {
      return <LoadingSpinner height={200} />;
    }
    if (!dataChartType.isError && dataChartType.isSuccess) {
      const stytles = classes;
      const colors = ["type", `${COLORS.BLUE_MEDIUM}-${COLORS.RED_MEDIUM}`];
      return (
        <>
          <ChartQRType dataChart={dataChartType?.data?.qr} total={dataChartType?.data?.totalQR} colors={colors} />
          <div className={stytles.wrapperLegend}>
            {dataChartType?.data?.qr.map((items, idx) => {
              return (
                <LegendRingChart
                  key={idx}
                  title={
                    items.type === "On Us" ? translations.legendRingChart.onUs : translations.legendRingChart.offUs
                  }
                  percent={items.value}
                  imgElipse={items.type === "On Us" ? IconElipseBlue : IconElipseRed}
                />
              );
            })}
          </div>
        </>
      );
    }
  }, [classes, dataChartType, translations]);

  const DATA_CHART_WE_CHAT_B2B = useMemo(() => {
    if (dataChartType.isError) {
      return <ServerErrorBoundary onClick={() => dataChartType.refetch()} title={dataChartType.error?.message} />;
    }
    if (dataChartType.isLoading) {
      return <LoadingSpinner height={200} />;
    }
    if (!dataChartType.isError && dataChartType.isSuccess) {
      const stytles = classes;
      const colors = ["type", `${COLORS.BLUE_MEDIUM}-${COLORS.RED_MEDIUM}`];
      return (
        <>
          <ChartQRType
            dataChart={dataChartType?.data?.weChatB2b}
            total={dataChartType?.data?.totalWeChatB2b}
            colors={colors}
          />
          <div className={stytles.wrapperLegend}>
            {dataChartType?.data?.weChatB2b.map((items, idx) => {
              return (
                <LegendRingChart
                  key={idx}
                  title={
                    items.type === "We Chat" ? translations.legendRingChart.weChat : translations.legendRingChart.b2b
                  }
                  percent={items.value}
                  imgElipse={items.type === "We Chat" ? IconElipseBlue : IconElipseRed}
                />
              );
            })}
          </div>
        </>
      );
    }
  }, [classes, dataChartType, translations]);

  const DATA_QR_LIST_MERCHANT = useMemo(() => {
    if (dataQRList.isError) {
      const styles = classes;
      return (
        <CardWrapper flexSize={1} heightSize="100%">
          <div className={styles.errorWrapper}>
            <ServerErrorBoundary onClick={() => dataQRList.refetch()} title={dataQRList.error?.message} />
          </div>
        </CardWrapper>
      );
    }

    if (!dataQRList.isError) {
      const styles = classes;
      return (
        <Grid container className={classes.dataQRListWrapper}>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6} className={classes.gridStyles}>
            <div className={styles.firstQRCard}>
              <CardPortfolioMerchantName
                titleText={t("PORTOFOLIO.QR_ON")}
                list={dataQRList}
                data={dataQRList?.data?.qr_on_us}
              />
              <CardPortfolioMerchantName
                titleText={t("PORTOFOLIO.WECHAT")}
                list={dataQRList}
                data={dataQRList?.data?.we_chat}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6} className={classes.gridStyles}>
            <div className={styles.cardQR}>
              <CardPortfolioMerchantName
                titleText={t("PORTOFOLIO.QR_OFF")}
                list={dataQRList}
                data={dataQRList?.data?.qr_off_us}
              />
              <CardPortfolioMerchantName
                titleText={t("PORTOFOLIO.B2B")}
                list={dataQRList}
                data={dataQRList?.data?.b2b}
              />
            </div>
          </Grid>
        </Grid>
      );
    }
  }, [classes, dataQRList, t]);

  return (
    <Grid container spacing={4} className={classes.gridWrapper}>
      <Grid Grid item xs={12} md={12} sm={12} lg={4} className={classes.gridStyles}>
        <CardWrapper heightSize="100%" flexSize={1}>
          <CardTitle iconSrc={IconTitle} title={translations.cardTitle} />
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={4}
            style={{ height: "100%" }}
          >
            <Grid item style={{ width: "100%" }}>
              <WrapperOutlineChart>
                <TitleWithIdr title={translations.titleWithIdr.generalQr} titleIdr={translations.titleWithIdr.idrBio} />
                {DATA_CHART_TYPE_QR}
              </WrapperOutlineChart>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <WrapperOutlineChart>
                <TitleWithIdr title={translations.titleWithIdr.weChatB2b} titleIdr={translations.titleWithIdr.idrMio} />
                {DATA_CHART_WE_CHAT_B2B}
              </WrapperOutlineChart>
            </Grid>
          </Grid>
        </CardWrapper>
      </Grid>
      <Grid Grid item xs={12} md={12} sm={12} lg={8} className={classes.gridStyles}>
        {DATA_QR_LIST_MERCHANT}
      </Grid>
    </Grid>
  );
};

CardQRType.defaultProps = {
  dataQRList: [],
  dataChartType: [],
};

CardQRType.propTypes = {
  dataQRList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dataChartType: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CardQRType;
