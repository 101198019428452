import { IconButton, InputAdornment, InputLabel, styled, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import IconClose from "assets/icons/general/x-circle.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const StyledInputLabel = styled(InputLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  color: COLORS.DARK_HARD,
  fontSize: SIZES.REGULAR_14,
  marginBottom: "5px",
});

const StyledTextfield = styled(TextField)({
  borderRadius: "10px",
  backgroundColor: ({ isInvalid }) => (isInvalid ? COLORS.WHITE : COLORS.GRAY_ULTRASOFT),
  fontSize: SIZES.REGULAR_14,
  color: COLORS.DARK_HARD,
  border: ({ isInvalid }) => `1px solid ${isInvalid ? COLORS.PRIMARY_DARK : COLORS.GRAY_ULTRASOFT}`,
  "& .MuiInputBase-input": {
    lineHeight: "20px",
    padding: "10px",
  },
  "& .MuiInputAdornment-positionEnd": {
    marginRight: "10px",
  },
});

const TextfieldClean = forwardRef(
  ({ value, name, label, onChange, placeholder, isInvalid, clearHandler, ...rest }, ref) => {
    return (
      <div>
        <StyledInputLabel>{`${label} :`}</StyledInputLabel>
        <StyledTextfield
          {...rest}
          value={value}
          name={name}
          onChange={onChange}
          type="text"
          placeholder={placeholder}
          variant="standard"
          fullWidth
          isInvalid={isInvalid}
          ref={ref}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={clearHandler} edge="end">
                  <img src={IconClose} alt="visibility off" />
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      </div>
    );
  },
);

TextfieldClean.defaultProps = {
  isInvalid: false,
};

TextfieldClean.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  clearHandler: PropTypes.func.isRequired,
};

export default TextfieldClean;
