import { axiosPrivate } from "../axiosPrivate";

const getListBranding = async brandingId => {
  const { data, status } = await axiosPrivate.get(`/dashboard/master/branding/auto-complete?branding=${brandingId}`);
  if (status !== 200) throw new Error(`[getListBranding] ${status}`);
  return { data: data.data.reduce((prev, curr) => [...prev, { ...curr, id: curr.branding_id }], []) };
};

const getParameterCost = async ({ brandingId, period, salesId, areaId }) => {
  const { data: responseBody, status } = await axiosPrivate.post("/dashboard/calculationprofitandlos/parameter", {
    branding_id: brandingId ?? null,
    period: period ?? new Date().getFullYear(),
    sales_id: salesId || null,
    area_id: areaId || null,
  });

  if (status !== 200) throw new Error("Error");

  const {
    interchange,
    interchange_npg,
    switching_fee,
    switching_fee2,
    edc_charge,
    edc_cost,
    net_revenue_after_transaction_with_shadow,
    shadow_nii,
  } = responseBody.data;

  return {
    interchange,
    interchange_npg,
    switching_fee,
    switching_fee2,
    edc_cost,
    edc_charge,
    net_revenue_after_transaction_with_shadow,
    shadow_nii,
  };
};

export { getListBranding, getParameterCost };
