/* eslint-disable no-unused-vars */
import moment from "moment";
import qs from "qs";
import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const NEGATIVE_CASE_QR = [
  {
    type: "On Us",
    value: 0,
    total: 0,
  },
  {
    type: "Off Us",
    value: 0,
    total: 0,
  },
];

const NEGATIVE_CASE_WE_CHAT = [
  {
    type: "We Chat",
    value: 0,
    total: 0,
  },
  {
    type: "B2B",
    value: 0,
    total: 0,
  },
];

const getDataChartType = async ({ month, year }, signal) => {
  const query = qs.stringify({
    month: new Date(month).getMonth() + 1,
    year: moment(year).format("YYYY"),
  });

  const { data: responseBody, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/dashboard/transaction/portfolio/portfolio-management-qr-type?${query}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartType] ${status}`);

  const { data } = responseBody;

  const qrData = Object.entries(data || {}).reduce((items, [key, val]) => {
    if (key === "qr") {
      const { total_qr, ...obj } = val;

      const datas = [
        {
          type: "On Us",
          value: obj.on_us,
          total: obj.total_on_us,
        },
        {
          type: "Off Us",
          value: obj.off_us,
          total: obj.total_off_us,
        },
      ];
      return datas;
    }
    return items;
  }, {});

  const weChatB2bData = Object.entries(data || {}).reduce((items, [key, val]) => {
    if (key === "we_chat_b2b") {
      const { total_we_chat_b2b, ...obj } = val;
      const datas = [
        {
          type: "We Chat",
          value: obj.we_chat,
          total: obj.total_wechat,
        },
        {
          type: "B2B",
          value: obj.b2b,
          total: obj.total_b2b,
        },
      ];
      return datas;
    }
    return items;
  }, {});

  const parsedData = {
    totalQR: data?.qr?.total_qr ? data?.qr?.total_qr : 0,
    totalWeChatB2b: data?.we_chat_b2b?.total_we_chat_b2b ? data?.we_chat_b2b?.total_we_chat_b2b : 0,
    qr: data.qr ? qrData : NEGATIVE_CASE_QR,
    weChatB2b: data.we_chat_b2b ? weChatB2bData : NEGATIVE_CASE_WE_CHAT,
  };

  return parsedData;
};

export { getDataChartType };
