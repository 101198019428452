import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// Assets
import IconTitle from "assets/icons/chart/icon-chart-pie.svg";

// Components
import { ServerErrorBoundary } from "components/general";
import LegendDetailDonutChart from "components/legend/legend-detail-donut-chart";
import TitleCharts from "components/title/title-charts";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";

// Elements
import CardTitle from "elements/card/card-header/card-title";
import ChartRing from "elements/chart/chart-ring";
import LoadingSpinner from "elements/loading/spinner";

// Colors
import COLORS from "utils/constants/colors";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  gridStyles: {
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
}));

const CardPortfolioChart = ({ dataCardPortfolioChart }) => {
  const classes = useStyles();

  /* translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      cardTitle: t("CARD.TITLE.PORTFOLIO_CHART"),
      titleCharts: {
        menuCasa: t("MENU.CASA"),
        salesVolume: t("MENU.SALES_VOLUME"),
        qrVolume: t("PORTOFOLIO.QR_VOLUME"),
        transaction: t("PORTOFOLIO.TRANSACTION"),
        idrBio: t("PORTOFOLIO.IDR_BIO"),
      },
    }),
    [t],
  );

  /* memo fetching */
  const DATA_SALES_VOLUME = useMemo(() => {
    if (dataCardPortfolioChart.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataCardPortfolioChart.isError) {
      return (
        <div>
          <ChartRing
            colorFill={COLORS.BLUE_MEDIUM}
            colorRing={COLORS.BLUE_MEDIUM}
            percenteRing={dataCardPortfolioChart?.data?.data?.sales_volume.percentage}
            contentText={
              dataCardPortfolioChart?.data?.data?.sales_volume.value
                ? dataCardPortfolioChart?.data?.data?.sales_volume.value
                : 0
            }
          />
          <LegendDetailDonutChart
            color={COLORS.BLUE_MEDIUM}
            value={dataCardPortfolioChart?.data?.target?.targetSalesVolume}
          />
        </div>
      );
    }
  }, [dataCardPortfolioChart]);

  const DATA_CASA = useMemo(() => {
    if (dataCardPortfolioChart.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataCardPortfolioChart.isError) {
      return (
        <div>
          <ChartRing
            colorFill={COLORS.GREEN_HARD}
            colorRing={COLORS.GREEN_HARD}
            percenteRing={dataCardPortfolioChart?.data?.data?.casa?.percentage}
            contentText={
              dataCardPortfolioChart?.data?.data?.casa?.value ? dataCardPortfolioChart?.data?.data?.casa?.value : 0
            }
          />
          <LegendDetailDonutChart
            color={COLORS.GREEN_MEDIUM}
            value={dataCardPortfolioChart?.data?.target?.targetCasa}
          />
        </div>
      );
    }
  }, [dataCardPortfolioChart]);

  const DATA_TRANSACTION = useMemo(() => {
    if (dataCardPortfolioChart.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataCardPortfolioChart.isError) {
      return (
        <div>
          <ChartRing
            colorFill={COLORS.PURPLE_HARD}
            colorRing={COLORS.PURPLE_HARD}
            percenteRing={dataCardPortfolioChart?.data?.data?.transaction?.percentage}
            contentText={
              dataCardPortfolioChart?.data?.data?.transaction?.value
                ? dataCardPortfolioChart?.data?.data?.transaction?.value
                : 0
            }
          />
          <LegendDetailDonutChart
            color={COLORS.PURPLE_HARD}
            value={dataCardPortfolioChart?.data?.target?.targetTransaction}
          />
        </div>
      );
    }
  }, [dataCardPortfolioChart]);

  const DATA_QR_VOLUME = useMemo(() => {
    if (dataCardPortfolioChart.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataCardPortfolioChart.isError) {
      return (
        <div>
          <ChartRing
            colorFill={COLORS.SECONDARY_MEDIUM}
            colorRing={COLORS.SECONDARY_MEDIUM}
            percenteRing={dataCardPortfolioChart?.data?.data?.qr_volume?.percentage}
            contentText={
              dataCardPortfolioChart?.data?.data?.qr_volume?.value
                ? dataCardPortfolioChart?.data?.data?.qr_volume?.value
                : 0
            }
          />
          <LegendDetailDonutChart
            color={COLORS.SECONDARY_MEDIUM}
            value={dataCardPortfolioChart?.data?.target?.targetQrVolume}
          />
        </div>
      );
    }
  }, [dataCardPortfolioChart]);

  return (
    <CardWrapper>
      <CardTitle iconSrc={IconTitle} title={translations.cardTitle} />
      {dataCardPortfolioChart.isError && (
        <ServerErrorBoundary
          onClick={() => dataCardPortfolioChart.refetch()}
          title={dataCardPortfolioChart.error?.message}
        />
      )}
      {!dataCardPortfolioChart.isError && (
        <Grid container className={classes.gridWrapper} spacing={3}>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <WrapperOutlineChart>
              <TitleCharts
                textTitle={translations.titleCharts.salesVolume}
                textSubtitle={translations.titleCharts.idrBio}
              />
              {/* Chart Sales Volume */}
              {DATA_SALES_VOLUME}
            </WrapperOutlineChart>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <WrapperOutlineChart>
              <TitleCharts
                textTitle={translations.titleCharts.menuCasa}
                textSubtitle={translations.titleCharts.idrBio}
              />
              {/* Chart CASA */}
              {DATA_CASA}
            </WrapperOutlineChart>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <WrapperOutlineChart>
              <TitleCharts textTitle={translations.titleCharts.transaction} textSubtitle="(.000)" />
              {/* Chart Transaction */}
              {DATA_TRANSACTION}
            </WrapperOutlineChart>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <WrapperOutlineChart>
              <TitleCharts
                textTitle={translations.titleCharts.qrVolume}
                textSubtitle={translations.titleCharts.idrBio}
              />
              {/* Chart QR Volume */}
              {DATA_QR_VOLUME}
            </WrapperOutlineChart>
          </Grid>
        </Grid>
      )}
    </CardWrapper>
  );
};

CardPortfolioChart.defaultProps = {
  dataCardPortfolioChart: [],
};

CardPortfolioChart.propTypes = {
  dataCardPortfolioChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CardPortfolioChart;
