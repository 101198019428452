import { makeStyles } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const useStyles = makeStyles({
  buttonContained: {
    borderRadius: "10px",
    gap: "10px",
    fontWeight: 500,
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    padding: "10px 18px 10px 14px",
    textTransform: "none",
  },
  buttonOutlined: {
    borderRadius: "10px",
    gap: "10px",
    fontWeight: 500,
    border: `2px solid ${COLORS.PRIMARY_DARK}`,
    color: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    padding: "10px 18px 10px 14px",
    textTransform: "none",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "40px",
  },
  margin20: {
    marginRight: "20px",
  },
  searchWrapper: {
    marginTop: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "10px",
    width: "100%",
    display: "flex",
    borderRadius: "15px",
    border: `1px solid ${COLORS.GRAY_SOFT}`,
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    gap: "1.2em",
  },
  dividerWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableWrapper: {
    borderRadius: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "20px",
    marginTop: "30px",
    marginBottom: "30px",
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    display: "flex",
    flexDirection: "column",
  },
  rowsPerPage: {
    fontWeight: 400,
    fontSize: SIZES.REGULAR_16,
    lineHeight: "24px",
    color: COLORS.GRAY_SECONDARY,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  boxChip: {
    display: "flex",
    flexWrap: "wrap",
    gap: ".5rem",
    alignItems: "center",
    marginTop: "10px",
  },
  buttonReset: {
    width: "130px",
    height: "32px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "30px",
    borderColor: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    border: "2px solid",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
    whiteSpace: "nowrap",
  },
  chipCustom: {
    marginLeft: "15px",
    marginBottom: "10px",
  },
  buttonApply: {
    marginLeft: "auto",
  },
  selectWrapper: {
    minWidth: "8em",
  },
  chipContainer: {
    marginTop: "1.5rem",
  },
});
