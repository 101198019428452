import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useUserManagement } from "pages/user-management/user-management-context";

import { useStyles } from "./styled";
import UserManagementRow from "./user-management-row";

const TABLE_HEAD_LABEL = [
  "ID",
  "Full Name",
  "Email & Phone Number",
  "Role & Position",
  "Supervisor",
  "Location & Sales Area",
  "Status",
  "",
];

const UserManagementTable = ({ data }) => {
  const { checkbox, setCheckbox } = useUserManagement();
  const classes = useStyles();

  const handleCheckAllOnChange = useCallback(
    e => {
      const isChecked = e.target.checked;
      if (isChecked) setCheckbox(old => ({ ...old, checkedAll: true }));
      else setCheckbox({ checkedAll: false, checked: {} });
    },
    [setCheckbox],
  );

  useEffect(() => {
    setCheckbox({ checkedAll: false, checked: {} });
  }, [data, setCheckbox]);

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCellCheckbox}>
              <Checkbox
                className={classes.checkbox}
                onChange={handleCheckAllOnChange}
                checked={checkbox ? checkbox.checkedAll : false}
                disabled={data.filter(item => item.status === "Submission").map(item => item.id).length === 0}
              />
            </TableCell>
            {TABLE_HEAD_LABEL.map(label => (
              <TableCell key={label} className={classes.tableCellHead}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(user => (
            <UserManagementRow key={user.id} data={user} disabled={user.status !== "Submission"} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

UserManagementTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserManagementTable;
