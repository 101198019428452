import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

// elements
import Textfield from "components/input/text-field";
import ButtonContained from "elements/button/button-contained";
import ButtonText from "elements/button/button-text";
import Description from "elements/description";
import FootNote from "elements/foot-note";
import Timer from "elements/timer";
import Title from "elements/title";

// utils
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  width: "400px",
  height: "auto",
  padding: "30px",
  backgroundColor: COLORS.WHITE,
  boxShadow: "0px 8px 12px rgba(188, 200, 231, 0.4)",
  borderRadius: "15px",
});

const StyledGeneral = styled("div")(({ align, bottom, left }) => ({
  alignItems: align,
  marginBottom: bottom,
  marginLeft: left,
}));

const ResendWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0 40px",
  width: "100%",
  alignSelf: "center",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const FormOtp = ({
  onRequestNewOTP,
  limitOTP,
  email,
  timeExpired,
  timeNow,
  onTimeOut: handleTimeOut,
  onSubmit,
  isInvalidOTP,
}) => {
  const { t } = useTranslation();
  const otpSchema = z.object({ otp: z.string() });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(otpSchema),
    defaultValues: {
      otp: "",
    },
  });

  const onTimeOut = () => {
    handleTimeOut();
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <StyledGeneral>
        <Title title={t("OTP.OTP_CODE")} />
      </StyledGeneral>

      <StyledGeneral bottom="20px">
        <Description description={`${t("OTP.SENT_EMAIL")}`} boldElement={email} size={SIZES.REGULAR_14} />
      </StyledGeneral>

      <StyledGeneral bottom="20px">
        <Timer timeExpired={timeExpired} onTimeOut={onTimeOut} timeStart={timeNow} />
      </StyledGeneral>

      <StyledGeneral align="left" bottom="0px">
        <Controller
          control={control}
          name="otp"
          render={({ field }) => (
            <Textfield
              label={t("OTP.OTP_CODE")}
              placeholder={t("PLACEHOLDER.INPUT_OTP_CODE")}
              isInvalid={!!errors?.otp?.message || isInvalidOTP}
              {...field}
              onChange={e => {
                e.target.value = e.target.value.replace(/\D/g, "");
                field.onChange(e);
              }}
            />
          )}
        />
      </StyledGeneral>

      {isInvalidOTP && <FootNote content={t("FOOTNOTE.OTP_UNMATCH")} />}
      {limitOTP && (
        <>
          <FootNote content={t("FOOTNOTE.OTP_LIMIT")} />
          <FootNote content={t("FOOTNOTE.TRY_AGAIN_24H")} />
        </>
      )}

      <ResendWrapper>
        {!limitOTP && (
          <>
            <Description description={t("OTP.NOT_RECEIVE_CODE")} size={SIZES.REGULAR_14} color={COLORS.DARK_HARD} />

            <ButtonText
              content={t("GENERAL.RESEND")}
              fontSize={SIZES.REGULAR_14}
              fontWeight={600}
              onClick={onRequestNewOTP}
              width="max-content"
              disabled={timeExpired?.getTime() >= timeNow?.getTime()}
            />
          </>
        )}
      </ResendWrapper>

      <ButtonWrapper>
        <ButtonContained content={t("GENERAL.VERIFICATION")} width="173px" type="submit" />
      </ButtonWrapper>
    </FormContainer>
  );
};

StyledGeneral.defaultProps = {
  align: "center",
  bottom: "5px",
  left: "0px",
};

StyledGeneral.propTypes = {
  align: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
};

FormOtp.defaultProps = {
  limitOTP: false,
};

FormOtp.propTypes = {
  onRequestNewOTP: PropTypes.func.isRequired,
  limitOTP: PropTypes.bool,
  email: PropTypes.string.isRequired,
  timeExpired: PropTypes.instanceOf(Date).isRequired,
  timeNow: PropTypes.instanceOf(Date).isRequired,
  onTimeOut: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isInvalidOTP: PropTypes.bool.isRequired,
};

export default FormOtp;
