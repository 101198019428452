import { Checkbox, ListItemText, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { ReactComponent as IconCheck } from "assets/icons/general/icon-check.svg";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down25.svg";
import { Select, MenuItem } from "elements/select";
import COLORS from "utils/constants/colors";
import { useQueryRoles } from "./use-query-roles";

const MultipleDropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles(() => ({
  checkbox: {
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
  },
}));

const KEY_CHOOSE_ALL = "CHOOSE_ALL";

const DropdownListRoles = ({ value, setValue }) => {
  const classes = useStyles();
  const queryRoles = useQueryRoles();

  const renderValue = useCallback(
    () => <span className="placeholder">{queryRoles.isSuccess ? "Sales area" : "Loading..."}</span>,
    [queryRoles.isSuccess],
  );

  const handleOnChange = useCallback(
    e => {
      const val = e.target.value;
      if (val.includes(KEY_CHOOSE_ALL)) {
        if (val.length === queryRoles.data?.data?.length + 1) return setValue([]);
        return setValue(queryRoles.data?.data);
      }

      const latest = val.pop();
      if (latest && value?.findIndex?.(x => x.id === latest.id) > -1)
        return setValue(value.filter(x => x.id !== latest.id));
      else return setValue([...value, latest]);
    },
    [setValue, value, queryRoles.data],
  );

  return (
    <Select
      multiple
      value={value}
      disableUnderline
      displayEmpty
      MenuProps={MultipleDropdownProps}
      renderValue={renderValue}
      IconComponent={IconChevronDown}
      disabled={!queryRoles.isSuccess}
      onChange={handleOnChange}
    >
      {queryRoles.isSuccess &&
        queryRoles.data?.data.map(area => (
          <MenuItem value={{ id: area.id, name: area.name }} key={area.id}>
            <Checkbox
              checked={value?.findIndex?.(x => x.id === area.id) > -1}
              icon={<IconCheck />}
              className={classes.checkbox}
            />
            <ListItemText primary={area.name} />
          </MenuItem>
        ))}
    </Select>
  );
};

DropdownListRoles.defaultProps = {
  value: [],
  setValue: () => {},
};

DropdownListRoles.propTypes = {
  value: PropTypes.array,
  setValue: PropTypes.func,
};

export { DropdownListRoles };
