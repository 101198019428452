import { Box, Grid } from "@material-ui/core";
import React from "react";
import CardExecutiveSummary from "components/card/card-executive-summary";

const ExecutiveSummary = () => {
  return (
    <Box>
      <Grid>
        <CardExecutiveSummary />
      </Grid>
    </Box>
  );
};

export default ExecutiveSummary;
