import { Box, IconButton, makeStyles, Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IconClose from "assets/icons/general/icon-close.svg";
import ButtonContained from "elements/button/button-contained";
import ButtonOutlined from "elements/button/button-outlined";
import Description from "elements/description";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxStyle: {
    width: "385px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
  },
  iconButtonBoxStyle: {
    height: "24px",
    width: "24px",
    alignSelf: "end",
  },
  imgBoxStyle: {
    marginTop: "20px",
    height: "128px",
    width: "128px",
    alignSelf: "center",
    marginBottom: "20px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  space10: {
    height: "10px",
  },
  space25: {
    height: "25px",
  },
}));

const PopupConfirmation = ({
  isOpen,
  isClose,
  onClose,
  onSubmit,
  onCancel,
  icon,
  title,
  subtitle,
  description,
  submitButtonText,
  cancelButtonText,
}) => {
  const classes = useStyles();

  let descriptionContent;

  if (typeof description === "string") {
    descriptionContent = (
      <Description description={description} align="center" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
    );
  } else if (Array.isArray(description)) {
    descriptionContent = description.map(x => (
      <Description description={x} key={x} align="center" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
    ));
  } else {
    descriptionContent = description;
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.boxStyle}>
        {(isClose || onClose) && (
          <IconButton onClick={onClose} className={classes.iconButtonBoxStyle}>
            <img src={IconClose} alt="icon-close" />
          </IconButton>
        )}
        <Title title={title} align="center" fontWeight={600} />
        <img src={icon} alt={`${icon}`} className={classes.imgBoxStyle} />
        {subtitle && (
          <div>
            <Description
              description={subtitle}
              align="center"
              size={SIZES.REGULAR_18}
              color={COLORS.DARK_MEDIUM}
              isBold
            />
            <div className={classes.space10} />
          </div>
        )}
        {descriptionContent}
        <div className={classes.space25} />
        <div className={classes.buttonStyle}>
          <ButtonOutlined content={cancelButtonText} width="100px" onClick={onCancel} />
          <ButtonContained content={submitButtonText} width="100px" onClick={onSubmit} />
        </div>
      </Box>
    </Modal>
  );
};

PopupConfirmation.defaultProps = {
  isOpen: false,
  isClose: false,
  subtitle: "",
  description: undefined,
  cancelButtonText: null,
  icon: null,
  onSubmit: () => {},
  title: null,
  submitButtonText: null,
};

PopupConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.string]),
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

export default PopupConfirmation;
