import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";

const generateInitialDatePayload = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedMonth = month < 10 ? `0${month}` : month;
  return `${year}-${formattedMonth}`;
};

const atomCalculationFilter = atomWithReset({
  sales: null,
  area: null,
  period: generateInitialDatePayload(),
  brandingId: null,
});

const useFilterCalculation = () => {
  return useAtom(atomCalculationFilter);
};

const useResetFilterCalculation = () => {
  const resetFilter = useResetAtom(atomCalculationFilter);
  return resetFilter;
};

export { useFilterCalculation, atomCalculationFilter, useResetFilterCalculation };
