/* eslint-disable no-console */
import { Box, Card, Grid, Typography, Button } from "@material-ui/core";
import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { ServerErrorBoundary } from "components/general";
import TablePortfolioCASA from "components/table/table-portfolio-casa";
import TextToggleButton from "elements/button/button-toggle-text";
import AutoCompleteDropdown from "elements/input/autocomplete-dropdown";
import CustomSelectDropdown from "elements/input/select-custom";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { useAuth } from "hooks/useAuth";
import {
  getSelectArcoHeadCASA,
  // getTableCASA,
  getSelectDivHeadCASA,
  getCategoryCasaList,
  downloadExcelCasa,
  // New
  getDataSelectDivHeadCASA,
  getDataSelectArco,
  getDataSelectSales,
} from "services/api/private-routes";

import COLORS from "utils/constants/colors";

const CURRENT_YEAR = new Date().getFullYear();
const INITIAL_FILTER = {
  year: CURRENT_YEAR,
  nameHead: null,
  filterType: "DIV_HEAD",
  target: {},
};

const DEFAULT_VALUE_FILTER_DROPDOWN_CASA = {
  id: "6",
  target_code: "AVB",
  target_name: "CASA",
};

// eslint-disable-next-line react/prop-types
const CardPortfolioTableCASA = ({ classes = {} }) => {
  const { getRole } = useAuth();
  const { t } = useTranslation();
  const { signal } = useAbortController();

  const translation = useMemo(
    () => ({
      toggle: {
        division: t("CASA.DIVISION"),
        area: t("PORTOFOLIO.AREA_COORDINATOR"),
        sales: t("BOX.TAT_PROCESS_SALES"),
      },
      general: {
        download: t("GENERAL.DOWNLOAD"),
      },
    }),
    [t],
  );
  const [toggle, setToggle] = useState(translation.toggle.division);
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const years = useMemo(() => [CURRENT_YEAR, CURRENT_YEAR - 1, CURRENT_YEAR - 2], []);
  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const divisionHeadList = useQuery(
    ["division-list-casa", filter.year],
    () => getSelectDivHeadCASA({ year: filter.year }),
    {
      enabled: toggle === translation.toggle.area,
      retry: 0,
      keepPreviousData: true,
    },
  );

  const categoryCasaList = useQuery(["category-casa-list", filter.year], () => {
    return getCategoryCasaList(filter.year, signal);
  });

  const arcoHeadList = useQuery(["arco-list-casa", filter.year], () => getSelectArcoHeadCASA({ year: filter.year }), {
    enabled: toggle === translation.toggle.sales,
    retry: 0,
    keepPreviousData: true,
  });

  // const tableCASA = useQuery(["table-casa", filter], () => getTableCASA(filter), { enabled: toggle });
  // const tableCASA = useQuery(
  //   ["table-casa", filter],
  //   () => {
  //     throw new Error();
  //   },
  //   { enabled: false },
  // );

  const casaSales = useQuery(
    ["table/casa/sales", filter],
    async () =>
      getDataSelectSales({
        positionCode: filter?.nameHead?.position_code,
        target: filter?.target?.target_code,
        year: filter.year,
      }),
    {
      enabled: toggle === translation.toggle.sales,
    },
  );

  const casaArco = useQuery(
    ["table/casa/arco", filter],
    async () =>
      getDataSelectArco({
        positionCode: filter?.nameHead?.position_code,
        target: filter?.target?.target_code,
        year: filter.year,
      }),
    {
      enabled: toggle === translation.toggle.area,
    },
  );

  const casaDivision = useQuery(
    ["table/casa/division", filter],
    async () =>
      getDataSelectDivHeadCASA({
        target: filter?.target?.target_code,
        year: filter.year,
      }),
    {
      enabled: toggle === translation.toggle.division,
    },
  );

  const activeQuery = useMemo(() => {
    const queries = {
      [translation.toggle.division]: casaDivision,
      [translation.toggle.area]: casaArco,
      [translation.toggle.sales]: casaSales,
    };
    return queries[toggle];
  }, [casaDivision, casaArco, casaSales, toggle, translation]);

  /* ------------------------- DOWNLOAD EXCEL ------------------------ */
  const downloadExcelCasaMenu = useMutation(() => downloadExcelCasa(filter));

  const handleToggleOnChange = useCallback(
    current => {
      if (current === translation.toggle.division) {
        setFilter(x => ({ ...x, year: new Date().getFullYear(), filterType: "DIV_HEAD", nameHead: null }));
      }
      if (current === translation.toggle.area) {
        setFilter(x => ({ ...x, year: new Date().getFullYear(), filterType: "ARCO", nameHead: null }));
      }
      if (current === translation.toggle.sales) {
        setFilter(x => ({ ...x, year: new Date().getFullYear(), filterType: "SALES", nameHead: null }));
      }
      setToggle(current);
    },
    [setToggle, setFilter, translation.toggle],
  );

  const activeSelect = useMemo(() => {
    const querySelect = {
      [translation.toggle.area]: divisionHeadList,
      [translation.toggle.sales]: arcoHeadList,
    };
    return querySelect[toggle];
  }, [arcoHeadList, divisionHeadList, toggle, translation]);

  const dropdownContent = useMemo(() => {
    const { area, sales } = translation.toggle;
    if (![area, sales].includes(toggle)) return null;
    return (
      <AutoCompleteDropdown
        key={toggle}
        data={activeSelect?.data?.data ?? []}
        value={filter.nameHead ?? ""}
        loading={activeSelect.isLoading}
        onChange={(x, val) => setFilter(y => ({ ...y, nameHead: val }))}
      />
    );
  }, [toggle, translation, filter.nameHead, setFilter, activeSelect]);

  const headTable = useMemo(() => {
    const heads = {
      [translation.toggle.division]: t("SALES_VOLUME.DIVISION_HEAD"),
      [translation.toggle.area]: t("SALES_VOLUME.AREA_COORDINATOR"),
      [translation.toggle.sales]: t("SALES_VOLUME.SALES"),
    };
    return heads[toggle];
  }, [toggle, translation, t]);

  const tableContent = useMemo(() => {
    if (!activeQuery || activeQuery.isError) return <ServerErrorBoundary onClick={activeQuery?.refetch} />;
    if (activeQuery?.isFetching) return <LoadingSpinner height={100} size={50} style={{}} />;
    return <TablePortfolioCASA {...activeQuery?.data} mainHead={headTable} />;
  }, [activeQuery, headTable]);

  return (
    <Card className={classes.cardTable}>
      <Box className={classes.box}>
        <TextToggleButton
          toggleData={Object.values(translation.toggle)}
          selected={toggle}
          onChange={handleToggleOnChange}
        />
        <div>
          <Grid container spacing={2}>
            <Grid item>{dropdownContent}</Grid>
            <Grid item>
              <CustomSelectDropdown
                placeholder={
                  categoryCasaList?.isFetching ? (
                    "Loading..."
                  ) : (
                    <span
                      style={{
                        color: COLORS.DARK_HARD,
                      }}
                    >
                      {DEFAULT_VALUE_FILTER_DROPDOWN_CASA.target_name}
                    </span>
                  )
                }
                disabled={categoryCasaList?.isFetching}
                arrayData={categoryCasaList?.data}
                onChange={e => setFilter(x => ({ ...x, target: e.target?.value }))}
                renderValue={val => val?.target_name}
                data={filter?.target?.target_name}
              />
            </Grid>
            <Grid item>
              <CustomSelectDropdown
                placeholder="2022"
                arrayData={years}
                onChange={e =>
                  setFilter(x => ({
                    ...x,
                    year: e.target?.value,
                    target: {
                      target_code: "AVB",
                      target_name: "CASA",
                    },
                  }))
                }
                data={filter.year}
              />
            </Grid>
            {!getRole().isInquiry && (
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={
                    downloadExcelCasaMenu.isLoading ? (
                      <LoadingSpinner height={20} size={20} style={{}} />
                    ) : (
                      <IconDownload />
                    )
                  }
                  className={classes.buttonContained}
                  onClick={() => downloadExcelCasaMenu.mutate()}
                  disabled={downloadExcelCasaMenu.isLoading}
                >
                  {translation.general.download}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Box>
      <Box className={classes.boxCentered}>
        <Typography className={classes.typographyTitle}>
          {filter.target.target_name ? filter.target.target_name : t("SIDEBAR.CASA")}
        </Typography>
        <Typography className={classes.typographyDesc}>{t("SALES_VOLUME.IDR_BIO")}</Typography>
      </Box>
      {tableContent}
    </Card>
  );
};

CardPortfolioTableCASA.propTypes = {};

export default CardPortfolioTableCASA;
