import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    relative: {
        position: "relative",
        marginTop: 20
    },
    titleAbsolute: {
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 67,
        paddingRight: 20,
        top: 0
    },
    titleChild: {
        flexShrink: 1,
        width: "100%"
    },
    minHCard: {
        minHeight: 500
    }
})

export default useStyles