import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, styled } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

// elements
import SelectInput from "components/input/select-input";
import Textfield from "components/input/text-field";
import TextfieldPrefix from "components/input/text-field-prefix";
import TextfieldPrivate from "components/input/text-field-private";
import ButtonContained from "elements/button/button-contained";
import ButtonOutlined from "elements/button/button-outlined";
import Description from "elements/description";
import FootNote from "elements/foot-note";
import Title from "elements/title";
import { getListPosition } from "services/api/private-routes";
import COLORS from "utils/constants/colors";

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  width: "840px",
  height: "auto",
  padding: "30px",
  backgroundColor: COLORS.WHITE,
  boxShadow: "0px 8px 12px rgba(188, 200, 231, 0.4)",
  borderRadius: "15px",
  position: "relative",
  zIndex: 10,
});

const StyledGeneral = styled("div")(({ align, left, bottom }) => ({
  alignItems: align,
  marginBottom: bottom,
  marginLeft: left,
}));

const InputWrapper = styled(Grid)({
  margin: "30px 0px 30px",
});

const VerticalDivider = styled("div")({
  height: "150px",
  border: `0.5px solid ${COLORS.GRAY_SOFT}`,
});

const DividerWrapper = styled(Grid)({
  alignSelf: "center",
});

const LeftInputWrapper = styled(Grid)({});

const RightInputWrapper = styled(Grid)({});

const FormRegister = ({ submitHandler, cancelHandler }) => {
  const { t } = useTranslation();

  const registerSchema = z.object({
    fullName: z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")),
    email: z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")).email(t("FOOTNOTE.INVALID_EMAIL")),
    password: z
      .string()
      .min(1, t("FOOTNOTE.REQUIRED_FIELD"))
      .transform((password, ctx) => {
        const passwordConfirmation = getValues("passwordConfirmation");
        if (passwordConfirmation && passwordConfirmation !== password)
          setError("passwordConfirmation", {
            type: "pattern",
            message: t("FOOTNOTE.PASSWORD_UNMATCH"),
          });
        else if (passwordConfirmation) {
          clearErrors("passwordConfirmation");
        }
        if (password.length < 5) ctx.addIssue({ code: "custom", message: "Password must be at least 5 characters" });
        else return password;
      }),
    passwordConfirmation: z.string().transform((pass, ctx) => {
      const password = getValues("password");
      if (pass.length === 0) ctx.addIssue({ code: "custom", message: t("FOOTNOTE.REQUIRED_FIELD") });
      else if (password !== pass) ctx.addIssue({ code: "custom", message: t("FOOTNOTE.PASSWORD_UNMATCH") });
      else return pass;
    }),
    phoneNumber: z.string().transform((phone, ctx) => {
      if (phone?.length === 0) ctx.addIssue({ code: "custom", message: t("FOOTNOTE.REQUIRED_FIELD") });
      else if (phone?.length <= 4) ctx.addIssue({ code: "custom", message: "Phone number must be atleast 4 digit" });
      else return phone;
    }),
    position: z.number({ invalid_type_error: t("FOOTNOTE.REQUIRED_FIELD") }),
  });

  const listPosition = useQuery(["list-position"], () => getListPosition(), { retry: 0 });

  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(registerSchema),
    defaultValues: {
      email: "",
      phoneNumber: "",
      fullName: "",
      position: " ",
      password: "",
      passwordConfirmation: "",
    },
  });

  const onSubmit = handleSubmit(data => {
    const payload = {
      email: data.email,
      password: data.fullName,
      phoneNumber: data.phoneNumber,
      fullName: data.fullName,
      position: {
        id: data.position,
      },
    };
    submitHandler(payload);
  });

  return (
    <FormContainer onSubmit={onSubmit}>
      <StyledGeneral bottom="0px">
        <Title title={t("REGISTER.REGISTER_YOUR_ACCOUNT")} />
      </StyledGeneral>
      <Description description={t("REGISTER.COMPLETE_FOLLOWING_FORM")} />
      <InputWrapper container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
        <LeftInputWrapper item xs={5}>
          <StyledGeneral align="left" bottom="20px">
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => (
                <Textfield
                  label={t("GENERAL.FULL_NAME")}
                  placeholder={t("PLACEHOLDER.INPUT_FULL_NAME")}
                  isInvalid={!!errors?.fullName?.message}
                  {...field}
                />
              )}
            />
            {!!errors?.fullName?.message && <FootNote content={errors?.fullName?.message} />}
          </StyledGeneral>

          <StyledGeneral align="left" bottom="20px">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Textfield
                  label={t("GENERAL.EMAIL")}
                  placeholder={t("PLACEHOLDER.INPUT_EMAIL")}
                  isInvalid={!!errors?.email?.message}
                  {...field}
                />
              )}
            />
            {!!errors?.email?.message && <FootNote content={errors?.email?.message} />}
          </StyledGeneral>

          <StyledGeneral align="left" bottom="20px">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <TextfieldPrefix
                  label={t("GENERAL.PHONE_NUMBER")}
                  placeholder={t("PLACEHOLDER.INPUT_PHONE_NUMBER")}
                  isInvalid={!!errors?.phoneNumber?.message}
                  {...field}
                />
              )}
            />
            {!!errors?.phoneNumber?.message && <FootNote content={errors?.phoneNumber?.message} />}
          </StyledGeneral>
        </LeftInputWrapper>

        <DividerWrapper item>
          <VerticalDivider />
        </DividerWrapper>

        <RightInputWrapper item xs={5}>
          <StyledGeneral align="left" bottom="20px">
            <Controller
              control={control}
              name="position"
              render={({ field }) => (
                <SelectInput
                  label={t("GENERAL.POSITION")}
                  placeholder={t("PLACEHOLDER.SELECT_POSITION")}
                  isInvalid={!!errors?.position?.message}
                  dataSelect={listPosition.data?.parsedData}
                  {...field}
                />
              )}
            />
            {!!errors?.position?.message && <FootNote content={errors?.position?.message} />}
          </StyledGeneral>

          <StyledGeneral align="left" bottom="20px">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextfieldPrivate
                  label={t("GENERAL.PASSWORD")}
                  placeholder={t("PLACEHOLDER.INPUT_PASSWORD")}
                  isInvalid={!!errors?.password?.message}
                  {...field}
                />
              )}
            />
            {!!errors?.password?.message && <FootNote content={errors?.password?.message} />}
          </StyledGeneral>

          {/* Password confirmation https://codesandbox.io/s/react-hook-form-password-match-check-standard-validation-eo6en */}
          <StyledGeneral align="left" bottom="20px">
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field }) => (
                <TextfieldPrivate
                  label={t("GENERAL.CONFIRM_PASSWORD")}
                  placeholder={t("PLACEHOLDER.INPUT_PASSWORD")}
                  isInvalid={errors?.passwordConfirmation?.message}
                  {...field}
                />
              )}
            />
            {errors?.passwordConfirmation?.message && <FootNote content={errors?.passwordConfirmation?.message} />}
          </StyledGeneral>
        </RightInputWrapper>
      </InputWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ButtonOutlined content={t("GENERAL.CANCEL")} onClick={cancelHandler} />
        <ButtonContained content={t("GENERAL.REGISTER")} type="submit" />
      </div>
    </FormContainer>
  );
};

StyledGeneral.defaultProps = {
  align: "center",
  bottom: "5px",
  left: "0px",
};

StyledGeneral.propTypes = {
  align: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
};

FormRegister.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default FormRegister;
