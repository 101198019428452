import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// Icons
import IconTitle from "assets/icons/chart/icon-chart-pie.svg";
import ElipseBlue from "assets/icons/general/icon-ellipse-blue.svg";
import ElipseGreen from "assets/icons/general/icon-ellipse-green.svg";
import ElipseGrey from "assets/icons/general/icon-ellipse-grey.svg";
import ElipseRed from "assets/icons/general/icon-ellipse-red.svg";
import ElipseYellow from "assets/icons/general/icon-ellipse-yellow.svg";

// Components
import { ServerErrorBoundary } from "components/general";
import LegendRingChart from "components/legend/legend-ring-chart";
import TitleDefault from "components/title/title-default";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
import CardTitle from "elements/card/card-header/card-title";
import DonutChartComp from "elements/chart/donut-chart";
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  gridStyles: {
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },

  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  wrapperLegend: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
  },
}));

const CardPortfolioTypeChart = ({ dataChartSales, dataChartCASA }) => {
  const classes = useStyles();

  /* translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      cardTitle: t("CARD.TITLE.PORTFOLIO_TYPE_CHART"),
      TitleDefault: {
        salesVolumeTransactionType: t("BOX.TITLE_SALES_VOLUME_TRANSACTION_TYPE"),
        casa: t("BOX.TITLE_CASA"),
      },
    }),
    [t],
  );

  /* memo fetching */
  const CHART_CASA = useMemo(() => {
    if (dataChartCASA.isError) {
      return <ServerErrorBoundary onClick={() => dataChartCASA.refetch()} title={dataChartCASA.error?.message} />;
    }
    if (dataChartCASA.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataChartCASA.isLoading && dataChartCASA.isSuccess) {
      const styles = classes;
      const colorCASAChart = [COLORS.RED_MEDIUM, COLORS.BLUE_MEDIUM, COLORS.SECONDARY_SOFT];
      const renderImgLegendCASA = type => {
        if (type === "Coba") {
          return ElipseRed;
        }
        if (type === "Comba") {
          return ElipseBlue;
        }
        if (type === "Consumer") {
          return ElipseYellow;
        }
        return ElipseGrey;
      };

      return (
        <>
          <DonutChartComp
            colorChart={colorCASAChart}
            dataDonutChart={dataChartCASA?.data.data}
            percentageLabel={dataChartCASA?.data.percentage}
          />
          <div className={styles.wrapperLegend}>
            {dataChartCASA?.data.data?.map((item, index) => (
              <div key={index ?? item}>
                <LegendRingChart
                  imgElipse={renderImgLegendCASA(item?.type) ?? ElipseGrey}
                  title={item.type}
                  percent={item.num}
                />
              </div>
            ))}
          </div>
        </>
      );
    }
  }, [dataChartCASA, classes]);

  const CHART_SALES_VOLUME = useMemo(() => {
    if (dataChartSales.isError) {
      return <ServerErrorBoundary onClick={() => dataChartSales.refetch()} title={dataChartSales.error?.message} />;
    }
    if (dataChartSales.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataChartSales.isLoading && dataChartSales.isSuccess) {
      const styles = classes;
      const colorSalesVolumeChart = [COLORS.SECONDARY_SOFT, COLORS.GREEN_LIGHT, COLORS.RED_MEDIUM, COLORS.BLUE_MEDIUM];

      const renderImgLegendSales = type => {
        if (type === "Credit Off Us") {
          return ElipseRed;
        }
        if (type === "Credit On Us") {
          return ElipseBlue;
        }
        if (type === "Debit On Us") {
          return ElipseYellow;
        }
        if (type === "Debit Off Us") {
          return ElipseGreen;
        }
        return ElipseGrey;
      };

      return (
        <>
          <DonutChartComp
            colorChart={colorSalesVolumeChart}
            dataDonutChart={dataChartSales?.data?.data}
            percentageLabel={dataChartSales?.data?.percentage}
          />
          <div className={styles.wrapperLegend}>
            {dataChartSales?.data?.data?.map((item, index) => (
              <div key={index ?? item}>
                <LegendRingChart
                  imgElipse={renderImgLegendSales(item?.type) ?? ElipseGrey}
                  title={item.type}
                  percent={item.num}
                />
              </div>
            ))}
          </div>
        </>
      );
    }
  }, [dataChartSales, classes]);

  return (
    <CardWrapper>
      <CardTitle iconSrc={IconTitle} title={translations.cardTitle} />
      <Grid container spacing={2} wrap="nowrap" className={classes.gridWrapper}>
        <Grid item xs={12} sm={12} lg={6} className={classes.gridStyles}>
          <WrapperOutlineChart>
            <div className={classes.wrapper}>
              <TitleDefault title={translations.TitleDefault.salesVolumeTransactionType} />
            </div>
            {/* Chart Sales Volume Chart */}
            {CHART_SALES_VOLUME}
          </WrapperOutlineChart>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} className={classes.gridStyles}>
          <WrapperOutlineChart>
            <div className={classes.wrapper}>
              <TitleDefault title={translations.TitleDefault.casa} />
            </div>
            {/* Chart CASA Chart */}
            {CHART_CASA}
          </WrapperOutlineChart>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

CardPortfolioTypeChart.defaultProps = {
  dataChartSales: [],
  dataChartCASA: [],
};

CardPortfolioTypeChart.propTypes = {
  dataChartSales: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dataChartCASA: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CardPortfolioTypeChart;
