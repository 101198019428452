import { Annotation, Axis, Chart, Coordinate, Point, registerShape } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";

import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

// Shape
registerShape("point", "pointer", {
  draw(cfg, container) {
    const group = container.addGroup();

    const center = this.parsePoint({ x: 0, y: 0 });
    const start = this.parsePoint({ x: 0, y: 0.5 });

    const angle1 = Math.atan((start.y - center.y) / (start.x - center.x));
    const angle = (Math.PI - 2 * angle1) * cfg.points[0].x;

    this.preAngle = angle;

    return group;
  },
});

const scale = {
  value: {
    min: 0,
    max: 1.01,
    tickInterval: 0.01,
    formatter: v => v * 100,
  },
};

const PieChartRing = ({ contents, color }) => {
  const data = [{ value: contents.percentage / 100 }];
  const startAngle = -Math.PI / 2;
  const endAngle = startAngle + Math.PI * 2;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Chart height={170} data={data} scale={scale} autoFit>
        <Coordinate type="polar" radius={0.75} startAngle={startAngle} endAngle={endAngle} />
        <Axis
          name="value"
          line={null}
          visible={false}
          label={{
            offset: -36,
            style: {
              fontSize: SIZES.REGULAR_18,
              textAlign: "center",
              textBaseline: "middle",
            },
          }}
          grid={null}
        />
        <Point position="value*1" color="#1890FF" shape="pointer" />
        <Annotation.Arc
          start={[data[0].value, 1]}
          end={[1, 1]}
          style={{
            stroke: COLORS.GRAY_SOFT,
            lineWidth: 20,
            lineDash: null,
            lineCap: "round",
          }}
        />
        <Annotation.Arc
          start={[0, 1]}
          end={[data[0].value, 1]}
          style={{
            stroke: color,
            lineCap: "round",
            lineWidth: 20,
            lineDash: null,
          }}
        />
        <Annotation.Text
          position={["50%", "45%"]}
          content={`${contents.percentage} %`}
          style={{
            fontSize: 18,
            fill: COLORS.DARK_HARD,
            textAlign: "center",
          }}
        />
        <Annotation.Text
          position={["50%", "55%"]}
          content={`${contents.total} Application`}
          style={{
            lineHeight: "240px",
            fontSize: SIZES.REGULAR_11,
            fill: COLORS.GRAY_PRIMARY,
            textAlign: "center",
          }}
        />
      </Chart>
      <div
        style={{
          height: 95,
          width: 95,
          borderRadius: "80%",
          border: `2px solid ${color}`,
          position: "absolute",
          top: 38,
        }}
      />
    </div>
  );
};

PieChartRing.defaultProps = {
  contents: {
    total: 0,
    textPercent: 0,
    percentage: 0,
  },
};

PieChartRing.propTypes = {
  contents: PropTypes.shape({
    total: PropTypes.number,
    textPercent: PropTypes.number,
    percentage: PropTypes.number,
  }),
  color: PropTypes.string.isRequired,
};

export default PieChartRing;
