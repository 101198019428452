import { Button, styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const StyledButton = styled(Button)(({ width, padding }) => ({
  border: `1.5px solid ${COLORS.PRIMARY_DARK}`,
  color: COLORS.PRIMARY_DARK,
  borderRadius: "10px",
  padding,
  gap: "10px",
  // height: "40px",
  boxShadow: "0px 3px 10px rgba(84, 163, 216, 0.25)",
  fontSize: SIZES.REGULAR_14,
  fontWeight: 500,
  width,
  "&:hover": {
    color: COLORS.PRIMARY_DARK,
    border: `1.5px solid ${COLORS.PRIMARY_DARK}`,
  },
  textTransform: "none",
  whiteSpace: "nowrap",
}));

const ButtonOutlined = ({ content, onClick, width, disabled, className, ...props }) => {
  return (
    <StyledButton
      variant="outlined"
      onClick={onClick}
      width={width}
      disabled={disabled}
      className={className}
      {...props}
    >
      {content}
    </StyledButton>
  );
};

ButtonOutlined.defaultProps = {
  width: "150px",
  disabled: false,
  className: undefined,
  content: null,
};

ButtonOutlined.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
export default ButtonOutlined;
