import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { convertTwoDigits, useCountDown } from "hooks/useCountDown";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const Timer = ({ timeExpired, onTimeOut, timeStart }) => {
  const { m, s } = useCountDown({ target: timeExpired, onTimeOut, timeStart });

  return (
    <div style={{ alignSelf: "center" }}>
      <Typography
        style={{
          color: COLORS.PRIMARY_DARK,
          fontSize: SIZES.MEDIUM_22,
          fontWeight: 700,
        }}
      >
        {convertTwoDigits(m)} : {convertTwoDigits(s)}
      </Typography>
    </div>
  );
};

Timer.propTypes = {
  timeExpired: PropTypes.instanceOf(Date).isRequired,
  timeStart: PropTypes.instanceOf(Date).isRequired,
  onTimeOut: PropTypes.func.isRequired,
};

export default Timer;
