import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Chips } from "components/chip";
import ButtonContained from "elements/button/button-contained";
import TextToggleButton from "elements/button/button-toggle-text";
import { CardBase } from "elements/card";
import TextfieldSearch from "elements/input/text-field-search";
import { TextFieldSelect } from "elements/input/text-field-select";
import { CustomSelect } from "elements/select";
import { MultipleDropdownSalesArea } from "features/sales-area";
import { getObjKey } from "utils/helpers/general";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.2em",
    alignItems: "center",
  },
  selectWrapper: {
    minWidth: "8em",
  },
  buttonApply: {
    marginLeft: "auto",
  },
});

export const INITIAL_FILTER = {
  search: "",
  typeSearch: "merchant",
  filterSalesArea: [],
  annualFilter: "",
};

const CardFilterDetailsTransaction = ({ activeToggle, setActiveToggle, onApply }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const translations = useMemo(
    () => ({
      annualFilter: {
        year: t("GENERAL.YEARLY"),
        month: t("GENERAL.MONTHLY"),
        onGoing: t("GENERAL.ONGOING"),
      },
      searchBar: {
        merchant: t("GENERAL.MERCHANT"),
        group: t("GENERAL.GROUP"),
      },
      toggle: {
        merchant: t("GENERAL.MERCHANT"),
        outlet: t("GENERAL.OUTLET"),
      },
      search: t("GENERAL.SEARCH"),
    }),
    [t],
  );

  const handleOnApply = useCallback(() => {
    if (onApply) onApply(filter);
  }, [filter, onApply]);

  const handleChangeToggle = useCallback(
    toggle => {
      if (setActiveToggle) setActiveToggle(getObjKey(translations.toggle, toggle));
      setFilter(INITIAL_FILTER);
    },
    [setActiveToggle, translations],
  );

  return (
    <>
      <CardBase className={classes.container}>
        {activeToggle === "merchant" ? (
          <TextFieldSelect
            dataSelect={Object.values(translations.searchBar)}
            selected={translations.searchBar[filter.typeSearch]}
            onSelect={val => setFilter(old => ({ ...old, typeSearch: getObjKey(translations.searchBar, val) }))}
            search={filter.search}
            onSearch={val => setFilter(old => ({ ...old, search: val }))}
            placeholder={translations.search}
            classNameSelect={classes.selectWrapper}
          />
        ) : (
          <TextfieldSearch
            onChange={val => setFilter(old => ({ ...old, search: val }))}
            value={filter.search}
            placeholder={translations.search}
          />
        )}
        <MultipleDropdownSalesArea
          value={filter.filterSalesArea}
          setValue={val => setFilter(old => ({ ...old, filterSalesArea: val }))}
        />
        <CustomSelect
          placeholder={translations.annualFilter.year}
          data={Object.values(translations.annualFilter)}
          value={filter.annualFilter}
          setValue={val => setFilter(old => ({ ...old, annualFilter: val }))}
        />
        <TextToggleButton
          selected={translations.toggle[activeToggle]}
          toggleData={Object.values(translations.toggle)}
          onChange={handleChangeToggle}
        />
        <ButtonContained content="Apply" width="72px" onClick={handleOnApply} className={classes.buttonApply} />
      </CardBase>
      {filter.filterSalesArea.length > 0 && (
        <Chips
          value={filter.filterSalesArea ?? []}
          renderItem={item => item.name}
          onDelete={item =>
            setFilter(old => ({ ...old, filterSalesArea: old.filterSalesArea.filter(y => y.name !== item.name) }))
          }
          onReset={() => setFilter(old => ({ ...old, filterSalesArea: [] }))}
        />
      )}
    </>
  );
};

CardFilterDetailsTransaction.defaultProps = {};

CardFilterDetailsTransaction.propTypes = {
  activeToggle: PropTypes.string.isRequired,
  setActiveToggle: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export { CardFilterDetailsTransaction };
