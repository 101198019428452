import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const BetterWorseIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.62535 17.2857H7.46463V0.785714C7.46463 0.57733 7.38185 0.37748 7.2345 0.23013C7.08715 0.0827803 6.8873 0 6.67892 0H5.8932C5.68482 0 5.48497 0.0827803 5.33762 0.23013C5.19027 0.37748 5.10749 0.57733 5.10749 0.785714V17.2857H2.94677C2.83163 17.2857 2.71899 17.3193 2.62277 17.3826C2.52655 17.4458 2.45097 17.5359 2.40537 17.6416C2.35976 17.7473 2.34613 17.8641 2.36616 17.9775C2.38619 18.0909 2.439 18.1959 2.51807 18.2796L5.85736 21.8154C5.91243 21.8736 5.97881 21.92 6.05244 21.9517C6.12607 21.9835 6.2054 21.9998 6.28557 21.9998C6.36574 21.9998 6.44507 21.9835 6.5187 21.9517C6.59232 21.92 6.65871 21.8736 6.71378 21.8154L10.0531 18.2796C10.1321 18.196 10.1849 18.091 10.2049 17.9777C10.225 17.8644 10.2115 17.7478 10.166 17.6421C10.1205 17.5364 10.0451 17.4463 9.94899 17.383C9.85292 17.3197 9.74041 17.2858 9.62535 17.2857V17.2857ZM19.4821 3.72036L16.1428 0.184643C16.0878 0.126386 16.0214 0.0799804 15.9478 0.0482647C15.8741 0.0165491 15.7948 0.000190701 15.7146 0.000190701C15.6345 0.000190701 15.5551 0.0165491 15.4815 0.0482647C15.4079 0.0799804 15.3415 0.126386 15.2864 0.184643L11.9471 3.72036C11.8681 3.80403 11.8153 3.90903 11.7952 4.02237C11.7752 4.13572 11.7888 4.25245 11.8343 4.35816C11.8799 4.46387 11.9554 4.55393 12.0515 4.61722C12.1477 4.68051 12.2602 4.71425 12.3753 4.71429H14.5361V21.2143C14.5361 21.4227 14.6188 21.6225 14.7662 21.7699C14.9135 21.9172 15.1134 22 15.3218 22H16.1075C16.3159 22 16.5157 21.9172 16.6631 21.7699C16.8104 21.6225 16.8932 21.4227 16.8932 21.2143V4.71429H19.0539C19.169 4.71425 19.2816 4.68051 19.3777 4.61722C19.4739 4.55393 19.5494 4.46387 19.5949 4.35816C19.6405 4.25245 19.6541 4.13572 19.634 4.02237C19.614 3.90903 19.5612 3.80403 19.4821 3.72036V3.72036Z"
      fill={fill}
    />
  </SvgIcon>
);

BetterWorseIcon.defaultProp = {
  fill: COLORS.WHITE,
};

BetterWorseIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default BetterWorseIcon;
