import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import calculatorSquare from "assets/icons/general/icon-calculator-square.svg";
import countSquare from "assets/icons/general/icon-count-square.svg";
import { ServerErrorBoundary } from "components/general";
import { CardBase } from "elements/card";
import LoadingSpinner from "elements/loading/spinner";
import { useStyles } from "./styled";
import { useQueryExistingCalculation } from "./use-query-existing-calculation";

const parseCurrency = (number = 0) => {
  const formattedNumber = new Intl.NumberFormat("id-ID").format(number);
  return formattedNumber || "0";
};

const CardExistingCalculation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryExistingCalculation = useQueryExistingCalculation();

  const handleOnRetry = useCallback(() => queryExistingCalculation.refetch(), [queryExistingCalculation]);

  const renderCardContent = useCallback(
    (grandTotal = {}, miscTotal = {}) => {
      return (
        <Box className={classes.cardSummary}>
          {Object.entries(grandTotal).map(([key, val]) => (
            <div className={clsx(classes.row, classes.rowGrandTotal)} key={key}>
              <Typography className={classes.column1}>{key}</Typography>
              <Typography className={classes.column2}>:</Typography>
              <Typography className={classes.column3}>{parseCurrency(val)}</Typography>
            </div>
          ))}
          {Object.entries(miscTotal).map(([key, val]) => (
            <div className={clsx(classes.row)} key={key}>
              <Typography className={classes.column1}>{key}</Typography>
              <Typography className={classes.column2}>:</Typography>
              <Typography className={classes.column3}>{parseCurrency(val)}</Typography>
            </div>
          ))}
        </Box>
      );
    },
    [classes],
  );

  const content = useMemo(() => {
    if (queryExistingCalculation?.isError) return <ServerErrorBoundary onClick={handleOnRetry} />;
    if (queryExistingCalculation?.isLoading || !queryExistingCalculation?.data) return <LoadingSpinner height={400} />;
    const { data } = queryExistingCalculation.data;
    if (!data) return null;
    return (
      <>
        <Box className={classes.boxPadding40px}>
          <Box className={classes.boxBorderDashed}>
            <Box className={classes.boxAbsoluteLeft}>
              <img src={calculatorSquare} alt="calculatorIcon" />
            </Box>
            <Box className={classes.boxAbsoluteLeft119}>
              <Typography className={classes.typography14pxGrayHard}>{t("GENERAL.NET_REVENUE")}</Typography>
              <Typography className={classes.typography24pxPrimaryDark}>
                {parseCurrency(data.netRevenue) ? parseCurrency(data.netRevenue) : 0}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.boxBorderDashed}>
            <Box className={classes.boxAbsoluteLeft}>
              <img src={countSquare} alt="calculatorIcon" />
            </Box>
            <Box className={classes.boxAbsoluteLeft119}>
              <Typography className={classes.typography14pxGrayHard}>{t("GENERAL.NET_REVENUE_SHADOW")}</Typography>
              <Typography className={classes.typography24pxPrimaryDark}>
                {parseCurrency(data.netRevenueShadow) ? parseCurrency(data.netRevenueShadow) : 0}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.boxPadding30px}>
          {renderCardContent(
            {
              [t("GENERAL.SALES_VOLUME")]: data.salesVolume.value,
            },
            {
              [t("GENERAL.CREDIT_ON_US")]: data.salesVolume.creditOnUs,
              [t("GENERAL.CREDIT_OFF_US")]: data.salesVolume.creditOffUs,
              [t("GENERAL.DEBIT_ON_US")]: data.salesVolume.debitOnUs,
              [t("GENERAL.DEBIT_OFF_US")]: data.salesVolume.debitOffUs,
              [t("GENERAL.QR")]: data.salesVolume.qr,
            },
          )}
          {renderCardContent(
            {
              [t("GENERAL.REVENUE")]: data.revenue.value,
            },
            {
              [t("GENERAL.CREDIT_ON_US")]: data.revenue.creditOnUs,
              [t("GENERAL.CREDIT_OFF_US")]: data.revenue.creditOffUs,
              [t("GENERAL.DEBIT_ON_US")]: data.revenue.debitOnUs,
              [t("GENERAL.DEBIT_OFF_US")]: data.revenue.debitOffUs,
              [t("GENERAL.QR")]: data.revenue.qr,
            },
          )}
          {renderCardContent(
            {
              [t("GENERAL.COST")]: data.cost.value,
            },
            {
              [t("GENERAL.INTERCHANGE")]: data.cost.interchange,
              [t("GENERAL.INTERCHANGE_NPG")]: data.cost.interchange_npg,
              [t("GENERAL.SWITCHING")]: data.cost.switching_fee,
            },
          )}
        </Box>
      </>
    );
  }, [queryExistingCalculation, t, renderCardContent, classes, handleOnRetry]);

  return (
    <CardBase flex>
      <Typography className={classes.typography18px}>{t("GENERAL.EXISTING_CALCULATION")}</Typography>
      {content}
    </CardBase>
  );
};

export { CardExistingCalculation };
