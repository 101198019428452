/* eslint-disable react/jsx-filename-extension */
import { styled } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// ICON
import iconList from "assets/icons/general/icon-list.svg";

// COMPONENT
import { ServerErrorBoundary } from "components/general";
import ListCommonPopover from "components/list/list-common-popover";
import TitleChart from "components/title/title-chart";
import TitleTable from "components/title/title-table";
import CardWrapper from "components/wrapper/wrapper-card";
import LoadingSpinner from "elements/loading/spinner";
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import { getDataIncomingApplicationBackToSales } from "services/api/private-routes/incoming-application/backToSales";
import { dateUtility } from "utils/helpers/general/date-time";

const SpaceStyle = styled("div")({
  height: "14px",
});

const CardBackToSales = () => {
  /* translations */
  const { t } = useTranslation();
  const [date] = useIncomingFilter();

  const translations = useMemo(
    () => ({
      titleCharts: t("INCOMING.BACK_TO_SALES"),
      titleTable: {
        title1: t("INCOMING.REASON"),
        title2: t("INCOMING.NO_OF_INCOMING"),
      },
    }),
    [t],
  );

  const dataIncomingApplicationBackToSales = useQuery(["data-incoming-application-back-to-sales", { ...date }], () =>
    getDataIncomingApplicationBackToSales({
      startDate: dateUtility(date.start).isoSeparator(),
      endDate: dateUtility(date.end).isoSeparator(),
    }),
  );

  /* memo fetching */
  const content = useMemo(() => {
    if (dataIncomingApplicationBackToSales.isError)
      return (
        <ServerErrorBoundary
          onClick={dataIncomingApplicationBackToSales.refetch}
          title={dataIncomingApplicationBackToSales.error?.message}
        />
      );
    if (dataIncomingApplicationBackToSales.isFetching) return <LoadingSpinner height={250} />;
    if (!dataIncomingApplicationBackToSales.isError && dataIncomingApplicationBackToSales.isSuccess) {
      return (
        <>
          {dataIncomingApplicationBackToSales.data?.data?.map((listSale, index) => (
            <ListCommonPopover
              key={listSale.name ?? index}
              number={index + 1}
              name={listSale.name}
              sum={listSale.sum}
              fontWeight={600}
              flex1={0.8}
              flex2={0.2}
            />
          ))}
        </>
      );
    }
    return null;
  }, [dataIncomingApplicationBackToSales]);

  return (
    <CardWrapper flexSize={0.7}>
      <TitleChart icon={iconList} title={translations.titleCharts} />
      <SpaceStyle />
      <TitleTable title1={translations.titleTable.title1} title2={translations.titleTable.title2} marginLeft="33px" />
      {content}
    </CardWrapper>
  );
};

export default CardBackToSales;
