import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Component
import { ROLE } from "hooks/useAuth";
import { withRoles } from "hooks/withRoles";
import AuthLayout from "layouts/auth";
import DashboardLayout from "layouts/dashboard";
import { CalculationLossProfit } from "pages/dashboard/dashboard-calculation-loss-profit";
import DashboardDetailRevenueByBranding from "pages/dashboard/dashboard-detail-revenue/branding";
import DashboardDetailTransactionByMerchant from "pages/dashboard/dashboard-detail-transaction";
import ExecutiveSummary from "pages/dashboard/dashboard-executive-summary";
import DashboardIncomingApp from "pages/dashboard/dashboard-incomming-app";
import DashboardPortofolioByCasa from "pages/dashboard/dashboard-portofolio/casa";
import DashboardPortofolio from "pages/dashboard/dashboard-portofolio/portofolio";
import DashboardPortofolioSalesVolume from "pages/dashboard/dashboard-portofolio/sales-volume";
import { DashboardProfitability } from "pages/dashboard/dashboard-profitability";
import DashboardDetailProfitability from "pages/dashboard/dashboard-profitability/detail-profitability";
import ProfitabilityByMerchant from "pages/dashboard/dashboard-profitability/profitability-by-merchant";
import Terminology from "pages/dashboard/dashboard-profitability/terminology";
import DashboardBetterWorseMerchant from "pages/dashboard/dashboard-worse-better-merchant";
import EditUserManagementPage from "pages/edit-user-management";
import ForgotPasswordPage from "pages/forgot-password";
import LoginPage from "pages/login";
import { Logout } from "pages/logout";
import { RNDPages, DesignSystemPages } from "pages/misc";
import NewPasswordPage from "pages/new-password";
import OtpPage from "pages/otp";
import RegisterPage from "pages/register";
import UserManagement from "pages/user-management";
import UserManagementInvalidData from "pages/user-management-invalid-data";
import VerificationSuccess from "pages/verification-success";
import { routes } from "utils/constants/paths";

const AppIndex = () => {
  return (
    <Routes>
      {process.env.NODE_ENV === "development" && (
        <>
          <Route path="/rnd" element={<RNDPages />} />
          <Route path="design-systems" element={<DesignSystemPages />} />
        </>
      )}
      <Route exact path="/" element={<Navigate to={routes.AUTH.LOGIN} replace />} />

      {/* ---------------------------------- AUTH ---------------------------------- */}
      <Route path={routes.AUTH.BASE_PREFIX} element={<AuthLayout />}>
        <Route path={routes.AUTH.LOGIN} element={<LoginPage />} />
        <Route path={routes.AUTH.REGISTER} element={<RegisterPage />} />
        <Route path={routes.AUTH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={routes.AUTH.NEW_PASSWORD} element={<NewPasswordPage />} />
        <Route path={routes.AUTH.OTP} element={<OtpPage />} />
        <Route path={routes.AUTH.VERIFICATION_SUCCESS} element={<VerificationSuccess />} />
      </Route>

      {/* ----------------------------- PRIVATE ROUTES ----------------------------- */}
      <Route path={routes.DASHBOARD.BASE_PREFIX} element={<DashboardLayout />}>
        <Route path={routes.DASHBOARD.INCOMING_APPLICATION} element={<DashboardIncomingApp />} />
        <Route path={routes.DASHBOARD.PORTOFOLIO} element={<DashboardPortofolio />} />
        <Route path={routes.DASHBOARD.PORTOFOLIO_SALES_VOLUME} element={<DashboardPortofolioSalesVolume />} />
        <Route path={routes.DASHBOARD.PORTOFOLIO_CASA} element={<DashboardPortofolioByCasa />} />
        <Route path="invalid-data" element={<UserManagementInvalidData />} />
        <Route
          path={routes.DASHBOARD.EXECUTIVE_SUMMARY}
          element={withRoles(ExecutiveSummary, [
            ROLE.ADMINISTRATOR,
            ROLE.SUPERVISOR,
            ROLE.SUPPORT_SERVICE,
            ROLE.INQUIRY,
          ])}
        />
        <Route path={routes.DASHBOARD.CALCULATION_PROFIT_LOSS} element={<CalculationLossProfit />} />
        <Route path={routes.DASHBOARD.BETTER_WORSE_MERCHANT} element={<DashboardBetterWorseMerchant />} />
        <Route path={routes.DASHBOARD.DETAIL_REVENUE_BRANDING} element={<DashboardDetailRevenueByBranding />} />
        <Route path={routes.DASHBOARD.PROFITABILITY} element={<DashboardProfitability />} />
        <Route path={routes.DASHBOARD.PROFITABILITY_DETAIL} element={<DashboardDetailProfitability />} />
        <Route path={routes.DASHBOARD.PROFITABILITY_BY_MERCHANT} element={<ProfitabilityByMerchant />} />
        <Route path={routes.DASHBOARD.TERMINOLOGY} element={<Terminology />} />
        <Route path={routes.DASHBOARD.USER_MANAGEMENT} element={withRoles(UserManagement, [ROLE.ADMINISTRATOR])} />
        <Route path="edit-user-management" element={withRoles(EditUserManagementPage, [ROLE.ADMINISTRATOR])}>
          <Route path=":id" element={<EditUserManagementPage />} />
        </Route>
        <Route path={routes.DASHBOARD.DETAIL_TRANSACTION} element={<DashboardDetailTransactionByMerchant />} />
      </Route>
      <Route path={routes.DASHBOARD.DETAIL_TRANSACTION} element={<DashboardDetailTransactionByMerchant />} />
      <Route path="logout" element={<Logout />} />
    </Routes>
  );
};

export default AppIndex;
