import { Annotation, Axis, Chart, Coordinate, Point, registerShape, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

// Change Shape Chart
registerShape("point", "pointer", {
  draw(cfg, container) {
    const group = container.addGroup();
    const center = this.parsePoint({ x: 0, y: 0 });
    const start = this.parsePoint({ x: 0, y: 0.5 });
    const angle1 = Math.atan((start.y - center.y) / (start.x - center.x));
    const angle = (Math.PI - 2 * angle1) * cfg.points[0].x;
    this.preAngle = angle;
    return group;
  },
});

const scale = {
  value: {
    min: 0,
    max: 1.01,
    tickInterval: 0.01,
  },
};

const ChartRing = ({ colorFill, colorRing, percenteRing, contentText }) => {
  const data = [{ value: percenteRing / 100 }];
  const startAngle = -Math.PI / 2;
  const endAngle = startAngle + Math.PI * 2;

  const parseCurrency = number => {
    const formattedNumber = new Intl.NumberFormat("id-ID").format(number);
    return formattedNumber || "0";
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Chart height={150} data={data} scale={scale} autoFit>
          <Coordinate type="polar" radius={0.75} startAngle={startAngle} endAngle={endAngle} />
          <Axis
            name="value"
            line={null}
            visible={false}
            label={{
              offset: -36,
              style: {
                fontSize: 18,
                textAlign: "center",
                textBaseline: "middle",
              },
            }}
            grid={null}
          />
          <Point position="value*1" color="#1890FF" shape="pointer" />
          <Annotation.Arc
            start={[data[0].value, 1]}
            end={[1, 1]}
            style={{
              stroke: COLORS.GRAY_SOFT,
              lineWidth: 20,
              lineDash: null,
              lineCap: "round",
            }}
          />
          <Annotation.Arc
            start={[0, 1]}
            end={[data[0].value, 1]}
            style={{
              stroke: colorFill, // rubah warna di sini
              lineCap: "round",
              lineWidth: 20,
              lineDash: null,
            }}
          />
          <Annotation.Html
            html={`<div style='text-align:center;transform:translate(-50%, -35%);font-size: 14px;'>${parseCurrency(
              contentText,
            )} <br/><span style='font-size: 12px;'>${percenteRing.toFixed(2)}%</span></div>`}
          />
          <Tooltip visible />
        </Chart>
        <div
          style={{
            height: 80,
            width: 80,
            borderRadius: "80%",
            border: `1px solid ${colorRing}`,
            position: "absolute",
            top: 35,
          }}
        />
      </div>
    </div>
  );
};

ChartRing.defaultProps = {
  colorFill: COLORS.GREEN_HARD,
  colorRing: COLORS.GRAY_MEDIUM,
  percenteRing: 0.01,
  contentText: 0.0,
};

ChartRing.propTypes = {
  colorFill: PropTypes.string,
  colorRing: PropTypes.string,
  percenteRing: PropTypes.number,
  contentText: PropTypes.number,
};

export default ChartRing;
