import { TableCell, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const StickyTableCell = withStyles(() => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 10,
    minWidth: "10rem",
  },
  body: {
    zIndex: 10,
    // TODO Ask to BA
    minWidth: "max-content",
    whiteSpace: "nowrap",
    left: 0,
    position: "sticky",
    backgroundColor: "inherit",
  },
}))(TableCell);

export const useStyles = makeStyles({
  overflow: {
    // overflowX: "auto",
  },
  tableContainer: {
    maxWidth: "100%",
    backgroundColor: COLORS.WHITE,
    borderRadius: "8px",
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableCellSemibold16: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
    padding: "0px 0px 0px 10px",
    zIndex: 2,
    position: "sticky",
  },
  tableHeadRedSoft: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.RED_SOFT,
    color: COLORS.WHITE,
    zIndex: 0,
    position: "relative",
  },
  tableHeadBlueMedium: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.BLUE_MEDIUM,
    color: COLORS.WHITE,
    zIndex: 0,
  },
  tableCellBorderReguler12: {
    padding: "12px",
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
    minWidth: "max-content",
    whiteSpace: "nowrap",
    zIndex: 0,
  },
  tableCellReguler10: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "8rem",
  },
  tableRow: {
    padding: "4px 10px",
    backgroundColor: COLORS.WHITE,
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  iconSort: {
    padding: 0,
    marginLeft: "7px",
  },
  mainTableHeadWrapper: {
    display: "flex",
    alignItems: "center",
  },
  subHeaderWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  iconButtonStyle: {
    padding: "1px",
  },
  iconSortStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
  },
});
