/**
 * Get cookie value by name
 * @param {string} name
 * @returns {string}
 */
export const getCookie = name => {
  const value = `; ${document.cookie}`.split(`; ${name}=`);
  if (value.length === 2) return value.pop().split(";").shift();
  else return null;
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach(cookie => {
    document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`;
  });
};
