export function getMonthsForLocale(locale = "en-EN") {
  const format = new Intl.DateTimeFormat(locale, { month: "long" });
  return Array(12)
    .fill("")
    .map((x, i) => format.format(new Date(Date.UTC(2000, i, 1, 0, 0, 0))));
}

export const formatMonthYear = new Intl.DateTimeFormat("en-EN", {
  month: "long",
  year: "numeric",
});

export const getLastMonth = (decrease, currDate, cbGetValue) => {
  return [...Array(decrease).keys()].reduce((prev, curr) => {
    const monthTarget = new Date();
    monthTarget.setMonth(currDate.getMonth() - curr);
    return [...prev, cbGetValue(monthTarget)];
  }, []);
};

const format = new Intl.DateTimeFormat("id-ID");

/**
 *
 * @param {Date} dateParam
 */
export const dateUtility = dateParam => {
  const date = new Date(dateParam);

  const dateOnly = (separator = "-") => format.format(date).split("/").join(separator);
  const dateOnlyReverse = (separator = "-") => dateOnly(separator).split(separator).reverse().join(separator);
  const isoSeparator = () => date.toISOString().split("T")[0];

  return {
    dateOnly,
    dateOnlyReverse,
    isoSeparator,
  };
};
