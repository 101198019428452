import { Box, IconButton, makeStyles, Modal, TextareaAutosize } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IconClose from "assets/icons/general/icon-close.svg";
import ButtonContained from "elements/button/button-contained";
import Description from "elements/description";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxStyle: {
    width: "385px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
  },
  iconButtonBoxStyle: {
    height: "24px",
    width: "24px",
    alignSelf: "end",
  },
  descStyle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginBottom: "25px",
  },
  labelWrapper: {
    marginTop: "30px",
  },
  textareaWrapper: {
    marginTop: "5px",
    width: "325px",
    marginBottom: "20px",
  },
  textareaStyle: {
    width: "100%",
    borderStyle: "none",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    padding: "10px",
    borderRadius: "10px",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    resize: "none",
    "&:hover": {
      border: `1px solid ${COLORS.PRIMARY_DARK}`,
    },
    "&:focus": {
      border: `1px solid ${COLORS.PRIMARY_DARK}`,
    },
  },
}));

const PopupFieldInput = ({
  isOpen,
  onClose,
  onSubmit,
  onChangeText,
  title,
  description,
  placeholder,
  submitButtonText,
  disabledButton,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.boxStyle}>
        <IconButton onClick={onClose} className={classes.iconButtonBoxStyle}>
          <img src={IconClose} alt="icon-close" />
        </IconButton>
        <Title title={title} align="center" fontWeight={600} />
        <div className={classes.labelWrapper}>
          <Description description={description} align="left" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
        </div>
        <div className={classes.textareaWrapper}>
          <TextareaAutosize
            minRows={5}
            placeholder={placeholder}
            className={classes.textareaStyle}
            onChange={onChangeText}
          />
        </div>
        <ButtonContained content={submitButtonText} width="100%" disabled={disabledButton} onClick={onSubmit} />
      </Box>
    </Modal>
  );
};

PopupFieldInput.defaultProps = {
  isOpen: false,
  disabledButton: true,
  onChangeText: () => {},
};

PopupFieldInput.propTypes = {
  isOpen: PropTypes.bool,
  disabledButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeText: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};

export default PopupFieldInput;
