import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const NEGATIVE_CASE = {
  percentage: 0,
  value: 0,
};

const getDataTypeChart = async signal => {
  const { data: responseBody, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.PORTOFOLIO_CHART, {
    signal,
  });
  if (status !== 200) throw new Error(`[getDataQrTypeList] ${status}`);
  const { data } = responseBody;
  const salesVolumeData = Object.entries(data).reduce((items, [val, key]) => {
    if (val === "sales_volume") return key;
    return items;
  }, []);

  const casaData = Object.entries(data).reduce((items, [val, key]) => {
    if (val === "casa") return key;
    return items;
  }, {});

  const transactionData = Object.entries(data).reduce((items, [val, key]) => {
    if (val === "transaction") return key;
    return items;
  }, {});

  const qrVolumeData = Object.entries(data).reduce((items, [val, key]) => {
    if (val === "qr_volume") return key;
    return items;
  }, {});

  const parsedData = {
    target: {
      targetSalesVolume: data.target.target_sales_volume ? data.target.target_sales_volume * 1 : 0,
      targetCasa: data?.target.target_casa ? data?.target.target_casa * 1 : 0,
      targetTransaction: data?.target.target_transaction ? data?.target.target_transaction * 1 : 0,
      targetQrVolume: data?.target.target_qr_volume ? data?.target.target_qr_volume * 1 : 0,
    },
    data: {
      sales_volume: data.sales_volume ? salesVolumeData : NEGATIVE_CASE,
      casa: data.casa ? casaData : NEGATIVE_CASE,
      transaction: data.transaction ? transactionData : NEGATIVE_CASE,
      qr_volume: data.qr_volume ? qrVolumeData : NEGATIVE_CASE,
    },
  };

  return parsedData;
};

export { getDataTypeChart };
