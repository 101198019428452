import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import IconSortDown from "assets/icons/general/icon-sort-asc.svg";
import IconSortUp from "assets/icons/general/icon-sort-desc.svg";
import { useStyles, StickyTableCell } from "./styled";
import { TableRowDetails } from "./table-row-details";

const heads = {
  merchant: "branding",
  outlet: "merchant_name",
};

const subHeads = [
  {
    credit_off_us: "credit_off_us_last",
    credit_on_us: "credit_on_us_last",
    debit_off_us: "debit_off_us_last",
    debit_on_us: "debit_on_us_last",
    qr: "qr_last",
    grand_total: "last_total",
  },
  {
    credit_off_us: "credit_off_us",
    credit_on_us: "credit_on_us",
    debit_off_us: "debit_off_us",
    debit_on_us: "debit_on_us",
    qr: "qr",
    grand_total: "current_total",
  },
];

const DetailTransactionTable = ({ data, mainTableHead, headers, subHeaders, currencyType, onSort }) => {
  const classes = useStyles();

  const headersContent = useMemo(() => {
    return headers?.map((header, index) => {
      const className = index % 2 === 0 ? classes.tableHeadRedSoft : classes.tableHeadBlueMedium;
      return (
        <TableCell align="center" colSpan={subHeaders.length} className={className} key={header}>
          {header}
        </TableCell>
      );
    });
  }, [classes, headers, subHeaders]);

  const mainHeadTableContent = useMemo(() => {
    const sort = heads[mainTableHead.toLowerCase()];
    return (
      <StickyTableCell rowSpan={2} className={classes.tableCellSemibold16}>
        <div className={classes.mainTableHeadWrapper}>
          {mainTableHead}
          <div className={classes.iconSortStyle}>
            <IconButton className={classes.iconButtonStyle} onClick={() => onSort({ key: sort, direction: "ASC" })}>
              <img src={IconSortUp} alt="icon sort up" width="11px" />
            </IconButton>
            <IconButton className={classes.iconButtonStyle} onClick={() => onSort({ key: sort, direction: "DESC" })}>
              <img src={IconSortDown} alt="icon sort down" width="11px" />
            </IconButton>
          </div>
        </div>
      </StickyTableCell>
    );
  }, [mainTableHead, classes, onSort]);

  const subHeaderContent = useMemo(() => {
    return headers?.map((header, index) =>
      subHeaders?.map(subHeader => {
        const sort = subHeads[index][subHeader?.replace(/ /g, "_").toLowerCase()];
        return (
          <TableCell align="right" className={classes.tableCellBorderReguler12} key={subHeader}>
            <div className={classes.subHeaderWrapper}>
              {subHeader}
              <div className={classes.iconSortStyle}>
                <IconButton className={classes.iconButtonStyle} onClick={() => onSort({ key: sort, direction: "ASC" })}>
                  <img src={IconSortUp} alt="icon sort up" width="11px" />
                </IconButton>
                <IconButton
                  className={classes.iconButtonStyle}
                  onClick={() => onSort({ key: sort, direction: "DESC" })}
                >
                  <img src={IconSortDown} alt="icon sort down" width="11px" />
                </IconButton>
              </div>
            </div>
          </TableCell>
        );
      }),
    );
  }, [subHeaders, classes, headers, onSort]);

  const detailsContent = useMemo(() => {
    if (!Array.isArray(data)) return null;
    return data.map((x, i) => (
      <TableRow className={classes.tableRow} key={x.id ?? i}>
        <StickyTableCell>{x.name}</StickyTableCell>
        <TableRowDetails
          data={x?.transactions?.map(y => y[currencyType] ?? [])}
          className={classes.tableCellReguler10}
          align="right"
          currencyType={currencyType}
        />
      </TableRow>
    ));
  }, [data, currencyType, classes]);

  if (data.length === 0) return null;
  return (
    <div className={classes.overflow}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {mainHeadTableContent}
              {headersContent}
            </TableRow>
            <TableRow>{subHeaderContent}</TableRow>
          </TableHead>
          <TableBody>{detailsContent}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

DetailTransactionTable.defaultProps = {
  onSort: () => {},
};

DetailTransactionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainTableHead: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  subHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  currencyType: PropTypes.string.isRequired,
  onSort: PropTypes.func,
};

export default DetailTransactionTable;
