import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "assets/icons/general/icon-search.svg";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles({
  root: {
    borderRadius: "10px",
    width: "220px",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    "& .MuiInputBase-input": {
      lineHeight: "20px",
      padding: "10px",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "10px",
    },
  },
  withDropdown: {
    borderEndEndRadius: "10px",
    borderStartEndRadius: "10px",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    "& .MuiInputBase-input": {
      lineHeight: "20px",
      padding: "10px",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "10px",
    },
  },
});

const TextfieldSearch = ({ onChange, value, placeholder, withDropdown, disabled }) => {
  const classes = useStyles();

  return (
    <TextField
      placeholder={placeholder}
      type="text"
      disabled={disabled}
      variant="standard"
      value={value}
      onChange={e => onChange(e.target.value)}
      className={withDropdown ? classes.withDropdown : classes.root}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <img src={SearchIcon} alt="search icon" />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

TextfieldSearch.defaultProps = {
  onChange: () => {},
  value: "",
  withDropdown: false,
  placeholder: undefined,
  disabled: false,
};

TextfieldSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  withDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TextfieldSearch;
