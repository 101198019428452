import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getDataTopMerchantSalesVolume = async signal => {
  const { data: responseBody, status } = await axiosPrivate.get(ENDPOINT.API_DASHBOARD.PORTOFOLIO.TOP_MERCHANT, {
    signal,
  });
  if (status !== 200) throw new Error(`[getDataTopMerchantSalesVolume] ${status}`);

  const { data } = responseBody;

  const parsedData = {
    credit_off_us: data.credit_off_us.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr.no,
          name: curr.name,
          sum: curr.total_bio,
        },
      ],
      [],
    ),
    credit_on_us: data.credit_on_us.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr.no,
          name: curr.name,
          sum: curr.total_bio,
        },
      ],
      [],
    ),
    debit_off_us: data.debit_off_us.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr.no,
          name: curr.name,
          sum: curr.total_bio,
        },
      ],
      [],
    ),
    debit_on_us: data.debit_on_us.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr.no,
          name: curr.name,
          sum: curr.total_bio,
        },
      ],
      [],
    ),
  };
  return parsedData;
};

export { getDataTopMerchantSalesVolume };
