import { Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import CustomPagination from ".";

const useStyles = makeStyles(theme => ({
  paginationContainer: {
    width: "100%",
    fontWeight: 400,
    fontSize: SIZES.REGULAR_16,
    lineHeight: "24px",
    color: COLORS.GRAY_SECONDARY,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
      gap: "1rem",
      flexDirection: "column",
    },
  },
}));

const PagePagination = ({ size, count, totalPage, page, onChange, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let end;
  let start;

  if (count < size) {
    end = count;
  } else {
    end = size * page;
  }

  if (count === 0) {
    start = 0;
  } else if (count < size) {
    start = 1;
  } else {
    start = end - size + 1;
  }

  return (
    <div className={clsx(classes.paginationContainer, className)}>
      <Typography>
        {t("GENERAL.SHOWING")} {start} - {end} {t("GENERAL.OF")} {count}
      </Typography>
      <CustomPagination totalPage={totalPage} onChange={(_, val) => onChange && onChange(val)} page={page} />
    </div>
  );
};

PagePagination.defaultProps = {
  size: 15,
  count: "-",
  totalPage: "-",
  page: "-",
  className: undefined,
};

PagePagination.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export { PagePagination };
