import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down25.svg";
import COLORS from "utils/constants/colors";
import { MenuItem, Select } from ".";

const useStyles = makeStyles({
  standard: {},
  dark: {
    background: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    minWidth: "unset",
    paddingRight: "1em",
    "& .MuiSelect-icon": {
      marginRight: ".5em",
    },
    "& .MuiSelect-icon > path": {
      stroke: COLORS.WHITE,
    },
  },
});

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  style: {
    maxHeight: "520px",
    marginTop: "5px",
    borderRadius: "10px",
  },
  getContentAnchorEl: null,
  disableScrollLock: true,
};

const CustomSelect = ({
  data,
  value,
  setValue,
  placeholder,
  renderItem,
  renderValue,
  className,
  variant,
  classNameItem,
  ...props
}) => {
  const classes = useStyles();
  const handleOnChange = useCallback(
    e => {
      if (setValue) setValue(e.target.value);
    },
    [setValue],
  );

  const handleRenderValue = useCallback(
    val => {
      if (!val) return <span className="placeholder">{placeholder}</span>;
      else return <span className="value">{renderValue ? renderValue(val, placeholder) : String(val)}</span>;
    },
    [renderValue, placeholder],
  );

  return (
    <Select
      value={value || ""}
      IconComponent={IconChevronDown}
      MenuProps={MenuProps}
      disableUnderline
      displayEmpty
      onChange={handleOnChange}
      renderValue={handleRenderValue}
      className={clsx(classes[variant], className)}
      {...props}
    >
      {data.map(val => (
        <MenuItem
          value={val.key || val}
          key={renderItem ? renderItem(val) : String(val)}
          className={clsx("select-item", classNameItem)}
        >
          {renderItem ? renderItem(val) : String(val)}
        </MenuItem>
      ))}
    </Select>
  );
};

CustomSelect.defaultProps = {
  data: [],
  value: "",
  setValue: undefined,
  placeholder: undefined,
  renderItem: undefined,
  renderValue: undefined,
  className: undefined,
  variant: "standard",
  classNameItem: undefined,
};

CustomSelect.propTypes = {
  data: PropTypes.array,
  value: PropTypes.any,
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  renderItem: PropTypes.func,
  renderValue: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.string,
  classNameItem: PropTypes.string,
};

export { CustomSelect };
