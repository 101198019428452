export * from "./popup-download";

export const DOWNLOAD_FEATURES = Object.freeze({
  TRANSACTION_OUTLET: "DetailTransactionOutlet",
  TRANSACTION_MERCHANT: "DetailTransactionMerchant",
  BETTER_WORSE_SALES_VOLUME: "BetterWorseSalesVolume",
  BETTER_WORSE_CASA: "BetterWorseCasa",
  REVENUE_BRANDING: "RevenueBranding",
  EXECUTIVE_SUMMARY: "ExecutiveSummary",
  CALCULATION_PROFIT_AND_LOSS: "ProfitAndLos",
  PROFITIBILITY_DETAIL_BRANDING: "ProfitabilityDetailBranding",
  DETAIL_BRANDING: "DetailBranding",
});
