/* eslint-disable react/jsx-filename-extension */
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import CardOctoMerchantQrVolume from "components/card/card-octo-merchant-qr-volume";
import CardPortfolioChart from "components/card/card-portfolio-chart";
// import CardPortfolioStatistik from "components/card/card-portfolio-statistik";
import CardPortfolioTypeChart from "components/card/card-portfolio-type-chart";
import CardQRType from "components/card/card-qr-type";
import CardTopMerchantSalesVolume from "components/card/card-top-merchant-sales-contribution";

// Signal
// import UnderMaintenance from "elements/under-maintenance";
import { useAbortController } from "hooks/useAbortController";

// Integration
import { getDataChartCasaPortfolioTypeChart } from "services/api/private-routes/portfolio-management/chart-casa";
import { getDataChartSalesVolumeTransaction } from "services/api/private-routes/portfolio-management/chart-sales-volume-transaction";
import { getDataChartType } from "services/api/private-routes/portfolio-management/chart-type";
import { getDataListMonthOctoMerchant } from "services/api/private-routes/portfolio-management/dropdown-month-octo-merchant";
// import { getDataPortfolioStatisticByDate } from "services/api/private-routes/portfolio-management/dropdown-portfolio-statistic-by-date";
// import { getDataChartByDivision } from "services/api/private-routes/portfolio-management/portfolio-statistic";
import { getDataQrTypeList } from "services/api/private-routes/portfolio-management/qr-type";
import { getDataTopMerchantSalesVolume } from "services/api/private-routes/portfolio-management/top-merchant-sales-volume";
import { getDataTypeChart } from "services/api/private-routes/portfolio-management/type-chart";

const useStyles = makeStyles(() => ({
  gridStyles: {
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
}));

const DashboardPortofolio = () => {
  const { signal } = useAbortController();
  const INITIAL_STATE = new Date();
  const classes = useStyles();
  const [selectMonth, setSelectedMonth] = useState("");

  const getDate = value => {
    const getMonths = value.replace(/\d+/gi, "").trim();
    const getYears = value.replace(/\D/g, "").trim();
    return `01/${getMonths}/${getYears}`;
  };

  // const dataChartByDivision = useQuery(["data-chart-by-division"], () => getDataChartByDivision(signal));

  const dataTopMerchantSalesVolume = useQuery(["data-top-merchant-sales-volume"], () =>
    getDataTopMerchantSalesVolume(signal),
  );

  const dataTypeChart = useQuery(["data-type-chart"], () => getDataTypeChart(signal));

  const dataChartSalesVolumeTransaction = useQuery(["data-chart-sales-volume-transaction"], () =>
    getDataChartSalesVolumeTransaction(signal),
  );

  const dataChartCasaPortfolioTypeChart = useQuery(["data-chart-casa-portfolio-type-chart"], () =>
    getDataChartCasaPortfolioTypeChart(signal),
  );

  const dataQrTypeList = useQuery(["data-qr-type-list", selectMonth], () =>
    getDataQrTypeList(
      {
        month: selectMonth ? getDate(selectMonth) : INITIAL_STATE,
        year: selectMonth ? getDate(selectMonth) : INITIAL_STATE,
      },
      signal,
    ),
  );

  const dataChartType = useQuery(["data-chart-type", selectMonth], () =>
    getDataChartType(
      {
        month: selectMonth ? getDate(selectMonth) : INITIAL_STATE,
        year: selectMonth ? getDate(selectMonth) : INITIAL_STATE,
      },
      signal,
    ),
  );

  // const dataPortfolioStatisticByDate = useQuery(["data-portfolio-statistic-by-date"], () =>
  //   getDataPortfolioStatisticByDate(),
  // );

  const dataListMonthOctoMerchant = useQuery(["data-list-month-octo-merchant"], () =>
    getDataListMonthOctoMerchant(signal),
  );

  useEffect(() => {}, [
    // dataChartByDivision,
    dataTypeChart,
    dataChartSalesVolumeTransaction,
    dataChartCasaPortfolioTypeChart,
    dataQrTypeList,
    dataChartType,
    // dataPortfolioStatisticByDate,
    dataListMonthOctoMerchant,
  ]);

  const selectedMonth = values => {
    setSelectedMonth(values);
  };

  return (
    <Grid container direction="column" spacing={4}>
      {/* <Grid item className={classes.gridStyles}>
        <UnderMaintenance>
          <CardPortfolioStatistik
            filterPortfolioStatistik={dataPortfolioStatisticByDate}
            dataByDivision={dataChartByDivision}
            legendByDivision={dataChartByDivision}
          />
        </UnderMaintenance>
      </Grid> */}
      <Grid item className={classes.gridStyles}>
        <CardPortfolioChart dataCardPortfolioChart={dataTypeChart} />
      </Grid>
      <Grid item className={classes.gridStyles}>
        <CardPortfolioTypeChart
          dataChartSales={dataChartSalesVolumeTransaction}
          dataChartCASA={dataChartCasaPortfolioTypeChart}
        />
      </Grid>
      <Grid item className={classes.gridStyles}>
        <CardTopMerchantSalesVolume data={dataTopMerchantSalesVolume} />
      </Grid>
      <Grid item className={classes.gridStyles}>
        <CardOctoMerchantQrVolume selectOctoMerchant={dataListMonthOctoMerchant} selectedMonth={selectedMonth} />
      </Grid>
      <Grid item className={classes.gridStyles}>
        <CardQRType dataQRList={dataQrTypeList} dataChartType={dataChartType} />
      </Grid>
    </Grid>
  );
};

export default DashboardPortofolio;
