import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  toggleButtonGroup: {
    borderRadius: "8px",
    border: `1px solid ${COLORS.PRIMARY_DARK}`,
    backgroundColor: "white",
    height: "max-content",
  },
  toggleButton: {
    color: COLORS.PRIMARY_DARK,
    minWidth: "61px",
    height: "32px",
    borderStyle: "none",
    textTransform: "initial",
    fontSize: SIZES.REGULAR_14,
    "&.Mui-selected": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.PRIMARY_DARK,
      "&:hover": {
        backgroundColor: COLORS.PRIMARY_DARK,
      },
    },
  },
}));

const TextToggleButton = ({ toggleData, selected, onChange }) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={(_, val) => {
        if (val === null || val === selected) return;
        onChange(val);
      }}
      className={classes.toggleButtonGroup}
    >
      {toggleData.map(name => (
        <ToggleButton className={classes.toggleButton} value={name} key={name}>
          {name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

TextToggleButton.defaultProps = {
  selected: null,
};

TextToggleButton.propTypes = {
  toggleData: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextToggleButton;
