import { TABLE_CELL_OPTIONS } from "utils/constants/table-cell-options";

export const mockupTableProfitabilityByMerchant = {
  branding: "MATAHARI DEPARTMENT STORES",
  heads: [
    {
      name: "branding",
      label: "Branding",
      isSort: false,
    },
    {
      name: "merchant_name",
      label: "Merchant Name",
      isSort: true,
    },
    {
      name: "tid",
      label: "TID",
      isSort: true,
    },
    {
      name: "sales_volume",
      label: "Sales Volume",
      isSort: true,
    },
    {
      name: "revenue",
      label: "Revenue",
      isSort: true,
    },
    {
      name: "interchange_fee",
      label: "Interchange + Switching Fee",
      isSort: true,
    },
    {
      name: "edc_cost",
      label: "EDC Cost",
      isSort: true,
    },
    {
      name: "irf_cc_on_us",
      label: "IRF CC on Us",
      isSort: true,
    },
    {
      name: "profit_merchant",
      label: "Profit Merchant",
      isSort: true,
    },
    {
      name: "nama_sales",
      label: "Nama Sales",
      isSort: true,
    },
    {
      name: "status_edc",
      label: "Status EDC",
      isSort: true,
    },
    {
      name: "total_profit",
      label: "Total Profit",
      isSort: true,
    },
  ],
  contents: [
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
    {
      merchant_name: { title: "MATAHARI DEPT S GRESIK", options: ["246,698,256", "246,698,256", "246,698,256"] },
      sales_volume: "246,698,256",
      revenue: "246,698,256",
      interchange_fee: "246,698,256",
      edc_cost: "246,698,256",
      irf_cc_on_us: "246,698,256",
      profit_merchant: "246,698,256",
      nama_sales: "246,698,256",
      status_edc: "246,698,256",
      total_profit: "246,698,256",
    },
  ],
};
const MOCK_CHART_MCC = {
  response_code: "200",
  response_message: "Success",
  data: [
    {
      year: 23,
      month_name: "July - 23 - 1",
      currency: "(IDR Bio)",
      total_bio: 1000.0,
      detail_data: [
        {
          year: 23,
          month_name: "July - 23 - 1",
          currency: "(IDR Bio)",
          total_bio: 90.0,
        },
        {
          year: 23,
          month_name: "August - 23",
          currency: "(IDR Bio)",
          total_bio: 100.24,
        },
        {
          year: 23,
          month_name: "September - 23",
          currency: "(IDR Bio)",
          total_bio: 1000.0,
        },
        {
          year: 23,
          month_name: "July - 23 - 2",
          currency: "(IDR Bio)",
          total_bio: 50.0,
        },
      ],
    },
    {
      year: 23,
      month_name: "August - 23",
      currency: "(IDR Bio)",
      total_bio: 2415.24,
      detail_data: [
        {
          year: 23,
          month_name: "July - 23",
          currency: "(IDR Bio)",
          total_bio: 80.0,
        },
        {
          year: 23,
          month_name: "August - 23",
          currency: "(IDR Bio)",
          total_bio: 100.24,
        },
        {
          year: 23,
          month_name: "September - 23",
          currency: "(IDR Bio)",
          total_bio: 190.0,
        },
      ],
    },
    {
      year: 23,
      month_name: "September - 23",
      currency: "(IDR Bio)",
      total_bio: 90.0,
      detail_data: [
        {
          year: 23,
          month_name: "July - 23",
          currency: "(IDR Bio)",
          total_bio: 100.0,
        },
        {
          year: 23,
          month_name: "August - 23",
          currency: "(IDR Bio)",
          total_bio: 241.24,
        },
        {
          year: 23,
          month_name: "September - 23",
          currency: "(IDR Bio)",
          total_bio: 70.0,
        },
      ],
    },
    {
      year: 23,
      month_name: "July - 23 - 2",
      currency: "(IDR Bio)",
      total_bio: 1000.0,
      detail_data: [
        {
          year: 23,
          month_name: "July - 23",
          currency: "(IDR Bio)",
          total_bio: 90.0,
        },
        {
          year: 23,
          month_name: "August - 23",
          currency: "(IDR Bio)",
          total_bio: 100.24,
        },
        {
          year: 23,
          month_name: "September - 23",
          currency: "(IDR Bio)",
          total_bio: 1000.0,
        },
      ],
    },
  ],
};

export { MOCK_CHART_MCC };

export const MockupLineChartProfitabilityByMerchant = {
  chart: [
    {
      date: "01-01-2023",
      month: "January",
      total_bio: 1,
    },
    {
      date: "01-02-2023",
      month: "February",
      total_bio: 3,
    },
    {
      date: "01-03-2023",
      month: "March",
      total_bio: 2,
    },
    {
      date: "01-04-2023",
      month: "April",
      total_bio: 3,
    },
    {
      date: "01-05-2023",
      month: "May",
      total_bio: 6,
    },
    {
      date: "01-06-2023",
      month: "June",
      total_bio: 5,
    },
    {
      date: "01-07-2023",
      month: "July",
      total_bio: 9,
    },
    {
      date: "01-08-2023",
      month: "August",
      total_bio: 7,
    },
    {
      date: "01-09-2023",
      month: "September",
      total_bio: 4,
    },
    {
      date: "01-10-2023",
      month: "October",
      total_bio: 8,
    },
    {
      date: "01-11-2023",
      month: "November",
      total_bio: 3,
    },
    {
      date: "01-12-2023",
      month: "December",
      total_bio: 1,
    },
  ],
};

export const MOCK_CUSTOMER_LEVEL_TRB_REVENUE = {
  coorporate_banking_month: ["December", "January", "February", "March", "April", "May", "June"],
  commercial_banking_month: ["December", "January", "February", "March", "April", "May", "June"],
  emmerging_business_banking_month: ["December", "January", "February", "March", "April", "May", "June"],
  coorporate_banking: [
    {
      name: "Funding-December",
      month: "December",
      total_bio: 2200000,
    },
    {
      name: "Lending-December",
      month: "December",
      total_bio: 1500000,
    },
    {
      name: "Revenue-December",
      month: "December",
      total_bio: 1700000,
    },
    {
      name: "Funding-January",
      month: "January",
      total_bio: 2000000,
    },
    {
      name: "Lending-January",
      month: "January",
      total_bio: 2500000,
    },
    {
      name: "Revenue-January",
      month: "January",
      total_bio: 2100000,
    },
    {
      name: "Funding-February",
      month: "February",
      total_bio: 2000000,
    },
    {
      name: "Lending-February",
      month: "February",
      total_bio: 2500000,
    },
    {
      name: "Revenue-February",
      month: "February",
      total_bio: 2100000,
    },
    {
      name: "Funding-March",
      month: "March",
      total_bio: 2000000,
    },
    {
      name: "Lending-March",
      month: "March",
      total_bio: 2500000,
    },
    {
      name: "Revenue-March",
      month: "March",
      total_bio: 2100000,
    },
    {
      name: "Funding-April",
      month: "April",
      total_bio: 2000000,
    },
    {
      name: "Lending-April",
      month: "April",
      total_bio: 2500000,
    },
    {
      name: "Revenue-April",
      month: "April",
      total_bio: 2100000,
    },
    {
      name: "Funding-Mei",
      month: "Mei",
      total_bio: 2000000,
    },
    {
      name: "Lending-Mei",
      month: "Mei",
      total_bio: 2500000,
    },
    {
      name: "Revenue-Mei",
      month: "Mei",
      total_bio: 2100000,
    },
    {
      name: "Funding-June",
      month: "June",
      total_bio: 2000000,
    },
    {
      name: "Lending-June",
      month: "June",
      total_bio: 2500000,
    },
    {
      name: "Revenue-June",
      month: "June",
      total_bio: 2100000,
    },
  ],
  commercial_banking: [
    {
      name: "Funding-December",
      month: "December",
      total_bio: 2200000,
    },
    {
      name: "Lending-December",
      month: "December",
      total_bio: 1500000,
    },
    {
      name: "Revenue-December",
      month: "December",
      total_bio: 1700000,
    },
    {
      name: "Funding-January",
      month: "January",
      total_bio: 2000000,
    },
    {
      name: "Lending-January",
      month: "January",
      total_bio: 2500000,
    },
    {
      name: "Revenue-January",
      month: "January",
      total_bio: 2100000,
    },
    {
      name: "Funding-February",
      month: "February",
      total_bio: 2000000,
    },
    {
      name: "Lending-February",
      month: "February",
      total_bio: 2500000,
    },
    {
      name: "Revenue-February",
      month: "February",
      total_bio: 2100000,
    },
    {
      name: "Funding-March",
      month: "March",
      total_bio: 2000000,
    },
    {
      name: "Lending-March",
      month: "March",
      total_bio: 2500000,
    },
    {
      name: "Revenue-March",
      month: "March",
      total_bio: 2100000,
    },
    {
      name: "Funding-April",
      month: "April",
      total_bio: 2000000,
    },
    {
      name: "Lending-April",
      month: "April",
      total_bio: 2500000,
    },
    {
      name: "Revenue-April",
      month: "April",
      total_bio: 2100000,
    },
    {
      name: "Funding-Mei",
      month: "Mei",
      total_bio: 2000000,
    },
    {
      name: "Lending-Mei",
      month: "Mei",
      total_bio: 2500000,
    },
    {
      name: "Revenue-Mei",
      month: "Mei",
      total_bio: 2100000,
    },
    {
      name: "Funding-June",
      month: "June",
      total_bio: 2000000,
    },
    {
      name: "Lending-June",
      month: "June",
      total_bio: 2500000,
    },
    {
      name: "Revenue-June",
      month: "June",
      total_bio: 2100000,
    },
  ],
  emmerging_business_banking: [
    {
      name: "Funding-December",
      month: "December",
      total_bio: 2200000,
    },
    {
      name: "Lending-December",
      month: "December",
      total_bio: 1500000,
    },
    {
      name: "Revenue-December",
      month: "December",
      total_bio: 1700000,
    },
    {
      name: "Funding-January",
      month: "January",
      total_bio: 2000000,
    },
    {
      name: "Lending-January",
      month: "January",
      total_bio: 2500000,
    },
    {
      name: "Revenue-January",
      month: "January",
      total_bio: 2100000,
    },
    {
      name: "Funding-February",
      month: "February",
      total_bio: 2000000,
    },
    {
      name: "Lending-February",
      month: "February",
      total_bio: 2500000,
    },
    {
      name: "Revenue-February",
      month: "February",
      total_bio: 2100000,
    },
    {
      name: "Funding-March",
      month: "March",
      total_bio: 2000000,
    },
    {
      name: "Lending-March",
      month: "March",
      total_bio: 2500000,
    },
    {
      name: "Revenue-March",
      month: "March",
      total_bio: 2100000,
    },
    {
      name: "Funding-April",
      month: "April",
      total_bio: 2000000,
    },
    {
      name: "Lending-April",
      month: "April",
      total_bio: 2500000,
    },
    {
      name: "Revenue-April",
      month: "April",
      total_bio: 2100000,
    },
    {
      name: "Funding-Mei",
      month: "Mei",
      total_bio: 2000000,
    },
    {
      name: "Lending-Mei",
      month: "Mei",
      total_bio: 2500000,
    },
    {
      name: "Revenue-Mei",
      month: "Mei",
      total_bio: 2100000,
    },
    {
      name: "Funding-June",
      month: "June",
      total_bio: 2000000,
    },
    {
      name: "Lending-June",
      month: "June",
      total_bio: 2500000,
    },
    {
      name: "Revenue-June",
      month: "June",
      total_bio: 2100000,
    },
  ],
};

export const DATA_PROFIT_TRB = [
  {
    month_name: "Desember 2023",
    total: 0,
    total_bio: 1.69,
  },
  {
    month_name: "Januari 2023",
    total: 0,
    total_bio: 1.64,
  },
  {
    month_name: "Februari 2023",
    total: 0,
    total_bio: 1.28,
  },
  {
    month_name: "Maret 2023",
    total: 0,
    total_bio: 2.48,
  },
  {
    month_name: "April 2023",
    total: 0,
    total_bio: 1.69,
  },
  {
    month_name: "Mei 2023",
    total: 0,
    total_bio: 1.23,
  },
  {
    month_name: "Juni 2023",
    total: 0,
    total_bio: 1.28,
  },
];

export const TABLE_PROFITABILITY_BY_MERCHANT_CONFIG = {
  fields: [
    "branding",
    "merchant_name",
    "sales_volume",
    "revenue",
    "interchange_fee",
    "edc_cost",
    "irf_cc_on_us",
    "profit_merchant",
    "nama_sales",
    "status_edc",
    "total_profit",
  ],
  cellOptions: [
    TABLE_CELL_OPTIONS.STRING,
    TABLE_CELL_OPTIONS.ACCORDION,
    TABLE_CELL_OPTIONS.NUMBER,
    TABLE_CELL_OPTIONS.NUMBER,
    TABLE_CELL_OPTIONS.NUMBER,
    TABLE_CELL_OPTIONS.NUMBER,
    TABLE_CELL_OPTIONS.NUMBER,
    TABLE_CELL_OPTIONS.STRING,
    TABLE_CELL_OPTIONS.STRING,
    TABLE_CELL_OPTIONS.STRING,
    TABLE_CELL_OPTIONS.NUMBER,
  ],
  stickyColumn: ["branding", "merchant_name"],
};
