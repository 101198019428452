import { useEffect, useRef } from "react";

const useTimeOut = ({ enable = false, duration = 60 * 60 * 60 * 1000, onTimeOut = () => {} }) => {
  const timerRef = useRef(null);

  useEffect(() => {
    const clear = () => window.clearTimeout(timerRef.current);
    const start = () => {
      timerRef.current = window.setTimeout(onTimeOut, duration);
    };

    if (enable) start();
    else if (!enable) clear();

    return () => clear();
  }, [enable, duration, onTimeOut]);

  return {};
};

export default useTimeOut;
