import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { ServerErrorBoundary } from "components/general";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
import BarChartTriple from "elements/chart/bar-chart-triple";
import LoadingSpinner from "elements/loading/spinner";
import Title from "elements/title";
import { useAbortController } from "hooks/useAbortController";
import { getEmmergingChart } from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import QUERY_NAME from "utils/constants/query";
import useStyles from "./styles";

const colorOverviewSales = [
  "name*total_bio",
  name => {
    const colorCode = {
      FUNDING: "#6889FF",
      LENDING: "#65D170",
      REVENUE: "#FFB443",
    };
    return colorCode[name.split("-")[0]] ?? "";
  },
];

const EmmergingChart = () => {
  const { id } = useParams();
  const classes = useStyles();
  const labelChart = {
    rotate: 1/4,
    autoEllipsis: true,
    style: {
      fill: COLORS.GRAY_DARK,
      fontSize: Number(SIZES.SMALL_8.replace("px", "")),
      fontWeight: 500,
      textAlign: "left",
      textBaseline: "top",
    },
    formatter(text) {
      return text.split("-")[0].toUpperCase();
    },
  };

  const axisNameHaveTrbLeft = "name";
  const intervalPositionHaveTrbLeft = "name*total_bio";

  const { signal } = useAbortController();
  const dataChart = useQuery([QUERY_NAME.BAR_CHART_EMMERGING], () => getEmmergingChart(signal, id));

  if (dataChart.isFetching) {
    return (
      <WrapperOutlineChart>
        <LoadingSpinner height={500} />
      </WrapperOutlineChart>
    );
  }

  if (dataChart.isError) {
    return (
      <WrapperOutlineChart>
        <ServerErrorBoundary className={classes.minHCard} onClick={dataChart.refetch} />
      </WrapperOutlineChart>
    );
  }

  return (
    <WrapperOutlineChart>
      <Title title="Emerging Business Banking" size={SIZES.REGULAR_14} fontWeight={500} align="center" />
      <Title title="(IDR Mio)" size={SIZES.REGULAR_10} fontWeight={400} align="center" />
      <div className={classes.relative}>
        <div className={classes.titleAbsolute}>
          {dataChart.data.months.map(month => (
            <div key={month} className={classes.titleChild}>
              <Title title={month} size={SIZES.REGULAR_10} fontWeight={500} align="center" />
            </div>
          ))}
        </div>
        <BarChartTriple
          data={{ data: dataChart.data.dataChart }}
          colors={colorOverviewSales}
          legendView={false}
          labelCustom={labelChart}
          axisName={axisNameHaveTrbLeft}
          positionName={intervalPositionHaveTrbLeft}
        />
      </div>
    </WrapperOutlineChart>
  );
};

export default EmmergingChart;
