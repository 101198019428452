import { Select as MSelect, styled } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const Select = styled(MSelect)({
  minWidth: "200px",
  borderRadius: "10px",
  height: "max-content",
  borderColor: COLORS.GRAY_ULTRASOFT,
  fontSize: SIZES.REGULAR_14,
  backgroundColor: COLORS.GRAY_ULTRASOFT,
  alignItems: "center",
  "& .MuiSelect-select": {
    padding: ".8rem 0",
    borderRadius: "10px",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  "& .placeholder": {
    color: COLORS.GRAY_HARD,
  },
  "& .value": {
    marginRight: "1.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "inherit",
  },
});

export { Select };
