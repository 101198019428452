/* eslint-disable react/jsx-no-bind */
// --no-error-on-unmatched-pattern
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CardBrandingTableByMerchant from "components/card/card-branding-table-by-merchant";
import CardFilterProfitabilityByMerchant from "components/card/card-filter-profitability-by-merchant";
import CardProfitabilityThreeBarChart from "components/card/card-profitability-three-bar-charts";
import CardProfitabilityTrendChart from "components/card/card-profitability-trend-chart";
import { downloadListProfitibilityMerchant } from "services/api/private-routes";
import { useStyles } from "./styles";

const ProfitabilityByMerchant = () => {
  const classes = useStyles();
  const { id, monthly } = useParams();
  const queryClient = useQueryClient();
  const splittedFilter = monthly.split("-");
  const filterMonth = `${splittedFilter[0]}-${splittedFilter[1]}`;
  const [state, setState] = useState({
    id,
    sortBy: "Merchant Name",
    page: 1,
    sortDirection: "ASC",
    limit: 10,
    monthly: filterMonth,
  });

  function handleChangeDate(e) {
    setState(prev => ({
      ...prev,
      monthly: moment(e).format("YYYY-MM"),
    }));
  }

  const downloadExcelOutlet = useMutation(
    payload => {
      downloadListProfitibilityMerchant(payload);
    },
    {
      onSettled: () => queryClient.invalidateQueries(["download"]),
    },
  );

  const cardBrandingTableMerchant = useMemo(() => {
    return (
      <CardBrandingTableByMerchant
        filter={{ start: state.monthly }}
        setFilterHome={({ page, limit, sortBy, sortDirection }) => {
          setState(prevState => {
            return {
              ...prevState,
              page,
              limit,
              sortBy,
              sortDirection,
              filterMonth,
            };
          });
        }}
      />
    );
  }, [state, filterMonth]);

  return (
    <div className={classes.root}>
      {/* filter */}
      <CardFilterProfitabilityByMerchant
        filter={state}
        onChange={handleChangeDate}
        onSubmitExcelDownload={() => downloadExcelOutlet.mutate(state)}
      />

      {cardBrandingTableMerchant}

      {/* trend chart */}
      <CardProfitabilityTrendChart monthly={state?.monthly} />

      {/* bar chart */}
      <CardProfitabilityThreeBarChart />
    </div>
  );
};

export default ProfitabilityByMerchant;
