/* eslint-disable no-console */
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
// import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ServerErrorBoundary } from "components/general";
import { CardBase } from "elements/card";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
// import { useAuth } from "hooks/useAuth";
import { getPdfTerminology } from "services/api/private-routes";
import { useStyles } from "./styled";

const Terminology = () => {
  // const { getRole } = useAuth();
  // const { t } = useTranslation();
  const { id } = useParams();

  const classes = useStyles();
  const { signal } = useAbortController();
  // const queryClient = useQueryClient();

  // /* -------------------------------------------------------------------------- */
  // /*                                    QUERY                                   */
  // /* -------------------------------------------------------------------------- */

  const pdfTerminology = useQuery(["pdf-terminology"], () => getPdfTerminology(signal, id), {
    retry: 0,
    keepPreviousData: true,
  });

  const activeQueryToggle = useMemo(() => pdfTerminology, [pdfTerminology]);

  const pdfContent = useMemo(() => {
    if (activeQueryToggle.isFetching) return <LoadingSpinner height={650} />;
    if (activeQueryToggle.isError)
      return (
        <ServerErrorBoundary onClick={() => activeQueryToggle.refetch()} title={activeQueryToggle.error?.message} />
      );
    const render = activeQueryToggle.data;

    if (!render) return null;
    return (
      <div className={classes.docViewer}>
        <DocViewer
          documents={render}
          pluginRenderers={DocViewerRenderers}
          // prefetchMethod="GET"
          // requestHeaders={headersDocViewer}
          style={{ width: 1000, height: 800 }}
        />
      </div>
    );
  }, [activeQueryToggle, classes]);

  // const docs = [
  //   {
  //     uri: "https://cyntler.github.io/react-doc-viewer/static/media/pdf-file.d751950f.pdf",
  //     fileType: "pdf",
  //     fileName: " ",
  //   },
  // ];

  // const headersDocViewer = {
  //   "X-Access-Token": "1234567890",
  //   // "My-Custom-Header": "my-custom-value",
  // };

  return (
    <div className={classes.page}>
      <CardBase flex>{pdfContent}</CardBase>
    </div>
  );
};

export default Terminology;
