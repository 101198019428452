import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { TextFormLabel } from "elements/typhography/text-form-label";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  textfield: {
    display: "flex",
    backgroundColor: ({ isInvalid }) => (isInvalid ? COLORS.WHITE : COLORS.GRAY_ULTRASOFT),
    fontSize: SIZES.REGULAR_14,
    height: "40px",
    borderEndEndRadius: "10px",
    borderStartEndRadius: "10px",
    "& .MuiInputBase-input": {
      lineHeight: "20px",
    },
    justifyContent: "center",
    paddingLeft: "5px",
    border: ({ isInvalid }) => `1px solid ${isInvalid ? COLORS.PRIMARY_DARK : COLORS.GRAY_ULTRASOFT}`,
  },
  prefix: {
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    fontWeight: "500",
    fontSize: SIZES.REGULAR_14,
    borderStartStartRadius: "10px",
    borderEndStartRadius: "10px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const TextfieldPrefix = forwardRef(({ value, label, onChange, placeholder, isInvalid, name, ...rest }, ref) => {
  const classes = useStyles({ isInvalid });
  return (
    <>
      <TextFormLabel>{label} :</TextFormLabel>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Typography className={classes.prefix}>+62</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            className={classes.textfield}
            {...rest}
            ref={ref}
            placeholder={placeholder}
            type="text"
            variant="standard"
            fullWidth
            value={value}
            autoComplete="off"
            onChange={e => {
              e.target.value = e.target.value.replace(/^0/g, "").replace(/\D/g, "");
              onChange(e);
            }}
            InputProps={{
              disableUnderline: true,
            }}
            name={name}
          />
        </Grid>
      </Grid>
    </>
  );
});

TextfieldPrefix.defaultProps = {
  isInvalid: false,
};

TextfieldPrefix.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
};

export default TextfieldPrefix;
