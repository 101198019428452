import { Checkbox, IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import IconElipsis from "assets/icons/user-management/icon-elipsis.svg";
import IconRotateCCW from "assets/icons/user-management/icon-rotate-ccw.svg";
import { useUserManagement } from "pages/user-management/user-management-context";
import { useStyles } from "./styled";

const UserManagementRow = ({ data, disabled }) => {
  const { showPopper, checkbox, setCheckbox, setSidePopupData } = useUserManagement();
  const classes = useStyles();
  const {
    id,
    email,
    phoneNumber,
    fullName,
    status,
    role,
    position,
    supervisor,
    location,
    salesAreaUsers,
    // roleUser,
    // isValid,
    // isLocked,
    // timeLocked,
    // userCode,
    // reasonReject,
  } = data;

  const handleOnCheckboxClick = useCallback(
    e => {
      const isChecked = e.target.checked;
      if (!isChecked) {
        setCheckbox(old => {
          const deleted = old.checked;
          delete deleted[id];
          return { checked: deleted, checkedAll: false };
        });
      } else setCheckbox(old => ({ ...old, checked: { ...old.checked, [id]: isChecked ? data : undefined } }));
    },
    [id, setCheckbox, data],
  );

  useEffect(() => {
    if (checkbox?.checkedAll && !disabled) setCheckbox(old => ({ ...old, checked: { ...old.checked, [id]: data } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkbox?.checkedAll]);

  const onReject = useCallback(() => {
    setSidePopupData({ data: [data], open: true });
  }, [setSidePopupData, data]);

  const onClickMenu = useCallback(
    e => showPopper({ anchorEl: e.currentTarget, menu: status, id }),
    [id, status, showPopper],
  );

  const statusStyle = {
    Rejected: classes.statusRejected,
    Submission: classes.statusSubmission,
    Active: classes.statusActive,
    "Non-Active": classes.statusNonActive,
  };

  const blankValueFormatter = value => value ?? "-";

  const doubleDataTableCell = (value1, value2) => {
    return (
      <TableCell className={classes.tableCellReguler}>
        <Typography className={classes.tableCellSemiBold}>{blankValueFormatter(value1)}</Typography>
        {blankValueFormatter(value2)}
      </TableCell>
    );
  };

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCellCheckbox}>
        <Checkbox
          className={classes.checkbox}
          disabled={disabled}
          checked={!!checkbox?.checked[id]}
          onChange={handleOnCheckboxClick}
        />
      </TableCell>
      <TableCell>
        <Typography className={classes.tableCellSemiBold}>{id}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.tableCellSemiBold}>{fullName}</Typography>
      </TableCell>
      {doubleDataTableCell(email, `+62 ${phoneNumber}`)}
      {doubleDataTableCell(role?.name, position?.name)}
      <TableCell>
        <Typography className={classes.tableCellSemiBold}>{blankValueFormatter(supervisor?.full_name)}</Typography>
      </TableCell>
      {doubleDataTableCell(location?.name, salesAreaUsers?.value)}
      <TableCell>
        <Typography className={statusStyle[status] ?? ""}>{status}</Typography>
      </TableCell>
      <TableCell>
        {status === "Rejected" ? (
          <IconButton onClick={onReject}>
            <img src={IconRotateCCW} alt="icon rejected" />
          </IconButton>
        ) : (
          <IconButton onClick={onClickMenu}>
            <img src={IconElipsis} alt="icon elipis" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

UserManagementRow.defaultProps = {
  disabled: false,
};

UserManagementRow.propTypes = {
  /* ------------------------------- DATA PROPS ------------------------------- */
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    isValid: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    role: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    roleUser: PropTypes.array,
    position: PropTypes.any, // TODO check
    supervisor: PropTypes.shape({
      id: PropTypes.number,
      user_code: PropTypes.string,
      full_name: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    salesAreaUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        salesAreaType: PropTypes.string,
      }),
    ),
    timeLocked: PropTypes.string,
    userCode: PropTypes.string,
    reasonReject: PropTypes.any,
  }).isRequired,

  /* --------------------------------- Action --------------------------------- */
  disabled: PropTypes.bool,
};

export default UserManagementRow;
