import { MenuItem, Select, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down25.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const DropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  style: {
    maxHeight: "520px",
    marginTop: "5px",
    marginLeft: "-10px",
    borderRadius: "10px",
  },
  getContentAnchorEl: null,
  disableScrollLock: true,
};

const useStyles = makeStyles(() => ({
  root: {
    displayEmpty: true,
    disableUnderline: true,
    width: "10vw",
    borderRadius: "10px",
    borderColor: COLORS.GRAY_ULTRASOFT,
    fontSize: SIZES.REGULAR_14,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    alignItems: "center",
    height: "100%",
    "& .MuiSelect-select": {
      borderRadius: "10px",
      paddingLeft: "10px",
      lineHeight: "20px",
    },
  },
  input: {
    padding: "10px 14px",
  },
  menuItem: {
    backgroundColor: "transparent",
    height: "52px",
    "&.Mui-selected": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
  },
  placeholder: {
    color: COLORS.GRAY_HARD,
  },
}));

const CustomSelectDropdown = ({ placeholder, renderValue, arrayData, onChange, data, ...props }) => {
  const classes = useStyles();

  const renderDefaultValue = useCallback(
    value => {
      const isObj = typeof value === "object";
      return !value ? (
        <span className={classes.placeholder}>{placeholder}</span>
      ) : (
        <span>{isObj ? renderValue?.(value) ?? String(value) : value}</span>
      );
    },
    [renderValue, placeholder, classes],
  );

  return (
    <Select
      displayEmpty
      MenuProps={DropdownProps}
      IconComponent={IconChevronDown}
      disableUnderline
      renderValue={renderDefaultValue}
      className={classes.root}
      onChange={onChange}
      value={data}
      input={<Input classes={{ input: classes.input }} />}
      {...props}
    >
      {arrayData.map(item => (
        <MenuItem className={classes.menuItem} value={item} key={item.id ?? item}>
          {typeof item === "object" ? renderValue?.(item) ?? Object.values(item) : item}
        </MenuItem>
      ))}
    </Select>
  );
};

CustomSelectDropdown.defaultProps = {
  onChange: () => {},
  placeholder: "",
  data: "",
  arrayData: [],
  renderValue: null,
};

CustomSelectDropdown.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  arrayData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  renderValue: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.any,
};

export default CustomSelectDropdown;
