import { makeStyles, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "assets/icons/general/icon-search.svg";
import { CustomSelect } from "elements/select";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  dropdown: {
    borderEndEndRadius: "0",
    borderStartEndRadius: "0",
    minWidth: "3rem",
    textTransform: "capitalize",
  },
  textField: {
    borderEndStartRadius: "0",
    borderStartStartRadius: "0",
    fontSize: SIZES.REGULAR_14,
    color: COLORS.DARK_HARD,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    borderRadius: 10,
  },
  input: {
    fontSize: "inherit",
    color: "inherit",
    padding: "0 .5em",
    height: "100%",
    width: "100%",
  },
});

const TextFieldSelect = ({ dataSelect, selected, onSelect, search, onSearch, placeholder, classNameSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CustomSelect
        value={selected}
        setValue={onSelect}
        data={dataSelect}
        variant="dark"
        className={clsx(classes.dropdown, classNameSelect)}
      />
      <TextField
        placeholder={placeholder}
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
          className: classes.input,
          endAdornment: (
            <InputAdornment position="end">
              <img src={SearchIcon} alt="search icon" />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={e => onSearch && onSearch(e.target.value)}
      />
    </div>
  );
};

TextFieldSelect.defaultProps = {
  dataSelect: [],
  selected: undefined,
  onSelect: undefined,
  onSearch: undefined,
  search: undefined,
  placeholder: undefined,
  classNameSelect: undefined,
};

TextFieldSelect.propTypes = {
  dataSelect: PropTypes.array,
  selected: PropTypes.any,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  search: PropTypes.string,
  placeholder: PropTypes.string,
  classNameSelect: PropTypes.string,
};

export { TextFieldSelect };
