import { Box, IconButton, makeStyles, Modal, Typography } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconArrowLeftRed from "assets/icons/popup/icon-arrow-left-red.svg";
import IconArrowLeftWhite from "assets/icons/popup/icon-arrow-left-white.svg";
import IconArrowRightRed from "assets/icons/popup/icon-arrow-right-red.svg";
import SelectInput from "components/input/select-input";
import SelectInputMultiple from "components/input/select-input-multiple-checkbox";
import { useCIMBLoading } from "components/loading/cimb-loading";
import ButtonContained from "elements/button/button-contained";
import ButtonOutlined from "elements/button/button-outlined";
import Title from "elements/title";
import { useUserManagement } from "pages/user-management/user-management-context";
import {
  acceptUser,
  getListPosition,
  getListRole,
  getListLocation,
  getListSupervisor,
  getListSalesArea,
} from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  boxStyle: {
    position: "absolute",
    right: "0vh",
    borderStartStartRadius: "20px",
    borderEndStartRadius: "20px",
    width: "40vw",
    height: "100vh",
    padding: "15px",
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleWrapper: {
    marginTop: "15px",
    marginLeft: "15px",
    display: "flex",
    flexDirection: "row",
  },
  navWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
  },
  iconButtonBoxStyle: {
    height: "36px",
    width: "36px",
    background: COLORS.PRIMARY_DARK,
    borderRadius: "10px",
    marginRight: "20px",
  },
  iconButtonBorderStyle: {
    height: "28px",
    width: "28px",
    border: `1px solid ${COLORS.PRIMARY_DARK}`,
    borderRadius: "8px",
    marginRight: "10px",
    marginLeft: "10px",
  },
  formBorder: {
    flex: "1 1 auto",
    margin: "30px",
    border: `1px solid ${COLORS.GRAY_MEDIUM}`,
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  overflowY: {
    width: "100%",
    height: "99%",
    padding: "30px",
    overflowY: "scroll",
    right: "-17px",
    position: "absolute",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
  },
  identifierNumber: {
    fontSize: SIZES.REGULAR_18,
    fontWeight: 600,
    color: COLORS.GRAY_HARD,
    alignSelf: "flex-end",
    marginBottom: "15px",
  },
  grayReguler14: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.GRAY_DARK,
  },
  semiBold14: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
  medium14: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    color: COLORS.DARK_HARD,
  },
  dataWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "10px",
  },
  inputWrapper: {
    marginBottom: "30px",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "10px",
  },
}));

const SidePopUp = () => {
  const classes = useStyles();

  const { sidePopupData, setSidePopupData } = useUserManagement();

  const [editable, setEditable] = useState(sidePopupData ? sidePopupData.data : []);
  const [page, setPage] = useState(0);

  const onClose = () => {
    setSidePopupData({ data: [], open: false });
  };

  return (
    <Modal
      open={sidePopupData ? sidePopupData.open : false}
      onClose={onClose}
      className={classes.modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {editable[page] ? (
        <FormDetailInformation editable={editable} setEditable={setEditable} page={page} setPage={setPage} />
      ) : (
        <></>
      )}
    </Modal>
  );
};

const FormDetailInformation = ({ page, setPage, editable, setEditable }) => {
  const { setActive } = useCIMBLoading();
  const {
    setSidePopupData,
    setCheckbox,
    popoverInfoApprovedUserData,
    popoverInfoApprovedUserDataFailed,
    popoverInternalServerError,
  } = useUserManagement();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState(editable[page]);

  const listPosition = useQuery(["list-position"], () => getListPosition(), { retry: 0 });
  const listRole = useQuery(["list-role"], () => getListRole(), { retry: 0 });
  const listLocation = useQuery(["list-location"], () => getListLocation(), { retry: 0 });
  const listSupervisor = useQuery(["list-supervisor"], () => getListSupervisor(), { retry: 0 });
  const listSalesArea = useQuery(["list-sales-area"], () => getListSalesArea(), { retry: 0 });

  const acceptUserSubmit = useMutation(acceptUser, {
    onMutate: () => setActive(true),
    onSuccess: res => {
      popoverInfoApprovedUserData({ length: res.total_data });
    },
    onError: res => {
      setActive(false);
      if (res.response.status === 400) {
        popoverInfoApprovedUserDataFailed({ length: res.response.data.data.total_failed });
      } else {
        popoverInternalServerError();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("list-user");
      setCheckbox({ checkedAll: false, checked: {}, type: "" });
      setSidePopupData({ data: [], open: false });
      setActive(false);
    },
  });

  const isValid = useMemo(() => {
    if (!data) return false;
    const isSales = data.role === 3;
    const isArco = data.role === 6;
    if ((isSales || isArco) && !data.supervisor?.user_code) return false;
    const validator = [
      data.role,
      data.position?.id,
      data.location?.id,
      Array.isArray(data.salesAreaUsers?.id),
      data.salesAreaUsers?.id?.length > 0,
    ];
    return validator.every(Boolean);
  }, [data]);

  const onSubmit = () => {
    const payload = editable.reduce(
      (prev, curr) => [
        ...prev,
        {
          user_id: curr.id,
          position_id: curr.position?.id,
          role_id: typeof curr.role === "number" ? curr.role : curr.role?.id,
          sales_area_id: curr.salesAreaUsers?.id,
          location_id: typeof curr.location === "object" ? curr.location.id : curr.location,
          supervisor_code: typeof curr.supervisor === "object" ? curr.supervisor.user_code : curr.supervisor,
        },
      ],
      [],
    );
    acceptUserSubmit.mutate(payload);
  };

  const updateEditableData = (currentPage, currentData) => {
    setEditable(old => {
      const newData = old;
      newData[currentPage] = { ...currentData };
      return newData;
    });
  };

  useEffect(() => {
    onChangePageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onChangePageData = () => setData(editable[page]);

  const onNext = useCallback(() => {
    updateEditableData(page, data);
    setPage(old => {
      const target = old + 1;
      const length = editable?.length;
      return target >= length ? length - 1 : target;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, setPage, setData, data]);

  const onPrev = useCallback(() => {
    updateEditableData(page, data);
    setPage(old => {
      const target = old - 1;
      return target < 0 ? 0 : target;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, setPage, setData, data]);

  const onCancel = () => setSidePopupData({ data: [], open: false });

  const onChangeData = (key, val) => setData(old => ({ ...old, [key]: { ...old[key], id: val } }));
  const onChangeSalesArea = val => setData(old => ({ ...old, salesAreaUsers: { ...old.salesAreaUsers, id: val } }));

  const onSingleChoose = event => {
    const val = parseInt(event.target.value, 0);
    if (data.salesAreaUsers.id.indexOf(val) === -1) {
      onChangeSalesArea([...data.salesAreaUsers.id, val]);
    } else {
      // remove value from data
      onChangeSalesArea(data.salesAreaUsers.id.filter(x => x !== val));
    }
  };

  const onChooseAll = () => {
    if (data.salesAreaUsers.id.length === listSalesArea.data.parsedData.length) {
      // remove all value
      onChangeSalesArea([]);
    } else {
      // add all value
      onChangeSalesArea(listSalesArea.data.parsedData.map(item => item.id));
    }
  };

  const handleSubmit = () => {
    updateEditableData(page, data);
    onSubmit();
  };

  return (
    <Box className={classes.boxStyle}>
      <div className={classes.titleWrapper}>
        <IconButton onClick={onCancel} className={classes.iconButtonBoxStyle}>
          <img src={IconArrowLeftWhite} alt="icon-close" />
        </IconButton>
        <Title title={t("GENERAL.INFORMATION_DETAIL")} />
      </div>
      <div className={classes.formBorder}>
        <div className={classes.overflowY}>
          {editable.length > 1 && (
            <div className={classes.navWrapper}>
              <IconButton className={classes.iconButtonBorderStyle} onClick={onPrev}>
                <img src={IconArrowLeftRed} alt="icon-prev" />
              </IconButton>
              <Typography className={classes.medium14}>
                {t("GENERAL.DATA")} {page + 1}/{editable?.length}
              </Typography>
              <IconButton className={classes.iconButtonBorderStyle} type="submit" onClick={onNext}>
                <img src={IconArrowRightRed} alt="icon-next" />
              </IconButton>
            </div>
          )}
          <div className={classes.formWrapper}>
            <Typography className={classes.identifierNumber}>#{data.id}</Typography>
            <div className={classes.dataWrapper}>
              <Typography className={classes.grayReguler14}>{t("GENERAL.FULL_NAME")}</Typography>
              <Typography className={classes.semiBold14}>{data.fullName}</Typography>
            </div>
            <div className={classes.dataWrapper}>
              <Typography className={classes.grayReguler14}>{t("GENERAL.PHONE_NUMBER")}</Typography>
              <Typography className={classes.semiBold14}>+62 {data.phoneNumber}</Typography>
            </div>
            <div className={classes.dataWrapper}>
              <Typography className={classes.grayReguler14}>{t("GENERAL.EMAIL")}</Typography>
              <Typography className={classes.semiBold14}>{data.email}</Typography>
            </div>
          </div>
          <div className={classes.inputWrapper}>
            <SelectInput
              label={t("GENERAL.ROLE")}
              placeholder={t("PLACEHOLDER.SELECT_ROLE")}
              value={typeof data.role === "number" ? data.role : data.role?.id || " "}
              onChange={e => {
                const { value } = e.target;
                setData(old => ({ ...old, supervisor: "", role: value }));
              }}
              dataSelect={listRole.data?.parsedData}
            />
          </div>
          <div className={classes.inputWrapper}>
            <SelectInput
              label={t("GENERAL.POSITION")}
              placeholder={t("PLACEHOLDER.SELECT_POSITION")}
              value={data.position?.id || ""}
              onChange={e => onChangeData("position", e.target.value)}
              dataSelect={listPosition.data?.parsedData}
            />
          </div>
          <div className={classes.inputWrapper}>
            <SelectInput
              label={t("GENERAL.SUPERVISOR")}
              placeholder={t("PLACEHOLDER.SELECT_SUPERVISOR")}
              value={data.supervisor?.user_code || ""}
              onChange={e => {
                const { value } = e.target;
                setData(old => ({ ...old, supervisor: { ...old.supervisor, user_code: value } }));
              }}
              dataSelect={listSupervisor.data?.parsedData}
            />
          </div>
          <div className={classes.inputWrapper}>
            <SelectInput
              label={t("GENERAL.LOCATION")}
              placeholder={t("PLACEHOLDER.SELECT_LOCATION")}
              value={data.location?.id || " "}
              onChange={e => onChangeData("location", e.target.value)}
              dataSelect={listLocation.data?.parsedData}
            />
          </div>
          <SelectInputMultiple
            label="Sales Area"
            placeholder={t("PLACEHOLDER.SELECT_SALES_AREA")}
            dataSelect={listSalesArea.data?.parsedData}
            value={Array.isArray(data.salesAreaUsers.id) ? data.salesAreaUsers.id : []}
            onChange={onSingleChoose}
            onChooseAll={onChooseAll}
          />
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <ButtonOutlined content={t("GENERAL.CANCEL")} width="79px" onClick={onCancel} />
        {page === editable?.length - 1 ? (
          <ButtonContained
            disabled={!isValid || acceptUserSubmit.isLoading}
            content={acceptUserSubmit.isLoading ? "Loading..." : t("GENERAL.SUBMIT")}
            width="67px"
            type="button"
            onClick={handleSubmit}
          />
        ) : null}
      </div>
    </Box>
  );
};

FormDetailInformation.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  editable: PropTypes.array.isRequired,
  setEditable: PropTypes.func.isRequired,
};

export default SidePopUp;
