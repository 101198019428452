import { atom, useAtom } from "jotai";

const INITIAL_FILTER = {
  start: new Date(new Date().setDate(1)),
  end: new Date(),
};

const atomIncomingFilter = atom(INITIAL_FILTER);

const useIncomingFilter = () => {
  return useAtom(atomIncomingFilter);
};

export { INITIAL_FILTER, atomIncomingFilter, useIncomingFilter };
