import { Box, Button, Typography } from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";

// Components
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import SearchBar from "components/input/search-bar";
import { DOWNLOAD_FEATURES, PopupDownload } from "components/popup/popup-download";
import BetterWorseTable from "components/table/better-worse-table";
import ButtonContained from "elements/button/button-contained";
import TextToggleButton from "elements/button/button-toggle-text";
import CustomDeleteChip from "elements/chip/chip-delete";
import MultipleSelectCheckbox from "elements/input/select-multiple-checkbox";
import LoadingView from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { useAuth } from "hooks/useAuth";
import {
  getListBetterWorseCASA,
  getListBetterWorseSalesVolume,
  getListSalesArea,
  downloadBetterWorseSalesVolume,
  downloadBetterWorseCasa,
} from "services/api/private-routes";
import { useStyles } from "./styled";

const INITIAL_STATE_OPTION = {
  sales_area: [],
  search: "",
  typeSearch: "",
};

const DashboardBetterWorseMerchant = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signal } = useAbortController();
  const [popUpDownloadOpen, setPopUpDownloadOpen] = useState(false);
  const queryClient = useQueryClient();
  const { getRole } = useAuth();

  const translations = useMemo(
    () => ({
      toggle: {
        sales_volume: t("GENERAL.SALES_VOLUME"),
        casa: t("SIDEBAR.CASA"),
      },
      searchBar: {
        merchant: t("GENERAL.MERCHANT"),
        group: t("GENERAL.GROUP"),
      },
    }),
    [t],
  );

  const [chipData, setChipData] = useState([]);
  const [activeToggle, setActiveToggle] = useState(translations.toggle.sales_volume);
  const [option, setOption] = useState(INITIAL_STATE_OPTION);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */
  const listSalesArea = useQuery(["list-sales-area"], () => getListSalesArea(), { retry: 0 });

  const listBetterWorseCASA = useQuery(
    ["list-better-worse-casa"],
    () =>
      getListBetterWorseCASA(
        { filterSalesArea: option.sales_area, search: option.search, typeSearch: option.typeSearch?.toLowerCase() },
        signal,
      ),
    {
      enabled: activeToggle === translations.toggle.casa,
      retry: 3,
    },
  );

  const listBetterWorseSalesVolume = useQuery(
    ["list-better-worse-sales-volume"],
    () =>
      getListBetterWorseSalesVolume(
        { filterSalesArea: option.sales_area, search: option.search, typeSearch: option.typeSearch?.toLowerCase() },
        signal,
      ),
    {
      enabled: activeToggle === translations.toggle.sales_volume,
      retry: 3,
    },
  );

  const downloadSalesVolume = useMutation(
    () =>
      downloadBetterWorseSalesVolume({
        filterSalesArea: option.sales_area,
        search: option.search,
        typeSearch: option.typeSearch?.toLowerCase(),
      }),
    { onSettled: () => queryClient.invalidateQueries(["download"]) },
  );

  const downloadCasa = useMutation(
    () =>
      downloadBetterWorseCasa({
        filterSalesArea: option.sales_area,
        search: option.search,
        typeSearch: option.typeSearch?.toLowerCase(),
      }),
    { onSettled: () => queryClient.invalidateQueries(["download"]) },
  );

  const selectSearchBarHandle = useCallback(val => setOption(old => ({ ...old, typeSearch: val })), [setOption]);

  const onSearch = useCallback(val => setOption(old => ({ ...old, search: val })), [setOption]);

  const handleToggleGroup = useCallback(
    value => {
      setActiveToggle(value);
      setOption(INITIAL_STATE_OPTION);
      setChipData([]);
    },
    [setActiveToggle, setOption, setChipData],
  );

  const handleDelete = useCallback(
    name => {
      setChipData(old => old.filter(string => string !== name));
      const filtered = listSalesArea?.data?.data.find(x => x.name === name);
      if (!filtered) return;
      setOption(x => ({ ...x, sales_area: x.sales_area.filter(y => y !== filtered.id) }));
    },
    [setChipData, setOption, listSalesArea],
  );

  const resetFilter = useCallback(() => {
    setChipData([]);
    setOption(x => ({ ...x, sales_area: [] }));
  }, [setChipData, setOption]);

  const checkedAllHandle = () => {
    if (listSalesArea.data?.data?.length === chipData.length) {
      setChipData([]);
      setOption({ ...option, sales_area: [] });
    } else {
      setChipData(listSalesArea.data?.data?.map(item => item.name) ?? []);
      setOption({ ...option, sales_area: listSalesArea.data?.data?.map(item => item.id) });
    }
  };

  const checkHandle = useCallback(
    ({ id, name }) => {
      const isRemoveAction = chipData.includes(name);
      setChipData(x => (isRemoveAction ? x.filter(y => y !== name) : [...x, name]));
      setOption(x => ({
        ...x,
        sales_area: isRemoveAction ? x.sales_area.filter(y => y !== id) : [...x.sales_area, id],
      }));
    },
    [setChipData, setOption, chipData],
  );

  const chipContent = useMemo(() => {
    return (
      <Box className={classes.boxChip}>
        {chipData.map(name => (
          <CustomDeleteChip content={name} onDelete={() => handleDelete(name)} />
        ))}
        {chipData.length !== 0 && (
          <Button variant="outlined" className={classes.buttonReset} onClick={resetFilter}>
            {t("GENERAL.RESET_DEFAULT")}
          </Button>
        )}
      </Box>
    );
  }, [classes, chipData, t, handleDelete, resetFilter]);

  const activeQuery = useMemo(() => {
    const queries = {
      [translations.toggle.casa]: listBetterWorseCASA,
      [translations.toggle.sales_volume]: listBetterWorseSalesVolume,
    };
    return queries[activeToggle];
  }, [listBetterWorseCASA, listBetterWorseSalesVolume, activeToggle, translations]);

  const tableContent = useMemo(() => {
    if (activeQuery.isLoadingError)
      return (
        <div className={classes.errorMessageWrapper}>
          <Typography className={classes.errorTypographyStyle}>{activeQuery.error?.message}</Typography>
          <ButtonContained content={t("GENERAL.TRY_AGAIN")} width="100px" onClick={activeQuery.refetch} />
        </div>
      );
    if (activeQuery.isFetching) return <LoadingView height={350} />;
    const render = activeQuery.data;
    if (!render) return null;
    return (
      <BetterWorseTable
        data={activeQuery.data.data}
        header={activeQuery.data.header}
        subHeader={activeQuery.data.subheader}
      />
    );
  }, [activeQuery, classes, t]);

  return (
    <div>
      {!getRole().isInquiry && (
        <PopupDownload
          isOpen={popUpDownloadOpen}
          onClose={() => setPopUpDownloadOpen(false)}
          feature={
            translations.toggle.sales_volume === activeToggle
              ? DOWNLOAD_FEATURES.BETTER_WORSE_SALES_VOLUME
              : DOWNLOAD_FEATURES.BETTER_WORSE_CASA
          }
          onSubmit={
            translations.toggle.sales_volume === activeToggle ? downloadSalesVolume.mutate : downloadCasa.mutate
          }
        />
      )}
      {!getRole().isInquiry && (
        <div className={classes.buttonWrapper}>
          <Button
            startIcon={<IconDownload />}
            variant="contained"
            className={classes.buttonContained}
            onClick={() => setPopUpDownloadOpen(true)}
          >
            {t("GENERAL.DOWNLOAD_EXCEL")}
          </Button>
        </div>
      )}
      <div className={classes.searchWrapper}>
        <div className={classes.dividerWrapper}>
          <div className={classes.margin20}>
            <SearchBar
              menuItem={Object.values(translations.searchBar)}
              onChange={selectSearchBarHandle}
              onSearch={onSearch}
              selected={option.typeSearch || translations.searchBar.merchant}
              value={option.search}
            />
          </div>
          <div className={classes.margin20}>
            <MultipleSelectCheckbox
              content={listSalesArea.isFetching ? "Loading..." : t("GENERAL.ALL_AREA")}
              arrayData={listSalesArea.data?.data ?? []}
              chipData={chipData}
              onChange={checkHandle}
              onChooseAll={checkedAllHandle}
              isChooseAll
            />
          </div>
          <div className={classes.centerWrapper}>
            <TextToggleButton
              toggleData={Object.values(translations.toggle)}
              selected={activeToggle}
              onChange={handleToggleGroup}
            />
          </div>
        </div>
        <ButtonContained content="Apply" width="72px" onClick={activeQuery.refetch} disabled={activeQuery.isFetching} />
      </div>
      {chipContent}
      <div className={classes.tableWrapper}>
        <div className={classes.tableTitleWrapper}>
          <Typography className={classes.tableTitle}>{activeToggle}</Typography>
          <Typography className={classes.tableSubtitle}>{t("GENERAL.IDR_BIO")}</Typography>
        </div>
        {tableContent}
      </div>
    </div>
  );
};

export default DashboardBetterWorseMerchant;
