import axios, { AxiosError } from "axios";
import ENDPOINT from "utils/constants/endpoint";

const axiosInstance = axios.create({
  baseURL: ENDPOINT.BASEURL,
});

axiosInstance.interceptors.request.use(
  req => req,
  err => err,
);

axiosInstance.interceptors.response.use(
  res => res,
  err => {
    if (err instanceof AxiosError) {
      return err.response;
    } else return err;
  },
);

export { axiosInstance };
