import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { SIZES } from "utils/constants/fonts";

const StyledFooter = styled("div")(({ ...props }) => ({
  position: "fixed",
  bottom: props.bottom,
  top: "auto",
  width: props.width,
  color: "rgb(128, 128, 128)",
  textAlign: "center",
  fontSize: SIZES.REGULAR_12,
  fontWeight: 400,
  lineHeight: SIZES.REGULAR_18,
}));

const Footer = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledFooter {...props}>
      <p>{t("LOGIN.FOOTER")}</p>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  width: "100%",
  bottom: "30px",
};

Footer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Footer;
