import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconChartBar from "assets/icons/chart/icon-chart-bar.svg";
import IconCrown from "assets/icons/general/icon-crown.svg";
import IconChartSales from "assets/icons/general/icon-mobile.svg";
import CardPortofolioSalesVolume from "components/card/card-portofolio-sales-volume";
import { CardSalesVolumeTable } from "components/card/card-sales-volume";
import LegendBarSalesVolume from "components/legend/legend-portofolio-sales-bar";
// import GeneralMap from "components/map";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";

// Elements
import BarChart from "elements/chart/bar-chart-vertikal";
import CustomSelectDropdown from "elements/input/select-custom";
// import UnderMaintenance from "elements/under-maintenance";

// >>>>>>>>>>>>>>>> SERVICES  <<<<<<<<<<<<<<
// GET DATA SALES_VOLUME_OVERVIEW
// import { useAbortController } from "hooks/useAbortController";
import {
  // getSalesVolumeMap,
  // getSalesVolumeType,
  getSalesVolumeOverView,
  getSalesByMonth,
  getSelectByLogin,
} from "services/api/private-routes";

// >>>>>>>>>>>>>>>> END SERVICES  <<<<<<<<<<<<<<
import COLORS from "utils/constants/colors";

// Style
import { useStyles } from "./styled";

const DashboardPortofolioSalesVolume = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const { signal } = useAbortController();

  const translation = useMemo(
    () => ({
      toggle: {
        division: t("CASA.DIVISION"),
        coordinator: t("PORTOFOLIO.AREA_COORDINATOR"),
        sales: t("BOX.TAT_PROCESS_SALES"),
      },
      title: {
        overview_sales_volume: t("PORTOFOLIO.OVERVIEW_SALES_VOLUME"),
        idr_bio: t("PORTOFOLIO.IDR_BIO"),
      },
      label: {
        current_month: t("PORTOFOLIO.CURRENT_MONTH_RR"),
        higgest_division: t("PORTOFOLIO.TOP_DIVISION_HEAD"),
        top_area_coordinator: t("PORTOFOLIO.TOP_AREA_COORDINATOR"),
      },
    }),
    [t],
  );

  const intervalPositionSalesOverview = "title*total_bio";
  const intervalPositionSalesVolume = "month_name*total_bio";
  const axisNameSalesOverview = "title";
  const axisNameSalesByMonth = "month_name";

  const [selectSalesAreaLogin, setSelectAreaLogin] = useState("");

  // SERVICES
  const salesAreaLogin = useQuery(["sales-area-login"], () => getSelectByLogin(), { retry: 0 });
  // const {
  //   data: salesVolumeMapData,
  //   isLoading: loadMap,
  //   isError: errorMap,
  //   refetch: refetchMap,
  // } = useQuery(["sales-volume-map"], () => getSalesVolumeMap(signal), {
  //   retry: 0,
  // });
  // const {
  //   data: salesVolumeTypeData,
  //   isLoading: loadType,
  //   isError: errorMapSalesType,
  //   refetch: refetchMapSalesType,
  // } = useQuery(["sales-volume-type"], () => getSalesVolumeType(signal), {
  //   retry: 0,
  // });
  const diagramSalesOverview = useQuery(["chart-sales-overview"], () => getSalesVolumeOverView(), { retry: 0 });

  const diagramSalesByMonth = useQuery(
    ["diagram-month", selectSalesAreaLogin],
    () => getSalesByMonth(selectSalesAreaLogin?.id ?? ""),
    { retry: 0, enable: salesAreaLogin.isSuccess },
  );

  // Chart COLOR
  const colorOverviewSales = [
    "title*total",
    name => {
      const colorCode = {
        total_volume: COLORS.GREEN_HARD,
        higgest_division: COLORS.PURPLE_HARD,
        higgest_arco: COLORS.GRAY_HARD,
      };
      return colorCode[name] ?? "";
    },
  ];

  // Chart COLORING
  const colorSalesVolume = [
    "id",
    id => {
      const colorCode = {
        0: COLORS.BLUE_MEDIUM,
        1: COLORS.GREEN_HARD,
        2: COLORS.SECONDARY_MEDIUM,
      };
      return colorCode[id] ?? COLORS.GREEN_HARD;
    },
  ];

  const labelSalesOverview = {
    formatter(items) {
      const { label } = translation;
      const labelCode = {
        total_volume: label.current_month,
        higgest_division: label.higgest_division,
        higgest_arco: label.top_area_coordinator,
      };
      return labelCode[items];
    },
  };

  return (
    <div>
      <Grid container direction="column" spacing={4}>
        {/* <Grid item>
          <UnderMaintenance>
            <GeneralMap
              locationData={salesVolumeMapData ?? []}
              summaryData={salesVolumeTypeData ?? []}
              isLoading={loadMap || loadType}
              isError={errorMap || errorMapSalesType}
              refetch={refetchMap || refetchMapSalesType}
            />
          </UnderMaintenance>
        </Grid> */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CardWrapper>
                <TitleChart
                  icon={IconChartSales}
                  title={translation.title.overview_sales_volume}
                  titleIdr={translation.title.idr_bio}
                />
                <BarChart
                  data={diagramSalesOverview}
                  legendView={false}
                  heightChart={350}
                  colors={colorOverviewSales}
                  labelCustom={labelSalesOverview}
                  positionName={intervalPositionSalesOverview}
                  axisName={axisNameSalesOverview}
                />
                <div className={classes.legendSalesVolumeWrapper}>
                  {diagramSalesOverview.isSuccess ? (
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={4}>
                        <LegendBarSalesVolume
                          title={diagramSalesOverview?.data[0].month_name || "-"}
                          marginSize="60px"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.legendSingleWrapper}>
                          <LegendBarSalesVolume title={diagramSalesOverview?.data[1].name || "-"} icon={IconCrown} />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.legendSingleWrapper}>
                          <LegendBarSalesVolume title={diagramSalesOverview?.data[2].name || "-"} icon={IconCrown} />
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={4}>
                        <LegendBarSalesVolume title="-" marginSize="60px" />
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.legendSingleWrapper}>
                          <LegendBarSalesVolume title="-" icon={IconCrown} />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.legendSingleWrapper}>
                          <LegendBarSalesVolume title="-" icon={IconCrown} />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </CardWrapper>
            </Grid>
            <Grid item xs={6}>
              <CardWrapper>
                <div className={classes.titleWrapper}>
                  <TitleChart
                    icon={IconChartBar}
                    title={t("SIDEBAR.SALES_VOLUME")}
                    titleIdr={t("PORTOFOLIO.IDR_BIO")}
                  />
                  <CustomSelectDropdown
                    placeholder={salesAreaLogin.isFetching ? "Loading..." : "Sales Area"}
                    arrayData={salesAreaLogin.data?.data ?? []}
                    onChange={e => setSelectAreaLogin(e.target.value)}
                    disabled={salesAreaLogin.isFetching}
                    renderValue={val => val.name}
                    data={selectSalesAreaLogin}
                  />
                </div>
                <BarChart
                  data={diagramSalesByMonth}
                  legendView={false}
                  heightChart={348}
                  colors={colorSalesVolume}
                  positionName={intervalPositionSalesVolume}
                  axisName={axisNameSalesByMonth}
                />
              </CardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CardPortofolioSalesVolume enable={salesAreaLogin.isSuccess} selectSalesAreaLogin={selectSalesAreaLogin} />
        </Grid>
      </Grid>
      <CardSalesVolumeTable classes={classes} />
    </div>
  );
};

export default DashboardPortofolioSalesVolume;
