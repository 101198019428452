import { makeStyles, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import LineIconArrowRight from "elements/icon/dashboard-profitibility/LineIconArrowRight";
import LineIconArrowUp from "elements/icon/dashboard-profitibility/LineIconArrowUp";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "start",
    gap: "18px",
    marginBottom: "40px",
  },
  textContainer: {
    flex: 0.05,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  textWrapper: {
    position: "relative",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotateText: {
    transform: "translate(-18%, -50%) rotate(-90deg)",
    position: "absolute",
    textAlign: "center",
    width: "212%",
  },
  labelText: {
    fontSize: SIZES.REGULAR_18,
    fontWeight: "600",
    lineHeight: "30px",
    color: COLORS.DARK_HARD,
  },
  iconArrowUp: {
    position: "absolute",
    top: "-7px",
    left: "-9px",
  },

  iconArrowRight: {
    position: "absolute",
    bottom: "-17.5px",
    right: "-12px",
  },
  textBottomWrapper: {
    position: "absolute",
    bottom: "-3%",
    left: "6%",
    width: "94%",
    display: "flex",
    height: "max-content",
    alignItems: "center",
    justifyContent: "space-around",
  },
  containerItems: {
    flex: 1,
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    paddingLeft: "30px",
    paddingBottom: "30px",
    borderLeft: `2px solid ${COLORS.DARK_SOFT}`,
    borderBottom: `2px solid ${COLORS.DARK_SOFT}`,
  },
});

const CardWrapperLine = ({ children, labelLeft, labelBottom }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <div className={classes.textContainer}>
        {labelLeft?.map(label => (
          <div className={classes.textWrapper}>
            <Typography className={[classes.rotateText, classes.labelText]}>{label}</Typography>
          </div>
        ))}
      </div>
      <div className={classes.containerItems}>
        <div className={classes.iconArrowUp}>
          <LineIconArrowUp fill={COLORS.DARK_SOFT} />
        </div>
        {children}
        <div className={classes.iconArrowRight}>
          <LineIconArrowRight fill={COLORS.DARK_MEDIUM} />
        </div>
      </div>
      <div className={classes.textBottomWrapper}>
        {labelBottom?.map(label => (
          <Typography className={classes.labelText}>{label}</Typography>
        ))}
      </div>
    </Grid>
  );
};

CardWrapperLine.defaultProps = {
  labelLeft: [],
  labelBottom: [],
};

CardWrapperLine.propTypes = {
  children: PropTypes.node.isRequired,
  labelLeft: PropTypes.array,
  labelBottom: PropTypes.array,
};

export default CardWrapperLine;
