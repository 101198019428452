import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { routes } from "utils/constants/paths";
import { useAuth } from "./useAuth";

const withRoles = (Component, roles) => {
  if (!Array.isArray(roles)) throw new Error(`Type roles is ${typeof roles} expected string of ROLES`);

  return (
    <RoutesGuard authorizedRoles={roles}>
      <Component />
    </RoutesGuard>
  );
};

const RoutesGuard = ({ children, authorizedRoles }) => {
  const { getRole } = useAuth();

  return authorizedRoles.includes(getRole().role) ? (
    children
  ) : (
    <Navigate to={routes.DASHBOARD.PARSE_PREFIX(routes.DASHBOARD.INCOMING_APPLICATION)} />
  );
};

RoutesGuard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  authorizedRoles: PropTypes.array.isRequired,
};

export { withRoles };
