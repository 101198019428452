/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import { makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { Axis, Chart, Legend, LineAdvance, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Assets
import IconChart from "assets/icons/chart/icon-chart-line.svg";
import IconLegendBlue from "assets/icons/general/icon-ellipse-blue.svg";
import IconLegendYellow from "assets/icons/general/icon-ellipse-yellow.svg";

// Components
import { ServerErrorBoundary } from "components/general";
import LegendChartText from "components/legend/legend-char-text";
import TitleCharts from "components/title/title-charts";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
// Elements
import CardTitle from "elements/card/card-header/card-title";

import LoadingSpinner from "elements/loading/spinner";
import { CustomSelect } from "elements/select";
import { useAbortController } from "hooks/useAbortController";
import { getDataOctoMerchantQrVolume } from "services/api/private-routes/portfolio-management/octo-merchant-qr-volume";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { maxScale } from "utils/helpers/useChartConfig";
import { parseCurrency } from "utils/helpers/useFormater";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const useStyles = makeStyles(theme => ({
  wrapperLegend: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
  btn: {
    color: COLORS.WHITE,
    padding: "10px 20px",
    borderRadius: 8,
    border: "none",
    backgroundColor: COLORS.RED_HARD,
  },
  containerHeader: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  customSelect: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
}));

const years = new Date().getFullYear();
const months = new Date().getMonth();

const INITIAL_STATE = `${MONTHS[months]} ${years}`;

const getDaysInMonth = (month, year) => {
  const arr = new Array(31)
    .fill("")
    .map((v, i) => new Date(year, month - 1, i + 1))
    .filter(v => v.getMonth() === month - 1);

  return arr.length;
};

const CardOctoMerchantQrVolume = ({ selectOctoMerchant, selectedMonth }) => {
  const classes = useStyles();
  const { signal } = useAbortController();
  const INITIAL = new Date();
  const [selectMonth, setSelectedMonth] = useState("");
  const [initialState, setInitialState] = useState(INITIAL_STATE);

  const getDate = value => {
    const getMonths = value.replace(/\d+/gi, "").trim();
    const getYears = value.replace(/\D/g, "").trim();
    return `01/${getMonths}/${getYears}`;
  };

  const dataOctoMerchantQrVolume = useQuery(["data-octo-merchant-qr-volume", initialState], () =>
    getDataOctoMerchantQrVolume(
      {
        month: selectMonth ? getDate(selectMonth) : INITIAL,
        year: selectMonth ? getDate(selectMonth) : INITIAL,
      },
      signal,
    ),
  );

  useEffect(() => {
    if (selectMonth) {
      setInitialState(selectMonth);
    }
  }, [selectMonth, dataOctoMerchantQrVolume]);

  const getChooseMonth = dates => {
    const date = new Date(dates);
    const month = date.getMonth() + 1;
    const arr = new Array(31)
      .fill("")
      .map((v, i) => new Date(date.getFullYear(), month - 1, i + 1))
      .filter(v => v.getMonth() === month - 1);

    return arr.length;
  };

  /* translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      cardTitle: t("PORTOFOLIO.OCTO_SALES_VOLUME"),
      titleCharts: {
        textTitle: t("PORTOFOLIO.QR_VOLUME"),
        textSubtitle: t("PORTOFOLIO.IDR_MIO"),
      },
      legendChartText: {
        octoMerchant: t("PORTOFOLIO.OCTO_MERCHANT"),
        qrVolume: t("PORTOFOLIO.QR_VOLUME"),
      },
    }),
    [t],
  );

  const labelAxis = {
    formatter(text) {
      return text;
    },
  };

  /* memo fetching */
  const DATA_CHART = useMemo(() => {
    if (dataOctoMerchantQrVolume.isError) {
      return (
        <ServerErrorBoundary
          onClick={() => dataOctoMerchantQrVolume.refetch()}
          title={dataOctoMerchantQrVolume.error?.message}
        />
      );
    }
    if (dataOctoMerchantQrVolume.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!dataOctoMerchantQrVolume.isLoading && dataOctoMerchantQrVolume.isSuccess) {
      const styles = classes;
      return (
        <Chart
          padding={[10, 20, 50, 60]}
          autoFit
          height={300}
          data={dataOctoMerchantQrVolume?.data}
          scale={{
            /** @type {import('bizcharts').ScaleConfig} */
            amount: {
              min: 0,
              max: maxScale(dataOctoMerchantQrVolume?.data || {}),
              nice: true,
            },
            /** @type {import('bizcharts').ScaleConfig} */
            date: {
              min: 1,
              max: !selectMonth ? getDaysInMonth(months + 1, years) : getChooseMonth(selectMonth),
              nice: true,
              tickInterval: 1,
            },
          }}
        >
          <Tooltip showCrosshairs={false} shared={false} />
          <Axis name="date" label={labelAxis} />
          <LineAdvance
            shape="smooth"
            area
            point="date*amount"
            position="date*amount"
            color={["value", `${COLORS.BLUE_MEDIUM}-${COLORS.SECONDARY_MEDIUM}`]}
          />
          <Tooltip position="bottom" showCrosshairs={false}>
            {(title, items) => {
              return (
                <div className={styles.wrapper}>
                  <div className={styles.datesWrapper}>{items[0].data.date ?? "-"}</div>
                  <div className={styles.valueWrapper}>
                    {parseCurrency(items[0].data.amount, { maximumFractionDigits: 10 }) ?? "-"}
                  </div>
                </div>
              );
            }}
          </Tooltip>
          <Legend visible={false} />
        </Chart>
      );
    }
  }, [classes, dataOctoMerchantQrVolume, labelAxis, selectMonth]);

  const placeholder = value => {
    return `${value}`;
  };

  return (
    <CardWrapper>
      <div className={classes.containerHeader}>
        <CardTitle iconSrc={IconChart} title={translations.cardTitle} />
        <CustomSelect
          className={classes.customSelect}
          placeholder={
            selectOctoMerchant.isFetching ? (
              "Loading..."
            ) : (
              <span
                style={{
                  color: "#000",
                }}
              >
                {placeholder(selectMonth || INITIAL_STATE)}
              </span>
            )
          }
          data={selectOctoMerchant?.data ?? []}
          disabled={selectOctoMerchant.isFetching}
          setValue={val => {
            setSelectedMonth(val.value);
            selectedMonth(val.value);
          }}
          renderItem={val => val.value}
          renderValue={val => val.value}
          value={selectMonth.value}
        />
      </div>
      <WrapperOutlineChart>
        <TitleCharts
          textTitle={translations.titleCharts.textTitle}
          textSubtitle={translations.titleCharts.textSubtitle}
        />
        {DATA_CHART}
      </WrapperOutlineChart>
      <div className={classes.wrapperLegend}>
        <LegendChartText text={translations.legendChartText.octoMerchant} icon={IconLegendBlue} />
        <LegendChartText text={translations.legendChartText.qrVolume} icon={IconLegendYellow} />
      </div>
    </CardWrapper>
  );
};

CardOctoMerchantQrVolume.defaultProps = {
  selectOctoMerchant: [],
};

CardOctoMerchantQrVolume.propTypes = {
  selectOctoMerchant: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CardOctoMerchantQrVolume;
