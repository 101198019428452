import { intl } from "utils/helpers/general";
import { axiosPrivate } from "../axiosPrivate";

const parseNewCalcSalesVolume = (obj = {}) => {
  return {
    value: obj.sales_volume,
    input: {
      creditOnUs: obj.sales_volume_percentage_credit_on_us,
      creditOffUs: obj.sales_volume_percentage_credit_off_us,
      debitOnUs: obj.sales_volume_percentage_debit_on_us,
      debitOffUs: obj.sales_volume_percentage_debit_off_us,
      qr: obj.sales_volume_percentage_qr,
      citlap3: obj.sales_volume_percentage_ciltap3,
      citlap6: obj.sales_volume_percentage_ciltap6,
      citlap12: obj.sales_volume_percentage_ciltap12,
      citlap24: obj.sales_volume_percentage_ciltap24,
      recurring: obj.sales_volume_percentage_recurring,
    },
  };
};

const parseNewCalcRevenue = (obj = {}) => {
  return {
    value: obj.revenue,
    input: {
      creditOnUs: obj.revenue_percentage_credit_on_us,
      creditOffUs: obj.revenue_percentage_credit_off_us,
      debitOnUs: obj.revenue_percentage_debit_on_us,
      debitOffUs: obj.revenue_percentage_debit_off_us,
      qr: obj.revenue_percentage_qr,
      citlap3: obj.revenue_percentage_ciltap3,
      citlap6: obj.revenue_percentage_ciltap6,
      citlap12: obj.revenue_percentage_ciltap12,
      citlap24: obj.revenue_percentage_ciltap24,
      recurring: obj.revenue_percentage_recurring,
    },
  };
};

const getExistingCalculation = async ({ brandingId, period, salesId, areaId }) => {
  const { status, data: responseBody } = await axiosPrivate.post("/dashboard/calculationprofitandlos/existing", {
    branding_id: brandingId ?? null,
    period: period ?? new Date().getFullYear(),
    sales_id: salesId || undefined,
    area_id: areaId || undefined,
  });

  if (status !== 200) throw new Error("Not found");

  const { data } = responseBody;

  const { existing_calculation } = data;

  const parsedData = {
    netRevenueShadow: existing_calculation.net_revenue_after_transaction_with_shadow,
    netRevenue: existing_calculation.net_revenue_after_transaction,
    salesVolume: {
      value: existing_calculation.sales_volume,
      creditOnUs: existing_calculation.sales_volume_credit_on_us,
      creditOffUs: existing_calculation.sales_volume_credit_off_us,
      debitOnUs: existing_calculation.sales_volume_debit_on_us,
      debitOffUs: existing_calculation.sales_volume_debit_off_us,
      qr: existing_calculation.sales_volume_qr,
    },
    revenue: {
      value: existing_calculation.revenue,
      creditOnUs: existing_calculation.revenue_credit_on_us,
      creditOffUs: existing_calculation.revenue_credit_off_us,
      debitOnUs: existing_calculation.revenue_debit_on_us,
      debitOffUs: existing_calculation.revenue_debit_off_us,
      qr: existing_calculation.revenue_qr,
    },
    cost: {
      value: existing_calculation.cost,
      interchange: existing_calculation.interchange,
      interchange_npg: existing_calculation.interchange_npg,
      switching_fee: existing_calculation.switching_fee,
    },
  };

  const updatedAt = responseBody.data.new_calculation?.updated_date;
  return {
    data: parsedData,
    rawData: responseBody.data,
    newCalculation: {
      updatedAt: updatedAt ? intl.dateFormat(new Date(updatedAt)) : null,
      salesVolume: updatedAt ? parseNewCalcSalesVolume(responseBody.data.new_calculation) : null,
      revenue: updatedAt ? parseNewCalcRevenue(responseBody.data.new_calculation) : null,
      cost: updatedAt
        ? {
            value: responseBody.data.new_calculation.cost,
            interchange: responseBody.data.new_calculation.interchange,
            npg: responseBody.data.new_calculation.interchange_npg,
            fee: responseBody.data.new_calculation.switching_fee,
            edcCost: responseBody.data.new_calculation.amount_edc_cost,
            edcCharge: responseBody.data.new_calculation.amount_edc_charge,
          }
        : null,
    },
  };
};

export { getExistingCalculation };
