const dummyPositions = [
  {
    key: "position1",
    value: "Position 1",
    label: "Position 1",
  },
  {
    key: "position2",
    value: "Position 2",
    label: "Position 2",
  },
  {
    key: "position3",
    value: "Position 3",
    label: "Position 3",
  },
];

export { dummyPositions };
