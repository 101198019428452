const routes = {
  AUTH: {
    BASE_PREFIX: "/",
    LOGIN: "login",
    REGISTER: "register",
    OTP: "otp",
    FORGOT_PASSWORD: "forgot-password",
    NEW_PASSWORD: "new-password",
    VERIFICATION_SUCCESS: "verification-success",
    PARSE_PREFIX: to => routes.AUTH.BASE_PREFIX + to,
  },
  DASHBOARD: {
    BASE_PREFIX: "/",
    INCOMING_APPLICATION: "dashboard-incoming-application",
    PORTOFOLIO: "dashboard-portofolio",
    PORTOFOLIO_SALES_VOLUME: "dashboard-portofolio-sales-volume",
    PORTOFOLIO_CASA: "dashboard-portofolio-by-casa",
    BETTER_WORSE_MERCHANT: "dashboard-worse-better-merchant",
    DETAIL_TRANSACTION: "dashboard-detail-transaction",
    DETAIL_REVENUE_BRANDING: "dashboard-detail-revenue-by-branding",
    PROFITABILITY: "dashboard-profitability",
    PROFITABILITY_DETAIL: "dashboard-profitability-detail/:monthly",
    PROFITABILITY_BY_MERCHANT: "dashboard-profitability-by-merchant/:monthly/:id/:branding",
    TERMINOLOGY: "terminology/:id",
    EXECUTIVE_SUMMARY: "dashboard-executive-summary",
    CALCULATION_PROFIT_LOSS: "calculation-profit-loss",
    USER_MANAGEMENT: "user-management",
    PARSE_PREFIX: to => routes.DASHBOARD.BASE_PREFIX + to,
  },
};

const routeAuthList = [
  routes.AUTH.LOGIN,
  routes.AUTH.REGISTER,
  routes.AUTH.OTP,
  routes.AUTH.FORGOT_PASSWORD,
  routes.AUTH.NEW_PASSWORD,
];

const PATHS_WITH_BACK = [
  "dashboard-profitability-detail",
  "dashboard-profitability-by-merchant",
  "terminology"
]

export { routes, routeAuthList, PATHS_WITH_BACK };
