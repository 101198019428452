const COLORS = {
  BACKGROUND: "#CACACA",
  PRIMARY_DARK: "#A60808",
  PRIMARY_HARD: "#DC241F",
  PRIMARY_ULTRASOFT: "#FFE9E9",
  DARK_HARD: "#2B2F3C",
  DARK_MEDIUM: "#374062",
  DARK_SOFT: "#4B4D53",
  GRAY_PRIMARY: "#727FA3",
  GRAY_SECONDARY: "#717A9C",
  GRAY_DARK: "#8D98B4",
  GRAY_HARD: "#97A5C9",
  GRAY_MEDIUM: "#BCC8E7",
  GRAY_SOFT: "#E9F0FF",
  GRAY_ULTRASOFT: "#F3F7FD",
  RED_HARD: "#E32D2D",
  RED_MEDIUM: "#FF6F6F",
  RED_MEDIUM_HARD: "#FF3C3C",
  RED_SOFT: "#ED5A5A",
  RED_ULTRASOFT: "#FFE6E6",
  GREEN_HARD: "#6DD13E",
  GREEN_MEDIUM: "#65D170",
  GREEN_LIGHT: "#9CF671",
  GREEN_SOFT: "#C4FFA8",
  GREEN_ULTRASOFT: "#E6FFDA",
  SECONDARY_MEDIUM: "#FFB443",
  SECONDARY_SOFT: "#FFD495",
  SECONDARY_ULTRASOFT: "#FFECD1",
  PURPLE_HARD: "#CB88FF",
  PURPLE_SOFT: "#ECD3FF",
  PURPLE_ULTRASOFT: "#F5E9FF",
  BLUE_MEDIUM: "#749BFF",
  WHITE: "#FFFFFF",
  BOX_SHADOW: "rgba(188, 200, 231, 0.25)",
  CYAN: "#43D9ED",
};

export default COLORS;
