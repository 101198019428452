const general = {
  value: "",
  input: {
    creditOnUs: "",
    creditOffUs: "",
    debitOnUs: "",
    debitOffUs: "",
    qr: "",
    citlap3: "",
    citlap6: "",
    citlap12: "",
    citlap24: "",
    recurring: "",
  },
};

const structure = {
  salesVolume: general,
  revenue: general,
  cost: {
    value: "",
    interchange: "",
    npg: "",
    fee: "",
    edcCost: "",
    edcCharge: "",
  },
  netRevenue: 0,
  netRevenueShadow: 0,
};

export { structure };
