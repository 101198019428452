import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

// css
import "./index.style.css";

// component
import DotIcon from "elements/icon/dot-icon";
// dummyProfile
import { useAuth } from "hooks/useAuth";

const MenuProfile = ({ open }) => {
  const { userData } = useAuth();

  return (
    <div className="wrapper-profile">
      <div className={`wrapper-avatar ${open ? "" : "close"}`}>
        <Avatar />
      </div>
      <div className={`wrapper-profile-detail ${open ? "" : "close"}`}>
        <p className="profile-name">
          {userData()?.fullName?.length >= 15 ? `${userData().fullName.slice(0, 15)}...` : userData().fullName}
        </p>
        <div className="profile-detail">
          <span>{userData()?.role?.name || "-"}</span>
          <div className="center-svg">
            <DotIcon fill="#727FA3" />
          </div>
          <span>{userData()?.location?.name}</span>
        </div>
      </div>
    </div>
  );
};

MenuProfile.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default MenuProfile;
