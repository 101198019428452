import { InputAdornment, makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as IconCalender } from "assets/icons/general/icon-calender-picker.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
    "& .MuiInputBase-input": {
      fontSize: SIZES.REGULAR_12,
      fontWeight: 400,
    },
  },
}));

const DatePickerComp = ({ minDate, maxDate, onChange, value, views, placeholder, format, onClose, autoOk }) => {
  const classes = useStyles();

  return (
    <DatePicker
      className={classes.root}
      autoOk={autoOk}
      minDate={minDate}
      maxDate={maxDate}
      variant="inline"
      size="small"
      inputVariant="outlined"
      placeholder={!placeholder ? "DD/MM/YYYY" : placeholder}
      views={!views ? undefined : views}
      format={!format ? "DD/MM/YYYY" : format}
      onChange={date => onChange?.(new Date(date))}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconCalender />
          </InputAdornment>
        ),
      }}
      animateYearScrolling
      onClose={onClose}
    />
  );
};

DatePickerComp.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  onChange: () => {},
  value: undefined,
  placeholder: "DD/MM/YYYY",
  format: "DD/MM/YYYY",
  views: undefined,
  autoOk: true,
  onClose: () => {},
};

DatePickerComp.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  views: PropTypes.any,
  onClose: PropTypes.func,
  autoOk: PropTypes.bool,
};

export default DatePickerComp;
