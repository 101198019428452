/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import React from "react";
import Accordion from "elements/accordion";
import { TABLE_CELL_OPTIONS } from "utils/constants/table-cell-options";
import { thousandFormatWithComma } from "utils/helpers/useFormater";

const TableCellOption = props => {
  function getRenderer() {
    switch (props.options) {
      case TABLE_CELL_OPTIONS.STRING:
        return props.value;
      case TABLE_CELL_OPTIONS.ACCORDION:
        return <Accordion title={props.value?.title} options={props.value?.options} />;
      case TABLE_CELL_OPTIONS.NUMBER:
        return thousandFormatWithComma(props.value);
      default:
        return props.value;
    }
  }

  return <>{getRenderer()}</>;
};

TableCellOption.propTypes = {
  options: PropTypes.string,
  value: PropTypes.any.isRequired,
};

TableCellOption.defaultProps = {
  options: TABLE_CELL_OPTIONS.STRING,
};

export default TableCellOption;
