import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ButtonContained from "elements/button/button-contained";
import Description from "elements/description";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxStyle: {
    width: "400px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 8px 12px rgba(188, 200, 231, 0.4)",
  },
  iconButtonBoxStyle: {
    height: "24px",
    width: "24px",
    alignSelf: "end",
  },
  imgBoxStyle: {
    marginTop: "20px",
    height: "128px",
    width: "128px",
    alignSelf: "center",
    marginBottom: "20px",
  },
  descStyle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginBottom: "25px",
  },
}));

const CardVerification = ({ title, description, buttonText, icon, onSubmit }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxStyle}>
      <Title title={title} align="center" fontWeight={600} />
      <img src={icon} alt="cardexpire" className={classes.imgBoxStyle} />
      <div className={classes.descStyle}>
        <Description description={description} align="center" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
      </div>
      {buttonText && <ButtonContained content={buttonText} width="100%" onClick={onSubmit} />}
    </Box>
  );
};

CardVerification.defaultProps = {
  buttonText: null,
  onSubmit: () => {},
};

CardVerification.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default CardVerification;
