import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import icon from "assets/icons/general/icon-rank.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const IconNumberStyle = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "24px",
  width: "24px",
});

const ImgStyle = styled("img")({
  height: "24px",
  width: "24px",
  alignSelf: "center",
});

const StyleTypography = styled(Typography)({
  color: COLORS.WHITE,
  fontSize: SIZES.REGULAR_12,
  fontWeight: 600,
  height: "24px",
  width: "24px",
  marginTop: "-20px",
});

const IconNumber = ({ number }) => (
  <IconNumberStyle>
    <ImgStyle src={icon} alt="icon-title" />
    <StyleTypography variant="subtitle1" component="div" align="center">
      {number}
    </StyleTypography>
  </IconNumberStyle>
);

IconNumber.defaultProps = {
  number: 1,
};

IconNumber.propTypes = {
  number: PropTypes.number,
};

export default IconNumber;
