import { makeStyles } from "@material-ui/core";
import { DonutChart } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(() => ({
  wrapperChart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  circleInsideChart: {
    height: 102,
    width: 102,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    border: "1px solid #C6D6FE",
    position: "absolute",
    margin: "auto auto auto 6px ",
  },
}));

const DonutChartComp = ({ dataDonutChart, colorChart, percentageLabel }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.wrapperChart}>
        <DonutChart
          data={dataDonutChart}
          autoFit
          height={200}
          padding={0}
          innerRadius={0.7}
          label={{
            visible: false,
          }}
          statistic={{
            title: "false",
            content: {
              style: {
                marginTop: "-12px",
                fontSize: 18,
                lineHeight: "26px",
                fontFamily: "Roboto",
                fontWeight: 600,
              },
              formatter: () => `${percentageLabel}%`,
            },
          }}
          legend={{
            visible: false,
          }}
          angleField="value"
          colorField="type"
          color={colorChart}
          pieStyle={{ lineWidth: 0 }}
        />
        {/* Circle inside Chart */}
        <div className={classes.circleInsideChart} />
      </div>
    </div>
  );
};

DonutChartComp.defaultProps = {
  colorChart: ["type"],
  dataDonutChart: [],
  percentageLabel: 0,
};

DonutChartComp.propTypes = {
  colorChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dataDonutChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  percentageLabel: PropTypes.number,
};

export default DonutChartComp;
