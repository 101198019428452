import { makeStyles } from "@material-ui/core";
import React, { useState, useMemo, useCallback } from "react";
import DatePickerComp from "components/datepicker";
import { Button } from "elements/button";
import { CardBase } from "elements/card";
import { INITIAL_FILTER, useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";

const useStyles = makeStyles({
  filter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: ".8rem",
  },
});

const MIN_THREE_YEARS_AGO = new Date().setFullYear(new Date().getFullYear() - 3);

const CardFilterDateIncoming = () => {
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_FILTER);
  const [, setFilter] = useIncomingFilter();

  const maxDate = useMemo(() => {
    const addThirtyOneDay = new Date(state.start);
    addThirtyOneDay.setDate(addThirtyOneDay.getDate() + 31);
    if (addThirtyOneDay.getTime() > new Date().getTime()) {
      return new Date();
    } else {
      return addThirtyOneDay;
    }
  }, [state]);

  const handleOnApply = useCallback(() => {
    setFilter(state);
  }, [state, setFilter]);

  return (
    <CardBase className={classes.filter}>
      <div className={classes.dateContainer}>
        <DatePickerComp
          onChange={e => setState({ start: e, end: e })}
          value={state.start}
          minDate={MIN_THREE_YEARS_AGO}
          maxDate={new Date()}
        />
        -
        <DatePickerComp
          onChange={e => setState(old => ({ ...old, end: e }))}
          value={state.end}
          minDate={state.start}
          maxDate={maxDate}
        />
      </div>
      <Button onClick={handleOnApply}>Apply</Button>
    </CardBase>
  );
};

export { CardFilterDateIncoming };
