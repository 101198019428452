const sampleResponseSalesVolume = {
  response_code: "200",
  response_message: "Success",
  data: [
    {
      branding: "2510 URBAN LIVING",
      current_year: {
        year: "2022",
        months: [
          {
            name: "Juni",
            value: 325000,
            value_bio: 325,
          },

          {
            name: "July",
            value: 325000,
            value_bio: 325,
          },

          {
            name: "RR July",
            value: 325000,
            value_bio: 325,
          },
        ],
      },
      last_year: {
        year: "2021",
        months: [
          {
            name: "Juni",
            value: 325000,
            value_bio: 325,
          },

          {
            name: "July",
            value: 325000,
            value_bio: 325,
          },
        ],
      },
      better_worse: {
        yearly: 139285.71428571432,
        yearly_bio: 139.28,
        monthly: -1485714.2857142857,
        monthly_bio: -1485714.2,
      },
    },
  ],
};

const sampleResponseCASA = {
  response_code: "200",
  response_message: "Success",
  data: [
    {
      branding: "2510 URBAN LIVING CASA",
      current_year: {
        year: "2022",
        months: [
          {
            name: "Juni",
            value: 325000,
            value_bio: 325,
          },

          {
            name: "July",
            value: 325000,
            value_bio: 325,
          },

          {
            name: "RR July",
            value: 325000,
            value_bio: 325,
          },
        ],
      },
      last_year: {
        year: "2021",
        months: [
          {
            name: "Juni",
            value: 325000,
            value_bio: 325,
          },

          {
            name: "July",
            value: 325000,
            value_bio: 325,
          },
        ],
      },
      better_worse: {
        yearly: 139285.71428571432,
        yearly_bio: 139.28,
        monthly: -1485714.2857142857,
        monthly_bio: -1485714.2,
      },
    },
  ],
};

const mockFilterArea = [
  {
    filter: "Choose All",
  },
  {
    filter: "Jakarta 1",
  },
  {
    filter: "Jakarta 2",
  },
  {
    filter: "Jawa Barat",
  },
  {
    filter: "Jawa Tengah",
  },
  {
    filter: "Jawa Timur",
  },
  {
    filter: "Sumatra",
  },
  {
    filter: "Kalimantan & Indtim",
  },
  {
    filter: "Bali Nusra",
  },
  {
    filter: "Ecommerce",
  },
  {
    filter: "Indirect",
  },
];

const mockTableHead = ["Rank", "Branding"];

const mockTotal = "Total";

const mockData = [
  {
    id: 1,
    branding: "Ace Hardware",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    branding: "Informa",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    branding: "Shell",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    branding: "Farmers Market",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    branding: "Prudential",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    branding: "Erafone",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: -2,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 7,
    branding: "RSPI Group",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: -1,
          },
        ],
      },
    ],
  },
  {
    id: 8,
    branding: "Lotte Mart",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: "0",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    branding: "Ranch Market",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 10,
    branding: "iBox",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 11,
    branding: "Erha Clinic",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: -20.02,
          },
          {
            yearly: 24,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    branding: "Hypermart",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: -13,
          },
        ],
      },
    ],
  },
  {
    id: 13,
    branding: "Nu Skin",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 14,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 14,
    branding: "AIA Financial",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: -20.02,
          },
          {
            yearly: "0",
          },
        ],
      },
    ],
  },
  {
    id: 15,
    branding: "Naga Swalayan",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 16,
    branding: "Mitra 10",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: 15.26,
          },
        ],
      },
    ],
  },
  {
    id: 17,
    branding: "blibli.com",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: "0",
          },
        ],
      },
    ],
  },
  {
    id: 18,
    branding: "Giordano",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: "0",
          },
          {
            yearly: -15.26,
          },
        ],
      },
    ],
  },
  {
    id: 19,
    branding: "Market City",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: -15.26,
          },
        ],
      },
    ],
  },
  {
    id: 20,
    branding: "MR. DIY",
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: "0",
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: -15.26,
          },
        ],
      },
    ],
  },
  {
    id: 21,
    data: [
      {
        key: "data1",
        year: 2021,
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: "0",
          },
        ],
      },
      {
        key: "data2",
        year: 2022,
        status: "ongoing",
        data: [
          {
            month: "January",
            amount: 59.64,
          },
          {
            month: "February",
            amount: 60,
          },
          {
            month: "RR",
            amount: 82.29,
          },
        ],
      },
      {
        key: "data3",
        data: [
          {
            monthly: 20.02,
          },
          {
            yearly: -15.26,
          },
        ],
      },
    ],
  },
];

export { mockData, mockFilterArea, mockTableHead, mockTotal, sampleResponseCASA, sampleResponseSalesVolume };
