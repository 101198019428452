import { Checkbox, ListItemText, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { ReactComponent as IconCheck } from "assets/icons/general/icon-check.svg";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down25.svg";
import { Select, MenuItem } from "elements/select";
import COLORS from "utils/constants/colors";
import { useQuerySalesArea } from "./use-query-sales-area";

const MultipleDropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  disableScrollLock: true,
};

const useStyles = makeStyles(() => ({
  checkbox: {
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
  },
}));

const KEY_CHOOSE_ALL = "CHOOSE_ALL";

const MultipleDropdownSalesArea = ({ value, setValue }) => {
  const classes = useStyles();
  const salesArea = useQuerySalesArea();

  const renderValue = useCallback(
    () => <span className="placeholder">{salesArea.isSuccess ? "Sales area" : "Loading..."}</span>,
    [salesArea.isSuccess],
  );

  const isChooseAll = useMemo(() => value.length === salesArea.data?.data?.length, [value, salesArea.data]);

  const handleOnChange = useCallback(
    e => {
      const val = e.target.value;
      if (val.includes(KEY_CHOOSE_ALL)) {
        if (val.length === salesArea.data?.data?.length + 1) return setValue([]);
        return setValue(salesArea.data?.data);
      }
      const latest = val.pop();
      if (latest && value.findIndex(x => x.id === latest.id) > -1)
        return setValue(value.filter(x => x.id !== latest.id));
      else return setValue([...value, latest]);
    },
    [setValue, value, salesArea.data],
  );

  return (
    <Select
      multiple
      value={value}
      disableUnderline
      displayEmpty
      MenuProps={MultipleDropdownProps}
      renderValue={renderValue}
      IconComponent={IconChevronDown}
      disabled={!salesArea.isSuccess}
      onChange={handleOnChange}
    >
      <MenuItem value={KEY_CHOOSE_ALL}>
        <Checkbox checked={isChooseAll} icon={<IconCheck />} className={classes.checkbox} />
        <ListItemText primary="Choose All" />
      </MenuItem>
      {salesArea.isSuccess &&
        salesArea.data?.data.map(area => (
          <MenuItem value={{ id: area.id, name: area.name }} key={area.id}>
            <Checkbox
              checked={value.findIndex(x => x.id === area.id) > -1}
              icon={<IconCheck />}
              className={classes.checkbox}
            />
            <ListItemText primary={area.name} />
          </MenuItem>
        ))}
    </Select>
  );
};

MultipleDropdownSalesArea.defaultProps = {
  value: [],
  setValue: () => {},
};

MultipleDropdownSalesArea.propTypes = {
  value: PropTypes.array,
  setValue: PropTypes.func,
};

export { MultipleDropdownSalesArea };
