import { styled, Typography } from "@material-ui/core";

const Placeholder = styled(Typography)({
  height: "auto",
  padding: "auto",
  fontSize: "inherit",
  lineHeight: "auto",
});

Placeholder.defaultProps = {
  ...Placeholder.defaultProps,
  className: "placeholder",
  inherit: true,
};

const SelectValue = styled(Typography)({
  height: "auto",
  padding: "auto",
  fontSize: "inherit",
  lineHeight: "auto",
});

SelectValue.defaultProps = {
  ...SelectValue.defaultProps,
  className: "value",
  inherit: true,
};

export { Placeholder, SelectValue };
