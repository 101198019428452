import { Box, Divider, makeStyles, styled } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

// Icons
import IconBackToSales from "assets/icons/general/icon-rounded-rectangle-blue.svg";
import IconApprove from "assets/icons/general/icon-rounded-rectangle-green.svg";
import IconIncoming from "assets/icons/general/icon-rounded-rectangle-grey.svg";
import IconProcess from "assets/icons/general/icon-rounded-rectangle-orange.svg";
import IconDeploy from "assets/icons/general/icon-rounded-rectangle-purple.svg";
import IconReject from "assets/icons/general/icon-rounded-rectangle-red.svg";

// Component
import { ServerErrorBoundary } from "components/general";
import WrapperSubCard from "components/wrapper/wrapper-sub-card";
import BoxTitle from "elements/box/box-title";
import PieChartRing from "elements/chart/pie-chart-ring";
import ListItemStatus from "elements/list/list-item-status";
import LoadingSpinner from "elements/loading/spinner";

// Integrasi
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import { getDataOnBoardingMerchant } from "services/api/private-routes/incoming-application/onBoardingMerchant";
// Color
import COLORS from "utils/constants/colors";

// dummy data
import { dateUtility } from "utils/helpers/general/date-time";

const Wrapper = styled(Box)({
  marginTop: "19px",
});

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "1.5rem",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  gridStyles: {
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapperSubCard: {
    // padding: "10px",
  },
  wrapperGrid: {
    height: "100%",
  },
  wrapperGridChart: {
    marginTop: "20px",
  },
  loadingCardWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const DataOnboardingMerchant = () => {
  const classes = useStyles();
  const [date] = useIncomingFilter();

  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      title: {
        onBoardingMerchant: t("BOX.TITLE_DATA_ONBOARDING_MERCHANT"),
        generalIncoming: t("GENERAL.INCOMING"),
        generalProcess: t("GENERAL.PROCESS"),
        generalApprove: t("GENERAL.APPROVE"),
        generalDeploy: t("GENERAL.DEPLOY"),
        generalReject: t("GENERAL.REJECT"),
        generalBackToSales: t("INCOMING.BACK_TO_SALES"),
      },
    }),
    [t],
  );

  const dataOnBoardingMerchant = useQuery(
    ["data-on-boarding-merchant", { ...date }],
    () =>
      getDataOnBoardingMerchant({
        start_date: dateUtility(date.start).isoSeparator(),
        end_date: dateUtility(date.end).isoSeparator(),
      }),
    {
      retry: 0,
    },
  );

  const getImageIcon = data => {
    return (
      {
        Incoming: IconIncoming,
        Process: IconProcess,
        Approval: IconApprove,
        Deploy: IconDeploy,
        Reject: IconReject,
        "Back To Sales": IconBackToSales,
      }[data] ?? IconIncoming
    );
  };

  const getTextLabel = useCallback(
    data => {
      return {
        Incoming: translations.title.generalIncoming,
        Process: translations.title.generalProcess,
        Approval: translations.title.generalApprove,
        Deploy: translations.title.generalDeploy,
        Reject: translations.title.generalReject,
        "Back To Sales": translations.title.generalBackToSales,
      }[data];
    },
    [translations],
  );

  const DATA_ON_BOARDING_MERCHANT = useMemo(() => {
    if (dataOnBoardingMerchant.isError) return <ServerErrorBoundary onClick={dataOnBoardingMerchant.refetch} />;
    if (dataOnBoardingMerchant.isSuccess) {
      return (
        <div style={{ marginTop: "8px", height: "100%" }}>
          {dataOnBoardingMerchant.data?.data?.data?.on_boarding_status.map(data => (
            <div key={data.status}>
              <ListItemStatus
                number={data.total}
                iconSrc={getImageIcon(data.status)}
                text={getTextLabel(data?.status)}
              />
              <Divider />
            </div>
          ))}
        </div>
      );
    }
    return <LoadingSpinner className={classes.loadingCardWrapper} size={100} />;
  }, [dataOnBoardingMerchant, classes, getTextLabel]);

  const renderCard = useCallback(
    ({ title, data, color }) => {
      let chartComponent;
      if (dataOnBoardingMerchant.isLoading) {
        chartComponent = <LoadingSpinner className={classes.loadingCardWrapper} size={100} />;
      } else if (dataOnBoardingMerchant.isError) {
        chartComponent = <ServerErrorBoundary />;
      } else {
        chartComponent = <PieChartRing contents={data} color={color} />;
      }
      return (
        <WrapperSubCard>
          <div className={classes.wrapperSubCard}>
            <BoxTitle title={title} />
            <div className={classes.wrapperGrid}>
              <div>{chartComponent}</div>
            </div>
          </div>
        </WrapperSubCard>
      );
    },
    [classes, dataOnBoardingMerchant],
  );

  const DATA_ON_BOARDING_PIE_CHART = useMemo(() => {
    return (
      <>
        {renderCard({
          title: translations.title.generalProcess,
          color: COLORS.SECONDARY_MEDIUM,
          data: dataOnBoardingMerchant.data?.data?.data?.on_boarding_percentage[1],
        })}
        {renderCard({
          title: translations.title.generalApprove,
          color: COLORS.GREEN_HARD,
          data: dataOnBoardingMerchant.data?.data?.data?.on_boarding_percentage[0],
        })}
        {renderCard({
          title: translations.title.generalDeploy,
          color: COLORS.PURPLE_HARD,
          data: dataOnBoardingMerchant.data?.data?.data?.on_boarding_percentage[2],
        })}
      </>
    );
  }, [dataOnBoardingMerchant, renderCard, translations]);

  return (
    <Wrapper className={classes.container}>
      <div className={classes.gridStyles}>
        <WrapperSubCard>
          <BoxTitle title={translations.title.onBoardingMerchant} />
          {DATA_ON_BOARDING_MERCHANT}
        </WrapperSubCard>
      </div>
      {/* Pie Chart */}
      {DATA_ON_BOARDING_PIE_CHART}
    </Wrapper>
  );
};

export default DataOnboardingMerchant;
