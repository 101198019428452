import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const useStyles = makeStyles({
  overflow: {
    overflowX: "auto",
  },
  tableContainer: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "8px",
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableCellSemibold16: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
  },
  tableHeadRedSoft: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.RED_SOFT,
    color: COLORS.WHITE,
  },
  tableHeadBlueMedium: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.BLUE_MEDIUM,
    color: COLORS.WHITE,
  },
  tableCellReguler11: {
    padding: "10px",
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
    whiteSpace: "nowrap",
    verticalAlign: "top",
    minWidth: 150,
    textAlign: "left",
  },
  tableCellReguler10Top: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    verticalAlign: "top",
    minWidth: 150,
  },
  tableCellReguler10: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    minWidth: 150,
  },
  tableRow: {
    padding: "4px 10px",
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  iconSortStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
  },
  iconButtonStyle: {
    padding: "1px",
    width: "11px",
  },
  subHeaderWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  mainTableHeadWrapper: {
    display: "flex",
    alignItems: "center",
  },
  // First Column
  stickyColumn: {
    position: "sticky",
    left: 0,
    background: "inherit",
    width: "fit-content",
  },
  stickyHeader: {
    position: "sticky",
    left: 0,
    background: COLORS.GRAY_SOFT,
    width: "fit-content",
    zIndex: 1,
  },
  // Second Column
  stickySecondColumn: {
    position: "sticky",
    left: 0,
    background: "inherit",
    minWidth: 200,
  },
  stickyHeaderSecondColumn: {
    position: "sticky",
    left: 0,
    background: COLORS.GRAY_SOFT,
    minWidth: 200,
    zIndex: 1,
  },
  tableCellBranding: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
  },
  emptyTable: {
    textAlign: "center",
    textAnchor: "middle",
    height: 450,
    fontWeight: 700,
    fontSize: 32,
  },
});
