import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { ReactComponent as DoubleArrowLeftIcon } from "assets/icons/general/icon-double-left.svg";
import { ReactComponent as DoubleArrowRightIcon } from "assets/icons/general/icon-double-right.svg";
import { ReactComponent as SingleArrowLeftIcon } from "assets/icons/general/icon-single-left.svg";
import { ReactComponent as SingleArrowRightIcon } from "assets/icons/general/icon-single-right.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  pagination: {
    "& ul li button": {
      marginStart: "10px",
      width: "36px",
      height: "36px",
      backgroundColor: COLORS.WHITE,
      color: COLORS.PRIMARY_DARK,
      fontWeight: 500,
      fontSize: SIZES.REGULAR_14,
      borderRadius: "10px",
    },
    "& ul li:first-child button": {
      backgroundColor: COLORS.PRIMARY_DARK,
      borderRadius: "10px",
      color: COLORS.WHITE,
    },
    "& ul li:nth-child(2) button": {
      backgroundColor: COLORS.PRIMARY_DARK,
      borderRadius: "10px",
      color: COLORS.WHITE,
    },
    "& ul li:nth-last-child(-n+2) button": {
      backgroundColor: COLORS.PRIMARY_DARK,
      borderRadius: "10px",
      color: COLORS.WHITE,
    },
    "& ul li button:disabled": {
      backgroundColor: COLORS.GRAY_MEDIUM,
      color: COLORS.WHITE,
    },
  },
  selectedButton: {
    border: `1.5px solid ${COLORS.PRIMARY_DARK}`,
    color: COLORS.PRIMARY_DARK,
    borderRadius: "10px",
    backgroundColor: COLORS.WHITE,
  },
  unselectedButton: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    borderRadius: "10px",
  },
});

const CustomPagination = ({ onChange, totalPage, page }) => {
  const classes = useStyles();

  const renderItem = useCallback(
    item => {
      // eslint-disable-next-line no-unused-vars
      const { color, ...props } = item; // Prevent passing all data to IconButton Props
      switch (item.type) {
        case "start-ellipsis":
        case "end-ellipsis":
          return <IconButton {...props}>...</IconButton>;
        case "page":
          return (
            <IconButton {...props} className={item.selected ? classes.selectedButton : classes.unselectedButton}>
              {item.page}
            </IconButton>
          );
        case "first":
          return (
            <IconButton {...props}>
              <DoubleArrowLeftIcon />
            </IconButton>
          );
        case "last":
          return (
            <IconButton {...props}>
              <DoubleArrowRightIcon />
            </IconButton>
          );
        case "next":
          return (
            <IconButton {...props}>
              <SingleArrowRightIcon />
            </IconButton>
          );
        case "previous":
          return (
            <IconButton {...props}>
              <SingleArrowLeftIcon />
            </IconButton>
          );
        default:
          return <IconButton />;
      }
    },
    [classes],
  );

  return (
    <Pagination
      count={totalPage}
      shape="rounded"
      showFirstButton
      showLastButton
      siblingCount={1}
      boundaryCount={1}
      defaultPage={1}
      onChange={onChange}
      className={classes.pagination}
      renderItem={renderItem}
      page={page}
    />
  );
};

CustomPagination.defaultProps = {
  onChange: undefined,
  page: 1,
};

CustomPagination.propTypes = {
  totalPage: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  page: PropTypes.number,
};

export default CustomPagination;
