import { zodResolver } from "@hookform/resolvers/zod";
import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import Textfield from "components/input/text-field";
import ButtonContained from "elements/button/button-contained";
import FootNote from "elements/foot-note";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const FormForgotPasswordStyle = styled("form")({
  width: 400,
  display: "flex",
  flexDirection: "column",
  padding: "30px",
  backgroundColor: COLORS.WHITE,
  boxShadow: "0px 8px 12px rgba(188, 200, 231, 0.4)",
  borderRadius: "15px",
});

const DescTypography = styled(Typography)({
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 400,
});

const Space5Div = styled("div")({
  height: "5px",
});
const Space20Div = styled("div")({
  height: "20px",
});

const DivButtonStyle = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: "40px",
});

const FormForgotPassword = ({ onSubmit, isEmailNotFound, isEmailLocked }) => {
  const { t } = useTranslation();
  const emailSchema = z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")).email();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(z.object({ email: emailSchema })),
    defaultValues: { email: "" },
  });

  const handleOnSumbit = handleSubmit(data => onSubmit(data));

  useEffect(() => {
    if (isEmailNotFound) setError("email", { message: "Email not registered" });
    if (isEmailLocked) setError("email", { message: "Email locked" });
  }, [isEmailNotFound, isEmailLocked, setError]);

  return (
    <FormForgotPasswordStyle onSubmit={handleOnSumbit}>
      <Title title={t("FORGOT_PASSWORD.FORGOT_PASSWORD")} color={COLORS.DARK_HARD} />
      <Space5Div />
      <DescTypography>{t("DESCRIPTION.RESET_PASSWORD")}</DescTypography>
      <Space20Div />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Textfield
            label={t("GENERAL.EMAIL")}
            placeholder={t("PLACEHOLDER.INPUT_REGISTERED_EMAIL")}
            isInvalid={!!errors?.email?.message}
            {...field}
          />
        )}
      />
      {!!errors?.email?.message && <FootNote content={errors?.email?.message} />}
      <DivButtonStyle>
        <ButtonContained content={t("GENERAL.NEXT")} type="submit" />
      </DivButtonStyle>
    </FormForgotPasswordStyle>
  );
};

FormForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEmailNotFound: PropTypes.bool.isRequired,
  isEmailLocked: PropTypes.bool.isRequired,
};

export default FormForgotPassword;
