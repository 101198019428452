import { Typography, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Button } from "elements/button";
import { axiosPrivate } from "services/api/private-routes";
import { cancelDownload } from "services/api/private-routes/general";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { downloadBlobObject } from "utils/helpers/general";

const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr 1fr",
    gap: "3rem",
  },
  descContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ".3em",
  },
  fileName: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
  },
  userName: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    color: COLORS.GRAY_SECONDARY,
  },
  date: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    color: COLORS.GRAY_SECONDARY,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  statusContainer: {
    textTransform: "capitalize",
  },
  center: {
    textAlign: "center",
  },
  loading: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    color: COLORS.SECONDARY_MEDIUM,
    marginBottom: ".5rem",
  },
  success: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    color: COLORS.GREEN_MEDIUM,
  },
  failed: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    color: COLORS.RED_MEDIUM_HARD,
  },
  cancel: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    color: COLORS.SECONDARY_SOFT,
  },
  linearProgressStyle: {
    backgroundColor: COLORS.SECONDARY_ULTRASOFT,
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: COLORS.SECONDARY_MEDIUM,
    },
  },
}));

const CardFile = ({ status, fileName, userName, date, url, printUrl, shareUrl, downloadId, showBtnPrint }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const isLoading = useMemo(() => ["processing", "start"].includes(status), [status]);
  const isFinish = useMemo(() => status === "finish", [status]);
  const isFailed = useMemo(() => status === "failed", [status]);
  const isCancel = useMemo(() => status === "canceled", [status]);

  const mutationDownload = useMutation(async ({ file, href }) => {
    const { data } = await axiosPrivate.get(href, {
      responseType: "blob",
    });
    downloadBlobObject(data, file);
  });

  const mutationCancelDownload = useMutation(cancelDownload, {
    onSettled: () => queryClient.invalidateQueries(["download"]),
  });

  const dateContent = useMemo(() => {
    const parsedDate = new Date(date ?? Date.now());
    return (
      <Typography className={classes.date}>
        &nbsp;{parsedDate.toLocaleDateString()} {parsedDate.toLocaleTimeString()}
      </Typography>
    );
  }, [date, classes]);

  const statusContent = useMemo(() => {
    if (isLoading)
      return (
        <>
          <Typography className={classes.loading}>Downloading</Typography>
          <LinearProgress className={classes.linearProgressStyle} />
        </>
      );
    return (
      <Typography
        className={clsx(classes.center, {
          [classes.success]: isFinish,
          [classes.failed]: isFailed,
          [classes.cancel]: isCancel,
        })}
      >
        {status}
      </Typography>
    );
  }, [classes, status, isLoading, isFailed, isFinish, isCancel]);

  return (
    <div className={classes.container}>
      <div className={classes.descContainer}>
        <Typography className={classes.fileName}>&nbsp;{fileName}</Typography>
        <Typography className={classes.userName} noWrap>
          &nbsp;{userName}
        </Typography>
        {dateContent}
      </div>
      <div className={classes.statusContainer}>{statusContent}</div>
      <div className={classes.buttonContainer}>
        {isFinish && !!shareUrl && (
          <Button
            variant="outlined"
            disabled={!shareUrl}
            href={`mailto:?subject=Existing%20%26%20New%20Calculation%20Profit%20and%20Loss&body=${encodeURIComponent(
              shareUrl,
            )}`}
            target="_blank"
            rel="noreferrer"
            children="Share"
            style={{ height: "max-content" }}
          />
        )}
        {isFinish && fileName && !shareUrl && showBtnPrint && (
          <Button
            variant="outlined"
            disabled={!printUrl}
            href={printUrl}
            target="_blank"
            rel="noreferrer"
            children="Print"
            style={{ height: "max-content" }}
          />
        )}
        {!isLoading && !isCancel && (
          <Button
            variant="outlined"
            style={{ height: "max-content" }}
            disabled={isFailed || (isFinish && !url)}
            children="Download"
            download
            onClick={() => mutationDownload.mutate({ file: fileName, href: url })}
          />
        )}
        {isLoading && (
          <Button
            variant="outlined"
            style={{ height: "max-content" }}
            disabled={isFailed || (isFinish && !url)}
            children="Cancel"
            download
            onClick={() => mutationCancelDownload.mutate({ id: downloadId })}
          />
        )}
      </div>
    </div>
  );
};

CardFile.defaultProps = {
  status: "loading",
  fileName: undefined,
  userName: undefined,
  date: undefined,
  url: undefined,
  printUrl: undefined,
  shareUrl: undefined,
  showBtnPrint: true,
};

CardFile.propTypes = {
  status: PropTypes.string,
  fileName: PropTypes.string,
  userName: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  printUrl: PropTypes.string,
  showBtnPrint: PropTypes.bool,
  shareUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  downloadId: PropTypes.string.isRequired,
};

export default CardFile;
