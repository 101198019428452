import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DotIcon from "elements/icon/dot-icon";
import COLORS from "utils/constants/colors";
import { parseCurrency } from "utils/helpers/useFormater";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 30px 0px 30px",
    // outline: "1px solid red"
  },
  imgWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1px",
    maxHeight: "1px",
    marginTop: "5px",
  },
  text: {
    fontSize: "12px",
    color: COLORS.GRAY_PRIMARY,
  },
}));

const LegendDetailDonutChart = ({ color, value }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      runRate: t("PORTOFOLIO.MONTHLY_RUN_RATE"),
    }),
    [t],
  );

  return (
    <div className={classes.wrapper}>
      <div style={{ display: "flex", alignIems: "center" }}>
        <div className={classes.imgWrapper}>
          <DotIcon size={0.5} fill={color} />
        </div>
        <div className={classes.text}>{translations.runRate}</div>
      </div>
      <div>
        <Typography className={classes.text}>{parseCurrency(value, { maximumFractionDigits: 10 })}</Typography>
      </div>
    </div>
  );
};

LegendDetailDonutChart.defaultProps = {
  color: "red",
  value: 0,
};

LegendDetailDonutChart.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number,
};

export default LegendDetailDonutChart;
