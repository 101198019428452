import * as Sentry from "@sentry/react";
import { axiosPrivate } from "../axiosPrivate";

const saveCalculation = async payloadData => {
  Sentry.captureMessage(payloadData);
  const { data: responseBody, status } = await axiosPrivate.post(
    "/dashboard/calculationprofitandlos/create",
    payloadData,
  );

  if (status !== 200) throw new Error(`[saveCalculation] ${status}`);
  return responseBody;
};

export { saveCalculation };
