import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  tableWrapper: {
    borderRadius: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "20px",
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    display: "flex",
    flexDirection: "column",
  },
});
