import PropTypes from "prop-types";
import React from "react";
import { CustomSelect } from "elements/select";
import { getLastMonth, formatMonthYear } from "utils/helpers/general/date-time";

const MONTH = getLastMonth(18, new Date(), date => ({
  key: date,
  text: formatMonthYear.format(date),
}));

const DropdownListMonthByYear = ({ value, setValue, ...props }) => {
  return (
    <CustomSelect
      placeholder="Month"
      data={MONTH}
      renderValue={item => item.text}
      renderItem={item => item.text}
      setValue={setValue}
      value={value}
      {...props}
    />
  );
};

DropdownListMonthByYear.defaultProps = {
  value: undefined,
  setValue: undefined,
};

DropdownListMonthByYear.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export { DropdownListMonthByYear };
