import { styled } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";

// ASSET
import CIMBLogo from "assets/icons/cimb/icon-cimb-niaga.svg";
import ImageTower from "assets/images/image-background-red.svg";
import ImageHeaderWave from "assets/images/image-header-wave.svg";
import ImageMapMarker from "assets/images/image-map-marker.svg";
// COMPONENT
import { useCIMBLoading } from "components/loading/cimb-loading";
import Footer from "elements/footer";
import Title from "elements/title";
import { useAuth } from "hooks/useAuth";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { routes } from "utils/constants/paths";

const LoginContainer = styled("div")({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: COLORS.WHITE,
});

const LeftContainer = styled("div")({
  width: "100%",
  minHeight: "100vh",
});

const RightContainer = styled("div")({
  width: "100%",
  minHeight: "100vh",
  backgroundImage: `url(${ImageTower})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const AuthContainer = styled("div")({
  width: "100%",
  minHeight: "100vh",
  backgroundImage: `url(${ImageMapMarker})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  backgroundSize: "67%",
  backgroundAttachment: "fixed",
  backgroundColor: COLORS.GRAY_ULTRASOFT,
});

const RegisterContainer = styled("div")({
  width: "100%",
  minHeight: "100vh",
  backgroundColor: COLORS.GRAY_ULTRASOFT,
});

const StyledContent = styled("div")({
  padding: "30px 40px",
  minHeight: "100vh",
});

const StyledContentLogin = styled("div")({
  padding: "40px 60px",
  width: "50%",
  minHeight: "100vh",
  position: "absolute",
  zIndex: 2,
  top: 0,
  left: 0,
});

const StyledHeaderWave = styled("div")({
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  zIndex: 1,
  backgroundColor: COLORS.WHITE,
  backgroundImage: `url(${ImageHeaderWave})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  backgroundSize: "100%",
});

const StyledImage = styled("img")(({ bottom }) => ({
  width: "300px",
  height: "95px",
  marginBottom: bottom,
}));

const ChildrenWrapper = styled("div")(({ position }) => ({
  display: "flex",
  justifyContent: position,
  alignItems: "center",
}));

const ChildrenWrapperLogin = styled("div")({
  padding: "0 170px 90px 90px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const AuthLayout = () => {
  const { isAuthenticated } = useAuth();
  const { setActive } = useCIMBLoading();
  const loadingTimeout = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const path = location.pathname;
  const passwordPath = ["/otp", "/forgot-password", "/verification-success", "/new-password"];

  // Simulate loading UI every route change
  useEffect(() => {
    setActive(true);
    loadingTimeout.current = setTimeout(() => setActive(false), 2000);
    return () => clearTimeout(loadingTimeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleBackToHome = useCallback(() => navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN)), [navigate]);

  const memo = useMemo(() => {
    const isPasswordPath = passwordPath.includes(path);
    const isLogin = path === "/login";

    const renderContent = () => (
      <div>
        <StyledContent>
          <StyledImage
            src={CIMBLogo}
            alt="cimb-logo"
            bottom={isPasswordPath ? "40px" : "10px"}
            onClick={handleBackToHome}
          />
          <ChildrenWrapper position={isPasswordPath ? "left" : "center"}>
            <Outlet />
          </ChildrenWrapper>
        </StyledContent>
        <Footer />
      </div>
    );

    const renderLeftContent = () => (
      <div>
        <StyledHeaderWave />
        <StyledContentLogin>
          <StyledImage src={CIMBLogo} alt="cimb-logo" bottom="60px" onClick={handleBackToHome} />
          <Outlet />
        </StyledContentLogin>
        <Footer width="50%" bottom="20px" />
      </div>
    );

    const renderRightContent = () => (
      <ChildrenWrapperLogin>
        <Title title={t("LOGIN.CIMB_SiMB")} size={SIZES.LARGE_32} color={COLORS.WHITE} />
      </ChildrenWrapperLogin>
    );

    // Authenticated user will redirect to dashboard page
    if (isAuthenticated()) return <Navigate to={routes.DASHBOARD.INCOMING_APPLICATION} replace />;

    let componentToRender;

    if (isLogin) {
      componentToRender = (
        <LoginContainer>
          <LeftContainer>{renderLeftContent()}</LeftContainer>
          <RightContainer>{renderRightContent()}</RightContainer>
        </LoginContainer>
      );
    } else if (isPasswordPath) {
      componentToRender = <AuthContainer>{renderContent()}</AuthContainer>;
    } else {
      componentToRender = <RegisterContainer>{renderContent()}</RegisterContainer>;
    }

    return componentToRender;
  }, [path, passwordPath, handleBackToHome, isAuthenticated, t]);

  return memo;
};

export default AuthLayout;
