import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { MoonLoader } from "react-spinners";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    minWidth: "max-content",
    height: "100%",
  },
}));

const LoadingSpinner = ({ height, size, style, className }) => {
  const classes = useStyles();
  return (
    <div style={{ height, ...style }} className={clsx(classes.wrapper, className)}>
      <MoonLoader data-testid="loading" color={COLORS.RED_SOFT} size={size} width={100} speedMultiplier={0.5} />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  height: 100,
  size: 100,
  style: {},
  className: undefined,
};

LoadingSpinner.propTypes = {
  height: PropTypes.number,
  size: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default LoadingSpinner;
