import { Grid, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down-white.svg";
import TextfieldSearch from "elements/input/text-field-search";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  styledSelect: {
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    borderStartStartRadius: "10px",
    borderEndStartRadius: "10px",
    borderRadius: 0,
    fontSize: SIZES.REGULAR_14,
    "& .MuiInputBase-input": {
      lineHeight: "20px",
      padding: "10px",
    },
    "& .MuiSelect-icon": {
      marginRight: "10px",
      marginTop: "5px",
    },
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
  },
  gridContainer: {
    maxWidth: "310px",
    width: "fit-content",
  },
});

const SearchBar = ({ menuItem, selected, onChange, onSearch, value, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const translation = useMemo(
    () => ({
      search: t("GENERAL.SEARCH"),
    }),
    [t],
  );

  const DropdownProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    style: {
      marginTop: "5px",
      marginLeft: "-5px",
      borderRadius: "10px",
    },
    getContentAnchorEl: null,
    disableScrollLock: true,
  };

  return (
    <Grid container spacing={0} className={classes.gridContainer}>
      <Grid item xs={4}>
        <Select
          data-testid="select"
          variant="standard"
          disableUnderline
          IconComponent={IconChevronDown}
          value={selected}
          fullWidth
          MenuProps={DropdownProps}
          className={classes.styledSelect}
          onChange={event => onChange(event.target.value)}
          disabled={disabled}
        >
          {menuItem?.map(item => (
            <MenuItem value={item} key={item} className={classes.menuItem}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={8}>
        <TextfieldSearch
          placeholder={translation.search}
          withDropdown
          onChange={onSearch}
          value={value}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

SearchBar.defaultProps = {
  selected: undefined,
  onSearch: () => {},
  value: "",
  disabled: false,
};

SearchBar.propTypes = {
  menuItem: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  onSearch: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchBar;
