import { Button } from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Components
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { Chips } from "components/chip";
import { ServerErrorBoundary } from "components/general";
import { DOWNLOAD_FEATURES, PopupDownload } from "components/popup/popup-download";
import DetailRevenueTable from "components/table/detail-revenue";
import ButtonContained from "elements/button/button-contained";
import { TextFieldSelect } from "elements/input/text-field-select";
import LoadingSpinner from "elements/loading/spinner";
import { PagePagination } from "elements/pagination/page-pagination";
import { CustomSelect } from "elements/select";
import { MultipleDropdownSalesArea } from "features/sales-area";
import { useAbortController } from "hooks/useAbortController";
import { useAuth } from "hooks/useAuth";
import { getListDetailRevenueByBranding, downloadListRevenueBranding } from "services/api/private-routes";
import { getObjKey } from "utils/helpers/general";
import { useStyles } from "./styled";

const INITIAL_STATE_FILTER = {
  search: "",
  typeSearch: "merchant",
  filterSalesArea: [],
  annualFilter: "",
  page: {},
  sorts: null,
};

const DashboardDetailRevenueByBranding = () => {
  const classes = useStyles();
  const { getRole } = useAuth();
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      searchBar: {
        merchant: t("GENERAL.MERCHANT"),
        group: t("GENERAL.GROUP"),
      },
      annualFilter: {
        year: t("GENERAL.YEARLY"),
        month: t("GENERAL.MONTHLY"),
        onGoing: t("GENERAL.ONGOING"),
      },
      search: t("GENERAL.SEARCH"),
    }),
    [t],
  );

  const { signal } = useAbortController();
  const queryClient = useQueryClient();
  const [popUpDownloadOpen, setPopUpDownloadOpen] = useState(false);

  const [state, setState] = useState(INITIAL_STATE_FILTER);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const listDetailRevenueBranding = useQuery(
    ["list-detail-revenue-branding", state.page?.number, state.sorts],
    () => {
      return getListDetailRevenueByBranding(
        {
          page: state.page?.number || 1,
          filterSalesArea: state.filterSalesArea.reduce((prev, curr) => [...prev, curr.id], []),
          annualFilter: state.annualFilter?.toUpperCase(),
          search: state.search || "",
          sorts: state.sorts || null,
          typeSearch: state.typeSearch?.toLowerCase() || "merchant",
        },
        signal,
      );
    },
    {
      retry: 0,
      keepPreviousData: true,
    },
  );

  const downloadExcelRevenueByBranding = useMutation(
    () =>
      downloadListRevenueBranding({
        page: state.page?.number,
        filterSalesArea: state.filterSalesArea?.reduce((prev, curr) => [...prev, curr?.id], []),
        annualFilter: state.annualFilter?.toUpperCase(),
        search: state.search,
        sorts: state.sorts,
        typeSearch: state.typeSearch?.toLowerCase(),
      }),
    {
      onSettled: () => queryClient.invalidateQueries(["download"]),
    },
  );

  /* -------------------------------------------------------------------------- */
  /*                                   FILTER                                   */
  /* -------------------------------------------------------------------------- */

  const handleOnApply = () => {
    // prefetch
    setState({ ...state, page: { ...state.page, number: 1 } });
    listDetailRevenueBranding.refetch();
  };

  const tableContent = React.useMemo(() => {
    if (listDetailRevenueBranding.isFetching) return <LoadingSpinner height={450} />;
    if (listDetailRevenueBranding.isError)
      return (
        <ServerErrorBoundary
          onClick={() => listDetailRevenueBranding.refetch()}
          title={listDetailRevenueBranding.error?.message}
        />
      );
    const render = listDetailRevenueBranding.data;

    if (!render) return null;
    return (
      <DetailRevenueTable
        data={render.data}
        mainTableHead={t("GENERAL.BRANDING")}
        headers={render.heads}
        subHeaders={render.subHeaders}
        onSort={sorts => setState(old => ({ ...old, sorts }))}
      />
    );
  }, [listDetailRevenueBranding, t]);

  return (
    <div>
      {!getRole().isInquiry && (
        <>
          <PopupDownload
            isOpen={popUpDownloadOpen}
            onClose={() => setPopUpDownloadOpen(false)}
            feature={DOWNLOAD_FEATURES.REVENUE_BRANDING}
            onSubmit={downloadExcelRevenueByBranding.mutate}
          />
          <div className={classes.buttonWrapper}>
            <Button
              startIcon={<IconDownload />}
              variant="contained"
              className={classes.buttonContained}
              onClick={() => setPopUpDownloadOpen(true)}
            >
              {t("GENERAL.DOWNLOAD_EXCEL")}
            </Button>
          </div>
        </>
      )}
      <div className={classes.searchWrapper}>
        <TextFieldSelect
          dataSelect={Object.values(translations.searchBar)}
          selected={translations.searchBar[state.typeSearch]}
          search={state.search}
          onSelect={val => setState(old => ({ ...old, typeSearch: getObjKey(translations.searchBar, val) }))}
          onSearch={val => setState(old => ({ ...old, search: val }))}
          placeholder={translations.search}
          classNameSelect={classes.selectWrapper}
        />
        <MultipleDropdownSalesArea
          value={state.filterSalesArea}
          setValue={val => setState(old => ({ ...old, filterSalesArea: val }))}
        />
        <CustomSelect
          placeholder={translations.annualFilter.year}
          value={state.annualFilter}
          data={Object.values(translations.annualFilter)}
          onChange={val => setState(x => ({ ...x, annualFilter: val.target?.value ?? " " }))}
        />
        <ButtonContained
          content="Apply"
          width="72px"
          onClick={handleOnApply}
          disabled={listDetailRevenueBranding.isLoading}
          className={classes.buttonApply}
        />
      </div>
      <div className={classes.chipContainer}>
        {state.filterSalesArea.length > 0 && (
          <Chips
            value={state.filterSalesArea ?? []}
            renderItem={item => item.name}
            onDelete={item =>
              setState(old => ({ ...old, filterSalesArea: old.filterSalesArea.filter(y => y.name !== item.name) }))
            }
            onReset={() => setState(old => ({ ...old, filterSalesArea: [] }))}
          />
        )}
      </div>
      <div className={classes.tableWrapper}>{tableContent}</div>
      {!listDetailRevenueBranding.isFetching && listDetailRevenueBranding.isSuccess && (
        <PagePagination
          size={listDetailRevenueBranding.data.page.size}
          count={listDetailRevenueBranding.data.page.dataCount}
          totalPage={listDetailRevenueBranding.data.page.totalPage}
          page={listDetailRevenueBranding.data.page.number}
          onChange={value => {
            setState({ ...state, page: { ...state.page, number: value } });
          }}
        />
      )}
    </div>
  );
};

export default DashboardDetailRevenueByBranding;
