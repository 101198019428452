import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "utils/constants/translations/en.json";

export default i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: {
      translations: enTranslation,
    },
  },
  debug: false,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  ns: ["translations"],
  defaultNS: ["translations"],
  keySeparator: false,
});
