const nominalyDigit = {
  THOUSAND: 1.0e3,
  MILLION: 1.0e6,
  BILLION: 1.0e9,
  TRILLION: 1.0e12,
};

const trbOptions = [
  {
    id: 1,
    target_code: "all",
    target_name: "Select All",
  },
  {
    id: 2,
    target_code: "have trb",
    target_name: "Have TRB",
  },
  {
    id: 3,
    target_code: "zero trb",
    target_name: "Zero TRB",
  },
];

const profitOptions = [
  {
    id: 1,
    target_code: "all",
    target_name: "Select All",
  },
  {
    id: 2,
    target_code: "profit",
    target_name: "Profit",
  },
  {
    id: 3,
    target_code: "not profit",
    target_name: "Not Profit",
  },
];

export { nominalyDigit, trbOptions, profitOptions };
