export const RESOLVER_TEXT = {
  sales_volume: "Sales Volume",
  on_us: "On Us",
  credit: "Credit",
  debit: "Debit",
  off_us: "Off Us",
  debit_lokal_npg: "Debit Lokal NPG",
  others: "Others",
  lokal: "Lokal",
  overseas: "Overseas",
  qr: "QR",
  non_ecomm: "Non Ecomm",
  ecomm: "Ecomm",
  trx: "Trx",
  trx_off_us: "Trx Off Us",
  trx_on_us: "Trx On Us",
  trx_on_us_off_us_percentage: "Trx On Us Off Us %",
  on_us_percentage: "% On Us",
  off_us_percentage: "% Off Us",
  tid: "#TID",
  edc: "# EDC",
  merchant: "#Merchant",
  non_ecom: "Non Ecom",
  bukalapak: "Bukalapak",
  QR: "QR",
  ecom: "Ecomm",
  noii: "NOII",
  mdr_revenue_before_contra: "MDR Revenue Before Contra",
  mdr_revenue_carry_forward: "MDR Revenue Carry Forward",
  contra_revenue: "Contra Revenue",
  mdr_revenue_cognos: "MDR Revenue sesuai Cognos",
  mdr_npg: "MDR NPG",
  other_revenue: "Other Revenue",
  jadit: "Selisih Kurs (Jadit)",
  interchange: "Interchange",
  visa: "Visa",
  mcbs: "MCBS",
  migs: "MIGS",
  migs_trx: "MIGS Trx",
  jcb: "JCB",
  revenue_hardcash_cognos: "Revenue Hardcash Cognos",
  revenue_real_hardcash: "Revenue Real Hardcash",
  cf_cost: "Biaya CF",
  accrual: "Accrual",
  acc_mov: "Acc Mov",
  total_acc: "Total ACC",
  casa: "CASA",
  ending_balance: "Ending Balance",
  avg_balance: "Avg Balance",
  casa_non_ecomm: "CASA Non Ecomm",
  casa_ecomm: "CASA Ecomm",
  shadow_nii: "Shadow Nii",
  revenue_after_shadow: "Revenue After Shadow",
  net_revenue: "Net Revenue",
  opex: "Opex",
  communication_and_utilities: "Communication and Utilities",
  deprecation_and_amortization: "Depreciation and Amortization - Fixed Assets and equipment",
  insurance: "Insurance",
  legal_and_professional_fee: "Legal and professional fees",
  office_activity: "Office Activity",
  office_stationary_and_supply: "Office stationery & supplies",
  out_sourcing: "Outsourcing",
  rental: "Rental",
  repair_and_maintenance: "Repair and Maintenance",
  tax: "Tax",
  travel_and_transportation: "Travel and transportation",
  employee_activity: "Employee Activity",
  it_related: "IT Related",
  dakom: "Dakom",
  it_maintenance: "IT Maintenance",
  total_ga: "Total GA",
  personal_expenses: "Personnel Expenses",
  promotion_expenses: "Promotion Expenses",
  total_opex: "Total Opex",
  sales_volume_principal: "Sales Volume by Principal",
  visa_npg: "Visa GPN",
  mastercard: "MasterCard",
  mastercard_npg: "MasterCard GPN",
  JCB: "JCB",
  jcb_npg: "JCB GPN",
  no_of_trx_principal: "No Of TRX by Principal",
  qr_sales_volume: "QR",
  non_ecomm_trx: "Non Ecom",
  ecomm_trx: "Ecom",
  qr_trx: "QR",
  non_ecomm_net_revenue: "Non Ecomm",
  ecomm_net_revenue: "Ecomm",
  ecomm_shadow_nii: "Ecom",
  non_ecomm_shadow_nii: "Non Ecom",
  qr_tid: "# QR",
  ecom_tid: "# ECOM",
  qr_merchant: "QR",
  ecom_merchant: "Ecom",
  regular: "Reguler",
  instalment: "Installment",
};
