import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import IconDown from "assets/icons/dashboard/icon-table-down.svg";
import IconUp from "assets/icons/dashboard/icon-table-up.svg";
import IconStripe from "assets/icons/general/icon-stripe.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { mockTableHead, mockTotal } from "utils/constants/mocks/dashboard/better-worse-merchant";

const useStyles = makeStyles({
  tableContainer: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "8px",
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableRow: {
    padding: "4px 10px",
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  tableCellTotalSales: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableCellSemiBold16: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
    padding: 0,
  },
  tableCellSemiBold12: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
    borderBottom: "none",
  },
  tableCellSemiBold11: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
  },
  tableCellReguler12: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
  },
  tableCellIconStyle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    "& img": {
      height: "15px",
    },
  },
  typographyCenter: {
    width: "50%",
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
  },
});

const formatNumber = number => (Number(number || 0) || 0).toFixed(2);

const BetterWorseTable = ({ data, header, subHeader }) => {
  const { tableData, total } = data;
  const classes = useStyles();

  const iconPicker = number => {
    const x = Number(number);
    if (Math.sign(x) === -1) {
      return <img src={IconDown} alt="icon down" />;
    }
    if (Math.sign(x) > 0) {
      return <img src={IconUp} alt="icon up" />;
    }
    return <img src={IconStripe} alt="icon stripe" />;
  };

  if (!tableData || tableData.length === 0) return null;
  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {mockTableHead.map((item, index) => (
              <TableCell align="center" rowSpan={2} className={classes.tableCellSemiBold16} key={item ?? index}>
                {item}
              </TableCell>
            ))}
            {header.map((item, index) => {
              return (
                <TableCell
                  align="center"
                  colSpan={subHeader[index].length}
                  className={classes.tableCellSemiBold12}
                  key={item ?? index}
                >
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            {subHeader.map(item => {
              return item.map(sub => (
                <TableCell align="center" className={classes.tableCellReguler12} key={`${item}-${sub}`}>
                  {sub}
                </TableCell>
              ));
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((item, index) => {
            return (
              <TableRow className={classes.tableRow} key={item?.id ?? index}>
                <TableCell align="center" className={classes.tableCellReguler12}>
                  {index + 1}
                </TableCell>
                <TableCell className={classes.tableCellReguler12}>{item.branding}</TableCell>
                {Array.isArray(item.last_year.months) &&
                  item.last_year.months.map(val => {
                    return (
                      <TableCell align="center" className={classes.tableCellReguler12} key={val.name}>
                        {formatNumber(val.value_bio)}
                      </TableCell>
                    );
                  })}
                {Array.isArray(item.current_year.months) &&
                  item.current_year.months.map(val => {
                    return (
                      <TableCell align="center" className={classes.tableCellReguler12} key={val.name}>
                        {formatNumber(val.value_bio)}
                      </TableCell>
                    );
                  })}

                <TableCell align="center">
                  <div className={classes.tableCellIconStyle}>
                    {iconPicker(item.better_worse.monthly_bio)}
                    <Typography align="center" className={classes.typographyCenter}>
                      {formatNumber(item.better_worse.monthly_bio)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.tableCellIconStyle}>
                    {iconPicker(item.better_worse.yearly_bio)}
                    <Typography align="center" className={classes.typographyCenter}>
                      {formatNumber(item.better_worse.yearly_bio)}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow className={classes.tableCellTotalSales}>
            <TableCell colSpan={2} align="center" className={classes.tableCellSemiBold11}>
              {mockTotal}
            </TableCell>
            {total.last_year.months.map(val => {
              return (
                <TableCell align="center" className={classes.tableCellReguler12} key={val.name}>
                  {formatNumber(val.value_bio)}
                </TableCell>
              );
            })}
            {total.current_year.months.map(val => {
              return (
                <TableCell align="center" className={classes.tableCellReguler12} key={val.name}>
                  {formatNumber(val.value_bio)}
                </TableCell>
              );
            })}
            <TableCell align="center">
              <div className={classes.tableCellIconStyle}>
                {iconPicker(total.better_worse.monthly_bio)}
                <Typography align="center" className={classes.typographyCenter}>
                  {formatNumber(total.better_worse.monthly_bio)}
                </Typography>
              </div>
            </TableCell>
            <TableCell align="center">
              <div className={classes.tableCellIconStyle}>
                {iconPicker(total.better_worse.monthly_bio)}
                <Typography align="center" className={classes.typographyCenter}>
                  {formatNumber(total.better_worse.yearly_bio)}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BetterWorseTable.defaultProps = {
  header: [],
};

BetterWorseTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  header: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  subHeader: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default BetterWorseTable;
