/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, makeStyles, Typography, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CimbLogo from "assets/icons/cimb/cimb-2x.png";
import HeaderBackground from "assets/icons/header/header.svg";
import IconArrowLeftRed from "assets/icons/popup/icon-arrow-left-red.svg";
import COLORS from "utils/constants/colors";
import { PATHS_WITH_BACK, routes } from "utils/constants/paths";

const useStyles = makeStyles(() => ({
  header: {
    backgroundImage: `url(${HeaderBackground})`,
    width: "100%",
    height: "75px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  text: {
    flexGrow: "1",
  },
  wrapper: {
    height: "75px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "7rem",
    paddingRight: "2rem",
  },
  img: {
    width: "100%",
    maxWidth: "170px",
    paddingTop: 5,
  },

  iconBack: {
    height: "18px",
  },

  iconButtonBoxStyle: {
    height: "36px",
    width: "36px",
    background: COLORS.WHITE,
    borderRadius: "10px",
    marginRight: "20px",
  },
}));

const Header = ({ text }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const pathNow = location.pathname.split("/")[1];

  const onBack = useCallback(() => {
    if (pathNow === PATHS_WITH_BACK[0]) {
      navigate(routes.DASHBOARD.PROFITABILITY);
      return;
    }
    navigate(-1);
  }, [pathNow, navigate]);

  const classes = useStyles();
  return (
    <AppBar className={classes.header} style={{ backgroundColor: `${COLORS.WHITE}` }}>
      <div className={classes.wrapper}>
        {PATHS_WITH_BACK.includes(pathNow) ? (
          <IconButton onClick={onBack} className={classes.iconButtonBoxStyle}>
            <img src={IconArrowLeftRed} alt="icon-close" className={classes.iconBack} />
          </IconButton>
        ) : null}
        <Typography variant="h6" className={classes.text}>
          {text}
        </Typography>
        <div>
          <img className={classes.img} src={CimbLogo} alt="cimb" />
        </div>
      </div>
    </AppBar>
  );
};

Header.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Header;
