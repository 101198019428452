import { Button as MButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  button: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    borderRadius: "10px",
    boxShadow: "0px 3px 10px rgba(84, 163, 216, 0.25)",
    whiteSpace: "nowrap",
    textTransform: "inherit",
  },
  primary: {
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: COLORS.WHITE,
    backgroundColor: COLORS.PRIMARY_DARK,
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_HARD,
      color: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:disabled": {
      backgroundColor: COLORS.GRAY_MEDIUM,
      color: COLORS.WHITE,
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: COLORS.PRIMARY_DARK,
    borderWidth: "1.5px",
    borderStyle: "solid",
    color: COLORS.PRIMARY_DARK,
    "&:hover": {
      color: COLORS.PRIMARY_DARK,
    },
    "&:disabled": {
      borderColor: COLORS.GRAY_MEDIUM,
      backgroundColor: COLORS.GRAY_MEDIUM,
      color: COLORS.WHITE,
    },
  },
  small: {
    padding: ".3rem 1rem",
  },
  normal: {
    padding: ".3rem 1rem",
  },
  large: {
    padding: ".5rem 1.4rem",
  },
  default: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 18px 10px 14px"
  }
});

const Button = ({ defaultButton, variant, className, size, ...props }) => {
  const classes = useStyles();
  return (
    <MButton
      className={
        defaultButton
          ? clsx(classes.default, classes.button, classes[variant], className)
          : clsx(classes.button, [classes[variant]], classes[size ?? "normal"], className)
      }
      {...props}
    />
  );
};

Button.defaultProps = {
  variant: "primary",
  size: "normal",
  className: undefined,
  defaultButton: false,
};

Button.propTypes = {
  variant: PropTypes.oneOf(["outlined", "primary"]),
  size: PropTypes.oneOf(["small", "normal", "large"]),
  className: PropTypes.string,
  defaultButton: PropTypes.bool,
};

export { Button };
