/* eslint-disable import/no-cycle */
import { Backdrop, Button, Grid, Popper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconApprove } from "assets/icons/user-management/icon-approve.svg";
import { ReactComponent as IconEdit } from "assets/icons/user-management/icon-edit.svg";
import { ReactComponent as IconRotateCCW } from "assets/icons/user-management/icon-rotate-ccw.svg";
import { ReactComponent as IconXSquare } from "assets/icons/user-management/icon-x-square.svg";
import { useUserManagement } from "pages/user-management/user-management-context";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1,
    backdropFilter: "blur(2px)",
  },
  popper: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "10px",
    padding: "8px",
    boxShadow: "0px 8px 12px rgba(188, 200, 231, 0.4)",
    zIndex: 2,
    maxWidth: "140px",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  buttonText: {
    color: COLORS.DARK_HARD,
    alignSelf: "start",
    textTransform: "none",
  },
});

const PopperUserManagementMenu = ({ open, anchorEl, id, menu, handleClose }) => {
  const {
    popoverTurnOffAccount,
    onEditUser,
    popoverConfirmationResetOTP,
    handleApprove,
    popoverConfirmationRejectUser,
    popoverConfirmationApproveUser,
  } = useUserManagement();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Backdrop open={open} onClick={handleClose} className={classes.backdrop}>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start" className={classes.popper}>
        {menu === "Active" && (
          <Grid className={classes.buttonWrapper}>
            <Button
              startIcon={<IconXSquare />}
              className={classes.buttonText}
              onClick={() => popoverTurnOffAccount({ id })}
            >
              {t("GENERAL.TURN_OFF")}
            </Button>
            <Button startIcon={<IconEdit />} className={classes.buttonText} onClick={() => onEditUser({ id })}>
              {t("GENERAL.EDIT")}
            </Button>
            <Button
              startIcon={<IconRotateCCW />}
              className={classes.buttonText}
              onClick={() => popoverConfirmationResetOTP({ id })}
            >
              {t("GENERAL.RESET_OTP")}
            </Button>
          </Grid>
        )}
        {menu === "Submission" && (
          <Grid className={classes.buttonWrapper}>
            <Button startIcon={<IconApprove />} className={classes.buttonText} onClick={() => handleApprove({ id })}>
              {t("GENERAL.APPROVE")}
            </Button>
            <Button
              startIcon={<IconXSquare />}
              className={classes.buttonText}
              onClick={() => popoverConfirmationRejectUser({ data: [id] })}
            >
              {t("GENERAL.REJECT")}
            </Button>
          </Grid>
        )}
        {menu === "Non-Active" && (
          <Grid className={classes.buttonWrapper}>
            <Button
              startIcon={<IconApprove />}
              className={classes.buttonText}
              onClick={() => popoverConfirmationApproveUser({ id })}
            >
              {t("GENERAL.TURN_ON")}
            </Button>
            <Button startIcon={<IconEdit />} className={classes.buttonText} onClick={() => onEditUser({ id })}>
              {t("GENERAL.EDIT")}
            </Button>
          </Grid>
        )}
      </Popper>
    </Backdrop>
  );
};

PopperUserManagementMenu.defaultProps = {
  anchorEl: null,
  menu: null,
  id: null,
};

PopperUserManagementMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.element,
  menu: PropTypes.string,
  id: PropTypes.number,
};

export default PopperUserManagementMenu;
