import { Box, Card, Grid, Typography, Button } from "@material-ui/core";
// import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { ServerErrorBoundary } from "components/general";
import TableSalesVolume from "components/table/table-sales-volume";
import TextToggleButton from "elements/button/button-toggle-text";
import AutoCompleteDropdown from "elements/input/autocomplete-dropdown";
import CustomSelectDropdown from "elements/input/select-custom";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { useAuth } from "hooks/useAuth";
// import {
//   getTableDivision,
//   getTableArco,
//   getSalesTable,
//   getSelectArcoHeadSalesVolume,
//   getSelectDivHeadSalesVolume,
//   getCategorySalesVolumeList,
//   downloadExcel,
// } from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import {
  useDownloadExcelSalesVolume,
  useFetchCategorySalesVolumeList,
  useFetchDataArcoHeadList,
  useFetchDataByArco,
  useFetchDataByDivision,
  useFetchDataSales,
  useFetchDivisionHeadList,
} from "./useFetchData";
// import { useDownloadExcelSalesVolume } from "./useDownloadExcelSalesVolume";
// import { useFetchCategorySalesVolumeList } from "./useFetchCategorySalesVolumeList";
// import { useFetchDataArcoHeadList } from "./useFetchDataArcoHeadList";
// import { useFetchDataByArco } from "./useFetchDataByArco";
// import { useFetchDataByDivision } from "./useFetchDataByDivision";
// import { useFetchDataSales } from "./useFetchDataSales";
// import { useFetchDivisionHeadList } from "./useFetchDivisionHeadList";

const CURRENT_YEAR = new Date().getFullYear();
const INITIAL_FILTER = {
  year: CURRENT_YEAR,
  value: "",
  target: {},
};
const FILTER_TYPE = {
  filterType: "DIV_HEAD",
};

const DEFAULT_VALUE_FILTER_DROPDOWN_SV = [
  {
    id: "1",
    target_code: "SV",
    target_name: "Sales Volume",
  },
];

// eslint-disable-next-line react/prop-types
const CardSalesVolumeTable = ({ classes = {} }) => {
  const { getRole } = useAuth();
  const { t } = useTranslation();
  const { signal } = useAbortController();

  const translation = useMemo(
    () => ({
      toggle: {
        division: t("CASA.DIVISION"),
        area: t("PORTOFOLIO.AREA_COORDINATOR"),
        sales: t("BOX.TAT_PROCESS_SALES"),
      },
      general: {
        download: t("GENERAL.DOWNLOAD"),
      },
    }),
    [t],
  );

  const [toggle, setToggle] = useState(translation.toggle.division);
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [filterType, setFilterType] = useState(FILTER_TYPE);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const dataByDivision = useFetchDataByDivision(filter, translation, toggle, signal);

  // useQuery(
  //   ["table-division", filter.year, filter.target],
  //   () => getTableDivision({ year: filter.year, target: filter.target.target_code }, signal),
  //   {
  //     enabled: toggle === translation.toggle.division,
  //     retry: 0,
  //     keepPreviousData: true,
  //   },
  // );

  const categorySalesVolumeList = useFetchCategorySalesVolumeList(filter.year, signal);
  // useQuery(["category-sales-volume-list"], () => {
  //   return getCategorySalesVolumeList(signal);
  // });

  const dataByArco = useFetchDataByArco(filter, translation, toggle, signal);

  // useQuery(
  //   ["table-arco", filter],
  //   () =>
  //     getTableArco(
  //       { year: filter.year, userCode: filter.value?.position_code, target: filter.target.target_code },
  //       signal,
  //     ),
  //   {
  //     enabled: toggle === translation.toggle.area,
  //     retry: 0,
  //     keepPreviousData: true,
  //   },
  // );

  const dataSales = useFetchDataSales(filter, translation, toggle, signal);
  // useQuery(
  //   ["table-sales", filter],
  //   () =>
  //     getSalesTable(
  //       { year: filter.year, userCode: filter.value?.position_code, target: filter.target.target_code },
  //       signal,
  //     ),
  //   {
  //     enabled: toggle === translation.toggle.sales,
  //     retry: 0,
  //     keepPreviousData: true,
  //   },
  // );

  const divisionHeadList = useFetchDivisionHeadList(translation, toggle, filter);
  // useQuery(["division-head-list-casa"], () => getSelectDivHeadSalesVolume(), {
  //   enabled: toggle === translation.toggle.area,
  //   retry: 0,
  // });

  const arcoHeadList = useFetchDataArcoHeadList(translation, toggle, filter);
  // useQuery(["arco-head-list"], () => getSelectArcoHeadSalesVolume(), {
  //   enabled: toggle === translation.toggle.sales,
  //   retry: 0,
  // });

  /* ------------------------- DOWNLOAD EXCEL ------------------------ */
  const downloadExcelSalesVolume = useDownloadExcelSalesVolume(filter, filterType);
  // useMutation(() =>
  //   downloadExcel({ year: filter.year, filterType: filterType.filterType }),
  // );

  const handleToggleOnChange = useCallback(
    current => {
      if (current === translation.toggle.division) {
        setFilterType(x => ({ ...x, filterType: "DIV_HEAD" }));
      }
      if (current === translation.toggle.area) {
        setFilterType(x => ({ ...x, filterType: "ARCO" }));
      }
      if (current === translation.toggle.sales) {
        setFilterType(x => ({ ...x, filterType: "SALES" }));
      }
      setFilter(INITIAL_FILTER);
      setToggle(current);
    },
    [setToggle, setFilter, setFilterType, translation.toggle],
  );

  const years = useMemo(() => [CURRENT_YEAR, CURRENT_YEAR - 1, CURRENT_YEAR - 2], []);
  const headTable = useMemo(() => {
    const heads = {
      [translation.toggle.division]: t("SALES_VOLUME.DIVISION_HEAD"),
      [translation.toggle.area]: t("SALES_VOLUME.AREA_COORDINATOR"),
      [translation.toggle.sales]: t("SALES_VOLUME.SALES"),
    };
    return heads[toggle];
  }, [toggle, translation, t]);

  const activeQuery = useMemo(() => {
    const queries = {
      [translation.toggle.division]: dataByDivision,
      [translation.toggle.area]: dataByArco,
      [translation.toggle.sales]: dataSales,
    };
    return queries[toggle];
  }, [dataByDivision, dataByArco, dataSales, toggle, translation]);

  const activeSelect = useMemo(() => {
    const querySelect = {
      [translation.toggle.area]: divisionHeadList,
      [translation.toggle.sales]: arcoHeadList,
    };
    return querySelect[toggle];
  }, [arcoHeadList, divisionHeadList, translation, toggle]);

  const dropdownContent = useMemo(() => {
    const { area, sales } = translation.toggle;
    if (![area, sales].includes(toggle)) return null;
    return (
      <AutoCompleteDropdown
        key={toggle}
        data={activeSelect?.data?.data ?? []}
        value={filter.value ?? ""}
        onChange={(x, val) => {
          setFilter(y => ({ ...y, value: val }));
        }}
        loading={activeSelect.isLoading}
      />
    );
  }, [toggle, translation, setFilter, activeSelect, filter.value]);

  const tableContent = useMemo(() => {
    if (!activeQuery || activeQuery?.isError) return <ServerErrorBoundary onClick={activeQuery?.refetch} />;
    if (activeQuery?.isFetching) return <LoadingSpinner height={100} size={50} style={{}} />;
    return <TableSalesVolume {...activeQuery?.data} mainHead={headTable} />;
  }, [activeQuery, headTable]);

  return (
    <Card className={classes.cardTable}>
      <Box className={classes.box}>
        <TextToggleButton
          toggleData={Object.values(translation.toggle)}
          selected={toggle}
          onChange={handleToggleOnChange}
        />
        <div>
          <Grid container spacing={2}>
            <Grid item>{dropdownContent}</Grid>
            <Grid item>
              <CustomSelectDropdown
                placeholder={
                  categorySalesVolumeList?.isFetching ? (
                    "Loading..."
                  ) : (
                    <span
                      style={{
                        color: COLORS.DARK_HARD,
                      }}
                    >
                      {categorySalesVolumeList?.isError
                        ? "Sales Volume"
                        : categorySalesVolumeList?.data[0]?.target_name}
                    </span>
                  )
                }
                disabled={categorySalesVolumeList?.isFetching}
                arrayData={
                  categorySalesVolumeList?.data ? categorySalesVolumeList?.data : DEFAULT_VALUE_FILTER_DROPDOWN_SV
                }
                onChange={e =>
                  setFilter(x => ({
                    ...x,
                    target: e?.target?.value,
                  }))
                }
                renderValue={val => val?.target_name}
                data={filter?.target?.target_name}
              />
            </Grid>
            <Grid item>
              <CustomSelectDropdown
                placeholder={String(CURRENT_YEAR)}
                arrayData={years}
                onChange={e =>
                  setFilter(x => ({
                    ...x,
                    year: e.target?.value,
                    target: {
                      target_code: "SV",
                      target_name: "Sales Volume",
                    },
                  }))
                }
                data={filter.year}
              />
            </Grid>
            {!getRole().isInquiry && (
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={
                    downloadExcelSalesVolume?.isLoading ? (
                      <LoadingSpinner height={20} size={20} style={{}} />
                    ) : (
                      <IconDownload />
                    )
                  }
                  className={classes.buttonContained}
                  onClick={() => downloadExcelSalesVolume?.mutate()}
                  disabled={downloadExcelSalesVolume?.isLoading}
                >
                  {translation.general.download}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Box>
      <Box className={classes.boxCentered}>
        <Typography className={classes.typographyTitle}>
          {filter.target.target_name ? filter.target.target_name : t("SALES_VOLUME.SALES_VOLUME")}
        </Typography>
        <Typography className={classes.typographyDesc}>{t("SALES_VOLUME.IDR_BIO")}</Typography>
      </Box>
      {tableContent}
    </Card>
  );
};

export { CardSalesVolumeTable };
