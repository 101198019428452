import { makeStyles, TextField as MTextField, Typography, InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PercentIcon } from "assets/icons/general/icon-percent.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  container: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    minHeight: "3.5rem",
  },
  textField: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    padding: "6px 10px",
    borderRadius: "10px",
    minWidth: "15rem",
    "&$disabled": {
      border: "1px solid gray",
      cursor: "not-allowed",
    },
  },
  disabled: {
    cursor: "not-allowed",
  },
  wrap: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  label: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 400,
    color: COLORS.BLACK,
    flex: "0 0 8rem",
  },
  calculation: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    color: COLORS.BLACK,
  },
});

const TextField = forwardRef(({ adornment, placeholder, ...props }, ref) => {
  const classes = useStyles();

  return (
    <MTextField
      ref={ref}
      variant="standard"
      size="small"
      placeholder={placeholder}
      autoComplete="off"
      InputProps={{
        autoComplete: "off",
        disableUnderline: true,
        classes: { disabled: classes.disabled, root: classes.textField },
        endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
      }}
      {...props}
    />
  );
});

TextField.defaultProps = {
  adornment: null,
  placeholder: null,
};

TextField.propTypes = {
  adornment: PropTypes.node,
  placeholder: PropTypes.string,
};

const TextInputCalculation = forwardRef(({ title, calculation, adornment, placeholder, ...props }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Typography children={title} className={classes.label} />
      <div className={classes.wrap}>
        <TextField placeholder={placeholder ?? t("GENERAL.PERCENTAGE")} adornment={adornment} {...props} ref={ref} />
        <Typography children=":" />
      </div>
      <Typography children={calculation} className={classes.calculation} />
    </div>
  );
});

TextInputCalculation.defaultProps = {
  ...TextField.defaultProps,
  title: "",
  calculation: "",
  adornment: <PercentIcon />,
};

TextInputCalculation.propTypes = {
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  calculation: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.number]),
  ...TextField.propTypes,
};

export { TextInputCalculation, TextField };
