import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(props => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    marginLeft: props.marginSize,
  },
  imgWrapper: {
    width: "16px",
    height: "10.4px",
    marginRight: "5px",
    alignItems: "center",
    display: "flex",
  },
  textStyle: {
    fontSize: SIZES.REGULAR_10,
    color: COLORS.DARK_HARD,
  },
}));

const LegendBarSalesVolume = ({ icon, title, marginSize }) => {
  const classes = useStyles(marginSize);

  return (
    <div className={classes.wrapper}>
      <div className={classes.imgWrapper}>{icon !== null ? <img src={icon} alt="icon-legend-sales" /> : ""}</div>
      <Typography className={classes.textStyle}>{title}</Typography>
    </div>
  );
};
LegendBarSalesVolume.defaultProps = {
  title: "",
  icon: null,
  marginSize: "80px",
};

LegendBarSalesVolume.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  marginSize: PropTypes.string,
};

export default LegendBarSalesVolume;
