import { Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// Components
import FormLogin from "components/form/form-login";
import { routes } from "utils/constants/paths";

const LoginPage = () => {
  const navigate = useNavigate();

  const handleRegister = useCallback(() => {
    navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.REGISTER));
  }, [navigate]);

  const handleForgotPassword = useCallback(() => {
    navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.FORGOT_PASSWORD));
  }, [navigate]);

  return (
    <Grid container spacing={0}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <FormLogin onRegister={handleRegister} onForgot={handleForgotPassword} />
      </div>
    </Grid>
  );
};

export default LoginPage;
