import { styled, Chip as MChip } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const Chip = styled(MChip)({
  paddingTop: "6px",
  paddingLeft: "5px",
  paddingRight: "5px",
  paddingBottom: "6px",
  fontSize: SIZES.REGULAR_14,
  height: "100%",
  backgroundColor: COLORS.WHITE,
  fontWeight: 600,
  "&.MuiChip-deletable": {
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  },
  "&.MuiChip-deleteIcon": {
    height: "16px",
    width: "16px",
  },
});

export { Chip };
