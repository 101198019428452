import { axiosPrivate } from "../axiosPrivate";

const listSalesName = async () => {
  const { data: responseBody, status } = await axiosPrivate.get(`/dashboard/incoming/application/sales-name/list`);
  if (status !== 200) throw new Error(`[list-sales-name] ${status}`);
  const responseSalesName = responseBody.data.map(item => {
    return {
      code: item.salesNameCode,
      name: item.salesName,
    };
  });
  return { responseSalesName, data: responseBody.data };
};

const listSalesByLogin = async () => {
  const { data: responseBody, status } = await axiosPrivate.get("/dashboard/calculationprofitandlos/sales");

  if (status !== 200) throw new Error(`[listSalesByLogin] ${status}`);

  return { data: responseBody.data };
};

const cancelDownload = async ({ id }) => {
  const { data: responseBody, status } = await axiosPrivate.post("/dashboard/download/cancel", {
    id,
  });

  if (status !== 200) throw new Error(`[cancelDownload] ${status}`);

  return { payload: responseBody };
};

export { listSalesName, listSalesByLogin, cancelDownload };
