import { makeStyles } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },

  gridWrapper: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  actionContainer: {
    margin: "2rem 0",
    display: "flex",
    gap: "1.5rem",
    justifyContent: "flex-end",
  },

  buttonExcel: {
    width: "180px",
    height: "40px",
    marginLeft: 20,
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    borderRadius: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },

  // Toogle
  toggleButtonGroup: {
    backgroundColor: "transparent",
    maxWidth: "max-content",
    height: "max-content",
  },
  toggleButton: {
    backgroundColor: "transparent",
    color: COLORS.GRAY_MEDIUM,
    minWidth: "120px",
    height: "32px",
    borderStyle: "none",
    textTransform: "initial",
    fontSize: SIZES.REGULAR_16,
    "&.Mui-selected": {
      backgroundColor: "transparent",
      borderBottom: `2px solid ${COLORS.PRIMARY_DARK}`,
      color: COLORS.DARK_HARD,
      "&:hover": {
        borderBottom: `1px solid ${COLORS.PRIMARY_DARK}`,
        color: COLORS.DARK_HARD,
      },
    },
  },
}));

export default useStyles;
