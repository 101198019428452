import { useQuery } from "@tanstack/react-query";
import { listSalesByLogin } from "services/api/private-routes/general";

const KEY_LIST_SALES_BY_LOGIN = "list-sales-by-login";

const useQueryListSalesByLogin = () => {
  return useQuery([KEY_LIST_SALES_BY_LOGIN], listSalesByLogin);
};

export { KEY_LIST_SALES_BY_LOGIN, useQueryListSalesByLogin };
