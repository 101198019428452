import axios, { AxiosError } from "axios";
import ENDPOINT from "utils/constants/endpoint";
import { getCookie, deleteAllCookies } from "utils/helpers/cookie";

const axiosPrivate = axios.create({
  baseURL: ENDPOINT.BASEURL,
  // Resolve only if the status code is less than 500
  validateStatus: status => status < 500,
});

axiosPrivate.interceptors.request.use(
  config => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = getCookie("_auth");
    return config;
  },
  err => {
    if (err instanceof AxiosError) {
      return err.response;
    } else return err;
  },
);

axiosPrivate.interceptors.response.use(
  success => {
    //  Clear cookies and redirec to auth page
    if (success.status === 401 && success.data?.response_message === "Invalid token") {
      deleteAllCookies();
      window.location.href = "/logout";
      return;
    }
    return success;
  },
  err => Promise.reject(err),
);

export { axiosPrivate };
