const maxScale = data => {
  const max = Object.entries(data).reduce((items, [, val]) => {
    return [...items, val.amount];
  }, []);
  return Math.ceil(Math.max(...max));
};

const negativeScaleBarChart = data => {
  const max = Object.entries(data).reduce((items, [, val]) => {
    return [...items, val.total];
  }, []);

  return Math.min(0, ...max) / 0.9;
};

const negativeScaleCustomChart = data => {
  const max = Object.entries(data).reduce((items, [, val]) => {
    return [...items, val.total_bio];
  }, []);

  return Math.min(0, ...max);
};
const negativeNotProfitScaleCustomChart = data => {
  const max = Object.entries(data).reduce((items, [, val]) => {
    return [...items, val.total_bio - 0.08];
  }, []);

  return Math.min(0, ...max);
};

const positiveProfitScaleCustomChart = data => {
  const max = Object.entries(data).reduce((items, [, val]) => {
    return [...items, val.total_bio + 0.2];
  }, []);

  return Math.min(0, ...max);
};

export {
  maxScale,
  negativeScaleBarChart,
  negativeScaleCustomChart,
  negativeNotProfitScaleCustomChart,
  positiveProfitScaleCustomChart,
};
