import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const DetailRevenueIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5167 12.3004L14.5002 19.3169C13.8558 19.9613 12.8111 19.9613 12.1667 19.3169L12.1544 19.3045L20.35 11.1336L11.3918 2.2002H13.0666C13.5042 2.2002 13.9238 2.37404 14.2333 2.68347L21.5167 9.96692C22.1611 10.6113 22.1611 11.656 21.5167 12.3004ZM17.1167 12.3004L10.1002 19.3169C9.77801 19.6391 9.35571 19.8002 8.93345 19.8002C8.51118 19.8002 8.08889 19.6391 7.76672 19.3169L0.483278 12.0335C0.173843 11.724 2.28826e-06 11.3044 0 10.8668L0 3.8502C0 2.93891 0.738719 2.2002 1.65 2.2002H8.66656C8.88324 2.20019 9.0978 2.24287 9.29799 2.32579C9.49817 2.40871 9.68007 2.53025 9.83328 2.68347L17.1167 9.96692C17.7611 10.6113 17.7611 11.656 17.1167 12.3004V12.3004ZM15.95 11.1336L8.66656 3.8502H1.65V10.8668L8.93358 18.1501L15.95 11.1336ZM4.95 5.5002C4.03872 5.5002 3.3 6.23892 3.3 7.1502C3.3 8.06148 4.03872 8.8002 4.95 8.8002C5.86128 8.8002 6.6 8.06148 6.6 7.1502C6.6 6.23892 5.86128 5.5002 4.95 5.5002Z"
      fill={fill}
    />
  </SvgIcon>
);

DetailRevenueIcon.defaultProp = {
  fill: COLORS.WHITE,
};

DetailRevenueIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default DetailRevenueIcon;
