import moment from "moment";
import qs from "qs";
import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getDataQrTypeList = async ({ month, year }, signal) => {
  const query = qs.stringify({
    month: new Date(month).getMonth() + 1,
    year: moment(year).format("YYYY"),
  });

  const { data: responseBody, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/dashboard/transaction/portfolio/portfolio-management-qr-detail-list?${query}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataQrTypeList] ${status}`);
  const { data } = responseBody;

  return data;
};

export { getDataQrTypeList };
