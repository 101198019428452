import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconTitle from "assets/icons/chart/icon-chart-pie.svg";
import SubTopMerchantSalesVolume from "components/card/sub-card/top-merchant-sales-volume";
import { ServerErrorBoundary } from "components/general";
import CardWrapper from "components/wrapper/wrapper-card";
import CardTitle from "elements/card/card-header/card-title";
import LoadingSpinner from "elements/loading/spinner";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  gridStyles: {
    width: "100%",
  },
}));

const CardTopMerchantSalesVolume = ({ data }) => {
  const classes = useStyles();
  /* translations */
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      cardTitle: t("CARD.TITLE.TOP_MERCHANT_SALES_VOLUME"),
      subTopMerchantSalesVolume: {
        creditOfUs: t("GENERAL.CREDIT_OFF_US"),
        creditOnUs: t("GENERAL.CREDIT_ON_US"),
        debitOfUs: t("GENERAL.DEBIT_OFF_US"),
        debitOnUs: t("GENERAL.DEBIT_ON_US"),
      },
    }),
    [t],
  );

  /* memo fetching */
  const TOP_MERCHANT_SALES_VOLUME = useMemo(() => {
    if (data.isError) {
      return <ServerErrorBoundary onClick={() => data.refetch()} title={data.error?.message} />;
    }
    if (data.isLoading) {
      return <LoadingSpinner height={500} />;
    }
    if (!data.isLoading && data.isSuccess) {
      return (
        <Grid container spacing={1} wrap="nowrap" sx={{ overflow: "auto" }} className={classes.gridWrapper}>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <SubTopMerchantSalesVolume
              title={translations.subTopMerchantSalesVolume.creditOfUs}
              list={data?.data?.credit_off_us}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <SubTopMerchantSalesVolume
              title={translations.subTopMerchantSalesVolume.creditOnUs}
              list={data?.data?.credit_on_us}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <SubTopMerchantSalesVolume
              title={translations.subTopMerchantSalesVolume.debitOfUs}
              list={data?.data?.debit_off_us}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className={classes.gridStyles}>
            <SubTopMerchantSalesVolume
              title={translations.subTopMerchantSalesVolume.debitOnUs}
              list={data?.data?.debit_on_us}
            />
          </Grid>
        </Grid>
      );
    }
  }, [data, translations, classes]);

  return (
    <CardWrapper>
      <CardTitle iconSrc={IconTitle} title={translations.cardTitle} />
      {TOP_MERCHANT_SALES_VOLUME}
    </CardWrapper>
  );
};

CardTopMerchantSalesVolume.defaultProps = {
  data: [],
};

CardTopMerchantSalesVolume.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CardTopMerchantSalesVolume;
