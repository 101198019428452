import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";
// import { VALUES_TRANSACTIONS_ALIASES } from "../dashboard-detail-transaction/util";
import { VALUES_TRANSACTIONS_ALIASES, formatBrandingTableByMerchant, formatByMerchantBarChart } from "./util";

const getListDetailProfitibility = async (filter, signal) => {
  const { data, status } = await axiosPrivate.post(`${ENDPOINT.BASEURL}/profitabilityservice/branding`, filter, {
    signal,
  });
  if (status !== 200) throw new Error(`[getListDetailDetailProfitibility] ${status}`);

  const { data: datas } = data;

  const parsedData = datas.data.reduce((prev, curr) => {
    const {
      branding,
      edc_cost,
      interchange_fee,
      mcc_group,
      mdr_revenue,
      merchant,
      profit,
      sales,
      status_edc,
      total_profit,
      trb,
    } = curr;

    return [
      ...prev,
      {
        id: branding.branding_id,
        name: branding.branding_name,
        transactions: {
          merchant_name: merchant.merchant_name,
          profit,
          trb,
          status_edc,
          sales,
          mdr_revenue: mdr_revenue.total,
          interchange_fee: interchange_fee.total,
          edc_cost: edc_cost.total,
          total_profit: total_profit.total,
          mcc_group,
          action: true,
        },
      },
    ];
  }, []);

  return {
    data: parsedData,
    subHeaders: VALUES_TRANSACTIONS_ALIASES,
    page: {
      dataCount: datas.row_count,
      totalPage: datas.total_page,
      offset: datas.page.offset,
      number: datas.page.pageNumber,
      size: datas.page.pageSize,
    },
    filter: {
      salesArea: datas?.linked_sort_pagination_dto?.search_request?.filters?.sales_area ?? [],
      type: datas?.linked_sort_pagination_dto?.search_request?.filters?.type ?? "Monthly",
    },
  };
};

const getDataListMcc = async signal => {
  const { data, status } = await axiosPrivate.get(`${ENDPOINT.BASEURL}/profitabilityservice/branding/mcc-group`, {
    signal,
  });
  if (status !== 200) throw new Error(`[getDataListMcc] ${status}`);
  const newData = [{ id: null, mccGroup: "Select All" }, ...data.data];
  return newData;
};

const getTrendChartMtdByMerchant = async (signal, id, category, monthly) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/branding/${id}/trend/${category}/${monthly}`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getTrendChartMtdByMerchant] ${status}`);

  return data.data;
};

const getCobaChart = async (signal, id) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/branding/${id}/trend/corporate-banking`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getCobaChart] ${status}`);

  return formatByMerchantBarChart(data.data);
};

const getCombaChart = async (signal, id) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/branding/${id}/trend/commercial-banking`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getCobaChart] ${status}`);

  return formatByMerchantBarChart(data.data);
};

const getEmmergingChart = async (signal, id) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/branding/${id}/trend/emerging-banking`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getCobaChart] ${status}`);

  return formatByMerchantBarChart(data.data);
};

const getBrandingByMerchantTable = async (signal, id, filter, tableConfig) => {
  const { data, status } = await axiosPrivate.post(
    `${ENDPOINT.BASEURL}/profitabilityservice/branding/detail-branding`,
    {
      filters: {
        branding_id: id,
        monthly: filter.month,
      },
      search: "",
      typeSearch: "",
      sorts: {
        key: tableConfig.sortBy,
        direction: tableConfig.sortDirection,
      },
      page: tableConfig.page,
      size: tableConfig.limit,
    },
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getBrandingByMerchantTable] ${status}`);

  return formatBrandingTableByMerchant(data.data);
};

const downloadListProfitibilityDetail = async filter => {
  const { data: responseBody, status } = await axiosPrivate.post("profitabilityservice/branding/downloads", filter);
  if (status !== 200) throw new Error(`[downloadListProfitibilityDetail] ${status}`);
  return { payload: responseBody.data };
};

const downloadListProfitibilityMerchant = async payload => {
  const param = payload?.monthly?.split("-");
  const { data: responseBody, status } = await axiosPrivate.post("profitabilityservice/branding/detail/download", {
    filters: {
      branding_id: payload?.id,
      monthly: `${param[0]}-${param[1]}`,
    },
    search: "",
    typeSearch: "",
    sorts: {
      key: payload?.sortBy,
      direction: payload?.sortDirection,
    },
    page: payload?.page,
    size: payload?.limit,
  });
  if (status !== 200) throw new Error(`[downloadListProfitibilityDetail] ${status}`);
  return { payload: responseBody.data };
};

const getDataSalesArea = async () => {
  const { data: response } = await axiosPrivate.get(`/auth/usermgmt/sales-area-user`);

  const { data } = response;

  const getIdByName = name => data?.find(x => x.name === name) || "";
  const getList = () => data?.reduce((prev, curr) => [...prev, curr.name], []);

  data.unshift({ description: "Select All", id: null, name: "Select All", salesAreaType: null });
  return { dataSales: data, data, getIdByName, getList };
};

export {
  getDataSalesArea,
  getListDetailProfitibility,
  getDataListMcc,
  getTrendChartMtdByMerchant,
  getCobaChart,
  getCombaChart,
  getEmmergingChart,
  getBrandingByMerchantTable,
  // Download Excel
  downloadListProfitibilityDetail,
  downloadListProfitibilityMerchant,
};
