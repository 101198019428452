import { Box, Card, Typography } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import calculatorSquare from "assets/icons/general/icon-calculator-square.svg";
import countSquare from "assets/icons/general/icon-count-square.svg";
import IconFailed from "assets/icons/popup/icon-state-failed.svg";
import IconSuccess from "assets/icons/popup/icon-state-success.svg";
import IconWarning from "assets/icons/popup/icon-warning.svg";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupConfirmation from "components/popup/popup-confirmation";
import PopupInformation from "components/popup/popup-information";
import { Button } from "elements/button";
import { TextInputCalculation, TextField } from "elements/input/text-input-calculation";
import useTimeOut from "hooks/useTimeOut";
import { useFilterCalculation } from "pages/dashboard/dashboard-calculation-loss-profit/use-calculation-filter";
import { saveCalculation } from "services/api/private-routes/calculation-profit-and-loss";
import { toCurrency } from "utils/helpers/useFormater";
import { useQueryExistingCalculation } from "../card-existing-calculation-pnl/use-query-existing-calculation";
import { structure } from "./data-structure";
import { StyledDiv, useStyles } from "./styled";
import { useQueryCalculationParameter } from "./use-calculation-parameter";

const CardCalculationProfitLoss = () => {
  const { t } = useTranslation();
  const { setActive } = useCIMBLoading();
  const classes = useStyles();
  const [popUp, setPopUp] = useState(null);
  const { control, reset, watch, setValue, getValues, handleSubmit } = useForm({ defaultValues: structure });
  const [filter] = useFilterCalculation();
  const queryCalculationParameter = useQueryCalculationParameter();
  const queryExistingCalculation = useQueryExistingCalculation({
    onSuccess: res => {
      if (res.newCalculation.salesVolume) {
        setValue("salesVolume", res.newCalculation.salesVolume);
        setValue("revenue", res.newCalculation.revenue);
        setValue("cost", res.newCalculation.cost);
        setValue("salesVolume.value", parseCurrency(res.newCalculation.salesVolume?.value));
      } else reset();
    },
  });

  useTimeOut({
    enable: typeof popUp === "string",
  });

  const mutateSaveCalculation = useMutation(saveCalculation, {
    onMutate: () => setActive(true),
    onSuccess: async () => {
      queryExistingCalculation.remove();
      setPopUp("success");
    },
    onError: () => setPopUp("failed"),
    onSettled: () => setActive(false),
  });

  const handleSaveCalculation = handleSubmit(data => {
    const rawData = {
      branding_id: filter.brandingId,
      period: filter.period,
      sales_area_id: filter.area,
      sales_id: filter.sales,
      sales_volume: Number(String(data.salesVolume.value).replace(/,/g, "")) || 0,
      revenue: calculation.revenueValue || 0,
      interchange: calculation.cost.interchange || 0,
      interchange_npg: calculation.cost.npg || 0,
      switching_fee: calculation.cost.fee || 0,
      cost: calculation.costTotal || 0,
      sales_volume_percentage_credit_on_us: data.salesVolume.input.creditOnUs || 0,
      sales_volume_percentage_credit_off_us: data.salesVolume.input.creditOffUs || 0,
      sales_volume_percentage_debit_on_us: data.salesVolume.input.debitOnUs || 0,
      sales_volume_percentage_debit_off_us: data.salesVolume.input.debitOffUs || 0,
      sales_volume_percentage_qr: data.salesVolume.input.qr || 0,
      sales_volume_percentage_ciltap3: data.salesVolume.input.citlap3 || 0,
      sales_volume_percentage_ciltap6: data.salesVolume.input.citlap6 || 0,
      sales_volume_percentage_ciltap12: data.salesVolume.input.citlap12 || 0,
      sales_volume_percentage_ciltap24: data.salesVolume.input.citlap24 || 0,
      sales_volume_percentage_recurring: data.salesVolume.input.recurring || 0,
      revenue_percentage_credit_on_us: data.revenue.input.creditOnUs || 0,
      revenue_percentage_credit_off_us: data.revenue.input.creditOffUs || 0,
      revenue_percentage_debit_on_us: data.revenue.input.debitOnUs || 0,
      revenue_percentage_debit_off_us: data.revenue.input.debitOffUs || 0,
      revenue_percentage_qr: data.revenue.input.qr || 0,
      revenue_percentage_ciltap3: data.revenue.input.citlap3 || 0,
      revenue_percentage_ciltap6: data.revenue.input.citlap6 || 0,
      revenue_percentage_ciltap12: data.revenue.input.citlap12 || 0,
      revenue_percentage_ciltap24: data.revenue.input.citlap24 || 0,
      revenue_percentage_recurring: data.revenue.input.recurring || 0,
      edc_cost: calculation.cost.edc_cost || 0,
      edc_charge: calculation.cost.edc_charge || 0,
      amount_edc_cost: data.cost.edcCost || 0,
      amount_edc_charge: data.cost.edcCharge || 0,
      net_revenue_after_transaction: calculation?.netRevenue,
      net_revenue_after_transaction_shadow: calculation?.netRevenueShadow,
    };
    const fillData = Object.entries(rawData).reduce((prev, [key, val]) => ({ ...prev, [key]: Number(val) || 0 }), {});

    const payload = {
      ...fillData,
      branding_id: filter.brandingId,
      period: filter.period,
      sales_area_id: filter.area,
      sales_id: filter.sales,
    };

    mutateSaveCalculation.mutate(payload);
  });

  const handleSalesVolumeOnChange = (name, value) => {
    if (String(value).length === 0) return setValue(name, "");
    const validValue = Number(String(value)?.replace(/\D/g, "")) || 0;
    const currentTotal = Object.entries(watch("salesVolume.input"))
      .filter(([key]) => !String(name).includes(key))
      .reduce((prev, [, val]) => prev + (Number(val) || 0), 0);
    if (currentTotal + validValue > 100) return setValue(name, 100 - currentTotal);
    return setValue(name, validValue || 0);
  };

  const handleInputNumberOnChange = (name, value) => {
    let validateValue = value;
    const isSalesVolumeValue = name === "salesVolume.value";
    const isDecimals = String(validateValue).includes(".");
    if (String(validateValue).length === 0) {
      if (isSalesVolumeValue) reset();
      setValue(name, "");
      return;
    }

    if (isDecimals) {
      const validDecimals = String(value).split(".");
      validDecimals.length > 2 && validDecimals.pop();
      if (validDecimals[1]?.length > 2) validDecimals[1] = validDecimals[1].slice(0, 2);
      validateValue = validDecimals.join(".");
    }

    validateValue = String(validateValue)?.replace(/([^\d.])+/g, "") || 0;
    if (isSalesVolumeValue) return setValue(name, toCurrency(validateValue) || 0);
    else return setValue(name, validateValue || 0);
  };

  const calculation = useMemo(() => {
    const salesVolume = String(getValues("salesVolume.value"))?.replace(/,/g, "");
    const toPercentage = val => Number(val) / 100;

    const salesVolumeFormula = {
      creditOnUs: toPercentage(getValues("salesVolume.input.creditOnUs") * salesVolume),
      creditOffUs: toPercentage(getValues("salesVolume.input.creditOffUs") * salesVolume),
      debitOnUs: toPercentage(getValues("salesVolume.input.debitOnUs") * salesVolume),
      debitOffUs: toPercentage(getValues("salesVolume.input.debitOffUs") * salesVolume),
      qr: toPercentage(getValues("salesVolume.input.qr") * salesVolume),
      ciltap3: toPercentage(getValues("salesVolume.input.citlap3") * salesVolume),
      ciltap6: toPercentage(getValues("salesVolume.input.citlap6") * salesVolume),
      ciltap12: toPercentage(getValues("salesVolume.input.citlap12") * salesVolume),
      ciltap24: toPercentage(getValues("salesVolume.input.citlap24") * salesVolume),
      recurring: toPercentage(getValues("salesVolume.input.recurring") * salesVolume),
    };

    const revenue = {
      creditOnUs: toPercentage(getValues("revenue.input.creditOnUs") * salesVolumeFormula.creditOnUs),
      creditOffUs: toPercentage(getValues("revenue.input.creditOffUs") * salesVolumeFormula.creditOffUs),
      debitOnUs: toPercentage(getValues("revenue.input.debitOnUs") * salesVolumeFormula.debitOnUs),
      debitOffUs: toPercentage(getValues("revenue.input.debitOffUs") * salesVolumeFormula.debitOffUs),
      qr: toPercentage(getValues("revenue.input.qr") * salesVolumeFormula.qr),
      ciltap3: toPercentage(getValues("revenue.input.citlap3") * salesVolumeFormula.ciltap3),
      ciltap6: toPercentage(getValues("revenue.input.citlap6") * salesVolumeFormula.ciltap6),
      ciltap12: toPercentage(getValues("revenue.input.citlap12") * salesVolumeFormula.ciltap12),
      ciltap24: toPercentage(getValues("revenue.input.citlap24") * salesVolumeFormula.ciltap24),
      recurring: toPercentage(getValues("revenue.input.recurring") * salesVolumeFormula.recurring),
    };

    const costFormula = {
      interchange: toPercentage(
        salesVolumeFormula.creditOffUs * Number(queryCalculationParameter.data?.interchange?.percentage || 1),
      ),
      npg: toPercentage(
        salesVolumeFormula.debitOffUs * Number(queryCalculationParameter.data?.interchange_npg?.percentage || 1),
      ),
      fee: toPercentage(
        salesVolumeFormula.creditOffUs * (queryCalculationParameter.data?.switching_fee?.percentage || 1) +
          salesVolumeFormula.debitOffUs * (queryCalculationParameter.data?.switching_fee2?.percentage || 1),
      ),
      edc_cost: getValues("cost.edcCost") * (queryCalculationParameter.data?.edc_cost || 1),
      edc_charge: getValues("cost.edcCharge") * (queryCalculationParameter.data?.edc_charge || 1),
    };

    const shadowNii = queryCalculationParameter.data?.shadow_nii || 0;

    const revenueValue = Object.values(revenue).reduce((prev, curr) => prev + Number(curr), 0);
    const costValue = Object.values(costFormula).reduce((prev, curr) => prev + (Number(curr) || 0), 0);
    const salesVolumeIsEmpty = (Number(salesVolume) || 0) <= 0;
    const revenueIsEmpty = revenueValue <= 0;
    const netRevenue = Number(revenueValue - costValue) || 0;
    const netRevenueShadow = netRevenue + (Number(queryCalculationParameter.data?.shadow_nii) || 0);
    return {
      netRevenue,
      netRevenueShadow,
      remaining: Object.values(getValues("salesVolume.input")).reduce((prev, curr) => prev + (Number(curr) || 0), 0),
      salesVolume: salesVolumeFormula,
      revenue,
      revenueValue,
      cost: costFormula,
      costTotal: costValue,
      revenueIsEmpty,
      salesVolumeIsEmpty,
      shadowNii,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCalculationParameter.data, getValues, watch()]);

  const parseCurrency = number => {
    const formattedNumber = new Intl.NumberFormat("en-EN").format(number);
    return formattedNumber || "0";
  };

  return (
    <form>
      <PopupConfirmation
        isOpen={popUp === "confirmation"}
        icon={IconWarning}
        title="Save"
        description={["Are you sure want to save", "Calculation Profit and Loss?"]}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        onCancel={() => setPopUp(null)}
        onClose={() => setPopUp(null)}
        onSubmit={handleSaveCalculation}
      />
      <PopupInformation
        isOpen={popUp === "success"}
        title="Save Success"
        icon={IconSuccess}
        buttonText="Close"
        description={["You have successfully saved", "Calculation Profit and Loss."]}
        onClose={() => setPopUp(null)}
        onSubmit={() => setPopUp(null)}
      />
      <PopupInformation
        isOpen={popUp === "failed"}
        title="Save Failed"
        icon={IconFailed}
        buttonText="Close"
        description={["Failed to save Calculation Profit and Loss calculation result. Please try again."]}
        onClose={() => setPopUp(null)}
        onSubmit={() => setPopUp(null)}
      />
      <Card className={classes.card}>
        <Box className={classes.boxMinHeight26px}>
          <Typography className={classes.typography18px}>{t("GENERAL.NEW_CALCULTAION")}</Typography>
          <Typography className={classes.typography18pxGrayHard}>
            {t("GENERAL.LAST_UPDATE")}
            {queryExistingCalculation.data?.newCalculation?.updatedAt || "-"}
          </Typography>
        </Box>
        <div className={classes.grid}>
          {/* SALES VOLUME */}
          <div className={classes.cols}>
            <div className={clsx(classes.cols, classes.flexTitle, classes.underlined)}>
              <Typography>
                {t("GENERAL.SALES_VOLUME")} <span>:</span>
              </Typography>
              <Controller
                control={control}
                name="salesVolume.value"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={e => {
                      handleInputNumberOnChange(field.name, e.target.value);
                    }}
                    placeholder="Nominal"
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="salesVolume.input.creditOnUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.CREDIT_ON_US")}
                  calculation={parseCurrency(calculation.salesVolume.creditOnUs)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.creditOffUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.CREDIT_OFF_US")}
                  calculation={parseCurrency(calculation.salesVolume.creditOffUs)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.debitOnUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.DEBIT_ON_US")}
                  calculation={parseCurrency(calculation.salesVolume.debitOnUs)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.debitOffUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.DEBIT_OFF_US")}
                  calculation={parseCurrency(calculation.salesVolume.debitOffUs)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.qr"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.QR")}
                  calculation={parseCurrency(calculation.salesVolume.qr)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.citlap3"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.CILTAP3")}
                  calculation={parseCurrency(calculation.salesVolume.ciltap3)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.citlap6"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.CILTAP6")}
                  calculation={parseCurrency(calculation.salesVolume.ciltap6)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.citlap12"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.CILTAP12")}
                  calculation={parseCurrency(calculation.salesVolume.ciltap12)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.citlap24"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.CILTAP24")}
                  calculation={parseCurrency(calculation.salesVolume.ciltap24)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="salesVolume.input.recurring"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.salesVolumeIsEmpty}
                  title={t("GENERAL.RECURRING")}
                  calculation={parseCurrency(calculation.salesVolume.recurring)}
                  onChange={e => handleSalesVolumeOnChange(field.name, e.target.value)}
                />
              )}
            />
          </div>

          {/* REVENUE */}
          <div className={clsx(classes.cols)}>
            <div className={clsx(classes.cols, classes.flexTitle, classes.underlined)}>
              <Typography>
                {t("GENERAL.REVENUE")} <span>:</span>
              </Typography>
              <Typography>{parseCurrency(calculation.revenueValue)}</Typography>
            </div>
            <Controller
              control={control}
              name="revenue.input.creditOnUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  title={t("GENERAL.CREDIT_ON_US")}
                  calculation={parseCurrency(calculation.revenue.creditOnUs)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!calculation.salesVolume.creditOnUs > 0}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.creditOffUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.CREDIT_OFF_US")}
                  calculation={parseCurrency(calculation.revenue.creditOffUs)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.creditOffUs") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.debitOnUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  calculation={parseCurrency(calculation.revenue.debitOnUs)}
                  title={t("GENERAL.DEBIT_ON_US")}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.debitOnUs") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.debitOffUs"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  calculation={parseCurrency(calculation.revenue.debitOffUs)}
                  title={t("GENERAL.DEBIT_OFF_US")}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.debitOffUs") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.qr"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.QR")}
                  calculation={parseCurrency(calculation.revenue.qr)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.qr") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.citlap3"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.CILTAP3")}
                  calculation={parseCurrency(calculation.revenue.ciltap3)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.citlap3") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.citlap6"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.CILTAP6")}
                  calculation={parseCurrency(calculation.revenue.ciltap6)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.citlap6") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.citlap12"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.CILTAP12")}
                  calculation={parseCurrency(calculation.revenue.ciltap12)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.citlap12") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.citlap24"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.CILTAP24")}
                  calculation={parseCurrency(calculation.revenue.ciltap24)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.citlap24") > 0)}
                />
              )}
            />
            <Controller
              control={control}
              name="revenue.input.recurring"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  title={t("GENERAL.RECURRING")}
                  calculation={parseCurrency(calculation.revenue.recurring)}
                  placeholder={t("GENERAL.MDR")}
                  disabled={!Number(watch("salesVolume.input.recurring") > 0)}
                />
              )}
            />
          </div>
        </div>

        <div className={classes.grid}>
          <div className={clsx(classes.cols, classes.flexTitle, classes.underlined)}>
            <Typography>
              {t("GENERAL.COST")} <span>:</span>
            </Typography>
            <Typography>{parseCurrency(calculation.costTotal) || "-"}</Typography>
          </div>
          <div className={clsx(classes.cols, classes.flexTitle, classes.underlined)}>
            <Typography>
              Shadow nii <span>:</span>
            </Typography>
            <Typography>{parseCurrency(calculation.shadowNii)}</Typography>
          </div>
        </div>

        <Box className={classes.grid}>
          <Box className={classes.cols}>
            <StyledDiv setjustify="flex-start" setpaddingbottom="0px" setborderbottom="none">
              <Typography className={classes.typography14px27}>{t("GENERAL.INTERCHANGE")}</Typography>
              <Typography className={classes.typography14pxNormal}>:</Typography>
              <Typography className={classes.typography14pxBold25}>
                {parseCurrency(calculation.cost.interchange)}
              </Typography>
            </StyledDiv>

            <StyledDiv setjustify="flex-start" setpaddingbottom="0px" setborderbottom="none">
              <Typography className={classes.typography14px27}>{t("GENERAL.INTERCHANGE_NPG")}</Typography>
              <Typography className={classes.typography14pxNormal}>:</Typography>
              <Typography className={classes.typography14pxBold25}>{parseCurrency(calculation.cost.npg)}</Typography>
            </StyledDiv>

            <StyledDiv setjustify="flex-start" setpaddingbottom="0px" setborderbottom="none">
              <Typography className={classes.typography14px27}>{t("GENERAL.SWITCHING_FEE")}</Typography>
              <Typography className={classes.typography14pxNormal}>:</Typography>
              <Typography className={classes.typography14pxBold25}>{parseCurrency(calculation.cost.fee)}</Typography>
            </StyledDiv>

            <Controller
              control={control}
              name="cost.edcCost"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.revenueIsEmpty}
                  title={t("GENERAL.EDC_COST")}
                  placeholder="No Of EDC"
                  calculation={parseCurrency(calculation.cost.edc_cost)}
                  onChange={e => handleInputNumberOnChange(field.name, e.target.value)}
                  adornment={null}
                />
              )}
            />

            <Controller
              control={control}
              name="cost.edcCharge"
              render={({ field }) => (
                <TextInputCalculation
                  {...field}
                  disabled={calculation.revenueIsEmpty}
                  onChange={e => handleInputNumberOnChange(field.name, Number(e.target.value))}
                  title={t("GENERAL.EDC_CHARGE")}
                  placeholder="No Of EDC"
                  calculation={parseCurrency(calculation.cost.edc_charge)}
                  adornment={null}
                />
              )}
            />
          </Box>

          <Box className={classes.boxNormalColumn}>
            <Box className={classes.boxBorderDashed}>
              <Box className={classes.boxAbsoluteLeft}>
                <img src={calculatorSquare} alt="calculatorIcon" />
              </Box>
              <Box className={classes.boxAbsoluteLeft119}>
                <Typography className={classes.typography14pxGrayHard}>{t("GENERAL.NET_REVENUE")}</Typography>
                <Typography className={classes.typography24pxPrimaryDark}>
                  {parseCurrency(calculation.netRevenue)}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.boxBorderDashed}>
              <Box className={classes.boxAbsoluteLeft}>
                <img src={countSquare} alt="calculatorIcon" />
              </Box>
              <Box className={classes.boxAbsoluteLeft119}>
                <Typography className={classes.typography14pxGrayHard}>{t("GENERAL.NET_REVENUE_SHADOW")}</Typography>
                <Typography className={classes.typography24pxPrimaryDark}>
                  {parseCurrency(calculation.netRevenueShadow)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Button variant="outlined" className={classes.btnReset} onClick={() => reset()}>
          {t("GENERAL.RESET")}
        </Button>
      </Card>

      <Card className={classes.cardBottom}>
        <Button
          className={classes.buttonContained}
          onClick={() => setPopUp("confirmation")}
          disabled={!queryExistingCalculation.isSuccess}
        >
          {t("GENERAL.SAVE_CALCULATION")}
        </Button>
      </Card>
    </form>
  );
};

export { CardCalculationProfitLoss };
