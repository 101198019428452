import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  title: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: "500",
    lineHeight: "20px",
    color: COLORS.DARK_HARD,
  },
  subtitle: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: "400",
    lineHeight: "14px",
    color: COLORS.GRAY_PRIMARY,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
});

const TitleCharts = ({ textTitle, textSubtitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{textTitle}</Typography>
      <Typography className={classes.subtitle}>{textSubtitle}</Typography>
    </div>
  );
};

TitleCharts.propTypes = {
  textTitle: PropTypes.string.isRequired,
  textSubtitle: PropTypes.string.isRequired,
};

export default TitleCharts;
