import { makeStyles } from "@material-ui/core";
import React from "react";
// Component
import CardAvgApplication from "components/card/card-average-application";
import CardBackToSales from "components/card/card-back-to-sales";
import CardDailyChartApplication from "components/card/card-daily-chart-application";
import { CardFilterDateIncoming } from "components/card/card-filter-date-incoming";
import CardTatProcess from "components/card/card-tat-process";
import CardTopArea from "components/card/card-top-area";
import CardTopIncoming from "components/card/card-top-incoming";
import ProsesOnboardingMerchantCard from "components/card/proses-onboarding-merchant";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  tatWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "15px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

const DashboardIncomingApp = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CardFilterDateIncoming />
      <CardDailyChartApplication />
      <ProsesOnboardingMerchantCard />
      <CardTatProcess />
      <div className={classes.tatWrapper}>
        <CardTopIncoming />
        <CardTopArea />
        <CardBackToSales />
      </div>
      <CardAvgApplication />
    </div>
  );
};
export default DashboardIncomingApp;
