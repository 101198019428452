import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const BoxTitle = ({ title }) => (
  <div style={{ marginBotom: "10px" }}>
    <Typography align="center">{title}</Typography>
  </div>
);

BoxTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BoxTitle;
