import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { CardFilterDetailProfitibility } from "components/card/card-filter-detail-profitibility";
import { ServerErrorBoundary } from "components/general";
import { DOWNLOAD_FEATURES, PopupDownload } from "components/popup/popup-download";
import DetailProfitibility from "components/table/detail-profotibility/detail-profitibility";
import { Button } from "elements/button";
import { CardBase } from "elements/card";
import LoadingSpinner from "elements/loading/spinner";
import { PagePagination } from "elements/pagination/page-pagination";
import { useAbortController } from "hooks/useAbortController";
import {
  getListDetailProfitibility,
  getDataListMcc,
  downloadListProfitibilityDetail,
  getDataSalesArea,
} from "services/api/private-routes/dashboard-profitibility-detail";
import { getSelectSalesName } from "services/api/private-routes/incoming-application";
import { useStyles } from "./styled";

const trbOptions = [
  {
    id: 1,
    target_code: "all",
    target_name: "All",
  },
  {
    id: 2,
    target_code: "have trb",
    target_name: "Have TRB",
  },
  {
    id: 3,
    target_code: "zero trb",
    target_name: "Zero TRB",
  },
];

const DashboardDetailProfitability = () => {
  const { t } = useTranslation();
  const { monthly } = useParams();
  const param = monthly.split("-");

  const INITIAL_FILTER = {
    filters: {
      sales_area: null,
      trb: trbOptions.find(x => x.target_code.includes(param[2]))?.target_code,
      profit: param[3] === "profit" ? "profit" : "not profit",
      sales: null,
      mcc: null,
      monthly: `${param[0]}-${param[1]}`,
      monthly_request: `${param[4]}-${param[5]}`,
    },
    search: "",
    type_search: "branding",
    sorts: {
      direction: null,
      key: null,
    },
    page: 1,
    size: 15,
  };

  const translations = useMemo(
    () => ({
      radio: {
        nominal: t("DETAIL_TRANSACTION.NOMINAL"),
        percentage: t("DETAIL_TRANSACTION.PERCENTAGE"),
      },
      toggle: {
        branding: t("GENERAL.BRANDING"),
        merchant: t("GENERAL.MERCHANT"),
      },
      searchBar: {
        branding: t("GENERAL.BRANDING"),
        group: t("GENERAL.GROUP"),
      },
      annualFilter: {
        year: t("GENERAL.YEARLY"),
        month: t("GENERAL.MONTHLY"),
        onGoing: t("GENERAL.ONGOING"),
      },
    }),
    [t],
  );

  const sortName = [
    "brandingName",
    "merchantName",
    "profit",
    "trb",
    "statusEdc",
    "salesCode",
    "mdrRevenue",
    "interchangeFee",
    "edcCost",
    "totalProfit",
    "mccGroup",
  ];

  const classes = useStyles();
  const { signal } = useAbortController();
  const queryClient = useQueryClient();
  const [activeToggle, setActiveToggle] = useState("branding");
  const [popUpDownloadOpen, setPopUpDownloadOpen] = useState(false);
  const [filter, setFilter] = useState(INITIAL_FILTER);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const listDetailProfitibility = useQuery(
    ["list-detail-profitibility", filter],
    () => getListDetailProfitibility(filter),
    signal,
    {
      retry: 0,
    },
  );

  const listSalesName = useQuery(["list-sales-name"], () => getSelectSalesName());
  const listMccGroup = useQuery(["list-mcc-group"], () => getDataListMcc(signal));
  const salesAreaLogin = useQuery(["sales-area-login"], () => getDataSalesArea(), { retry: 0 });

  const downloadExcelOutlet = useMutation(() => downloadListProfitibilityDetail(filter), {
    onSettled: () => queryClient.invalidateQueries(["download"]),
  });

  const activeQueryToggle = useMemo(() => listDetailProfitibility, [listDetailProfitibility]);

  const tableContent = useMemo(() => {
    if (activeQueryToggle.isFetching) return <LoadingSpinner height={650} />;
    if (activeQueryToggle.isError)
      return (
        <ServerErrorBoundary onClick={() => activeQueryToggle.refetch()} title={activeQueryToggle.error?.message} />
      );
    const render = activeQueryToggle.data;
    if (!render) return null;
    return (
      <DetailProfitibility
        data={render.data}
        monthly={monthly}
        headers={sortName}
        mainTableHead={translations.toggle[activeToggle]}
        subHeaders={render.subHeaders}
        onSort={sorts => {
          setFilter(prevState => {
            return {
              ...prevState,
              sorts,
            };
          });
        }}
      />
    );
  }, [activeQueryToggle, activeToggle, translations, monthly, sortName]);

  return (
    <div className={classes.page}>
      <PopupDownload
        isOpen={popUpDownloadOpen}
        onClose={() => setPopUpDownloadOpen(false)}
        feature={DOWNLOAD_FEATURES.PROFITIBILITY_DETAIL_BRANDING}
        onSubmit={downloadExcelOutlet.mutate}
        showBtnPrint={false}
      />

      <div className={classes.buttonWrapper}>
        <Button startIcon={<IconDownload />} variant="primary" onClick={() => setPopUpDownloadOpen(true)} defaultButton>
          {t("GENERAL.DOWNLOAD_EXCEL")}
        </Button>
      </div>

      <CardFilterDetailProfitibility
        listSalesName={listSalesName}
        salesAreaLogin={salesAreaLogin}
        listMccGroup={listMccGroup}
        setActiveToggle={toggle => {
          setFilter(INITIAL_FILTER);
          setActiveToggle(toggle);
        }}
        onApply={applyFilter => {
          setFilter({
            filters: {
              monthly: `${param[0]}-${param[1]}`,
              mcc: applyFilter?.mcc?.id !== "all" || applyFilter?.mcc !== "all" ? applyFilter?.mcc?.id : null,
              profit: applyFilter?.chip?.target_code !== "all" ? applyFilter?.chip?.target_code : "all",
              sales:
                applyFilter?.sales === null
                  ? null
                  : applyFilter?.sales?.code !== "all"
                  ? applyFilter?.sales?.code
                  : null,
              trb: applyFilter?.type?.target_code !== "all" ? applyFilter?.type?.target_code : "all",
              sales_area: applyFilter?.filterSalesArea?.id ? applyFilter?.filterSalesArea?.id : null,
            },
            search: applyFilter?.search,
            type_search: applyFilter?.typeSearch?.toLowerCase(),
            sorts: {
              direction: null,
              key: null,
            },
            page: 0,
            size: 15,
          });
        }}
      />

      <CardBase flex>{tableContent}</CardBase>

      {!activeQueryToggle.isFetching && activeQueryToggle.isSuccess && (
        <PagePagination
          size={activeQueryToggle.data.page.size}
          count={activeQueryToggle.data.page.dataCount}
          totalPage={activeQueryToggle.data.page.totalPage}
          page={activeQueryToggle.data.page.number}
          onChange={page => {
            setFilter(prevState => {
              return {
                ...prevState,
                page: page !== null ? page : 1,
              };
            });
          }}
        />
      )}
    </div>
  );
};

export default DashboardDetailProfitability;
