/* eslint-disable react/no-array-index-key */
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IconSortDown from "assets/icons/general/icon-sort-asc.svg";
import IconSortUp from "assets/icons/general/icon-sort-desc.svg";
import TableCellOption from "elements/table-cell-option";
import COLORS from "utils/constants/colors";
import { useStyles } from "./styles";

const BrandingTable = ({ header, body, branding, onSort, fields, cellOptions, stickyColumn }) => {
  const classes = useStyles();

  function handleSort({ key, direction }) {
    onSort({ key, direction });
  }

  const headersList = fields.map((head, index) => {
    const headerLabel = header.find(x => x.name === head)?.label;
    const headerName = header.find(x => x.name === head)?.name;
    const sortingCondition = header.find(x => x.name === head)?.isSort;
    const mLeft = index !== 1 ? 200 * index : 150;
    const stickyCondition = stickyColumn && stickyColumn.includes(headerName);
    return (
      <TableCell
        style={{ left: stickyCondition && mLeft }}
        className={
          stickyCondition && index === 0
            ? [classes.stickyHeader, classes.tableCellReguler11]
            : stickyCondition && index !== 0
            ? [classes.stickyHeaderSecondColumn, classes.tableCellReguler11]
            : classes.tableCellReguler11
        }
        key={index}
      >
        <div className={classes.subHeaderWrapper}>
          {headerLabel || ""}
          {sortingCondition && (
            <div className={classes.iconSortStyle}>
              <IconButton
                className={classes.iconButtonStyle}
                onClick={() => handleSort({ key: headerLabel, direction: "ASC" })}
              >
                <img src={IconSortUp} alt="icon-sort-up" width={11} />
              </IconButton>
              <IconButton
                className={classes.iconButtonStyle}
                onClick={() => handleSort({ key: headerLabel, direction: "DESC" })}
              >
                <img src={IconSortDown} alt="icon-sort-down" width={11} />
              </IconButton>
            </div>
          )}
        </div>
      </TableCell>
    );
  });

  function Content({ data, evenOdd }) {
    const contentList = fields?.map((content, index) => (
      <React.Fragment key={index}>
        {!!branding && index === 0 ? null : (
          <TableCell
            style={{
              left: stickyColumn && stickyColumn?.includes(content) && index !== 1 ? 200 * index : 150,
              background: evenOdd && COLORS.WHITE,
            }}
            className={
              stickyColumn && stickyColumn?.includes(content) && index === 0
                ? [classes.stickyColumn, classes.tableCellReguler10]
                : stickyColumn && stickyColumn?.includes(content) && index !== 0
                ? [classes.stickyHeaderSecondColumn, classes.tableCellReguler10]
                : classes.tableCellReguler10Top
            }
          >
            <TableCellOption options={cellOptions[index]} value={data[content]} />
          </TableCell>
        )}
      </React.Fragment>
    ));

    return contentList;
  }

  const contentList =
    body?.length > 0 ? (
      body?.map((content, index) => {
        const evenIndex = (index + 1) % 2 === 1;
        return (
          <TableRow key={index} className={classes.tableRow}>
            {!!branding && index === 0 ? (
              <TableCell
                rowSpan={body.length}
                style={{ left: stickyColumn && 200 * index, background: COLORS.WHITE }}
                className={stickyColumn ? [classes.stickyColumn, classes.tableCellBranding] : classes.tableCellBranding}
              >
                {branding}
              </TableCell>
            ) : null}
            <Content data={content} evenOdd={evenIndex} />
          </TableRow>
        );
      })
    ) : (
      <TableCell colSpan={fields.length} className={classes.emptyTable}>
        data is empty
      </TableCell>
    );

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>{headersList}</TableRow>
        </TableHead>
        <TableBody>{contentList}</TableBody>
      </Table>
    </TableContainer>
  );
};

BrandingTable.propTypes = {
  header: PropTypes.array.isRequired,
  body: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  branding: PropTypes.string,
  fields: PropTypes.array.isRequired,
  cellOptions: PropTypes.array.isRequired,
  stickyColumn: PropTypes.array,
};

BrandingTable.defaultProps = {
  branding: undefined,
  stickyColumn: undefined,
};

export default BrandingTable;
