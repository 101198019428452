import { axiosPrivate } from "../axiosPrivate";

const getDataTatProcess = async ({ salesName, device, salesArea, start_date, end_date }) => {
  const { data: responseBody, status } = await axiosPrivate.post(`/dashboard/incoming/application/tat`, {
    start_date,
    end_date,
    sales_user_code: salesName?.code || "all",
    sales_area_code: salesArea?.code || "all",
    device_type: device?.code || "all",
  });

  if (status !== 200) throw new Error(`[getDataTatProcess] ${status}`);

  const rawData = responseBody.data;
  const parser = [];
  Object.keys(rawData).forEach(key =>
    parser.push({
      title: key,
      contents: rawData[key],
    }),
  );

  return { responseBody, parser };
};

const getDataTatCity = async () => {
  const { data: TATCity, status } = await axiosPrivate.get(`/dashboard/incoming/application/business-area/list`);
  if (status !== 200) throw new Error(`[getDataTatCity] ${status}`);

  let response = TATCity.data.map(item => {
    return {
      code: item.businessAreaCode,
      name: item.businessArea,
    };
  });

  response = [{ code: "all", name: "Select All" }, ...response];
  return { response, TATCity };
};

const getDataTatDevice = async () => {
  const { data, status } = await axiosPrivate.get(`/dashboard/incoming/application/device/list`);

  if (status !== 200) throw new Error(`[getDataTatDevice] ${status}`);

  let dataResponse = data?.data?.map(items => {
    return {
      code: items.code,
      name: items.device,
    };
  });

  dataResponse = [{ code: "all", name: "Select All" }, ...dataResponse];
  return { data: dataResponse };
};

const getDataTatSales = async () => {
  const { data: salesName, status } = await axiosPrivate.get(`dashboard/incoming/application/sales-name/list`);

  if (status !== 200) throw new Error(`[getDataTatSales] ${status}`);

  let responseSalesName = salesName.data.map(item => {
    return {
      code: item.sales_user_code,
      name: item.sales_user_name,
    };
  });
  responseSalesName = [{ code: "all", name: "Select All" }, ...responseSalesName];
  return { responseSalesName, salesName };
};

export { getDataTatProcess, getDataTatCity, getDataTatDevice, getDataTatSales };
