import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const NEGATIVE_CASE = [
  {
    type: "Debit On Us",
    value: 0,
    num: 0,
  },
  {
    type: "Debit Off Us",
    value: 0,
    num: 0,
  },
  {
    type: "Credit Off Us",
    value: 0,
    num: 0,
  },
  {
    type: "Credit On Us",
    value: 0,
    num: 0,
  },
];

const getDataChartSalesVolumeTransaction = async signal => {
  const { data: responseBody, status } = await axiosPrivate.get(
    ENDPOINT.API_DASHBOARD.PORTOFOLIO.SALES_VOLUME_TRANSACTION_TYPE,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartSalesVolumeTransaction] ${status}`);
  const { data } = responseBody;
  const parsedData = {
    percentage: data?.percentage ? data?.percentage : 0,
    data: data
      ? [
          {
            type: "Debit On Us",
            value: data.debit_on_us_total ? data.debit_on_us_total : 0,
            num: data.debit_on_us ? data.debit_on_us : 0,
          },
          {
            type: "Debit Off Us",
            value: data.debit_off_us_total ? data.debit_off_us_total : 0,
            num: data.debit_off_us ? data.debit_off_us : 0,
          },
          {
            type: "Credit Off Us",
            value: data.credit_off_us_total ? data.credit_off_us_total : 0,
            num: data.credit_off_us ? data.credit_off_us : 0,
          },
          {
            type: "Credit On Us",
            value: data.credit_on_us_total ? data.credit_on_us_total : 0,
            num: data.credit_on_us ? data.credit_on_us : 0,
          },
        ]
      : NEGATIVE_CASE,
  };

  return parsedData;
};

export { getDataChartSalesVolumeTransaction };
