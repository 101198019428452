import { useEffect, useState, useRef } from "react";

const format = num => {
  const date = new Date(num);
  return {
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  };
};

const useCountDown = ({ target, onTimeOut, timeStart }) => {
  const ref = useRef(0);
  const targetTime = new Date(target).getTime();
  const isExpired = new Date(timeStart).getTime() > new Date(targetTime).getTime();
  const [state, setState] = useState(isExpired ? 0 : new Date(targetTime) - new Date(timeStart));

  useEffect(() => {
    const clear = () => {
      window.clearInterval(ref.current);
      ref.current = null;
    };
    const start = () => {
      ref.current = window.setInterval(() => {
        setState(old => {
          const nextValue = old - 1000;
          if (nextValue <= 0) {
            if (onTimeOut) onTimeOut();
            clear();
            setState(0);
            return 0;
          }
          return nextValue;
        });
      }, 1000);
    };

    setState(isExpired ? 0 : new Date(targetTime) - new Date(timeStart));
    if (state > 0) start();

    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, onTimeOut, timeStart, setState, targetTime, isExpired]);

  return format(state);
};

const convertTwoDigits = num => (String(num).length === 1 ? `0${num}` : num);

export { useCountDown, convertTwoDigits };
