import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const LineIconArrowRight = ({ fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9L5 5L1 1" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

LineIconArrowRight.defaultProp = {
  fill: COLORS.WHITE,
};

LineIconArrowRight.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default LineIconArrowRight;
