import { makeStyles } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  table: {
    width: "100%",
    borderColor: "transparent",
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableRow: {
    padding: "4px 10px",
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  tableCellCheckbox: {
    padding: "10px",
  },
  checkbox: {
    borderRadius: "4px",
    color: COLORS.GRAY_MEDIUM,
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
    "& path": {
      backgroundColor: COLORS.WHITE,
    },
  },
  tableCellHead: {
    fontWeight: 600,
    fontSize: SIZES.REGULAR_12,
    lineHeight: "18px",
  },
  tableCellSemiBold: {
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "14px",
  },
  tableCellReguler: {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
  },
  statusRejected: {
    display: "inline-block",
    color: "#e32d2d",
    backgroundColor: "#ffe6e6",
    textAlign: "center",
    gap: "4x",
    borderRadius: "4px",
    alignItems: "center",
    padding: "4px 10px 4px 8px",
    fontWeight: "500",
    fontSize: "12px",
  },
  statusSubmission: {
    display: "inline-block",
    color: "#cb88ff",
    backgroundColor: "#f5e9ff",
    textAlign: "center",
    gap: "4x",
    borderRadius: "4px",
    alignItems: "center",
    padding: "4px 10px 4px 8px",
    fontWeight: "500",
    fontSize: "12px",
  },
  statusActive: {
    display: "inline-block",
    color: "#6dd13e",
    backgroundColor: "#e6ffda",
    textAlign: "center",
    gap: "4x",
    borderRadius: "4px",
    alignItems: "center",
    padding: "4px 10px 4px 8px",
    fontWeight: "500",
    fontSize: "12px",
  },
  statusNonActive: {
    display: "inline-block",
    color: "#ffb443",
    backgroundColor: "#ffecd1",
    textAlign: "center",
    gap: "4x",
    borderRadius: "4px",
    alignItems: "center",
    padding: "4px 10px 4px 8px",
    fontWeight: "500",
    fontSize: "12px",
  },
});

export { useStyles };
