import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Components
import IconLockSuccess from "assets/icons/popup/icon-lock-success.svg";
import IconLockWarning from "assets/icons/popup/icon-lock-warning.svg";
import IconStateFailed from "assets/icons/popup/icon-state-failed.svg";
import IconStateSuccess from "assets/icons/popup/icon-state-success.svg";
import IconUserMultipleDenied from "assets/icons/popup/icon-user-multiple-denied.svg";
import IconUserMultipleFailed from "assets/icons/popup/icon-user-multiple-failed.svg";
import IconUserMultipleSuccess from "assets/icons/popup/icon-user-multiple-success.svg";
import IconUserMultipleWarning from "assets/icons/popup/icon-user-multiple-warning.svg";
import { ReactComponent as IconDownload } from "assets/icons/user-management/icon-download-red.svg";
import { ReactComponent as IconFileText } from "assets/icons/user-management/icon-file-text.svg";
import { ReactComponent as IconUpload } from "assets/icons/user-management/icon-upload.svg";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupUpload from "components/popup/popup-upload";
import SidePopUp from "components/popup/side-popup";
import UserManagementTable from "components/table/user-management-table";
import ToasterUserManagement from "components/toaster/toaster-user-management";
import TextToggleButton from "elements/button/button-toggle-text";
import TextfieldSearch from "elements/input/text-field-search";
import { PagePagination } from "elements/pagination/page-pagination";
import useTimeOut from "hooks/useTimeOut";
import {
  downloadExcelTemplate,
  getListUser,
  rejectUser,
  resetOtpUser,
  updateStatusUser,
  uploadExcel,
  downloadUserData,
} from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import UserManagementProvider from "./user-management-context";

const useStyles = makeStyles({
  buttonContained: {
    borderRadius: "10px",
    gap: "10px",
    fontWeight: 500,
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    padding: "10px 18px 10px 14px",
    textTransform: "none",
  },
  buttonOutlined: {
    borderRadius: "10px",
    gap: "10px",
    fontWeight: 500,
    border: `2px solid ${COLORS.PRIMARY_DARK}`,
    color: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    padding: "10px 18px 10px 14px",
    textTransform: "none",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "43px",
  },
  margin20: {
    marginRight: "20px",
  },
  centerWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchWrapper: {
    marginTop: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "15px",
    border: `1px solid ${COLORS.GRAY_SOFT}`,
    boxShadow: "0px 3px 10px rgba(188, 200, 231, 0.25)",
  },
  dividerWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableWrapper: {
    borderRadius: "15px",
    backgroundColor: COLORS.WHITE,
    padding: "20px",
    marginTop: "30px",
    marginBottom: "30px",
    boxShadow: "0px 3px 10px rgba(188, 200, 231, 0.25)",
  },
  rowsPerPage: {
    fontWeight: 400,
    fontSize: SIZES.REGULAR_16,
    lineHeight: "24px",
    color: COLORS.GRAY_SECONDARY,
  },
});

const TOGGLE_ITEMS = ["All", "Submission", "Rejected", "Active", "Non-Active"];

const POPOVER_INITIAL = {
  title: null,
  subtitle: null,
  description: null,
  buttonText: null,
  submitButtonText: null,
  onSubmit: null,
  cancelButtonText: null,
  icon: null,
  typePopover: null, // "info" | "confirm" | "reject-input"
};

const CHECKBOX_INITIAL = {
  checkedAll: false,
  checked: {},
  type: "",
};

const UserManagement = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setActive } = useCIMBLoading();

  // content toggle & popup
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterName, setFilterName] = useState("");

  const [checkbox, setCheckbox] = useState(CHECKBOX_INITIAL);
  const [sidePopupData, setSidePopupData] = useState({
    data: [],
    open: false,
  });

  // Popover
  const [popover, setPopover] = useState(POPOVER_INITIAL);

  // toggle button
  const [filterStatus, setFilterStatus] = useState("All");

  /* ------------------------- QUERY USERS MANAGEMENT ------------------------- */
  const listUser = useQuery(
    ["list-user", currentPage, filterStatus, filterName],
    () => getListUser({ currentPage, filterStatus, filterName }),
    { retry: 0, refetchOnMount: true },
  );

  /* ------------------------- DOWNLOAD EXCEL TEMPLATE ------------------------ */
  const mutateDownloadExcelFormat = useMutation(downloadExcelTemplate);

  // popup
  const [openToast, setOpenToast] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  useEffect(() => {
    setOpenToast(Object.keys(checkbox.checked).length > 0 && !sidePopupData.open);
  }, [checkbox, sidePopupData, setOpenToast]);

  const onCloseToast = () => {
    setOpenToast(false);
    setCheckbox(CHECKBOX_INITIAL);
  };

  const handleClosePopUp = () => {
    if (file) setFile(null);
    onClosePopover();
    [setOpenToast, setOpenUpload].forEach(x => x(false));
  };

  // search ,filter & pagination handler
  const searchHandler = value => {
    setFilterName(value);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [filterStatus, filterName]);

  // button download & upload function
  const handlePrintButton = () => setOpenUpload(true);

  const uploadHandler = event => {
    const fileTarget = event.target.files[0];
    if (fileTarget) setFile(fileTarget);
  };

  const removeFileHandler = () => setFile(null);

  const submitUploadHandler = () => {
    if (!file) return;
    const formData = new FormData();
    formData.set("file", file);
    mutationUploadExcel.mutate({ formData });
  };

  const resetOtpSubmit = useMutation(resetOtpUser, {
    onMutate: () => setActive(true),
    onSuccess: () => {
      queryClient.invalidateQueries("list-user");
      popoverSuccessResetOTP();
    },
    onSettled: () => setActive(false),
  });

  const onDeactivatedUser = useMutation(updateStatusUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-user");
      popoverDeactiveUser();
    },
  });

  const onActivatedUser = useMutation(updateStatusUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-user");
      popoverActivatedUser();
    },
  });

  const mutationRejectUser = useMutation(rejectUser, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries("list-user");
      popoverInfoSuccessRejectUser({ total_data: data?.data?.total_data });
    },
    onError: () => popoverInfoFailedRejectUser(),
  });

  const [errorData, setErrorData] = useState([]);

  const mutationUploadExcel = useMutation(uploadExcel, {
    onMutate: () => setActive(true),
    onSuccess: res => {
      if (res.invalid) {
        popoverInfoUploadFailed({
          subtitle: res.data.response_code,
          description: res.data.response_message,
        });
        setErrorData(res.data.data);
        return;
      }
      handleClosePopUp();
      popoverInfoUploadSuccess();
    },
    onSettled: () => {
      setFile(null);
      setActive(false);
    },
  });

  const mutationDownloadUserData = useMutation(downloadUserData);

  const onNavigateInvalid = data => navigate("/invalid-data", { state: { data } });

  const changePopoverState = ({ ...props }) => setPopover({ ...POPOVER_INITIAL, ...props });
  const onClosePopover = () => {
    setCheckbox(CHECKBOX_INITIAL);
    setPopover(POPOVER_INITIAL);
  };

  const popoverInfoUploadSuccess = () =>
    changePopoverState({
      icon: IconStateSuccess,
      title: t("USER_MANAGEMENT.UPLOAD_SUCCESS"),
      description: t("USER_MANAGEMENT.DATA_UPLOAD_SUCCESS"),
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverInfoUploadFailed = ({ subtitle, description }) =>
    changePopoverState({
      icon: IconStateFailed,
      title: t("USER_MANAGEMENT.UPLOAD_FAILED"),
      subtitle,
      description,
      buttonText: t("GENERAL.SHOW_DETAIL"),
      typePopover: "info",
    });

  const popoverSuccessResetOTP = () =>
    changePopoverState({
      icon: IconLockSuccess,
      title: t("DESCRIPTION.OTP_SUCCESS"),
      subtitle: false,
      description: t("DESCRIPTION.USER_CAN_REQUEST_NEW_OTP"),
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverDeactiveUser = () =>
    changePopoverState({
      icon: IconUserMultipleSuccess,
      title: t("DESCRIPTION.DEACTIVATION_SUCCESS"),
      subtitle: false,
      description: t("DESCRIPTION.THIS_USER_NON_ACTIVE"),
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverActivatedUser = () =>
    changePopoverState({
      icon: IconUserMultipleSuccess,
      title: t("DESCRIPTION.ACTIVATION_SUCCESS"),
      subtitle: false,
      description: t("DESCRIPTION.THIS_USER_ACTIVE"),
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverInfoSuccessRejectUser = ({ total_data }) =>
    changePopoverState({
      icon: IconUserMultipleDenied,
      title: t("USER_MANAGEMENT.REJECT_SUCCESS"),
      subtitle: `${total_data} ${t("USER_MANAGEMENT.USER_DATA_REJECTED")}`,
      description: `${t("USER_MANAGEMENT.POPUP_SUCCESFULLY_REJECTED")} ${total_data} ${t(
        "USER.MANAGEMENT.POPUP_USER_DATA",
      )}`,
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverInfoFailedRejectUser = () =>
    changePopoverState({
      icon: IconUserMultipleFailed,
      title: t("USER_MANAGEMENT.REJECTION_FAILED_TITLE"),
      subtitle: `${t("USER_MANAGEMENT.REJECTION_FAILED_DESC")}`,
      description: `${t("USER_MANAGEMENT.POPUP_SUCCESFULLY_REJECTED")} ${t("USER.MANAGEMENT.POPUP_USER_DATA")}`,
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverTurnOffAccount = ({ id }) =>
    changePopoverState({
      icon: IconUserMultipleWarning,
      title: t("GENERAL.CONFIRMATION"),
      subtitle: t("DESCRIPTION.CONFIRM_DEACTIVE_ACCOUNT"),
      description: t("DESCRIPTION.INFORMATION"),
      submitButtonText: t("GENERAL.TURN_OFF"),
      cancelButtonText: t("GENERAL.CANCEL"),
      typePopover: "confirm",
      onSubmit: () => onDeactivatedUser.mutate({ id, userStatus: "N" }),
    });

  const popoverConfirmationResetOTP = ({ id }) =>
    changePopoverState({
      icon: IconLockWarning,
      title: t("GENERAL.CONFIRMATION"),
      subtitle: t("DESCRIPTION.CONFIRM_RESET_OTP_USER"),
      description: t("DESCRIPTION.INFORMATION"),
      submitButtonText: t("GENERAL.RESET_OTP"),
      cancelButtonText: t("GENERAL.CANCEL"),
      typePopover: "confirm",
      onSubmit: () => resetOtpSubmit.mutate({ id }),
    });

  const popoverConfirmationRejectUser = ({ data }) => {
    setOpenToast(false);
    changePopoverState({
      icon: IconUserMultipleWarning,
      title: t("GENERAL.CONFIRMATION"),
      subtitle: `${t("USER.MANAGEMENT.POPUP_REJECT_CONFIRMATION")} ${data?.length} ${t(
        "USER.MANAGEMENT.POPUP_USER_DATA?",
      )}`,
      description: t("DESCRIPTION.INFORMATION"),
      submitButtonText: t("USER_MANAGEMENT.POPUP_YES_REJECT"),
      cancelButtonText: t("GENERAL.CANCEL"),
      typePopover: "confirm",
      onSubmit: () => {
        popoverRejectFieldInput({ id: data });
      },
    });
  };

  const popoverConfirmationApproveUser = ({ id }) => {
    changePopoverState({
      icon: IconUserMultipleWarning,
      title: t("GENERAL.CONFIRMATION"),
      subtitle: t("DESCRIPTION.CONFIRM_ACTIVE_ACCOUNT"),
      description: t("DESCRIPTION.INFORMATION"),
      submitButtonText: t("GENERAL.TURN_ON"),
      cancelButtonText: t("GENERAL.CANCEL"),
      typePopover: "confirm",
      onSubmit: () => onActivatedUser.mutate({ id, userStatus: "A" }),
    });
  };

  const popoverInternalServerError = () =>
    changePopoverState({
      icon: IconStateFailed,
      title: t("GENERAL.ERROR"),
      subtitle: t("GENERAL.REQUEST_FAILED"),
      description: `${t("GENERAL.ERROR")}. ${t("GENERAL.TRY_AGAIN")}`,
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverInfoApprovedUserData = ({ length }) =>
    changePopoverState({
      icon: IconUserMultipleSuccess,
      title: t("USER_MANAGEMENT.ACCEPT_SUCCESS"),
      subtitle: `${length} ${t("USER_MANAGEMENT.USER_DATA_APPROVED")}`,
      description: `${t("USER_MANAGEMENT.POPUP_SUCCESFULLY_ACCEPTED")} ${length} ${t(
        "USER.MANAGEMENT.POPUP_USER_DATA",
      )}`,
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverInfoApprovedUserDataFailed = ({ length }) =>
    changePopoverState({
      icon: IconUserMultipleFailed,
      title: t("USER_MANAGEMENT.FAILED_ACCEPT"),
      subtitle: `${length} ${t("USER_MANAGEMENT.SUB_TITLE_FAILED_ACCEPT")}`,
      description: `${t("USER_MANAGEMENT.DESC_FAILED_ACCEPT")} ${length} ${t("USER.MANAGEMENT.POPUP_USER_DATA")}`,
      buttonText: t("GENERAL.CLOSE"),
      typePopover: "info",
    });

  const popoverRejectFieldInput = ({ id }) => {
    changePopoverState({
      typePopover: "reject-input",
      onSubmit: rejectionReason => {
        const payload = typeof id === "string" ? [id] : id;
        mutationRejectUser.mutate({ id: payload, rejectionReason });
      },
    });
  };

  const onEditUser = ({ id }) => navigate(`/edit-user-management/${id}`);

  const handleApprove = ({ id }) => {
    const data = listUser?.data?.users.find(x => String(x.id) === String(id));
    if (!data) return;
    setSidePopupData({ data: [data], open: true });
  };

  useTimeOut({
    enable: popover.typePopover === "info",
    onTimeOut: () => setPopover(POPOVER_INITIAL),
  });

  return (
    <UserManagementProvider
      data-testid="user-management"
      popover={popover}
      onClosePopover={onClosePopover}
      onUploadTryAgain={handlePrintButton}
      onNavigateInvalid={() => onNavigateInvalid(errorData)}
      value={{
        onEditUser,
        popoverActivatedUser,
        popoverTurnOffAccount,
        popoverConfirmationResetOTP,
        handleApprove,
        popoverConfirmationRejectUser,
        popoverConfirmationApproveUser,
        popoverInfoApprovedUserData,
        popoverInfoApprovedUserDataFailed,
        popoverInternalServerError,

        // Checkbox
        checkbox,
        setCheckbox,

        // Sidebar
        sidePopupData,
        setSidePopupData,

        listUser,
      }}
    >
      <div>
        <div className={classes.buttonWrapper}>
          <div className={classes.margin20}>
            <Button
              startIcon={<IconFileText />}
              variant="outlined"
              className={classes.buttonOutlined}
              onClick={mutateDownloadExcelFormat.mutate}
              disabled={mutateDownloadExcelFormat.isLoading}
            >
              {t("GENERAL.DOWNLOAD_FORMAT_EXCEL")}
            </Button>
          </div>
          <div className={classes.margin20}>
            <Button
              startIcon={<IconDownload />}
              variant="outlined"
              className={classes.buttonOutlined}
              onClick={() => mutationDownloadUserData.mutate()}
              disabled={mutationDownloadUserData.isLoading}
            >
              {t("GENERAL.DOWNLOAD_USER_RECORD")}
            </Button>
          </div>
          <Button
            startIcon={<IconUpload />}
            variant="contained"
            className={classes.buttonContained}
            onClick={handlePrintButton}
          >
            {t("GENERAL.UPLOAD_EXCEL")}
          </Button>
        </div>
        <div className={classes.searchWrapper}>
          <div className={classes.dividerWrapper}>
            <div className={classes.margin20}>
              <TextfieldSearch placeholder={t("GENERAL.SEARCH")} onChange={searchHandler} value={filterName} />
            </div>
            <div className={classes.centerWrapper}>
              <TextToggleButton toggleData={TOGGLE_ITEMS} selected={filterStatus} onChange={setFilterStatus} />
            </div>
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <UserManagementTable data={listUser.data?.users || []} />
        </div>
        {!listUser.isFetching && listUser.isSuccess && (
          <PagePagination
            size={listUser.data.page.pageSize}
            count={listUser.data.rowCount}
            totalPage={listUser.data.totalPage}
            page={Number(listUser.data.page.pageNumber) + 1}
            onChange={value => setCurrentPage(value - 1)}
          />
        )}
        <PopupUpload
          isOpen={openUpload}
          onCancel={handleClosePopUp}
          onClose={handleClosePopUp}
          uploadButtonText={t("GENERAL.BROWSE_FILE")}
          submitButtonText={t("GENERAL.UPLOAD")}
          cancelButtonText={t("GENERAL.CANCEL")}
          title={t("GENERAL.UPLOAD_MASTER_DATA")}
          description={t("GENERAL.UPLOAD_FILE")}
          placeholder={t("GENERAL.BROWSE_UPLOAD_FILE")}
          onUpload={uploadHandler}
          fileName={file?.name}
          onRemoveFile={removeFileHandler}
          onSubmit={submitUploadHandler}
        />
        {sidePopupData.open && <SidePopUp />}
        <ToasterUserManagement isOpen={openToast} onClose={onCloseToast} />
      </div>
    </UserManagementProvider>
  );
};

export default UserManagement;
