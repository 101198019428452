import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const LineIconArrowUp = ({ fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 10L8 6L4 10" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

LineIconArrowUp.defaultProp = {
  fill: COLORS.WHITE,
};

LineIconArrowUp.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default LineIconArrowUp;
