import { makeStyles, Typography } from "@material-ui/core";
import { Axis, Chart, Interaction, Interval, Legend, Tooltip, Point } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
// Component
import { ServerErrorBoundary } from "components/general";
import LegendChartBar from "components/legend/legend-chart-bar";
// Color
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { negativeScaleBarChart } from "utils/helpers/useChartConfig";

const useStyles = makeStyles(() => ({
  error: {
    height: "360px",
  },
  wrapperTooltip: {
    height: "350px",
    width: "500px",
  },
  otherChip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  otherDot: {
    width: 12,
    height: 12,
    borderRadius: "50%",
  },
  otherText: {
    marginLeft: 3,
    fontSize: SIZES.REGULAR_12,
    fontWeight: 600,
    color: COLORS.GRAY_DARK,
  },
}));

const BarChartTooltip = ({
  data,
  legendView,
  heightChart,
  colors,
  labelCustom,
  positionName,
  axisName,
  positionNameDetail,
  axisNameDetail,
  pointValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const labelDefault = {
    rotate: 1 / 4,
    autoEllipsis: true,
    formatter(items) {
      const showText = items?.length > 16 ? `${items.substr(0, 16)}...` : items;
      return showText;
    },
    autoHide: false,
    style: {
      textAlign: "left",
      textBaseline: "top",
      fontSize: 10,
    },
  };

  if (data.isLoading) {
    return <LoadingSpinner height={350} />;
  }

  if (data.isError) {
    return <ServerErrorBoundary className={classes.error} onClick={() => data.refetch()} title={data.error?.message} />;
  }

  return (
    <div>
      <Chart
        padding={[30, 20, 60, 50]}
        autoFit
        scale={{
          total: { min: negativeScaleBarChart(data?.data?.list_data || []), type: "linear-strict" },
          nice: true,
          type: "linear",
        }}
        height={heightChart}
        data={data?.data?.list_data}
        theme={{ maxColumnWidth: 40 }}
      >
        <Interaction type="element-active" />
        <Axis
          name="total"
          grid={{
            align: "center",
            line: {
              type: "line",
              style: {
                lineWidth: 0,
              },
            },
          }}
        />
        <Axis name={axisName} label={labelDefault} />
        <Tooltip position="bottom">
          {(items, title) => {
            return (
              <div className={classes.wrapperTooltip}>
                <Chart
                  padding={[30, 20, 50, 50]}
                  autoFit
                  scale={{
                    total: { min: negativeScaleBarChart(title[0].data.detail_data || []), type: "linear-strict" },
                    nice: true,
                    type: "linear",
                  }}
                  height={heightChart}
                  data={title[0].data.detail_data}
                  theme={{ maxColumnWidth: 40 }}
                >
                  <Interaction type="element-active" />
                  <Axis
                    name="total"
                    grid={{
                      align: "center",
                      line: {
                        type: "line",
                        style: {
                          lineWidth: 0,
                        },
                      },
                    }}
                  />
                  <Axis
                    name={axisNameDetail}
                    label={title[0].data?.detail_data?.length !== 0 ? labelCustom : labelDefault}
                  />
                  <Interval
                    position={positionNameDetail}
                    color={title[0].data?.detail_data?.length !== 0 ? COLORS.GRAY_PRIMARY : ""}
                  />
                  <Legend visible={false} />
                </Chart>
              </div>
            );
          }}
        </Tooltip>
        {pointValue && (
          <Point
            position={positionName}
            label={[
              pointValue,
              val => ({
                content: String(val).replace(".", ","),
              }),
            ]}
            size={0}
          />
        )}
        <Interval position={positionName} color={data.length !== 0 ? colors : ""} />
        <Legend visible={false} />
      </Chart>
      {legendView === true ? <LegendChartBar /> : ""}
      <div className={classes.otherChip}>
        <div className={classes.otherDot} style={{ backgroundColor: colors }} />
        <Typography className={classes.otherText}>{`${t("GENERAL.OTHERS")} (${
          data?.data?.other_data?.bio
        })`}</Typography>
      </div>
    </div>
  );
};

BarChartTooltip.defaultProps = {
  data: [],
  legendView: true,
  heightChart: 500,
  colors: [COLORS.GREEN_MEDIUM, COLORS.SECONDARY_MEDIUM, COLORS.PRIMARY_HARD],
  labelCustom: {},
  positionName: "",
  axisName: "",
  isLoading: false,
  isError: false,
  axisNameDetail: "",
  positionNameDetail: "",
  pointValue: "",
};

BarChartTooltip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  legendView: PropTypes.bool,
  heightChart: PropTypes.number,
  colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelCustom: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  positionName: PropTypes.string,
  axisName: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  axisNameDetail: PropTypes.string,
  positionNameDetail: PropTypes.string,
  pointValue: PropTypes.string,
};

export default BarChartTooltip;
