import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useSignOut } from "react-auth-kit";
import { Navigate } from "react-router-dom";

const Logout = () => {
  const signOut = useSignOut();
  const queryClient = useQueryClient();

  const doLogout = useCallback(() => {
    queryClient.removeQueries();
    return signOut();
  }, [signOut, queryClient]);

  return doLogout() ? <Navigate to="/" /> : <p>Something wrong</p>;
};

export { Logout };
