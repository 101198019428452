export const VALUE_REVENUE_ALIASES = {
  net_revenue: "Net Revenue",
  nii_shadow: "Nii Shadow",
  total_revenue: "Total Revenue",
};

export const ALIASES_RESPONSE_KEYS = Object.keys(VALUE_REVENUE_ALIASES);

export const orderingKeys = (obj = {}, keys = ALIASES_RESPONSE_KEYS) =>
  keys.reduce((prev, curr) => ({ ...prev, [curr]: obj[curr] ?? null }), {});
