import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonContained from "elements/button/button-contained";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    gap: "4em",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    maxHeight: "100%",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "500",
  },
  button: {},
});

const ServerErrorBoundary = ({ title, button, className, onClick }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      {typeof title === "string" ? <Typography className={classes.title}>{title}</Typography> : title}
      {typeof title === "string" ? (
        <ButtonContained content={button} onClick={onClick} className={classes.button} />
      ) : (
        button
      )}
    </div>
  );
};

ServerErrorBoundary.defaultProps = {
  title: "Server error boundary",
  button: "Try again",
  className: "",
  onClick: undefined,
};

ServerErrorBoundary.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { ServerErrorBoundary };
