import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

// icons
import iconTop from "assets/icons/general/icon-rounded-rectangle-green.svg";
import iconMedium from "assets/icons/general/icon-rounded-rectangle-orange.svg";
import iconShort from "assets/icons/general/icon-rounded-rectangle-red.svg";

// Component
import LegendChartBarLineText from "components/legend/legend-chart-bar-text";

const LegendChartBar = () => {
  const { t } = useTranslation();
  return (
    <div style={{ justifyContent: "center" }}>
      <Grid container justifyContent="center" alignItems="center" direction="row" spacing={5}>
        <Grid item>
          <LegendChartBarLineText icon={iconTop} text={t("GENERAL.TOP")} dataTestId={t("GENERAL.TOP")} />
        </Grid>
        <Grid item>
          <LegendChartBarLineText icon={iconMedium} text={t("GENERAL.MEDIUM")} dataTestId={t("GENERAL.MEDIUM")} />
        </Grid>
        <Grid item>
          <LegendChartBarLineText icon={iconShort} text={t("GENERAL.SHORT")} dataTestId={t("GENERAL.SHORT")} />
        </Grid>
      </Grid>
    </div>
  );
};

export default LegendChartBar;
