import PropTypes from "prop-types";
import React from "react";
import { CustomSelect } from "elements/select";
import { useQuerySalesArea } from "./use-query-sales-area";

const KEY_SELECT_ALL = {
  name: "Select All",
};

const DropdwownSalesArea = ({ value, setValue }) => {
  const salesArea = useQuerySalesArea();

  return (
    <CustomSelect
      data={Array.isArray(salesArea.data?.data) ? [KEY_SELECT_ALL, ...salesArea.data.data] : []}
      renderItem={item => item.name}
      renderValue={item => item.name}
      value={value}
      setValue={setValue}
      placeholder="Sales Area"
      disabled={!salesArea.isSuccess}
    />
  );
};

DropdwownSalesArea.defaultProps = {
  value: undefined,
  setValue: undefined,
};
DropdwownSalesArea.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export { DropdwownSalesArea };
