import { useMutation, useQuery } from "@tanstack/react-query";
import {
  downloadExcel,
  getCategorySalesVolumeList,
  getSelectArcoHeadSalesVolume,
  getTableArco,
  getTableDivision,
  getSalesTable,
  getSelectDivHeadSalesVolume,
} from "services/api/private-routes";

export const useDownloadExcelSalesVolume = (filter, filterType) => {
  const { mutate, isLoading } = useMutation(() =>
    downloadExcel({ year: filter.year, filterType: filterType.filterType }),
  );

  return { mutate, isLoading };
};

export const useFetchCategorySalesVolumeList = (year, signal) => {
  const { data, isFetching, error } = useQuery(["category-sales-volume-list", year], () => {
    return getCategorySalesVolumeList(year, signal);
  });

  return { data, isFetching, error };
};

export const useFetchDataArcoHeadList = (translation, toggle, filter) => {
  const { data, isFetching, error } = useQuery(["arco-head-list", filter.year], () => {
    return getSelectArcoHeadSalesVolume({ year: filter.year });
  });

  return { data, isFetching, error };
};

export const useFetchDataByArco = (filter, translation, toggle, signal) => {
  const { data, isFetching, isError } = useQuery(
    ["table-arco", filter],
    () =>
      getTableArco(
        { year: filter.year, userCode: filter.value?.position_code, target: filter.target.target_code },
        signal,
      ),
    {
      enabled: toggle === translation.toggle.area,
      retry: 0,
      keepPreviousData: true,
    },
  );

  return { data, isFetching, isError };
};

export const useFetchDataByDivision = (filter, translation, toggle, signal) => {
  const { data, isFetching, error } = useQuery(
    ["table-division", filter.year, filter.target],
    () => getTableDivision({ year: filter.year, target: filter.target.target_code }, signal),
    {
      enabled: toggle === translation.toggle.division,
      retry: 0,
      keepPreviousData: true,
    },
  );

  return { data, isFetching, error };
};

export const useFetchDataSales = (filter, translation, toggle, signal) => {
  const { data, isFetching, error } = useQuery(
    ["table-sales", filter],
    () =>
      getSalesTable(
        { year: filter.year, userCode: filter.value?.position_code, target: filter.target.target_code },
        signal,
      ),
    {
      enabled: toggle === translation.toggle.sales,
      retry: 0,
      keepPreviousData: true,
    },
  );

  return { data, isFetching, error };
};

export const useFetchDivisionHeadList = (translation, toggle, filter) => {
  const { data, isFetching, error } = useQuery(
    ["division-head-list-casa", filter.year],
    () => getSelectDivHeadSalesVolume({ year: filter.year }),
    {
      enabled: toggle === translation.toggle.area,
      retry: 0,
    },
  );

  return { data, isFetching, error };
};
