import { makeStyles } from "@material-ui/core";
import { Axis, Chart, Legend, LineAdvance, Tooltip } from "bizcharts";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { negativeScaleCustomChart } from "utils/helpers/useChartConfig";
import { thousandFormat } from "utils/helpers/useFormater";

const useStyles = makeStyles(() => ({
  wrapperLegend: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "5px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
  arcoWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
}));

const ChartPortofolioLine = ({ data, customTooltip, handleClickPoint, axisname, axislabel, position, colors }) => {
  const classes = useStyles();

  const render = useMemo(() => {
    if (!Array.isArray(data)) return null;
    return data?.reduce((prev, curr) => {
      const parse = { ...curr, day: curr.date.split("/")[0] };
      return [...prev, parse];
    }, []);
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <Chart
        padding={[50, 50, 50, 50]}
        autoFit
        height={500}
        data={render || []}
        scale={{ total_bio: { min: negativeScaleCustomChart(render || []), type: "linear-strict" } }}
        onPointClick={evt => handleClickPoint(evt)}
      >
        <Tooltip showCrosshairs={false} shared={false} />
        <Axis name={axisname} label={axislabel} />
        <LineAdvance size={4} point position={position} color={colors} />
        <Tooltip position="bottom" showCrosshairs={false}>
          {!customTooltip
            ? (title, items) => {
                return (
                  <div className={classes.wrapper}>
                    <div className={classes.datesWrapper}>{items[0]?.data?.date ?? "-"}</div>
                    <div className={classes.valueWrapper}>{thousandFormat(items[0]?.data?.total_bio) ?? "-"}</div>
                  </div>
                );
              }
            : customTooltip}
        </Tooltip>
        <Legend visible={false} />
      </Chart>
    </div>
  );
};

ChartPortofolioLine.defaultProps = {
  data: [],
  customTooltip: null,
  handleClickPoint: () => {},
  axisname: "date",
  axislabel: {
    formatter(text) {
      const textFormated = moment(text, ["DD-MM-YYYY"]).format("D");
      return textFormated;
    },
  },
  position: "day*total_bio*month_name",
  colors: ["month_name", `${COLORS.BLUE_MEDIUM}-${COLORS.GREEN_HARD}-${COLORS.SECONDARY_MEDIUM}`],
};

ChartPortofolioLine.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  customTooltip: PropTypes.any,
  handleClickPoint: PropTypes.func,
  axisname: PropTypes.string,
  axislabel: PropTypes.object,
  position: PropTypes.string,
  colors: PropTypes.array,
};

export default ChartPortofolioLine;
