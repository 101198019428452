import { Button, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRightWhite } from "assets/icons/popup/icon-arrow-right-white.svg";
import InvalidDataTable from "components/table/table-invalid-data";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "40px",
  },
  buttonNavigateStyle: {
    width: "168px",
    height: "40px",
    backgroundColor: COLORS.PRIMARY_DARK,
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    color: COLORS.WHITE,
    borderRadius: "10px",
    textTransform: "initial",
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_DARK,
      color: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:disabled": {
      backgroundColor: COLORS.GRAY_MEDIUM,
      color: COLORS.WHITE,
    },
  },
  tableWrapper: {
    backgroundColor: COLORS.WHITE,
    border: "1px solid #E9F0FF",
    padding: "20px",
    borderRadius: "15px",
  },
});

const UserManagementInvalidData = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  // TODO temporary
  const content = useMemo(() => {
    const data = Array.isArray(location.state?.data) ? location.state?.data : [];

    return <InvalidDataTable data={data} />;
  }, [location.state]);

  if (!Array.isArray(location.state?.data)) return <Navigate to="/user-management" />;

  return (
    <div>
      <div className={classes.header}>
        <Title title="Invalid Data" />
        <Button
          className={classes.buttonNavigateStyle}
          endIcon={<IconArrowRightWhite />}
          variant="contained"
          onClick={() => navigate("/user-management")}
        >
          {t("SIDEBAR.USER_MANAGEMENT")}
        </Button>
      </div>
      <div className={classes.tableWrapper}>{content}</div>
    </div>
  );
};

UserManagementInvalidData.propTypes = {};

export default UserManagementInvalidData;
