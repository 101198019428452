import PropTypes from "prop-types";
import React from "react";
import { CustomSelect } from "elements/select";
import { useListSales } from "./use-query-sales";

const DropdownSales = ({ value, setValue, ...props }) => {
  const sales = useListSales();

  return (
    <CustomSelect
      placeholder={!sales.isSuccess ? "Loading..." : "Sales"}
      data={sales.data?.data || []}
      renderItem={item => item.salesName}
      renderValue={item => item.salesName}
      value={value}
      setValue={setValue}
      disabled={!sales.isSuccess}
      {...props}
    />
  );
};

DropdownSales.defaultProps = {
  setValue: undefined,
  value: undefined,
};
DropdownSales.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.any,
};

export { DropdownSales };
