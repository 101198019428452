import { makeStyles } from "@material-ui/core";
import { Axis, Chart, Interaction, Interval, Legend, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
// Component
import LegendChartBar from "components/legend/legend-chart-bar";
// Color
import PlaceholderChart from "components/placeholder/chart-placeholder";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
}));

const BarChartColor = ({ data, legendView, heightChart, positionName, axisNameGrid }) => {
  const classes = useStyles();
  const labelDefault = {
    style: {
      fontSize: 10,
    },
    formatter(text) {
      const txt = text.replace(/ /g, "\r\n");
      return txt.length > 25 ? `${txt.substring(0, 25)}...` : txt;
    },
  };

  const maxScale = x => {
    const max = Object.entries(x).reduce((items, [, val]) => {
      return [...items, val.sum];
    }, []);
    return Math.ceil(Math.max(...max) + 2);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Chart
        animate={false}
        placeholder={<PlaceholderChart className="" />}
        padding="auto"
        autoFit
        height={heightChart}
        data={data.data}
        theme={{ maxColumnWidth: 35 }}
        scale={{
          sum: { min: 0, nice: true, max: maxScale(data.data || {}) },
          type: "linear",
        }}
      >
        <Interaction type="element-active" />
        <Axis
          name={axisNameGrid}
          grid={{
            align: "center",
            line: {
              type: "line",
              style: {
                lineWidth: 0,
              },
            },
          }}
        />
        <Axis name="name" label={labelDefault} />
        <Tooltip position="bottom">
          {(items, title) => {
            return (
              <div className={classes.wrapper}>
                <div className={classes.valueWrapper}>{`${title[0]?.data.sum}` || "-"}</div>
              </div>
            );
          }}
        </Tooltip>
        <Interval
          position={positionName}
          color={[
            "avg",
            xVal => {
              if (xVal >= 15) {
                // Green
                return COLORS.GREEN_MEDIUM;
              }
              if (xVal < 15 && xVal >= 10) {
                // Orange
                return COLORS.SECONDARY_MEDIUM;
              }
              if (xVal < 10) {
                // Red
                return COLORS.PRIMARY_HARD;
              }
              return COLORS.SECONDARY_MEDIUM;
            },
          ]}
        />
        <Legend visible={false} />
      </Chart>
      <div style={{ marginTop: "24px" }}>
        {legendView === true && data?.data?.length !== 0 ? <LegendChartBar /> : ""}
      </div>
    </div>
  );
};

BarChartColor.defaultProps = {
  data: [],
  legendView: true,
  heightChart: 500,
  positionName: "",
  axisNameGrid: "",
};

BarChartColor.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  legendView: PropTypes.bool,
  heightChart: PropTypes.number,
  positionName: PropTypes.string,
  axisNameGrid: PropTypes.string,
};

export default BarChartColor;
