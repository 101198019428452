import { useQuery } from "@tanstack/react-query";
import { useFilterCalculation } from "pages/dashboard/dashboard-calculation-loss-profit/use-calculation-filter";
import { getParameterCost } from "services/api/private-routes/calculation-profit-and-loss";

const useQueryCalculationParameter = () => {
  const [filter] = useFilterCalculation();
  return useQuery(["cost-paramater", filter], () =>
    getParameterCost({
      brandingId: filter.brandingId || 15312,
      period: filter.period,
      salesId: filter.sales || null,
      areaId: filter.area || null,
    }),
  );
};

export { useQueryCalculationParameter };
