import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import IconHouse from "assets/icons/general/icon-house.svg";
import { CardFilterDetailsTransaction, INITIAL_FILTER } from "components/card/card-filter-details-transaction";
import CardSummaryOutlet from "components/card/card-summary-outlet";
import { ServerErrorBoundary } from "components/general";
import { PopupDownload, DOWNLOAD_FEATURES } from "components/popup/popup-download";
import DetailTransactionTable from "components/table/detail-transaction";
import { CardBase } from "elements/card";
import LoadingSpinner from "elements/loading/spinner";
import { PagePagination } from "elements/pagination/page-pagination";
import { Title } from "elements/typhography/title";
import { useAbortController } from "hooks/useAbortController";
import { useAuth } from "hooks/useAuth";
import {
  getListDetailTransactionMerchant,
  getListDetailTransactionOulet,
  downloadListDetailTransactionMerchant,
  downloadListDetailTransactionOutlet,
} from "services/api/private-routes";
import { getObjKey } from "utils/helpers/general";
import { useStyles } from "./styled";

const INITIAL_LIVE_FILTER = {
  page: 1,
  sorts: null,
};

const DashboardDetailTransaction = () => {
  const { getRole } = useAuth();
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      radio: {
        nominal: t("DETAIL_TRANSACTION.NOMINAL"),
        percentage: t("DETAIL_TRANSACTION.PERCENTAGE"),
      },
      toggle: {
        merchant: t("GENERAL.MERCHANT"),
        outlet: t("GENERAL.OUTLET"),
      },
      searchBar: {
        merchant: t("GENERAL.MERCHANT"),
        group: t("GENERAL.GROUP"),
      },
      annualFilter: {
        year: t("GENERAL.YEARLY"),
        month: t("GENERAL.MONTHLY"),
        onGoing: t("GENERAL.ONGOING"),
      },
    }),
    [t],
  );

  const classes = useStyles();
  const { signal } = useAbortController();
  const queryClient = useQueryClient();

  const [activeToggle, setActiveToggle] = useState("merchant");
  const [showDataType, setShowDataType] = useState(translations.radio.nominal);
  const [popUpDownloadOpen, setPopUpDownloadOpen] = useState(false);

  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [liveFilter, setLiveFilter] = useState(INITIAL_LIVE_FILTER);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const getQueryFilter = useCallback(
    () => ({
      page: liveFilter.page ?? 1,
      filterSalesArea: filter.filterSalesArea.reduce((prev, curr) => [...prev, curr.id], []),
      annualFilter: filter.annualFilter?.toUpperCase(),
      search: filter.search,
      typeSearch: filter.typeSearch?.toLowerCase(),
      sorts: liveFilter.sorts,
    }),
    [filter, liveFilter],
  );

  const listTransactionMerchant = useQuery(
    ["list-transaction-merchant", liveFilter, filter],
    () => getListDetailTransactionMerchant(getQueryFilter(), signal),
    {
      retry: 0,
      keepPreviousData: true,
      enabled: activeToggle === "merchant",
    },
  );

  const listTransactionOutlet = useQuery(
    ["list-transaction-outlet", liveFilter, filter],
    () => getListDetailTransactionOulet(getQueryFilter(), signal),
    {
      retry: 0,
      keepPreviousData: true,
      enabled: activeToggle === "outlet",
    },
  );

  const downloadExcelMerchant = useMutation(() => downloadListDetailTransactionMerchant(getQueryFilter()), {
    onSettled: () => queryClient.invalidateQueries(["download"]),
  });

  const downloadExcelOutlet = useMutation(() => downloadListDetailTransactionOutlet(getQueryFilter()), {
    onSettled: () => queryClient.invalidateQueries(["download"]),
  });

  const activeQueryToggle = useMemo(
    () => (activeToggle === "merchant" ? listTransactionMerchant : listTransactionOutlet),
    [activeToggle, listTransactionMerchant, listTransactionOutlet],
  );

  const tableContent = useMemo(() => {
    if (activeQueryToggle.isFetching) return <LoadingSpinner height={650} />;
    if (activeQueryToggle.isError)
      return (
        <ServerErrorBoundary onClick={() => activeQueryToggle.refetch()} title={activeQueryToggle.error?.message} />
      );
    const render = activeQueryToggle.data;
    if (!render) return null;
    return (
      <DetailTransactionTable
        data={render.data}
        mainTableHead={translations.toggle[activeToggle]}
        headers={render.heads}
        subHeaders={render.subHeaders}
        currencyType={getObjKey(translations.radio, showDataType)}
        onSort={sorts => setLiveFilter(old => ({ ...old, sorts }))}
      />
    );
  }, [activeQueryToggle, showDataType, activeToggle, translations]);

  return (
    <div className={classes.page}>
      {!getRole().isInquiry && (
        <>
          <PopupDownload
            isOpen={popUpDownloadOpen}
            onClose={() => setPopUpDownloadOpen(false)}
            feature={
              activeToggle === "outlet" ? DOWNLOAD_FEATURES.TRANSACTION_OUTLET : DOWNLOAD_FEATURES.TRANSACTION_MERCHANT
            }
            onSubmit={activeToggle === "outlet" ? downloadExcelOutlet.mutate : downloadExcelMerchant.mutate}
          />
          <div className={classes.buttonWrapper}>
            <Button
              startIcon={<IconDownload />}
              variant="contained"
              className={classes.buttonContained}
              onClick={() => setPopUpDownloadOpen(true)}
            >
              {t("GENERAL.DOWNLOAD_EXCEL")}
            </Button>
          </div>
        </>
      )}
      {activeToggle === "outlet" && <CardSummaryOutlet />}
      <CardFilterDetailsTransaction
        activeToggle={activeToggle}
        setActiveToggle={toggle => {
          setShowDataType(translations.radio.nominal);
          setFilter(INITIAL_FILTER);
          setLiveFilter(INITIAL_LIVE_FILTER);
          setActiveToggle(toggle);
        }}
        onApply={applyFilter => {
          setShowDataType(translations.radio.nominal);
          setLiveFilter({ page: 0, sorts: null });
          setFilter(applyFilter);
        }}
      />
      <CardBase flex>
        <div className={classes.tableHeaderWrapper}>
          <Title
            icon={<img src={IconHouse} alt="icon house" className={classes.tableTitleIcon} />}
            title={`${t("GENERAL.BY")} ${translations.toggle[activeToggle]}`}
          />
          <FormControl>
            <RadioGroup
              row
              name="row-radio-buttons-group"
              onChange={(_, val) => setShowDataType(val)}
              value={showDataType}
            >
              <FormControlLabel
                value={translations.radio.nominal}
                control={<Radio className={classes.radio} />}
                label={translations.radio.nominal}
              />
              <FormControlLabel
                value={translations.radio.percentage}
                control={<Radio className={classes.radio} />}
                label={translations.radio.percentage}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {tableContent}
      </CardBase>
      {!activeQueryToggle.isFetching && activeQueryToggle.isSuccess && (
        <PagePagination
          size={activeQueryToggle.data.page.size}
          count={activeQueryToggle.data.page.dataCount}
          totalPage={activeQueryToggle.data.page.totalPage}
          page={activeQueryToggle.data.page.number}
          onChange={page => setLiveFilter(old => ({ ...old, page }))}
        />
      )}
    </div>
  );
};

export default DashboardDetailTransaction;
