import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import IconSortDown from "assets/icons/general/icon-sort-asc.svg";
import IconSortUp from "assets/icons/general/icon-sort-desc.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { TableRowDetailsRevenue } from "./table-row-details";

const useStyles = makeStyles({
  overflow: {
    overflowX: "auto",
  },
  tableContainer: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "8px",
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
  },
  tableCellSemibold16: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
  },
  tableHeadRedSoft: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.RED_SOFT,
    color: COLORS.WHITE,
  },
  tableHeadBlueMedium: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.BLUE_MEDIUM,
    color: COLORS.WHITE,
  },
  tableCellReguler11: {
    padding: "5px",
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
    whiteSpace: "nowrap",
  },
  tableCellReguler10: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
  },
  tableRow: {
    padding: "4px 10px",
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  iconSortStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
  },
  iconButtonStyle: {
    padding: "1px",
    width: "11px",
  },
  subHeaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainTableHeadWrapper: {
    display: "flex",
    alignItems: "center",
  },
});

const subHeads = [
  {
    net_revenue: "net_revenue_last",
    nii_shadow: "shadow_nii_last",
    total_revenue: "total_revenue_last",
  },
  {
    net_revenue: "net_revenue",
    nii_shadow: "shadow_nii",
    total_revenue: "total_revenue",
  },
];

const DetailRevenueTable = ({ data, headers, subHeaders, mainTableHead, onSort }) => {
  const classes = useStyles();

  const YEAR_BY_INDEX = {
    0: "last",
    1: "current",
  };

  const sortParser = ({ key, head, direction }) => {
    const formattedKey = `${YEAR_BY_INDEX[head]}_${key.replace(/ /g, "_").toLowerCase()}`;
    return {
      key: formattedKey,
      direction,
    };
  };

  const subHeaderContent = React.useMemo(() => {
    return headers?.map((_, index) => {
      return subHeaders?.map(subHeader => {
        const sort = subHeads[index][subHeader?.replace(/ /g, "_").toLowerCase()];
        return (
          <TableCell align="center" className={classes.tableCellReguler11} key={subHeader}>
            <div className={classes.subHeaderWrapper}>
              {subHeader}
              <div className={classes.iconSortStyle}>
                <IconButton className={classes.iconButtonStyle} onClick={() => onSort({ key: sort, direction: "ASC" })}>
                  <img src={IconSortUp} alt="icon sort up" width="11px" />
                </IconButton>
                <IconButton
                  className={classes.iconButtonStyle}
                  onClick={() => onSort({ key: sort, direction: "DESC" })}
                >
                  <img src={IconSortDown} alt="icon sort down" width="11px" />
                </IconButton>
              </div>
            </div>
          </TableCell>
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subHeaders, classes, onSort, sortParser, headers]);

  const headersContent = React.useMemo(() => {
    return headers.map((header, index) => {
      const className = index % 2 === 0 ? classes.tableHeadRedSoft : classes.tableHeadBlueMedium;
      return (
        <TableCell align="center" colSpan={subHeaders.length} className={className} key={header}>
          {header}
        </TableCell>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, headers, classes, subHeaders]);

  const detailsContent = React.useMemo(() => {
    if (!Array.isArray(data)) return null;
    return data.map((x, i) => (
      <TableRow className={classes.tableRow} key={x.id ?? i}>
        <TableCell align="left" className={classes.tableCellReguler10}>
          {x.name}
        </TableCell>
        <TableRowDetailsRevenue
          data={x?.transactions?.map(y => y.nominal ?? [])}
          className={classes.tableCellReguler10}
          align="center"
        />
      </TableRow>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, classes]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="left" rowSpan={2} className={classes.tableCellSemibold16}>
              <div className={classes.mainTableHeadWrapper}>
                {mainTableHead}
                <div className={classes.iconSortStyle}>
                  <IconButton
                    className={classes.iconButtonStyle}
                    onClick={() => onSort({ key: "branding", direction: "ASC" })}
                  >
                    <img src={IconSortUp} alt="icon sort up" width="11px" />
                  </IconButton>
                  <IconButton
                    className={classes.iconButtonStyle}
                    onClick={() => onSort({ key: "branding", direction: "DESC" })}
                  >
                    <img src={IconSortDown} alt="icon sort down" width="11px" />
                  </IconButton>
                </div>
              </div>
            </TableCell>
            {headersContent}
          </TableRow>
          <TableRow>{subHeaderContent}</TableRow>
        </TableHead>
        <TableBody>{detailsContent}</TableBody>
      </Table>
    </TableContainer>
  );
};

DetailRevenueTable.defaultProps = {
  onSort: () => {},
};

DetailRevenueTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  mainTableHead: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  subHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSort: PropTypes.func,
};

export default DetailRevenueTable;
