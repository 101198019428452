import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const StyledText = styled(Typography)({
  textAlign: ({ align }) => align,
  fontSize: ({ size }) => size,
  fontWeight: ({ fontWeight }) => fontWeight,
  color: ({ maxHit }) => (maxHit ? COLORS.PRIMARY_DARK : COLORS.DARK_MEDIUM),
});

const StyledDescOTP = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const DescOTP = ({ description, align, size, maxHit }) => {
  const texts = useMemo(() => description?.split("."), [description]);
  return (
    <div>
      <StyledDescOTP>
        <StyledText align={align} size={size} fontWeight={400}>
          {`${texts[0]}.`}
        </StyledText>
        <StyledText align={align} size={size} maxHit={maxHit} fontWeight={700}>
          {texts[1]}
        </StyledText>
      </StyledDescOTP>
      <StyledText align={align} size={size} fontWeight={400}>
        {`${texts[2]}.`}
      </StyledText>
    </div>
  );
};

DescOTP.defaultProps = {
  align: "left",
  size: SIZES.REGULAR_16,
  maxHit: false,
};

DescOTP.propTypes = {
  description: PropTypes.string.isRequired,
  align: PropTypes.string,
  size: PropTypes.string,
  maxHit: PropTypes.bool,
};

export default DescOTP;
