import { TableCell, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const StickyTableCell = withStyles(() => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 10,
    minWidth: "10rem",
  },
  body: {
    zIndex: 10,
    // TODO Ask to BA
    minWidth: "max-content",
    whiteSpace: "nowrap",
    left: 0,
    position: "sticky",
    backgroundColor: "inherit",
  },
}))(TableCell);

export const useStyles = makeStyles({
  overflow: {
    overflowX: "auto",
  },
  tableContainer: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "8px",
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: COLORS.GRAY_SOFT,
    minWidth: 200,
  },
  tableCellSemibold16: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    borderRight: `1px solid ${COLORS.WHITE}`,
  },
  tableHeadRedSoft: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.RED_SOFT,
    color: COLORS.WHITE,
  },
  tableHeadBlueMedium: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 700,
    backgroundColor: COLORS.BLUE_MEDIUM,
    color: COLORS.WHITE,
  },
  tableCellReguler11: {
    padding: "10px",
    fontSize: SIZES.REGULAR_14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    verticalAlign: "top",
  },
  tableCellReguler10Center: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    verticalAlign: "top",
  },
  tableCellReguler10: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
  },
  tableRow: {
    padding: "4px 10px",
    "&:nth-child(even)": {
      backgroundColor: COLORS.GRAY_ULTRASOFT,
    },
  },
  iconSortStyle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
  },
  iconButtonStyle: {
    padding: "1px",
    width: "11px",
  },
  subHeaderWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: 8,
  },

  mainTableHeadWrapper: {
    display: "flex",
    alignItems: "center",
  },
  stickyColumn: {
    position: "sticky",
    left: 0,
    background: "inherit",
    minWidth: 200,
  },
  stickyHeader: {
    position: "sticky",
    left: 0,
    background: COLORS.GRAY_SOFT,
    minWidth: 200,
    zIndex: 1,
  },
  tableCellBranding: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
  },
});
