import { Chip, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as IconDelete } from "assets/icons/general/icon-close.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  chipCustom: {
    paddingTop: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingBottom: "6px",
    height: "32px",
    fontSize: SIZES.REGULAR_14,
    backgroundColor: COLORS.WHITE,
    fontWeight: 600,
    "&.MuiChip-deletable": {
      backgroundColor: COLORS.WHITE,
      color: COLORS.BLACK,
      boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    },
    "&.MuiChip-deleteIcon": {
      height: "16px",
      width: "16px",
    },
  },
}));

const CustomDeleteChip = ({ content, color, onDelete }) => {
  const classes = useStyles();
  return (
    <Chip
      label={content}
      onDelete={onDelete}
      deleteIcon={<IconDelete />}
      color={color}
      className={classes.chipCustom}
    />
  );
};

CustomDeleteChip.propTypes = {
  content: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  color: PropTypes.string,
};

CustomDeleteChip.defaultProps = {
  color: COLORS.PRIMARY_DARK,
};

export default CustomDeleteChip;
