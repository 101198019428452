import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SearchBar from "components/input/search-bar";
import ButtonContained from "elements/button/button-contained";
import { CardBase } from "elements/card";
import { CustomSelect } from "elements/select";
import COLORS from "utils/constants/colors";
import { trbOptions, profitOptions } from "utils/constants/general";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    gap: "0.6em",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectWrapper: {
    minWidth: "8em",
  },

  customDropDown: {
    flex: 1,
    borderRadius: "10px",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    color: COLORS.DARK_HARD,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export const INITIAL_FILTER = {
  search: "",
  typeSearch: "",
  filterSalesArea: "",
  annualFilter: "",
  type: {
    id: 3,
    target_code: "zero_trb",
    target_name: "Zero TRB",
  },
  sales: "",
  mcc: "",
};

const CardFilterDetailProfitibility = ({ onApply, listSalesName, salesAreaLogin, listMccGroup }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { monthly } = useParams();
  const param = monthly.split("-");
  const profitName = param[3] === "profit" ? "profit" : "not profit";
  const [filter, setFilter] = useState({
    search: "",
    typeSearch: "",
    filterSalesArea: "",
    annualFilter: "",
    type: trbOptions.find(x => x.target_code.includes(param[2])),
    sales: null,
    mcc: null,
    chip: profitOptions.find(x => x.target_code === profitName),
  });

  const translations = useMemo(
    () => ({
      annualFilter: {
        all: t("GENERAL.SELECT_ALL"),
        salesArea: t("GENERAL.SALES_AREA"),
        trb: t("GENERAL.TRB"),
        nameSales: t("GENERAL.NAME_SALES"),
        mcc: t("GENERAL.MCC"),
      },
      searchBar: {
        branding: t("GENERAL.BRANDING"),
        merchant: t("GENERAL.MERCHANT"),
      },
      search: t("GENERAL.SEARCH"),
    }),
    [t],
  );

  const handleOnApply = useCallback(() => {
    onApply(filter);
  }, [filter, onApply]);

  const selectSearchBarHandle = useCallback(val => setFilter(old => ({ ...old, typeSearch: val })), [setFilter]);
  const onSearch = useCallback(val => setFilter(old => ({ ...old, search: val })), [setFilter]);

  return (
    <CardBase className={classes.container}>
      <div style={{ flexShrink: 0 }}>
        <SearchBar
          menuItem={Object.values(translations.searchBar)}
          onChange={selectSearchBarHandle}
          onSearch={onSearch}
          selected={filter.typeSearch || translations.searchBar.branding}
          value={filter.search}
          disabled={false}
        />
      </div>

      <CustomSelect
        className={classes.customDropDown}
        placeholder={translations.annualFilter.all}
        data={profitOptions}
        disabled={false}
        setValue={val => setFilter(x => ({ ...x, chip: val }))}
        renderItem={val => val.target_name}
        renderValue={val => val.target_name}
        value={filter.chip}
        classNameItem={classes.selectItemDropdownSalesArea}
      />

      <CustomSelect
        className={classes.customDropDown}
        placeholder={salesAreaLogin?.isFetching ? "Loading..." : "Sales Area"}
        data={salesAreaLogin?.data?.data ?? []}
        disabled={salesAreaLogin?.isFetching}
        setValue={val => setFilter(x => ({ ...x, filterSalesArea: val }))}
        renderItem={val => val.name}
        renderValue={val => val.name}
        value={filter.filterSalesArea}
        classNameItem={classes.selectItemDropdownSalesArea}
      />

      <CustomSelect
        className={classes.customDropDown}
        placeholder={translations.annualFilter.trb}
        data={trbOptions}
        disabled={false}
        setValue={val => setFilter(x => ({ ...x, type: val }))}
        renderItem={val => val.target_name}
        renderValue={val => val.target_name}
        value={filter.type}
        classNameItem={classes.selectItemDropdownSalesArea}
      />

      <CustomSelect
        className={classes.customDropDown}
        placeholder={listSalesName?.isFetching ? "Loading..." : translations.annualFilter.nameSales}
        data={listSalesName?.data?.responseSalesName ?? []}
        disabled={listSalesName?.isFetching}
        setValue={val => setFilter(x => ({ ...x, sales: val }))}
        renderItem={val => val.name}
        renderValue={val => val.name}
        value={filter.sales}
        classNameItem={classes.selectItemDropdownSalesArea}
      />

      <CustomSelect
        className={classes.customDropDown}
        placeholder={listMccGroup?.isFetching ? "Loading..." : translations.annualFilter.mcc}
        data={listMccGroup?.data ?? []}
        disabled={listMccGroup?.isFetching}
        setValue={val => setFilter(x => ({ ...x, mcc: val }))}
        renderItem={val => val.mccGroup}
        renderValue={val => val.mccGroup}
        value={filter.mcc}
        classNameItem={classes.selectItemDropdownSalesArea}
      />

      <ButtonContained content="Apply" width="72px" onClick={handleOnApply} />
    </CardBase>
  );
};

CardFilterDetailProfitibility.defaultProps = {
  listSalesName: [],
  salesAreaLogin: [],
  listMccGroup: [],
};

CardFilterDetailProfitibility.propTypes = {
  onApply: PropTypes.func.isRequired,
  listSalesName: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  salesAreaLogin: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  listMccGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { CardFilterDetailProfitibility };
