import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

// Component
import ChartLinePoint from "elements/chart/char-line-point";

const useStyles = makeStyles({
  containerLegend: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2rem",
    alignItems: "center",
    justifyContent: "center",
  },
  containerLegendItem: {
    display: "flex",
    flexWrap: "wrap",
    gap: ".5rem",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
  },
  box: {
    width: "1rem",
    height: "1rem",
    borderRadius: "5px",
  },
});

const ChartLineCustom = ({ dataChart, scaleChart, heightChart, colorChart, legends, monthLabel }) => {
  const classes = useStyles();
  return (
    <div>
      <ChartLinePoint data={dataChart} scaleChart={scaleChart} colorChart={colorChart} heightChart={heightChart} monthLabel={monthLabel} />
      <div className={classes.containerLegend}>
        {legends?.map(x => (
          <div key={x.key} className={classes.containerLegendItem}>
            <div style={{ background: x.color }} className={classes.box} />
            <span>{x?.key}</span>
            <span>({x?.total || 0})</span>
          </div>
        ))}
      </div>
    </div>
  );
};

ChartLineCustom.defaultProps = {
  dataChart: [],
  scaleChart: {},
  heightChart: 400,
  colorChart: [],
  legends: [],
  monthLabel: false
};

ChartLineCustom.propTypes = {
  dataChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  scaleChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  heightChart: PropTypes.number,
  colorChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  legends: PropTypes.array,
  monthLabel: PropTypes.bool
};

export default ChartLineCustom;
