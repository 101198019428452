/**
 * Utility for converting blob response to binary as downloadable content
 * @param {string} blob
 * @param {string} fileName
 */
export const downloadBlobObject = (blob, fileName) => {
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
