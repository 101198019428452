import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

export const downloadDataTableExecutiveSummary = async ({ year }) => {
  const { data: responseBody, status } = await axiosPrivate.post(
    `${ENDPOINT.API_DASHBOARD.EXECUTIVE_SUMMARY.EXECUTIVE_SUMMARY_DOWNLOAD}`,
    {
      year,
    },
  );
  if (status !== 200) throw new Error(responseBody);

  return { payload: responseBody };
};
