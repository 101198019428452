/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ServerErrorBoundary } from "components/general";
import BrandingTable from "components/table/branding";
import LoadingSpinner from "elements/loading/spinner";
import { PagePagination } from "elements/pagination/page-pagination";
import { useAbortController } from "hooks/useAbortController";
import { getBrandingByMerchantTable } from "services/api/private-routes";
import {
  TABLE_PROFITABILITY_BY_MERCHANT_CONFIG,
  mockupTableProfitabilityByMerchant,
} from "utils/constants/mocks/dashboard/profitability";
import QUERY_NAME from "utils/constants/query";
import { useStyles } from "./styles";

const CardBrandingTableByMerchant = ({ filter, setFilterHome }) => {
  const { id } = useParams();
  const classes = useStyles();

  const [state, setState] = useState({
    sortBy: "Merchant Name",
    page: 1,
    sortDirection: "ASC",
    limit: 10,
  });

  const { signal } = useAbortController();
  const payload = {
    filter: {
      month: filter.start,
    },
    pageConfig: {
      ...state,
      page: state.page,
    },
  };
  const dataTable = useQuery(
    [QUERY_NAME.BRANDING_TABLE_BY_MERCHANT, state.page, state.sortBy, state.sortDirection, filter.start],
    () => getBrandingByMerchantTable(signal, id, payload.filter, payload.pageConfig),
  );

  function changePage(page) {
    setState(prev => ({
      ...prev,
      page: page !== null ? page : 1,
    }));
  }

  function handleSort(value) {
    setState(prev => ({
      ...prev,
      sortBy: value.key,
      sortDirection: value.direction,
    }));
  }

  useEffect(() => {
    if (state) {
      setFilterHome(state);
    }
  }, [state]);

  if (dataTable.isFetching) {
    return (
      <div className={classes.tableWrapper}>
        <LoadingSpinner height={450} />
      </div>
    );
  }

  if (dataTable.isError) {
    return (
      <div className={classes.tableWrapper}>
        <ServerErrorBoundary className={classes.minHCard} onClick={dataTable.refetch()} />
      </div>
    );
  }

  return (
    <div className={classes.tableWrapper}>
      <BrandingTable
        branding={dataTable?.data?.branding}
        header={mockupTableProfitabilityByMerchant?.heads}
        body={dataTable?.data?.contents}
        fields={TABLE_PROFITABILITY_BY_MERCHANT_CONFIG?.fields}
        cellOptions={TABLE_PROFITABILITY_BY_MERCHANT_CONFIG?.cellOptions}
        stickyColumn={TABLE_PROFITABILITY_BY_MERCHANT_CONFIG?.stickyColumn}
        onSort={handleSort}
      />
      <div style={{ marginTop: 30 }} />
      <PagePagination
        size={state?.limit}
        count={dataTable?.data?.total_elements}
        totalPage={dataTable?.data?.total_pages}
        page={state?.page}
        onChange={changePage}
      />
    </div>
  );
};

CardBrandingTableByMerchant.propTypes = {
  setFilterHome: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filter: PropTypes.exact({
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
};

export default CardBrandingTableByMerchant;
