import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  titleContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  },
  titleTypography: {
    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_18,
    fontWeight: 500,
  },
  subTitleTypography: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 400,
    color: COLORS.GRAY_DARK,
    lineHeight: "18px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "start",
  },
});

const Title = ({ icon, title, subTitle, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      {icon}
      <div className={classes.titleContent}>
        <Typography children={title || children} className={classes.titleTypography} />
        {subTitle && <Typography children={subTitle} className={classes.subTitleTypography} />}
      </div>
    </div>
  );
};

Title.defaultProps = {
  title: undefined,
  icon: null,
  subTitle: undefined,
  children: undefined,
};

Title.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
};

export { Title };
