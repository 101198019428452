import { axiosPrivate } from "../axiosPrivate";

const downloadCalculation = async ({ brandingId, period, sales, area }) => {
  const { status, data: responseBody } = await axiosPrivate.post(
    "/dashboard/calculationprofitandlos/existing/download",
    {
      branding_id: brandingId ?? null,
      period: period ?? new Date().getFullYear(),
      sales_id: sales,
      area_id: area,
    },
  );

  if (status !== 200) throw new Error(`[downloadCalculation] ${status}`);

  return { data: responseBody };
};

export { downloadCalculation };
