/* eslint-disable import/no-cycle */
import PropTypes from "prop-types";
import React, { createContext, useState, useContext, useCallback } from "react";

import { useTranslation } from "react-i18next";
import PopperUserManagementMenu from "components/popper/popper-user-management-menu";
import PopupConfirmation from "components/popup/popup-confirmation";
import PopupFieldInput from "components/popup/popup-field-input";
import PopupInformation from "components/popup/popup-information";

const UserManagementContext = createContext(null);

const useUserManagement = () => {
  const ctx = useContext(UserManagementContext);
  if (!ctx) {
    throw new Error("useUserManagement must be used within the UserManagementProvider");
  }

  const { setPopperUser } = ctx;

  const showPopper = ({ anchorEl, menu, id }) => {
    setPopperUser({ anchorEl, menu, id });
  };

  return { showPopper, ...ctx };
};

const INITIAL_POPPER = {
  anchorEl: null,
  menu: null,
  id: null,
};

const UserManagementProvider = ({ children, value, popover, onClosePopover, onUploadTryAgain, onNavigateInvalid }) => {
  const { t } = useTranslation();
  const [popperUser, setPopperUser] = useState(INITIAL_POPPER);
  const [rejectionReason, setRejectionReason] = useState("");

  const handleClose = () => {
    if (rejectionReason) setRejectionReason("");
    setPopperUser(INITIAL_POPPER);
  };

  const handlerRejectionReason = useCallback(e => setRejectionReason(e.target.value), [setRejectionReason]);

  const onFieldInputClose = () => {
    if (rejectionReason) setRejectionReason("");
    onClosePopover();
  };

  let onSubmitHandler;

  if (popover?.buttonText === "Try Again ") {
    onSubmitHandler = onUploadTryAgain;
  } else if (popover?.buttonText === "Show Detail") {
    onSubmitHandler = onNavigateInvalid;
  } else {
    onSubmitHandler = onClosePopover;
  }

  return (
    <UserManagementContext.Provider value={{ popperUser, setPopperUser, ...value }}>
      <PopperUserManagementMenu
        open={!!popperUser.anchorEl}
        anchorEl={popperUser.anchorEl}
        handleClose={handleClose}
        menu={popperUser.menu}
        id={popperUser.id}
      />
      <PopupInformation {...popover} isOpen={popover?.typePopover === "info"} onSubmit={onSubmitHandler} />
      <PopupConfirmation
        {...popover}
        isOpen={popover?.typePopover === "confirm"}
        isClose
        onClose={onClosePopover}
        onCancel={onClosePopover}
      />
      <PopupFieldInput
        isOpen={popover?.typePopover === "reject-input"}
        onClose={onFieldInputClose}
        title={t("USER.MANAGEMENT.REJECTION_REASON")}
        description={`${t("USER.MANAGEMENT.REJECTION_REASON")} :`}
        submitButtonText={t("GENERAL.SUBMIT")}
        placeholder={t("USER.MANAGEMENT.FILL_IN_REJECTION_REASON")}
        onSubmit={() => popover.onSubmit(rejectionReason)}
        onChangeText={handlerRejectionReason}
        disabledButton={Boolean(!rejectionReason)}
      />
      {children}
    </UserManagementContext.Provider>
  );
};

UserManagementProvider.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.object.isRequired,
  popover: PropTypes.object.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  onUploadTryAgain: PropTypes.func.isRequired,
  onNavigateInvalid: PropTypes.func.isRequired,
};

export default UserManagementProvider;
export { useUserManagement };
