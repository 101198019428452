import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getListDataTopMerchantTopBranding = async ({ monthly }, signal) => {
  const { data: responseData, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/top-merchant/top-branding?monthly=${monthly}`,

    { signal },
  );

  if (status !== 200) throw new Error(`[getListDataTopMerchantTopBranding] ${status}`);

  const { data } = responseData;

  // Update Structure Data
  const formatData = [
    {
      branding_name: "Branding",
      branding_id: "Total Profit",
      total_bio: "Total Profit",
      total: "Total Profit",
    },
    ...data?.details,
  ];

  return formatData;
};

const getListDataTopMerchantBottomBranding = async ({ monthly }, signal) => {
  const { data: responseData, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/top-merchant/bottom-branding?monthly=${monthly}`,
    { signal },
  );

  if (status !== 200) throw new Error(`[getListDataTopMerchantBottomBranding] ${status}`);
  const { data } = responseData;

  // Update Structure Data
  const formatData = [
    {
      branding_name: "Branding",
      branding_id: "Total Profit",
      total_bio: "Total Profit",
      total: "Total Profit",
    },
    ...data?.details,
  ];

  return formatData;
};

const getListDataTopMerchantTopWatchlist = async ({ monthly }, signal) => {
  const { data: responseData, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/top-merchant/top-watchlist?monthly=${monthly}`,
    { signal },
  );

  if (status !== 200) throw new Error(`[getListDataTopMerchantTopWatchlist] ${status}`);
  const { data } = responseData;

  // Update Structure Data
  const formatData = [
    {
      branding_name: "Branding",
      branding_id: "Not Profit Frequency",
      total_bio: "Not Profit Frequency",
      total: "Not Profit Frequency",
    },
    ...data?.details,
  ];

  return formatData;
};

// API HIT DATA PROFIT
const getDataChartWaveZeroProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/${newFilter?.toggle}/zero-trb/profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveZeroProfit] ${status}`);
  return data.data ?? [];
};

const getDataChartWaveZeroNotProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/${newFilter?.toggle}/zero-trb/not-profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveZeroNotProfit] ${status}`);
  return data.data ?? [];
};

const getDataChartWaveHaveProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/${newFilter?.toggle}/have-trb/profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveHaveProfit] ${status}`);
  return data.data ?? [];
};

const getDataChartWaveHaveNotProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/${newFilter?.toggle}/have-trb/not-profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveHaveNotProfit] ${status}`);
  return data.data ?? [];
};

// API HIT DATA MCC
const getDataChartMccZeroProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/mcc/${newFilter?.toggle}/zero-trb/profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveZeroProfit] ${status}`);
  return data.data ?? [];
};

const getDataChartMccZeroNotProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/mcc/${newFilter?.toggle}/zero-trb/not-profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveZeroNotProfit] ${status}`);
  return data.data ?? [];
};

const getDataChartMccHaveProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/mcc/${newFilter?.toggle}/have-trb/profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveHaveProfit] ${status}`);
  return data.data ?? [];
};

const getDataChartMccHaveNotProfit = async ({ newFilter }, signal) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/mcc/${newFilter?.toggle}/have-trb/not-profit?monthly=${newFilter?.date}`,
    { signal },
  );
  if (status !== 200) throw new Error(`[getDataChartWaveHaveNotProfit] ${status}`);
  return data.data ?? [];
};

const downloadPdf = async body => {
  const { data, status } = await axiosPrivate.post(`${ENDPOINT.BASEURL}/profitabilityservice/pdf/download`, body);
  if (status !== 200) throw new Error(`[downloadPdf] ${status}`);
  if (status === 200) {
    fetch(data.data.uri)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const filename = data.data.file_name;
        a.href = url;
        a.download = filename;
        a.click();
      });
  }
};

export {
  getListDataTopMerchantTopBranding,
  getListDataTopMerchantBottomBranding,
  getListDataTopMerchantTopWatchlist,
  getDataChartWaveZeroProfit,
  getDataChartWaveZeroNotProfit,
  getDataChartWaveHaveProfit,
  getDataChartWaveHaveNotProfit,
  // get mcc
  getDataChartMccZeroProfit,
  getDataChartMccZeroNotProfit,
  getDataChartMccHaveProfit,
  getDataChartMccHaveNotProfit,
  // download pdf
  downloadPdf,
};
