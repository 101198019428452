import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconChartSales from "assets/icons/general/icon-mobile.svg";
import ChartProfitabilityCustom from "components/chart/chart-profitability-custom";
import { ServerErrorBoundary } from "components/general";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";
import WrapperOutlineChart from "components/wrapper/wrapper-oultine-chart";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import {
  getDataChartWaveZeroProfit,
  getDataChartWaveZeroNotProfit,
  getDataChartWaveHaveProfit,
  getDataChartWaveHaveNotProfit,
} from "services/api/private-routes/dashboard-profitibility";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { parseCurrency } from "utils/helpers/useFormater";
import CardWrapperLine from "../card-wrapper-line";

const monthList = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
const currentYear = new Date().getFullYear().toString().slice(2);
const useStyles = makeStyles({
  wrapper: {
    width: "49.25%",
    height: "49.25%",
  },
  topRightTextChart: {
    padding: "6px 12px",
    backgroundColor: "transparent",
    border: `1px solid ${COLORS.GRAY_SOFT}`,
    borderRadius: "30px",

    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_16,
    fontWeight: "500",
    lineHeight: "20px",
  },
  labelText: {
    fontSize: SIZES.REGULAR_18,
    fontWeight: "600",
    lineHeight: "30px",
    color: COLORS.DARK_HARD,
  },
  wrapperTooltip: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
    minWidth: "280px",
  },
  textTooltip: {
    fontSize: SIZES.REGULAR_12,
    fontWeight: 500,
    color: COLORS.GRAY_PRIMARY,
  },
  minHCard: {
    minHeight: 500,
  },

  title: {
    fontSize: SIZES.REGULAR_14,
    fontWeight: "500",
    lineHeight: "20px",
    color: COLORS.GRAY_PRIMARY,
  },

  wrapperTitleChart: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
});

const CardMtdChart = ({ dataFilter }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { signal } = useAbortController();

  const year = dataFilter?.date.getFullYear();
  const month = (dataFilter?.date.getMonth() + 1).toString().padStart(2, "0");
  const formattedDate = `${year}-${month}`;
  const monthlyReq = useMemo(() => `${year}-${month}`, [year, month]);

  const newFilter = {
    toggle: dataFilter?.toggle?.toLowerCase(),
    date: formattedDate,
  };

  // query hit API
  const dataChartWaveZeroProfit = useQuery(["chart-wave-zero-profit", dataFilter], () =>
    getDataChartWaveZeroProfit({ newFilter }, signal),
  );
  const dataChartWaveZeroNotProfit = useQuery(["chart-wafe-zero-not-profit", dataFilter], () =>
    getDataChartWaveZeroNotProfit({ newFilter }, signal),
  );
  const dataChartWaveHaveProfit = useQuery(["chart-wafe-have-profit", dataFilter], () =>
    getDataChartWaveHaveProfit({ newFilter }, signal),
  );
  const dataChartWaveHaveNotProfit = useQuery(["chart-wafe-have-not-profit", dataFilter], () =>
    getDataChartWaveHaveNotProfit({ newFilter }, signal),
  );

  // Translation
  const translation = useMemo(
    () => ({
      title: {
        zero_trb: t("PROFITABILITY.ZERO_TRB"),
        have_trb: t("PROFITABILITY.HAVE_TRB"),
        profit: t("PROFITABILITY.PROFIT"),
        not_profit: t("PROFITABILITY.NOT_PROFIT"),
        no_terminal_id: t("PROFITABILITY.NO_TERMINAL_ID"),
        no_marchant: t("PROFITABILITY.NO_MARCHANT"),
        total_sales_volume: t("PROFITABILITY.TOTAL_SALES_VOLUME"),
        total_cost: t("PROFITABILITY.TOTAL_COST"),
        total_revenue: t("PROFITABILITY.TOTAL_REVENUE"),
        total_profit: t("PROFITABILITY.TOTAL_PROFIT"),
      },
      titleCharts: {
        textTitle: t("PORTOFOLIO.IDR_BIO"),
      },
      label: {
        zero_trb: t("PROFITABILITY.ZERO_TRB"),
        have_trb: t("PROFITABILITY.HAVE_TRB"),
        profit: t("PROFITABILITY.PROFIT"),
        not_profit: t("PROFITABILITY.NOT_PROFIT"),
        no_terminal_id: t("PROFITABILITY.NO_TERMINAL_ID"),
        no_marchant: t("PROFITABILITY.NO_MARCHANT"),
        total_sales_volume: t("PROFITABILITY.TOTAL_SALES_VOLUME"),
        total_cost: t("PROFITABILITY.TOTAL_COST"),
        total_revenue: t("PROFITABILITY.TOTAL_REVENUE"),
        total_profit: t("PROFITABILITY.TOTAL_PROFIT"),
      },
    }),
    [t],
  );

  // label line
  const labelLeft = [translation.label.have_trb, translation.label.zero_trb];
  const labelBottom = [translation.label.not_profit, translation.label.profit];

  const labelsTooltipProfit = [
    {
      label: translation.label.no_terminal_id,
      key: "total_tid",
    },
    {
      label: translation.label.no_marchant,
      key: "total_merchant",
    },
    {
      label: translation.label.total_sales_volume,
      key: "total_sales_volume",
    },
    {
      label: translation.label.total_cost,
      key: "total_cost",
    },
    {
      label: translation.label.total_revenue,
      key: "total_revenue",
    },
    {
      label: translation.label.total_profit,
      key: "total_profit",
    },
  ];

  const customTooltip = useCallback(
    (_, items) => {
      const { detail_data } = items[0].data;
      return (
        <div className={classes.wrapperTooltip}>
          {labelsTooltipProfit.map(({ label, key }) => (
            <Grid container direction="row" justifyContent="space-between" spacing={4}>
              <Grid item>
                <Typography className={classes.textTooltip}>{label} :</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.textTooltip}>{parseCurrency(detail_data[key])}</Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      );
    },
    [labelsTooltipProfit, classes],
  );
  const labelAxis = {
    formatter(text) {
      return text;
    },
    style(item) {
      return {
        fill: item.includes(currentYear) ? COLORS.GRAY_HARD : COLORS.RED_HARD,
      };
    },
  };

  const handleClickPoint = useCallback(
    (event, titleName, chipName) => {
      const { year: getYear, month_name } = event?.data?.data;
      const splitMonth = month_name?.split(" ")[0]?.toLowerCase()?.trim();
      const getMonthNumb = monthList.indexOf(splitMonth) + 1;
      navigate(
        `/dashboard-profitability-detail/${getYear}-${
          getMonthNumb < 10
            ? `0${getMonthNumb}-${titleName}-${chipName}-${monthlyReq}`
            : `${getMonthNumb}-${titleName}-${chipName}-${monthlyReq}`
        }`,
      );
    },
    [navigate, monthlyReq],
  );

  const colorsChart = [COLORS.SECONDARY_MEDIUM, COLORS.BLUE_MEDIUM, COLORS.RED_MEDIUM, COLORS.GREEN_MEDIUM];

  const showChartWaveZeroProfit = useMemo(() => {
    if (dataChartWaveZeroProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartWaveZeroProfit.isError) {
      return <ServerErrorBoundary className={classes.minHCard} onClick={dataChartWaveZeroProfit.refetch} />;
    }
    return (
      <>
        <ChartProfitabilityCustom
          typeChart="profit"
          data={dataChartWaveZeroProfit?.data}
          customTooltip={customTooltip}
          handleClickPoint={e => handleClickPoint(e, "zero", "profit")}
          customLabelAxis={labelAxis}
          positionLine="month_name*total_bio"
          colors={colorsChart[3]}
          axisName="month_name"
          pointValue="total_bio"
        />
      </>
    );
  }, [dataChartWaveZeroProfit, classes, colorsChart, customTooltip, handleClickPoint, labelAxis]);

  const showChartWaveZeroNotProfit = useMemo(() => {
    if (dataChartWaveZeroNotProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartWaveZeroNotProfit.isError) {
      return <ServerErrorBoundary className={classes.minHCard} onClick={dataChartWaveZeroNotProfit.refetch} />;
    }
    return (
      <>
        <ChartProfitabilityCustom
          typeChart="notProfit"
          data={dataChartWaveZeroNotProfit?.data}
          customTooltip={customTooltip}
          handleClickPoint={e => handleClickPoint(e, "zero", "notprofit")}
          customLabelAxis={labelAxis}
          positionLine="month_name*total_bio"
          colors={colorsChart[2]}
          axisName="month_name"
          pointValue="total_bio"
        />
      </>
    );
  }, [dataChartWaveZeroNotProfit, classes, colorsChart, customTooltip, handleClickPoint, labelAxis]);

  const showChartWaveHaveProfit = useMemo(() => {
    if (dataChartWaveHaveProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartWaveHaveProfit.isError) {
      return <ServerErrorBoundary className={classes.minHCard} onClick={dataChartWaveHaveProfit.refetch} />;
    }
    return (
      <>
        <ChartProfitabilityCustom
          typeChart="profit"
          data={dataChartWaveHaveProfit?.data}
          customTooltip={customTooltip}
          handleClickPoint={e => handleClickPoint(e, "have", "profit")}
          customLabelAxis={labelAxis}
          positionLine="month_name*total_bio"
          colors={colorsChart[1]}
          axisName="month_name"
          pointValue="total_bio"
        />
      </>
    );
  }, [dataChartWaveHaveProfit, classes, colorsChart, customTooltip, handleClickPoint, labelAxis]);

  const showChartWaveHaveNotProfit = useMemo(() => {
    if (dataChartWaveHaveNotProfit.isFetching) {
      return <LoadingSpinner height={500} />;
    }
    if (dataChartWaveHaveNotProfit.isError) {
      return <ServerErrorBoundary className={classes.minHCard} onClick={dataChartWaveHaveNotProfit.refetch} />;
    }
    return (
      <>
        <ChartProfitabilityCustom
          typeChart="notProfit"
          data={dataChartWaveHaveNotProfit?.data}
          customTooltip={customTooltip}
          handleClickPoint={e => handleClickPoint(e, "have", "notprofit")}
          customLabelAxis={labelAxis}
          positionLine="month_name*total_bio"
          colors={colorsChart[0]}
          axisName="month_name"
          pointValue="total_bio"
        />
      </>
    );
  }, [dataChartWaveHaveNotProfit, classes, colorsChart, customTooltip, handleClickPoint, labelAxis]);

  return (
    <CardWrapperLine labelLeft={labelLeft} labelBottom={labelBottom}>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.label.have_trb} />
            <Typography className={classes.topRightTextChart}>{translation.label.not_profit}</Typography>
          </Grid>
          <WrapperOutlineChart>
            <div className={classes.wrapperTitleChart}>
              <Typography className={classes.title}>{translation.titleCharts.textTitle}</Typography>
            </div>
            {showChartWaveHaveNotProfit}
          </WrapperOutlineChart>
        </CardWrapper>
      </div>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.label.have_trb} />
            <Typography className={classes.topRightTextChart}>{translation.label.profit}</Typography>
          </Grid>
          <WrapperOutlineChart>
            <div className={classes.wrapperTitleChart}>
              <Typography className={classes.title}>{translation.titleCharts.textTitle}</Typography>
            </div>
            {showChartWaveHaveProfit}
          </WrapperOutlineChart>
        </CardWrapper>
      </div>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.label.zero_trb} />
            <Typography className={classes.topRightTextChart}>{translation.label.not_profit}</Typography>
          </Grid>

          <WrapperOutlineChart>
            <div className={classes.wrapperTitleChart}>
              <Typography className={classes.title}>{translation.titleCharts.textTitle}</Typography>
            </div>
            {showChartWaveZeroNotProfit}
          </WrapperOutlineChart>
        </CardWrapper>
      </div>
      <div className={classes.wrapper}>
        <CardWrapper flexSize={1}>
          <Grid container justifyContent="space-between">
            <TitleChart icon={IconChartSales} title={translation.label.zero_trb} />
            <Typography className={classes.topRightTextChart}>{translation.label.profit}</Typography>
          </Grid>

          <WrapperOutlineChart>
            <div className={classes.wrapperTitleChart}>
              <Typography className={classes.title}>{translation.titleCharts.textTitle}</Typography>
            </div>
            {showChartWaveZeroProfit}
          </WrapperOutlineChart>
        </CardWrapper>
      </div>
    </CardWrapperLine>
  );
};

CardMtdChart.defaultProps = {
  dataFilter: {},
};

CardMtdChart.propTypes = {
  dataFilter: PropTypes.object,
};

export { CardMtdChart };
