import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconChartLine from "assets/icons/chart/icon-chart-line.svg";
import iconSquareBlue from "assets/icons/general/icon-rounded-rectangle-blue.svg";
import iconSquareGreen from "assets/icons/general/icon-rounded-rectangle-green.svg";
import iconSquareYellow from "assets/icons/general/icon-rounded-rectangle-orange.svg";
import ChartPortofolioLine from "components/chart/chart-portofolio-line";
import { ServerErrorBoundary } from "components/general";
import LegendChartLineText from "components/legend/legend-chart-line-text";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";
import LoadingSpinner from "elements/loading/spinner";
import { getSalesLineChart } from "services/api/private-routes";

const CardPortofolioSalesVolume = ({ selectSalesAreaLogin, enable }) => {
  const { t } = useTranslation();

  const diagramSalesLineChart = useQuery(
    ["diagram-line-sales", selectSalesAreaLogin],
    () => getSalesLineChart(selectSalesAreaLogin?.id ?? ""),
    { retry: 0, enable },
  );

  const chart = useMemo(() => {
    if (diagramSalesLineChart?.isLoading) return <LoadingSpinner height={500} />;
    if (diagramSalesLineChart?.isError) return <ServerErrorBoundary onClick={diagramSalesLineChart.refetch} />;
    return (
      <>
        <ChartPortofolioLine data={diagramSalesLineChart?.data?.dataChart} />
        <Grid container justifyContent="center" spacing={4}>
          <Grid item>
            <LegendChartLineText
              icon={iconSquareBlue}
              text={diagramSalesLineChart?.data?.months[0]?.month_name || "-"}
            />
          </Grid>
          <Grid item>
            <LegendChartLineText
              icon={iconSquareGreen}
              text={diagramSalesLineChart?.data?.months[1]?.month_name || "-"}
            />
          </Grid>
          <Grid item>
            <LegendChartLineText
              icon={iconSquareYellow}
              text={diagramSalesLineChart?.data?.months[2]?.month_name || "-"}
            />
          </Grid>
        </Grid>
      </>
    );
  }, [diagramSalesLineChart]);

  return (
    <CardWrapper>
      <TitleChart icon={IconChartLine} title={t("SIDEBAR.SALES_VOLUME")} titleIdr={t("PORTOFOLIO.IDR_BIO")} />
      {chart}
    </CardWrapper>
  );
};

CardPortofolioSalesVolume.defaultProps = {
  enable: false,
  selectSalesAreaLogin: {},
};

CardPortofolioSalesVolume.propTypes = {
  enable: PropTypes.bool,
  selectSalesAreaLogin: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default CardPortofolioSalesVolume;
