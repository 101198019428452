import { Checkbox, ListItemText, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { ReactComponent as IconCheck } from "assets/icons/general/icon-check.svg";
import { MenuItem } from "elements/select";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles(() => ({
  checkbox: {
    overflow: "hidden",
    width: "3rem",
    height: "3rem",
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
  },
}));

/**
 * @type React.ForwardRefRenderFunction<React.HTMLButtonElement, Props>
 */
const MenuItemCheckBox = forwardRef(({ value, checked, text, size = "medium", ...rest }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem {...rest} value={value} ref={ref}>
      <Checkbox
        size={size === "medium" ? "100px" : null}
        checked={checked}
        icon={<IconCheck />}
        className={classes.checkbox}
      />
      <ListItemText primary={text} />
    </MenuItem>
  );
});

MenuItemCheckBox.defaultProps = {};

// eslint-disable-next-line no-unused-vars
const Props = {
  value: PropTypes.any.isRequired,
  text: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
};

MenuItemCheckBox.propTypes = {
  value: PropTypes.any.isRequired,
  text: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
};

export { MenuItemCheckBox };
