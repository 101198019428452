import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";
import {
  VALUES_TRANSACTIONS_ALIASES,
  orderingKeysTransaction,
  orderingKeysSummary,
  SUMMARY_OUTLET_ALIASES,
} from "./util";

const getSummaryOutlet = async signal => {
  const { data, status } = await axiosPrivate.get(`${ENDPOINT.BASEURL}/dashboard/transaction/detail/outlet/summary`, {
    signal,
  });

  if (status !== 200) throw new Error(`[getSummaryOutlet] ${status}`);

  const parsedData = Object.entries(orderingKeysSummary(data.data)).reduce((prev, [key, val]) => {
    return [
      ...prev,
      {
        category: SUMMARY_OUTLET_ALIASES[key],
        data: {
          outletName: val.outlet_name,
          nominal: val.total_bio,
        },
      },
    ];
  }, []);

  return { ...data, parsedData };
};

const getListDetailTransactionOulet = async ({ page, filterSalesArea, annualFilter, search, sorts }, signal) => {
  const { data: responseData, status } = await axiosPrivate.post(
    `${ENDPOINT.BASEURL}/dashboard/transaction/detail/outlet`,
    {
      filters: {
        sales_area: filterSalesArea || [],
        type: annualFilter || "YEARLY",
      },
      search,
      sorts,
      page: page ?? 1,
      size: 15,
    },
    { signal },
  );

  if (status !== 200) throw new Error(`[getListDetailTransactionOulet] ${status}`);
  const { data } = responseData;

  const transactionsParser = transactions =>
    transactions
      .reduce((prev, curr) => {
        return [
          ...prev,
          {
            ...curr,
            nominal: orderingKeysTransaction(curr.nominal),
            percentage: orderingKeysTransaction(curr.percentage),
          },
        ];
      }, [])
      .sort((a, b) => {
        if (["ONGOING", "YEARLY"].includes(String(annualFilter || "YEARLY"))) return Number(a.year) - Number(b.year);
        else return 1;
      });

  const parsedData = data.data.reduce((prev, curr) => {
    const { merchant, transactions } = curr;
    return [
      ...prev,
      {
        id: merchant.branding_id,
        name: merchant.branding_name,
        transactions: transactionsParser(transactions),
      },
    ];
  }, []);

  const heads =
    parsedData[0]?.transactions?.reduce((prev, curr) => {
      const head =
        String(annualFilter).toUpperCase() === "ONGOING" || String(annualFilter).toUpperCase() === "MONTHLY"
          ? `${curr.month} - ${curr.year}`
          : curr.year;
      return [...prev, head];
    }, []) ?? [];

  return {
    data: parsedData,
    subHeaders: VALUES_TRANSACTIONS_ALIASES,
    heads,
    page: {
      dataCount: data.row_count,
      totalPage: data.total_page,
      offset: data.page.offset,
      number: data.page.pageNumber,
      size: data.page.pageSize,
    },
    filter: {
      salesArea: data?.linked_sort_pagination_dto?.search_request?.filters?.sales_area ?? [],
      type: data?.linked_sort_pagination_dto?.search_request?.filters?.type ?? "Monthly",
    },
  };
};

const downloadListDetailTransactionOutlet = async ({
  page,
  filterSalesArea,
  annualFilter,
  search,
  typeSearch,
  sorts,
}) => {
  const { status, data: responseBody } = await axiosPrivate.post("/dashboard/transaction/detail/outlet/download", {
    filters: {
      sales_area: filterSalesArea ?? [],
      type: annualFilter || "YEARLY",
    },
    search,
    type_search: typeSearch,
    sorts,
    page: page ?? 0,
    size: 15,
  });
  if (status !== 200) throw new Error(`[downloadListDetailTransactionOutlet] ${status}`);

  return { payload: responseBody.data };
};

export { getListDetailTransactionOulet, getSummaryOutlet, downloadListDetailTransactionOutlet };
