import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  root: {
    width: props => props.width,
    height: props => props.heightsize || "auto",
    padding: props => props.paddingSize,
    backgroundColor: COLORS.PRIMARY_DARK,
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    color: COLORS.WHITE,
    textTransform: "initial",
    borderRadius: "10px",
    boxShadow: "0px 3px 10px rgba(84, 163, 216, 0.25)",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_DARK,
      color: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:disabled": {
      backgroundColor: COLORS.GRAY_MEDIUM,
      color: COLORS.WHITE,
    },
  },
});

const ButtonContained = ({ content, onClick, width, paddingSize, heightSize, disabled, type, className }) => {
  const props = { width, paddingSize, heightSize };
  const classes = useStyles(props);
  return (
    <Button className={clsx(classes.root, className)} onClick={onClick} type={type} disabled={disabled}>
      {content}
    </Button>
  );
};

ButtonContained.defaultProps = {
  width: "150px",
  paddingSize: "10px 18px",
  heightSize: "40px",
  onClick: () => {},
  disabled: false,
  type: "button",
  className: undefined,
  content: null,
};

ButtonContained.propTypes = {
  width: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
  paddingSize: PropTypes.string,
  heightSize: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonContained;
