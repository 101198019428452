import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  wrapperImg: {
    marginRight: "10px",
  },
  textTitle: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "14px",
  },
  textPercent: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14px",
  },
  wrapperLegend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.GRAY_PRIMARY,
  },
});

const LegendRingChart = ({ title, percent, imgElipse }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperImg}>
        <img src={imgElipse} alt="haha" />
      </div>
      <div>
        <Typography className={classes.textTitle}>{title}</Typography>
        <Typography className={classes.textPercent}>{percent} %</Typography>
      </div>
    </div>
  );
};

LegendRingChart.propTypes = {
  title: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  imgElipse: PropTypes.string.isRequired,
};

export default LegendRingChart;
