import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { SIZES } from "utils/constants/fonts";
import { thousandFormat } from "utils/helpers/useFormater";

const useStyles = makeStyles(() => ({
  textStyle: {
    margin: "-20px",
  },
  listWrap: {
    padding: 1,
  },
  numberTypography: {
    fontSize: SIZES.REGULAR_14,
  },
}));

const ListItemStatus = ({ number, iconSrc, text }) => {
  const classes = useStyles();
  return (
    <div>
      <List className={classes.listWrap} dense>
        <ListItem>
          <ListItemAvatar>
            <img src={iconSrc} alt="icon" />
          </ListItemAvatar>
          <ListItemText className={classes.textStyle}>{text}</ListItemText>
          <Typography className={classes.numberTypography}>{thousandFormat(number)}</Typography>
        </ListItem>
      </List>
    </div>
  );
};
ListItemStatus.propTypes = {
  number: PropTypes.number.isRequired,
  iconSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ListItemStatus;
