import React from "react";
import BetterWorseIcon from "elements/icon/better-worse-icon";
import CalculationProfitLossIcon from "elements/icon/calculation-profit-loss-icon";
import CasaIcon from "elements/icon/casa-icon";
import DetailRevenueIcon from "elements/icon/detail-revenue-icon";
import DetailTransactionIcon from "elements/icon/detail-transaction-icon";
import ExecutiveSummaryIcon from "elements/icon/executive-summary-icon";
import IncomingIcon from "elements/icon/incoming-icon";
import PortofolioIcon from "elements/icon/portofolio-icon";
import RbbIcon from "elements/icon/rbb-icon";
import SalesVolumeIcon from "elements/icon/sales-volume-icon";
import UserManagementIcon from "elements/icon/user-management-icon";
import COLORS from "utils/constants/colors";

export const privateMenuRoutes = [
  {
    name: "SIDEBAR.INCOMING_APPLICATION",
    key: 1,
    path: "/dashboard-incoming-application",
    icon: <IncomingIcon fill={COLORS.RED_HARD} />,
    iconWhite: <IncomingIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.PORTFOLIO",
    key: 2,
    path: "/dashboard-portofolio",
    icon: <PortofolioIcon fill={COLORS.RED_HARD} />,
    iconWhite: <PortofolioIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.SALES_VOLUME",
    key: 3,
    path: "/dashboard-portofolio-sales-volume",
    icon: <SalesVolumeIcon fill={COLORS.RED_HARD} />,
    iconWhite: <SalesVolumeIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.CASA",
    key: 4,
    path: "/dashboard-portofolio-by-casa",
    icon: <CasaIcon fill={COLORS.RED_HARD} />,
    iconWhite: <CasaIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.BETTER_WORSE_MERCHANT",
    key: 5,
    path: "/dashboard-worse-better-merchant",
    icon: <BetterWorseIcon fill={COLORS.RED_HARD} />,
    iconWhite: <BetterWorseIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.DETAIL_TRANSACTION",
    key: 6,
    path: "/dashboard-detail-transaction",
    icon: <DetailTransactionIcon fill={COLORS.RED_HARD} />,
    iconWhite: <DetailTransactionIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.DETAIL_REVENUE",
    key: 7,
    path: "/dashboard-detail-revenue-by-branding",
    icon: <DetailRevenueIcon fill={COLORS.RED_HARD} />,
    iconWhite: <DetailRevenueIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.EXECUTIVE_SUMMARY",
    key: 8,
    path: "/dashboard-executive-summary",
    icon: <ExecutiveSummaryIcon fill={COLORS.RED_HARD} />,
    iconWhite: <ExecutiveSummaryIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.CALCULATION_PROFIT_LOSS",
    key: 9,
    path: "/calculation-profit-loss",
    icon: <CalculationProfitLossIcon fill={COLORS.RED_HARD} />,
    iconWhite: <CalculationProfitLossIcon fill={COLORS.GRAY_PRIMARY} />,
  },
  {
    name: "SIDEBAR.RBB_AND_PORTOFOLIO",
    key: 10,
    path: "",
    icon: <RbbIcon fill={COLORS.RED_HARD} />,
    iconWhite: <RbbIcon fill={COLORS.GRAY_PRIMARY} />,

    subMenu: [
      {
        name: "SIDEBAR.PROFITABILITY",
        key: 10.1,
        path: "/dashboard-profitability",
      },
    ],
  },
  {
    name: "SIDEBAR.USER_MANAGEMENT",
    key: 11,
    path: "/user-management",
    icon: <UserManagementIcon fill={COLORS.RED_HARD} />,
    iconWhite: <UserManagementIcon fill={COLORS.GRAY_PRIMARY} />,
  },
];
