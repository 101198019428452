const QUERY_NAME = {
  TREND_CHART_MTD_BY_MERCHANT: "trend-chart-mtd-by-merchant",
  TREND_CHART_YTD_BY_MERCHANT: "trend-chart-ytd-by-merchant",
  BAR_CHART_COBA: "bar-chart-coba",
  BAR_CHART_COMBA: "bar-chart-comba",
  BAR_CHART_EMMERGING: "bar-chart-emmerging",
  BRANDING_TABLE_BY_MERCHANT: "branding_table_by_merchant",
  FILTER_BRANDING_TABLE_BY_MERCHANT: "filter_branding_table_by_merchant"
};

export default QUERY_NAME;
