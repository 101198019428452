import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  text: {
    textAlign: props => props.align,
    fontSize: props => props.size,
    fontWeight: props => (props.isBold ? 600 : 400),
    color: props => props.color,
    width: props => props.width,
  },
  boldStyle: {
    textAlign: props => props.align,
    fontSize: props => props.size,
    fontWeight: 600,
    color: props => props.color,
  },
});

const Description = ({ description, boldElement, align, size, color, isBold, width }) => {
  const props = { align, size, color, isBold, width };
  const classes = useStyles(props);

  return (
    <Typography className={classes.text}>
      {description} {boldElement && <span className={classes.boldStyle}>{` ${boldElement}`}</span>}
    </Typography>
  );
};

Description.defaultProps = {
  align: "left",
  size: SIZES.REGULAR_16,
  color: COLORS.GRAY_PRIMARY,
  isBold: false,
  boldElement: "",
  width: "100%",
};

Description.propTypes = {
  description: PropTypes.string.isRequired,
  align: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  isBold: PropTypes.bool,
  boldElement: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Description;
