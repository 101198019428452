import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const PortofolioIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.05527 11.9303C5.32383 12.1988 5.7591 12.1988 6.02766 11.9303L8.25 9.70836L11.889 13.3474C12.1576 13.6159 12.5929 13.6159 12.8614 13.3474L17.5974 8.61094L18.8646 9.87852C19.5143 10.5282 20.625 10.068 20.625 9.14934V4.8125C20.625 4.43266 20.3173 4.125 19.9375 4.125H15.6007C14.682 4.125 14.2218 5.23574 14.8715 5.88543L16.1391 7.15258L12.375 10.9166L8.73598 7.27762C8.46742 7.00906 8.03215 7.00906 7.76359 7.27762L4.5693 10.4719C4.30074 10.7405 4.30074 11.1757 4.5693 11.4443L5.05527 11.9303ZM21.3125 17.1875H2.0625V3.4375C2.0625 3.05766 1.75484 2.75 1.375 2.75H0.6875C0.307656 2.75 0 3.05766 0 3.4375V17.875C0 18.6343 0.615742 19.25 1.375 19.25H21.3125C21.6923 19.25 22 18.9423 22 18.5625V17.875C22 17.4952 21.6923 17.1875 21.3125 17.1875Z"
      fill={fill}
    />
  </SvgIcon>
);

PortofolioIcon.defaultProp = {
  fill: COLORS.WHITE,
};

PortofolioIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default PortofolioIcon;
