/**
 * Fomat date to dd/mmmm/yyyy
 * @param {Date} date
 * @returns {string} 1 November 2022
 */
const dateFormat = date => {
  const intl = new Intl.DateTimeFormat("en-US", { dateStyle: "long" }).format(date);
  const splitted = intl.split(", ");
  const dateMonth = splitted[0].split(/ /).reverse().join(" ");
  return [dateMonth, splitted[1]].join(" ");
};

export const intl = { dateFormat };
