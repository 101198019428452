import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// Icons
import IconAvgApp from "assets/icons/general/icon-mobile.svg";
import { ServerErrorBoundary } from "components/general";
import CardWrapper from "components/wrapper/wrapper-card";
import TextToggleButton from "elements/button/button-toggle-text";
import CardTitle from "elements/card/card-header/card-title";
import BarChartColor from "elements/chart/bar-chart-color";

// Color
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import { getAvgDeploy, getAvgIncoming } from "services/api/private-routes/incoming-application";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { dateUtility } from "utils/helpers/general/date-time";

const useStyles = makeStyles(() => ({
  typhographyStyle: {
    marginLeft: "8px",
    color: COLORS.GRAY_MEDIUM,
  },
  toggleButtonStyle: {
    padding: "2px",
    width: "91px",
    color: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    minWidth: "61px",
    height: "32px",
    borderRadius: "8px",
    borderColor: COLORS.PRIMARY_DARK,
    textTransform: "initial",
    fontSize: SIZES.REGULAR_14,
    "&.Mui-selected": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.PRIMARY_DARK,
      "&:hover": {
        backgroundColor: COLORS.PRIMARY_DARK,
      },
    },
  },
  wrapperToggle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapTitle: {
    display: "flex",
  },
}));

/* -------------------------------------------------------------------------- */
/*                                 Chart Config                               */
/* -------------------------------------------------------------------------- */
const intervalPosition = "name*sum";
const namaGrid = "sum";
const colorChart = ["sum", ["#ff0000", "#ffff00"]];

const CardAvgApplication = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [date] = useIncomingFilter();
  const { signal } = useAbortController();

  const translation = useMemo(
    () => ({
      toggle: {
        incoming: t("INCOMING.INCOMING"),
        deploy: t("INCOMING.DEPLOY"),
      },
    }),
    [t],
  );

  const [toggle, setTogle] = useState(translation.toggle.incoming);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const dataAvgIncoming = useQuery(
    ["data-avg-incoming", { ...date }],
    () =>
      getAvgIncoming(
        {
          startDate: dateUtility(date.start).isoSeparator(),
          endDate: dateUtility(date.end).isoSeparator(),
        },
        signal,
      ),
    {
      enabled: toggle === translation.toggle.incoming,
      retry: 0,
      keepPreviousData: true,
    },
  );

  const dataAvgDeploy = useQuery(
    ["data-avg-deploy", { ...date }],
    () =>
      getAvgDeploy(
        {
          startDate: dateUtility(date.start).isoSeparator(),
          endDate: dateUtility(date.end).isoSeparator(),
        },
        signal,
      ),
    {
      enabled: toggle === translation.toggle.deploy,
      retry: 0,
      keepPreviousData: true,
    },
  );

  const activeQuery = useMemo(() => {
    return {
      [translation.toggle.incoming]: dataAvgIncoming,
      [translation.toggle.deploy]: dataAvgDeploy,
    }[toggle];
  }, [dataAvgDeploy, dataAvgIncoming, toggle, translation]);

  const content = useMemo(() => {
    if (activeQuery?.isLoading) return <LoadingSpinner height={348} />;
    if (activeQuery?.isError)
      return <ServerErrorBoundary onClick={activeQuery?.refetch} title={activeQuery?.error?.message} />;
    if (activeQuery?.isSuccess)
      return (
        <BarChartColor
          data={activeQuery?.data}
          legendView
          heightChart={348}
          positionName={intervalPosition}
          axisNameGrid={namaGrid}
          colors={colorChart}
        />
      );
    return null;
  }, [activeQuery]);

  return (
    <CardWrapper>
      <Grid container style={{ alignItems: "center" }} justifyContent="space-between">
        <Grid item xs={10} style={{ alignItems: "center" }}>
          <div className={classes.wrapTitle}>
            <CardTitle iconSrc={IconAvgApp} title={t("CARD.TITLE_AVG_APP")} />
            {toggle === translation.toggle.incoming ? (
              <Typography className={classes.typhographyStyle} variant="subtitle1">
                ({t("GENERAL.INCOMING")})
              </Typography>
            ) : (
              <Typography className={classes.typhographyStyle} variant="subtitle1">
                ({t("GENERAL.DEPLOY")})
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={2} className={classes.wrapperToggle}>
          <TextToggleButton toggleData={Object.values(translation.toggle)} selected={toggle} onChange={setTogle} />
        </Grid>
      </Grid>
      {content}
    </CardWrapper>
  );
};

export default CardAvgApplication;
