import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

export const useStyles = makeStyles(() => ({
  boxTransparent: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center|top",
    height: "86px",
    marginTop: "-10px",
  },

  buttonPrint: {
    width: "82px",
    height: "40px",
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    border: "2px solid",
    borderColor: COLORS.PRIMARY_DARK,
    borderRadius: "10px",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },
  buttonExcel: {
    width: "155px",
    height: "40px",
    marginLeft: 20,
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    borderRadius: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },

  buttonApply: {
    backgroundColor: COLORS.PRIMARY_DARK,
    borderRadius: "10px",
    color: COLORS.WHITE,
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },
  cardFilter: {
    height: "70px",
    padding: "15px",
    borderRadius: "15px",
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  },
  cardTable: {
    padding: "20px",
    borderRadius: "15px",
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    marginTop: "30px",
  },
  customSelect: {
    marginRight: "20px",
  },
  grid: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxChip: {
    display: "flex",
    flexWrap: "wrap",
    gap: ".5rem",
    alignItems: "center",
    marginTop: "10px",
  },
  buttonReset: {
    width: "130px",
    height: "32px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "30px",
    borderColor: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    border: "2px solid",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
  },
}));
