import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IconNumber from "elements/dynamic-icon/icon-number";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const ListCommonStyle = styled("div")(({ borderstyle, height }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: borderstyle,
  height,
}));

const Flex1Style = styled("div")(({ flex1 }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: flex1,
}));

const Flex2Style = styled("div")(({ flex2 }) => ({
  display: "flex",
  flex: flex2,
  justifyContent: "flex-end",
}));

const StyleTypography = styled(Typography)(({ fontSize, color = COLORS.DARK_HARD, name }) => ({
  marginLeft: "10px",
  fontWeight: name === "Branding" ? 600 : 400,
  color,
  fontSize,
}));

const ListCommon = ({
  number,
  name,
  sum,
  fontWeight,
  borderBottomSize,
  height,
  fontSize,
  flex1,
  flex2,
  onMouseOver,
  onMouseOut,
}) => (
  <ListCommonStyle borderstyle={borderBottomSize} height={height} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
    <Flex1Style flex1={flex1}>
      {number !== 0 ? <IconNumber number={number} /> : <div style={{ marginLeft: 26 }} />}
      <StyleTypography
        variant="subtitle1"
        component="div"
        align="left"
        fontSize={fontSize}
        name={name}
        color={number !== 0 ? COLORS.DARK_HARD : COLORS.GRAY_DARK}
      >
        {name}
      </StyleTypography>
    </Flex1Style>
    <Flex2Style flex2={flex2}>
      <StyleTypography
        variant="subtitle1"
        component="div"
        name={name}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={number !== 0 ? COLORS.DARK_HARD : COLORS.GRAY_DARK}
      >
        {sum}
      </StyleTypography>
    </Flex2Style>
  </ListCommonStyle>
);

StyleTypography.defaultProps = {
  fontSize: SIZES.REGULAR_14,
};

StyleTypography.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
};

Flex1Style.propTypes = {
  flex1: PropTypes.number,
};

Flex1Style.propTypes = {
  flex2: PropTypes.number,
};

ListCommonStyle.propTypes = {
  borderStyle: PropTypes.string,
  height: PropTypes.number,
};

ListCommon.defaultProps = {
  fontWeight: 400,
  borderBottomSize: "1px solid #E9F0FF",
  height: 50,
  fontSize: SIZES.REGULAR_14,
  flex1: 0.5,
  flex2: 0.5,
};

ListCommon.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sum: PropTypes.string.isRequired,
  fontWeight: PropTypes.number,
  borderBottomSize: PropTypes.string,
  height: PropTypes.number,
  fontSize: PropTypes.string,
  flex1: PropTypes.number,
  flex2: PropTypes.number,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
};

export default ListCommon;
