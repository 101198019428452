import { zodResolver } from "@hookform/resolvers/zod";
import { styled, Typography } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import SelectInput from "components/input/select-input";
import Textfield from "components/input/text-field";
import TextfieldClean from "components/input/text-field-clean";
import TextfieldPrefix from "components/input/text-field-prefix";
import ButtonContained from "elements/button/button-contained";
import ButtonOutlined from "elements/button/button-outlined";
import FootNote from "elements/foot-note";
import { MenuItem, Placeholder, Select, SelectMultiple, SelectValue } from "elements/select";
import { MenuItemCheckBox } from "elements/select/menu-item-checkbox";
import { TextFormLabel } from "elements/typhography/text-form-label";
import { useQueryRoles } from "features/list-roles";
import { useQuerySalesArea } from "features/sales-area";
import { getListPosition, getListLocation, getListSupervisor } from "services/api/private-routes";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const FormEditUserManagementStyle = styled("form")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  marginTop: "37px",
});

const ContainerStyle = styled("div")({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  padding: "30px",
  backgroundColor: COLORS.WHITE,
  boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  borderRadius: "15px",
});

const TitleTypography = styled(Typography)({
  color: COLORS.DARK_HARD,
  fontSize: SIZES.MEDIUM_22,
  fontWeight: 500,
});

const DescTypography = styled(Typography)({
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_14,
  fontWeight: 400,
});

const SpaceDiv = styled("div")(({ height }) => ({
  height,
}));

const ContainerFormStyle = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const FormStyle = styled("div")(({ borderRight, paddingLeft, paddingRight }) => ({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  borderRight: borderRight === true ? "1px solid #E9F0FF" : "",
  paddingLeft,
  paddingRight,
}));

const ContainerBottomStyle = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  marginTop: "41px",
  justifyContent: "space-between",
});

const FormEditUserManagement = ({ userData, submitCancel, onSubmit }) => {
  const { t } = useTranslation();
  const listPosition = useQuery(["list-position"], () => getListPosition(), { retry: 0 });
  const listLocation = useQuery(["list-location"], () => getListLocation(), { retry: 0 });
  const listSupervisor = useQuery(["list-supervisor"], () => getListSupervisor(), { retry: 0 });
  const queryRoles = useQueryRoles();
  const querySalesArea = useQuerySalesArea();

  const editSchema = z.object({
    full_name: z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")),
    email: z.string().min(1, t("FOOTNOTE.REQUIRED_FIELD")).email(t("FOOTNOTE.INVALID_EMAIL")),
    phone_number: z
      .string()
      .min(1, t("FOOTNOTE.REQUIRED_FIELD"))
      .transform((phone, ctx) => {
        if (phone?.length <= 4) ctx.addIssue({ code: "custom", message: "Phone number must be atleast 4 digit" });
        else return phone;
      }),
    position: z.number({ invalid_type_error: t("FOOTNOTE.REQUIRED_FIELD") }),
    role: z.string().min(1),
    roleUser: z.array(z.string()),
    supervisor_code: z.string().transform((arg, ctx) => {
      const selectedRole = getValues("role");
      const roleId = queryRoles.data.findByName?.(selectedRole)?.id;
      const supervisorCode = listSupervisor.data?.findByUserCode(arg);
      if ((roleId === 3 || roleId === 6) && !supervisorCode)
        return ctx.addIssue({
          code: "custom",
          message: t("FOOTNOTE.REQUIRED_FIELD"),
        });
      return arg;
    }),
    location: z.number({ invalid_type_error: t("FOOTNOTE.REQUIRED_FIELD") }),
    sales_area: z.array(z.string()).min(1),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      full_name: userData.fullName || "",
      email: userData.email || "",
      phone_number: userData.phoneNumber || "",
      position: userData.position?.id || 0,
      role: userData.role?.name || [],
      roleUser: userData?.roleUser?.map(x => x.name) || [],
      supervisor_code: userData.supervisor?.user_code || "",
      location: userData.location?.id || 0,
      sales_area: userData?.salesAreaUsers?.map(x => x.name),
    },
    resolver: zodResolver(editSchema),
  });

  const handleOnSubmit = handleSubmit(data => {
    const payload = {
      ...data,
      // roleUser: data.roleUser?.map(x => queryRoles.data.findByName(x).id),
      roleUser: [queryRoles.data.findByName(data.role)?.id],
      sales_area: data?.sales_area?.map(x => querySalesArea.data.findByName(x).id),
      role: queryRoles.data.findByName(data.role)?.id,
    };
    onSubmit && onSubmit(payload);
  });

  return (
    <FormEditUserManagementStyle onSubmit={handleOnSubmit}>
      <ContainerStyle>
        <TitleTypography>{t("USERMANAGEMENT.EDIT")}</TitleTypography>
        <SpaceDiv height="5px" />
        <DescTypography>{t("USERMANAGEMENT.DESC")}</DescTypography>
        <SpaceDiv height="30px" />
        <ContainerFormStyle>
          <FormStyle borderRight paddingRight="50px">
            <Controller
              control={control}
              name="full_name"
              render={({ field }) => (
                <TextfieldClean
                  label={t("GENERAL.FULL_NAME")}
                  placeholder={t("PLACEHOLDER.INPUT_FULL_NAME")}
                  isInvalid={!!errors?.full_name?.message}
                  clearHandler={() => setValue("full_name", "")}
                  {...field}
                />
              )}
            />
            {!!errors?.full_name?.message && <FootNote content={errors.full_name.message} />}
            <SpaceDiv height="20px" />
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Textfield
                  label={t("GENERAL.EMAIL")}
                  placeholder={t("PLACEHOLDER.INPUT_EMAIL")}
                  isInvalid={!!errors?.email?.message}
                  disabled
                  {...field}
                />
              )}
            />
            {!!errors?.email?.message && <FootNote content={errors.email.message} />}
            <SpaceDiv height="20px" />
            <Controller
              control={control}
              name="phone_number"
              render={({ field }) => (
                <TextfieldPrefix
                  label={t("GENERAL.PHONE_NUMBER")}
                  placeholder={t("PLACEHOLDER.INPUT_PHONE_NUMBER")}
                  isInvalid={!!errors?.phone_number?.message}
                  {...field}
                />
              )}
            />
            {!!errors?.phone_number?.message && <FootNote content={errors.phone_number.message} />}
            <SpaceDiv height="20px" />
            <Controller
              control={control}
              name="position"
              render={({ field }) => (
                <SelectInput
                  label={t("GENERAL.POSITION")}
                  placeholder={t("PLACEHOLDER.SELECT_POSITION")}
                  isInvalid={!!errors?.position?.message}
                  dataSelect={listPosition.data?.parsedData}
                  {...field}
                />
              )}
            />
            {!!errors?.position?.message && <FootNote content={t("FOOTNOTE.REQUIRED_FIELD")} />}
            <SpaceDiv height="20px" />
            <TextFormLabel>{t("GENERAL.ROLE")}</TextFormLabel>
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <Select
                  value={field?.value}
                  onChange={e => {
                    setValue("supervisor_code", "");
                    field.onChange(e);
                  }}
                  disableUnderline
                  renderValue={() =>
                    field?.value?.length >= 1 ? (
                      <SelectValue style={{ fontSize: "16px" }}>{field?.value}</SelectValue>
                    ) : (
                      <Placeholder style={{ fontSize: "16px" }}>{t("PLACEHOLDER.SELECT_ROLE")}</Placeholder>
                    )
                  }
                >
                  {queryRoles?.data?.names?.map(x => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {!!errors?.role?.message && <FootNote content={t("FOOTNOTE.REQUIRED_FIELD")} />}
            {/* <Controller
              control={control}
              name="roleUser"
              render={({ field }) => (
                <SelectMultiple
                  {...field}
                  value={field.value}
                  setValue={val => field.onChange(val)}
                  renderValue={() =>
                    field.value.length >= 1 ? (
                      <SelectValue style={{ fontSize: "16px" }}>{field.value.length} area(s) selected</SelectValue>
                    ) : (
                      <Placeholder style={{ fontSize: "16px" }}>{t("PLACEHOLDER.SELECT_ROLE")}</Placeholder>
                    )
                  }
                >
                  {queryRoles.data.names.map(x => (
                    <MenuItemCheckBox value={x} checked={field.value.includes(x)} text={x} key={x} />
                  ))}
                </SelectMultiple>
              )}
            /> */}
          </FormStyle>
          <FormStyle paddingLeft="50px">
            <Controller
              control={control}
              name="supervisor_code"
              render={({ field }) => (
                <SelectInput
                  label={t("GENERAL.SUPERVISOR")}
                  placeholder={t("PLACEHOLDER.SELECT_SUPERVISOR")}
                  isInvalid={!!errors?.supervisor_code?.message}
                  dataSelect={listSupervisor.data?.parsedData}
                  {...field}
                  value={getValues("supervisor_code") || ""}
                  onChange={e => setValue("supervisor_code", e.target.value)}
                  isUpperCase
                />
              )}
            />
            {!!errors?.supervisor_code?.message && <FootNote content={t("FOOTNOTE.REQUIRED_FIELD")} />}
            <SpaceDiv height="20px" />
            <Controller
              control={control}
              name="location"
              render={({ field }) => (
                <SelectInput
                  label={t("GENERAL.LOCATION")}
                  placeholder={t("PLACEHOLDER.SELECT_LOCATION")}
                  isInvalid={!!errors?.location?.message}
                  dataSelect={listLocation.data?.parsedData}
                  {...field}
                />
              )}
            />
            {!!errors?.location?.message && <FootNote content={t("FOOTNOTE.REQUIRED_FIELD")} />}
            <SpaceDiv height="20px" />
            <TextFormLabel>{t("GENERAL.SALES_AREA")}</TextFormLabel>
            <Controller
              control={control}
              name="sales_area"
              render={({ field }) => {
                const KEY_CHOOSE_ALL = "Choose All";
                const { names } = querySalesArea.data;
                const isSelectedAll = field?.value?.length === names.length;
                return (
                  <SelectMultiple
                    value={field?.value}
                    setValue={val => {
                      if (val[val.length - 1] === KEY_CHOOSE_ALL)
                        field.onChange(names.length === field?.value?.length ? [] : names);
                      else field.onChange(val);
                    }}
                    renderValue={() =>
                      field?.value?.length > 0 ? (
                        <SelectValue>{field?.value?.length} area(s) selected</SelectValue>
                      ) : (
                        <Placeholder>{t("PLACEHOLDER.SELECT_SALES_AREA")}</Placeholder>
                      )
                    }
                  >
                    <MenuItemCheckBox text={KEY_CHOOSE_ALL} value={KEY_CHOOSE_ALL} checked={isSelectedAll} />
                    {names?.map(x => (
                      <MenuItemCheckBox text={x} key={x} value={x} checked={field.value.includes(x)} />
                    ))}
                  </SelectMultiple>
                );
              }}
            />
            {!!errors?.sales_area?.message && <FootNote content={t("FOOTNOTE.REQUIRED_FIELD")} />}
          </FormStyle>
        </ContainerFormStyle>
        <ContainerBottomStyle>
          <ButtonOutlined content={t("GENERAL.CANCEL")} onClick={submitCancel} />
          <ButtonContained
            content={t("GENERAL.SAVE")}
            type="submit"
            disabled={
              ![
                listLocation.isSuccess,
                listSupervisor.isSuccess,
                queryRoles.isSuccess,
                querySalesArea.isSuccess,
                listPosition.isSuccess,
              ].every(Boolean)
            }
          />
        </ContainerBottomStyle>
      </ContainerStyle>
    </FormEditUserManagementStyle>
  );
};

FormStyle.defaultProps = {
  paddingLeft: "0px",
  paddingRight: "0px",
};

FormStyle.propTypes = {
  borderRight: PropTypes.bool.isRequired,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
};

FormEditUserManagement.defaultProps = {
  userData: {},
};

FormEditUserManagement.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userData: PropTypes.object,
  submitCancel: PropTypes.func.isRequired,
};

export default FormEditUserManagement;
