import { AxiosError } from "axios";
import ENDPOINT from "utils/constants/endpoint";

const { axiosPrivate } = require("../axiosPrivate");

const getPdfTerminology = async (signal, category) => {
  const { data, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/profitabilityservice/profit/terminology?feature=${category}`,
    {
      signal,
    },
  );
  if (status !== 200) throw new Error(`[pdf-terminology] ${status}`);

  const docs = [
    {
      uri: data.data.uri,
      fileType: data.data.file_type,
      fileName: " ",
    },
  ];

  return docs;
};

const uploadPdfTerminology = async ({ formData, typeCategory }) => {
  const { data, status } = await axiosPrivate
    .post(`${ENDPOINT.BASEURL}/profitabilityservice/profit/terminology?feature=${typeCategory}`, formData, {
      "Content-Type": "multipart/form-data",
    })
    .catch(reason => {
      if (reason instanceof AxiosError) {
        return { data: reason.response.data, status: reason.response.status };
      }
    });

  if (status !== 200) {
    return { invalid: true, data };
  }
  return { data };
};

export { getPdfTerminology, uploadPdfTerminology };
