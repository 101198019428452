import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconChartLine from "assets/icons/chart/icon-chart-line.svg";
import iconSquareBlue from "assets/icons/general/icon-rounded-rectangle-blue.svg";
import iconSquareGreen from "assets/icons/general/icon-rounded-rectangle-green.svg";
import iconSquareYellow from "assets/icons/general/icon-rounded-rectangle-orange.svg";
import ChartPortofolioLine from "components/chart/chart-portofolio-line";
import { ServerErrorBoundary } from "components/general";
import LegendChartLineText from "components/legend/legend-chart-line-text";
import TitleChart from "components/title/title-chart";
import CardWrapper from "components/wrapper/wrapper-card";
import LoadingSpinner from "elements/loading/spinner";
import { getCASALine } from "services/api/private-routes";

const CardPortofolioByCasa = ({ selectSalesAreaLogin }) => {
  const { t } = useTranslation();

  const diagramLineCASA = useQuery(["diagram-line-casa", selectSalesAreaLogin], () =>
    getCASALine(selectSalesAreaLogin?.id ?? ""),
  );

  const chart = useMemo(() => {
    if (diagramLineCASA.isLoading) return <LoadingSpinner height={500} />;
    if (diagramLineCASA.isError) return <ServerErrorBoundary onClick={diagramLineCASA.refetch} />;
    const { months } = diagramLineCASA.data;
    return (
      <>
        <ChartPortofolioLine data={diagramLineCASA.data?.data_chart} />
        <Grid container justifyContent="center" spacing={4}>
          <Grid item>
            <LegendChartLineText icon={iconSquareBlue} text={months[0]?.month_name || "-"} />
          </Grid>
          <Grid item>
            <LegendChartLineText icon={iconSquareGreen} text={months[1]?.month_name || "-"} />
          </Grid>
          <Grid item>
            <LegendChartLineText icon={iconSquareYellow} text={months[2]?.month_name || "-"} />
          </Grid>
        </Grid>
      </>
    );
  }, [diagramLineCASA]);

  return (
    <CardWrapper>
      <TitleChart icon={IconChartLine} title={t("SIDEBAR.CASA")} titleIdr={t("PORTOFOLIO.IDR_BIO")} />
      {chart}
    </CardWrapper>
  );
};

CardPortofolioByCasa.defaultProps = {
  selectSalesAreaLogin: "",
};

CardPortofolioByCasa.propTypes = {
  selectSalesAreaLogin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { CardPortofolioByCasa };
