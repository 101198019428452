/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ChevronDown from "assets/icons/general/icon-chevron-down.svg";
import ChevronUp from "assets/icons/general/icon-chevron-up.svg";
import COLORS from "utils/constants/colors";

const Accordion = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  function handleOpen() {
    setOpen(prev => !prev);
  }
  return (
    <div>
      <div onClick={handleOpen} className={classes.containerTitle}>
        {open ? (
          <img src={ChevronUp} alt={ChevronDown} />
        ) : (
          <img src={ChevronDown} alt={ChevronDown} />
        )}
        <span>{props.title}</span>
      </div>
      {open ? (
        <div className={classes.containerOptions}>
          {props.options.map((item, index) => (
            <span className={classes.textOption} key={index}>
              {item}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
};

Accordion.defaultProps = {
  title: "title",
};

const useStyles = makeStyles({
  containerTitle: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 6,
    alignItems: "center",
    cursor: "pointer",
  },
  containerOptions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 8,
    paddingLeft: 22,
  },
  textOption: {
    color: COLORS.GRAY_DARK,
  },
});

export default Accordion;
