import { makeStyles } from "@material-ui/core";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  cardTable: {
    width: "100%",
    padding: "20px 20px 35px 20px",
    borderRadius: "15px",
    marginTop: "30px",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxCentered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  typographyTitle: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
  },
  typographyDesc: {
    fontSize: SIZES.REGULAR_16,
    fontWeight: 600,
    color: COLORS.GRAY_MEDIUM,
    marginLeft: 5,
  },
  legendSalesVolumeWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  legendSingleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  buttonContained: {
    width: "171px",
    height: 40,
    borderRadius: "10px",
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    fontSize: SIZES.REGULAR_14,
    fontWeight: 500,
    textTransform: "initial",
  },
});

export { useStyles };
