import { Box, IconButton, makeStyles, Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IconClose from "assets/icons/general/icon-close.svg";
import ButtonContained from "elements/button/button-contained";
import Description from "elements/description";
import Title from "elements/title";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxStyle: {
    width: "385px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: COLORS.WHITE,
  },
  iconButtonBoxStyle: {
    height: "24px",
    width: "24px",
    alignSelf: "end",
  },
  imgBoxStyle: {
    marginTop: "20px",
    height: "128px",
    width: "128px",
    alignSelf: "center",
    marginBottom: "20px",
  },
  descStyle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginBottom: "25px",
  },
}));

const PopupInformation = ({ isOpen, isClose, onClose, onSubmit, icon, title, subtitle, description, buttonText }) => {
  const classes = useStyles();
  let descriptionContent;

  if (typeof description === "string") {
    descriptionContent = (
      <Description description={description} align="center" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
    );
  } else if (Array.isArray(description)) {
    descriptionContent = description.map(x => (
      <Description description={x} key={x} align="center" size={SIZES.REGULAR_14} color={COLORS.DARK_MEDIUM} />
    ));
  } else {
    descriptionContent = description;
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modalStyle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.boxStyle}>
        {isClose && (
          <IconButton onClick={onClose} className={classes.iconButtonBoxStyle}>
            <img src={IconClose} alt="icon-close" />
          </IconButton>
        )}
        <Title title={title} align="center" fontWeight={600} />
        <img src={icon} alt={icon} className={classes.imgBoxStyle} />
        <div className={classes.descStyle}>
          {subtitle && (
            <div>
              <Description
                description={subtitle}
                align="center"
                size={SIZES.REGULAR_18}
                color={COLORS.DARK_MEDIUM}
                isBold
              />
              <div style={{ height: "10px" }} />
            </div>
          )}
          {descriptionContent}
        </div>
        <ButtonContained content={buttonText} width="100%" onClick={onSubmit} />
      </Box>
    </Modal>
  );
};

PopupInformation.defaultProps = {
  isOpen: false,
  isClose: false,
  subtitle: "",
  description: null,
  onClose: () => {},
  icon: null,
  title: null,
  buttonText: null,
};

PopupInformation.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.string]),
  buttonText: PropTypes.string,
};

export default PopupInformation;
