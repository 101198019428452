import { InputLabel, Checkbox, ListItemText, makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCheck } from "assets/icons/general/icon-check.svg";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { dummyPositions } from "utils/constants/mocks/register";

const DropdownProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  style: {
    marginTop: "5px",
    marginLeft: "-10px",
    borderRadius: "10px",
  },

  getContentAnchorEl: null,
};

const useStyles = makeStyles(() => ({
  labelStyle: {
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    color: COLORS.DARK_HARD,
    fontSize: SIZES.REGULAR_14,
    marginBottom: "5px",
  },
  selectStyle: {
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    width: "100%",
    size: "14px",
    borderRadius: "10px",
    lineHeight: "20px",
    "& .MuiSelect-select": {
      borderRadius: "10px",
      paddingLeft: "10px",
      lineHeight: "20px",
      height: "27px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-icon": {
      marginRight: "10px",
      marginTop: "4px",
      alignItems: "center",
    },
  },
  checkbox: {
    paddingLeft: 0,
    paddingRight: 10,
    "&.Mui-checked": {
      color: COLORS.PRIMARY_DARK,
    },
  },
  menuItem: {
    textTransform: "none",
    backgroundColor: "transparent",
    "&.Mui-selected": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_ULTRASOFT,
    },
    height: "52px",
  },
}));

const SelectInputMultiple = forwardRef(
  ({ label, onChange, onChooseAll, placeholder, dataSelect, isInvalid, value, ...rest }, ref) => {
    const { t } = useTranslation();
    const classes = useStyles({ isInvalid });
    const renderDefaultValue = val => {
      if (val?.length === 0) {
        return <span style={{ color: COLORS.GRAY_HARD }}>{placeholder}</span>;
      }

      return <span style={{ color: COLORS.GRAY_HARD.at }}>{`${val?.length} area(s) selected`}</span>;
    };

    return (
      <>
        <InputLabel className={classes.labelStyle}>{label} :</InputLabel>
        <Select
          className={classes.selectStyle}
          multiple
          displayEmpty
          MenuProps={DropdownProps}
          IconComponent={IconChevronDown}
          disableUnderline
          renderValue={val => renderDefaultValue(val)}
          ref={ref}
          value={value}
          {...rest}
        >
          <MenuItem className={classes.menuItem}>
            <Checkbox
              onChange={onChooseAll}
              checked={value.length === dataSelect.length}
              icon={<IconCheck />}
              className={classes.checkbox}
            />
            {t("GENERAL.CHOOSE_ALL")}
          </MenuItem>
          {dataSelect.map(item => (
            <MenuItem key={item.id} value={item.id} className={classes.menuItem}>
              <Checkbox
                onChange={onChange}
                // checked={value.indexOf(item.id) >= 0}
                checked={value.indexOf(item.id) >= 0}
                icon={<IconCheck />}
                className={classes.checkbox}
                value={item.id}
              />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </>
    );
  },
);

SelectInputMultiple.defaultProps = {
  checkedAll: false,
  checkedData: [],
  isInvalid: false,
  selected: undefined,
  dataSelect: dummyPositions,
  onChooseAll: () => {},
  value: [],
};

SelectInputMultiple.propTypes = {
  checkedAll: PropTypes.bool,
  checkedData: PropTypes.arrayOf(PropTypes.number),
  label: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChooseAll: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  dataSelect: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.array,
};

export default SelectInputMultiple;
