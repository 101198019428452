import { styled } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// Icons
import iconList from "assets/icons/general/icon-map-marker.svg";

// Compoenents
import ChartListBar from "components/chart/chart-list-bar";
import { ServerErrorBoundary } from "components/general";
import TitleChart from "components/title/title-chart";
import TitleTable from "components/title/title-table";
import LoadingSpinner from "elements/loading/spinner";

// Color
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import { getDataTopAreaCoordinatorContribution } from "services/api/private-routes/incoming-application/topAreaCoordinatorContribution";
import COLORS from "utils/constants/colors";
import { dateUtility } from "utils/helpers/general/date-time";
// import { useFetchDataTopAreaCoordinatorContribution } from "./useFetchData";

const CardTopAreaStyle = styled("div")({
  backgroundColor: COLORS.WHITE,
  padding: "20px",
  borderRadius: "15px",
  boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
  flex: 1,
});

const HEIGHT_SIZE_COMP = 250;

const SpaceStyle = styled("div")({
  height: "14px",
});

const CardTopArea = () => {
  const { t } = useTranslation();
  const [date] = useIncomingFilter();

  const translations = useMemo(
    () => ({
      titleCharts: t("INCOMING.TOP_AREA"),
      titleTable: {
        title1: t("INCOMING.PIC"),
        title2: t("INCOMING.APPROVAL"),
      },
    }),
    [t],
  );

  const dataTopAreaCoordinatorContribution = useQuery(["data-top-area-coordinator-contribution", { ...date }], () =>
    getDataTopAreaCoordinatorContribution({
      startDate: dateUtility(date.start).isoSeparator(),
      endDate: dateUtility(date.end).isoSeparator(),
    }),
  );

  const content = useMemo(() => {
    if (dataTopAreaCoordinatorContribution?.isError)
      return (
        <ServerErrorBoundary
          height={HEIGHT_SIZE_COMP}
          title={dataTopAreaCoordinatorContribution?.error?.message}
          onClick={dataTopAreaCoordinatorContribution.refetch}
        />
      );
    if (dataTopAreaCoordinatorContribution?.isFetching) return <LoadingSpinner height={HEIGHT_SIZE_COMP} />;
    if (!dataTopAreaCoordinatorContribution?.isError && dataTopAreaCoordinatorContribution?.isSuccess) {
      return (
        <>
          {dataTopAreaCoordinatorContribution?.data?.data?.map(listSale => {
            return (
              <ChartListBar
                key={listSale.name}
                name={listSale.name}
                percen={listSale.percen}
                sum={listSale.sum}
                percenNum={listSale.percenNum}
              />
            );
          })}
        </>
      );
    }
    return null;
  }, [dataTopAreaCoordinatorContribution]);

  return (
    <CardTopAreaStyle>
      <TitleChart icon={iconList} title={translations.titleCharts} />
      <SpaceStyle />
      <TitleTable
        title1={translations.titleTable.title1}
        title2={translations.titleTable.title2}
        align="right"
        flex1={0.45}
        flex2={0.55}
      />
      {content}
    </CardTopAreaStyle>
  );
};

export default CardTopArea;
