import { useRef, useEffect } from "react";

export const useAbortController = () => {
  const abortController = useRef(new AbortController());

  useEffect(() => {
    // eslint-disable-next-line
    return () => !abortController.current.signal.aborted && abortController.current.abort();
  }, []);

  return { signal: abortController.current.signal, abort: abortController.current.abort };
};
