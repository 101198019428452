import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const StyledFootnote = styled(Typography)({
  color: COLORS.PRIMARY_DARK,
  fontSize: SIZES.REGULAR_12,
  marginTop: "5px",
});

const FootNote = ({ content }) => <StyledFootnote>{content}</StyledFootnote>;

FootNote.propTypes = {
  content: PropTypes.string.isRequired,
};

export default FootNote;
