import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getListBetterWorseCASA = async ({ filterSalesArea, search, typeSearch }, signal) => {
  const { data, status } = await axiosPrivate.post(
    `${ENDPOINT.BASEURL}/dashboard/transaction/detail/better-worse/casa`,
    {
      filters: {
        sales_area: filterSalesArea,
      },
      search,
      type_search: typeSearch,
    },
    { signal },
  );
  if (status !== 200) throw new Error(`[getListBetterWorseSalesVolume] ${status}`);
  if (data.data.length === 0) return { data: { tableData: data.data } };

  const keyData = data.data.find(x => x.branding);
  const subheader = Object.keys(keyData)
    .filter(item => item !== "branding")
    .sort()
    .reverse()
    .map(item => {
      if (item === "better_worse") {
        return ["Monthly", "Yearly"];
      }
      return keyData[item].months.map(val => {
        if (val.name.slice(0, 2) === "RR") {
          return val.name.slice(0, 6);
        }
        return val.name.slice(0, 3);
      });
    });

  const header = Object.keys(keyData)
    .filter(item => item !== "branding")
    .sort()
    .reverse()
    .map(item => {
      if (item === "better_worse") {
        return "Better/Worse";
      }
      return keyData[item].year;
    });

  const total = {
    current_year: {
      months: [
        {
          name: keyData.current_year.months[0].name,
          value_bio: data.data
            .map(item => (Array.isArray(item.current_year.months) ? item.current_year.months[0].value_bio : 0))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2),
        },
        {
          name: keyData.current_year.months[1].name,
          value_bio: data.data
            .map(item => (Array.isArray(item.current_year.months) ? item.current_year?.months[1]?.value_bio : 0))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2),
        },

        {
          name: keyData.current_year.months[2].name,
          value_bio: data.data
            .map(item => (Array.isArray(item.current_year.months) ? item.current_year?.months[2]?.value_bio : 0))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2),
        },
      ],
    },
    last_year: {
      months: [
        {
          name: keyData.last_year.months[0].name,
          value_bio: data.data
            .map(item => (Array.isArray(item.last_year.months) ? item.last_year?.months[0]?.value_bio : 0))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2),
        },

        {
          name: keyData.last_year.months[1].name,
          value_bio: data.data
            .map(item => (Array.isArray(item.last_year.months) ? item.last_year?.months[1]?.value_bio : 0))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2),
        },
      ],
    },
    better_worse: {
      yearly_bio: data.data
        .map(item => item.better_worse?.yearly_bio || 0)
        .reduce((partialSum, a) => partialSum + a, 0)
        .toFixed(2),
      monthly_bio: data.data
        .map(item => item.better_worse?.monthly_bio || 0)
        .reduce((partialSum, a) => partialSum + a, 0)
        .toFixed(2),
    },
  };

  return { data: { tableData: data.data, total }, subheader, header };
};

const downloadBetterWorseCasa = async ({ filterSalesArea, search, typeSearch }) => {
  const { status, data: responseBody } = await axiosPrivate.post(
    "/dashboard/transaction/detail/better-worse/casa/download",
    {
      filters: {
        sales_area: filterSalesArea,
      },
      search,
      type_search: typeSearch,
    },
  );

  if (status !== 200) throw new Error(`[downloadBetterWorseCasa] ${status}`);

  return { data: responseBody.data };
};

export { getListBetterWorseCASA, downloadBetterWorseCasa };
