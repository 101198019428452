import { InputLabel, styled } from "@material-ui/core";
import PropType from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const StyledInputLabel = styled(InputLabel)({
  marginBottom: "5px",
  fontStyle: "normal",
  fontSize: SIZES.REGULAR_14,
  fontWeight: 400,
  lineHeight: "20px",
  color: COLORS.DARK_HARD,
});

const TextFormLabel = ({ children, className, ...rest }) => {
  return (
    <StyledInputLabel {...rest} className={className}>
      {children}
    </StyledInputLabel>
  );
};

TextFormLabel.defaultProps = {
  className: undefined,
};

TextFormLabel.propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string,
};

export { TextFormLabel };
