import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "15px",
    borderColor: COLORS.GRAY_SOFT,
    backgroundColor: COLORS.WHITE,
    boxShadow: `0px 3px 10px ${COLORS.BOX_SHADOW}`,
    overflowX: "auto",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  normal: {
    padding: "20px",
  },
  small: {
    padding: "10px",
  },
}));

const CardBase = ({ children, className, flex, variant, ...props }) => {
  const classes = useStyles();
  return (
    <Card {...props} className={clsx(classes.card, classes[variant], flex && classes.flex, className)}>
      {children}
    </Card>
  );
};

CardBase.defaultProps = {
  className: undefined,
  flex: false,
  variant: "normal",
};

CardBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flex: PropTypes.bool,
  variant: PropTypes.oneOf(["normal", "small"]),
};

export { CardBase };
