import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const TitleChartStyle = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px",
});

const ImgStyle = styled("img")({
  height: "24px",
  width: "24px",
  alignSelf: "center",
});

const StyleTypography = styled(Typography)({
  marginLeft: "10px",
  color: COLORS.DARK_HARD,
  fontSize: SIZES.REGULAR_18,
  fontWeight: 500,
});
const StyleTypographyIdr = styled(Typography)({
  marginLeft: "10px",
  color: COLORS.GRAY_MEDIUM,
  fontWeight: 500,
});

const TitleChart = ({ icon, title, titleIdr }) => (
  <TitleChartStyle>
    <ImgStyle src={icon} alt="icon-title" />
    <StyleTypography variant="subtitle1" component="div" align="left">
      {title}
    </StyleTypography>
    <StyleTypographyIdr variant="subtitle1" component="div" align="left">
      {titleIdr}
    </StyleTypographyIdr>
  </TitleChartStyle>
);

TitleChart.defaultProps = {
  titleIdr: "",
};

TitleChart.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleIdr: PropTypes.string,
};

export default TitleChart;
