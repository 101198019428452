import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const DetailTransactionIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.849 7.23905L17.7171 11.3709C17.3914 11.6966 16.8369 11.4629 16.8369 11.0063L16.8366 7.90592H0.515625C0.230871 7.90592 0 7.67505 0 7.3903V6.35905C0 6.07429 0.230871 5.84342 0.515625 5.84342H16.8366L16.837 2.74271C16.837 2.28367 17.3934 2.05426 17.7172 2.37812L21.8489 6.50982C22.0503 6.71117 22.0504 7.03769 21.849 7.23905V7.23905ZM0.151035 15.4895L4.28274 19.6212C4.60655 19.945 5.16291 19.7157 5.16295 19.2566L5.16338 16.1559H21.4844C21.7691 16.1559 22 15.9251 22 15.6403V14.609C22 14.3242 21.7691 14.0934 21.4844 14.0934H5.16343L5.16313 10.9931C5.16313 10.5365 4.60861 10.3027 4.28291 10.6285L0.151035 14.7603C-0.0503594 14.9616 -0.0503164 15.2882 0.151035 15.4895V15.4895Z"
      fill={fill}
    />
  </SvgIcon>
);

DetailTransactionIcon.defaultProp = {
  fill: COLORS.WHITE,
};

DetailTransactionIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default DetailTransactionIcon;
