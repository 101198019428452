import { makeStyles } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { CardCalculationProfitLoss } from "components/card/card-calculation-profit-loss";
import { CardExistingCalculation } from "components/card/card-existing-calculation-pnl";
import { CardFilterPNL } from "components/card/card-filter-calc-pnl";
import { DOWNLOAD_FEATURES, PopupDownload } from "components/popup/popup-download";
import { Button } from "elements/button";
import { useAuth } from "hooks/useAuth";
import { downloadCalculation } from "services/api/private-routes/calculation-profit-and-loss";
import { useFilterCalculation, useResetFilterCalculation } from "./use-calculation-filter";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  actionContainer: {
    margin: "2rem 0",
    display: "flex",
    gap: "1.5rem",
    justifyContent: "flex-end",
  },
});

const CalculationLossProfit = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getRole } = useAuth();
  const [filter] = useFilterCalculation();
  const resetFilter = useResetFilterCalculation();
  const [popUpDownloadOpen, setPopUpDownloadOpen] = useState(false);
  const queryClient = useQueryClient();

  const mutateDownloadCalculation = useMutation(() => downloadCalculation(filter), {
    onSuccess: () => queryClient.invalidateQueries(["download"]),
  });

  useEffect(() => {
    // Reset filter on page mounted
    return () => resetFilter();
  }, [resetFilter]);

  return (
    <div className={classes.container}>
      {!getRole().isInquiry && (
        <>
          <PopupDownload
            feature={DOWNLOAD_FEATURES.CALCULATION_PROFIT_AND_LOSS}
            isOpen={popUpDownloadOpen}
            onClose={() => setPopUpDownloadOpen(false)}
            onSubmit={mutateDownloadCalculation.mutate}
            disabledSubmit={!filter.brandingId || !filter.period || mutateDownloadCalculation.isLoading}
          />
          <div className={classes.actionContainer}>
            <Button startIcon={<IconDownload />} variant="primary" onClick={() => setPopUpDownloadOpen(true)}>
              {t("GENERAL.DOWNLOAD")}
            </Button>
          </div>
        </>
      )}
      <CardFilterPNL />
      {filter.brandingId && filter.period && <CardExistingCalculation />}
      <CardCalculationProfitLoss />
    </div>
  );
};

export { CalculationLossProfit };
