/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// import { Divider } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import IconTerminology from "assets/icons/general/icon-warning-terminology.svg";
import DatePickerComp from "components/datepicker";
import TextToggleButton from "elements/button/button-toggle-text";
import { CardBase } from "elements/card";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { toogleFilterProfitibility } from "utils/constants/mocks/calculation-profit-loss";

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divider: {
    height: "20px",
  },
  button: {
    height: "auto",
    marginLeft: "auto",
  },
  sales: {
    minWidth: "20rem",
  },
  wrapperToggle: {
    display: "flex",
    justifyContent: "center",
  },

  wrapperbtn: {
    display: "flex",
    alignItems: "center",
    gap: ".8rem",
  },

  typhographyStyle: {
    fontSize: SIZES.REGULAR_14,
    color: COLORS.PRIMARY_DARK,
  },

  link: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: ".4rem",
    width: "100%",
  },
}));

const CardFilterProfitability = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dateNow = new Date();
  const lastyear = new Date(new Date().getFullYear() - 1, 0);
  const [count, setCount] = useState(1);
  // Translation
  const translation = useMemo(
    () => ({
      title: {
        terminology: t("PROFITABILITY.TERMINILOGY"),
      },
      label: {
        apply: t("GENERAL.APPLY"),
      },
    }),
    [t],
  );

  const rightHeader = useMemo(() => {
    return (
      <NavLink to={`/terminology/${filter?.category?.toLowerCase()}`} className={classes.link}>
        <h1 className={classes.typhographyStyle}>{translation.title.terminology}</h1>
        <img src={IconTerminology} alt={`${IconTerminology}`} />
      </NavLink>
    );
  }, [translation, classes, filter]);

  return (
    <CardBase className={classes.filterContainer}>
      <DatePickerComp
        onChange={e => {
          setCount(prevState => prevState + 1);
          if (count > 0) {
            localStorage.setItem("selectedDate", e);
            setFilter(old => ({ ...old, date: e }));
            localStorage.setItem("selectedDate", e);
            setCount(0);
          }
        }}
        value={filter.date}
        minDate={lastyear}
        maxDate={dateNow}
        placeholder="MM/YYYY"
        format="MM/YYYY"
        views={["year", "month"]}
        autoOk={false}
        onClose={() => setCount(0)}
      />

      <Grid item xs={12} className={classes.wrapperToggle}>
        <TextToggleButton
          toggleData={toogleFilterProfitibility}
          onChange={val => {
            setFilter(old => ({ ...old, date: new Date(dateNow.getFullYear(), dateNow.getMonth(), 1), toggle: val }));
            localStorage.removeItem("selectedDate");
          }}
          selected={filter.toggle}
        />
      </Grid>

      <Grid item xs={2} className={classes.wrapperbtn}>
        {rightHeader}
      </Grid>
    </CardBase>
  );
};

export { CardFilterProfitability };
