import { Box, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Dropdown } from "assets/icons/general/icon-chevron-down.svg";
import { ReactComponent as Iconlogout } from "assets/icons/sidebar/icon-logout-gray.svg";
import MenuProfile from "elements/menu-item-profile";
import { ROLE, useAuth } from "hooks/useAuth";
import { privateMenuRoutes } from "routes/privateRoutes";
import { headerText } from "utils/constants/mocks/dashboard/incoming-application";
import { StyledPaper, useStyles } from "./styled";

const Sidebar = ({ open, setIsOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const { doSignOut, getRole } = useAuth();
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState({});

  const onMouseEnter = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onMouseLeave = useCallback(() => setIsOpen(false), [setIsOpen]);

  const pathNow = location.pathname.split("/")[1];
  const pathName = useMemo(() => {
    return headerText.find(x => x.url === pathNow)?.url;
  }, [pathNow]);

  const handlerSignOut = useCallback(() => {
    localStorage.removeItem("selectedDate");
    if (doSignOut()) navigate("/logout");
    else throw new Error("Unhandled failed signout");
  }, [doSignOut, navigate]);

  const handleOpenSub = key => {
    setOpenSubMenu(prevOpenSubMenu => ({
      ...prevOpenSubMenu,
      [key]: !prevOpenSubMenu[key],
    }));
  };

  return (
    <div className={clsx("no-print", classes.wrapperSidebar)}>
      <StyledPaper
        open={open}
        className={clsx(classes.navigation, open ? classes.navigationOpen : classes.navigationClose)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        square
      >
        <MenuList className={classes.menu}>
          <div className={classes.menuTop}>
            <MenuItem className={classes.menuItemUser}>
              <MenuProfile open={open} />
            </MenuItem>
            <Divider variant="middle" className={classes.divider} />
            <div className={classes.menuTopItem}>
              {privateMenuRoutes.map(item => {
                // User management
                if (
                  item.key === 10 &&
                  ![
                    ROLE.SALES,
                    ROLE.INQUIRY,
                    ROLE.SUPPORT_SERVICE,
                    ROLE.SUPERVISOR,
                    ROLE.ADMINISTRATOR,
                    ROLE.ARCO,
                  ].includes(getRole().role)
                )
                  return null;

                // Executive Summary
                if (item.key === 8 && [ROLE.SALES, ROLE.ARCO].includes(getRole().role)) return null;
                const sameKeys = openSubMenu[item.key];
                return (
                  <>
                    <NavLink
                      onClick={e => {
                        if (!item?.path) {
                          e.preventDefault();
                        }
                        localStorage.removeItem("selectedDate");
                      }}
                      to={item?.path}
                      key={item?.key}
                      className={({ isActive }) =>
                        isActive && !item.subMenu ? classes.navLinkActive : classes.navLinkInactive
                      }
                    >
                      <MenuItem
                        selected={pathName === item?.path}
                        className={classes.menuButton}
                        onClick={() => handleOpenSub(item?.key)}
                      >
                        <ListItemIcon className={classes.menuIcon}>
                          {location.pathname === item.path ? item.icon : item.iconWhite}
                        </ListItemIcon>
                        <ListItemText
                          className={[classes.menuText, open ? classes.menuTextOpen : classes.menuTextClose].join(" ")}
                        >
                          {t(item?.name).length > 22 ? `${t(item?.name).substring(0, 22)}...` : t(item?.name)}
                        </ListItemText>
                        {item?.subMenu && open && (
                          <ListItemIcon className={classes.menuIconDropdown}>
                            <Dropdown
                              style={{
                                transition: "all 0.3s ease-out",
                                transform: openSubMenu[item.key] ? "rotate(180deg)" : "none",
                              }}
                            />
                          </ListItemIcon>
                        )}
                      </MenuItem>
                    </NavLink>
                    {open &&
                      sameKeys &&
                      item?.subMenu?.map(items => {
                        return (
                          <NavLink
                            to={items?.path}
                            key={items?.key}
                            className={({ isActive }) =>
                              isActive ? classes.subnavLinkActive : classes.subnavLinkInactive
                            }
                          >
                            <MenuItem selected={pathName === items?.path} className={classes.menuButton}>
                              <ListItemText
                                className={[classes.menuText, open ? classes.menuTextOpen : classes.menuTextClose].join(
                                  " ",
                                )}
                              >
                                {t(items?.name).length > 22 ? `${t(items?.name).substring(0, 22)}...` : t(items?.name)}
                              </ListItemText>
                            </MenuItem>
                          </NavLink>
                        );
                      })}
                  </>
                );
              })}
            </div>
          </div>
          <div className={classes.menuBottom}>
            {/* Logout */}
            <Box className={classes.wrapperLogout} onClick={handlerSignOut}>
              {/* <Divider variant="middle" className={classes.divider} /> */}
              <MenuItem className={classes.menuButtonLogout}>
                <ListItemIcon className={classes.menuIcon}>
                  <Iconlogout className={classes.iconlogout} />
                </ListItemIcon>
                <ListItemText
                  className={[classes.menuText, open ? classes.menuTextOpen : classes.menuTextClose].join(" ")}
                >
                  {t("GENERAL.LOGOUT")}
                </ListItemText>
              </MenuItem>
            </Box>
          </div>
        </MenuList>
      </StyledPaper>
    </div>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Sidebar;
