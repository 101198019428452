import { axiosPrivate } from "../axiosPrivate";

const getDataOnBoardingMerchant = async ({ start_date, end_date }) => {
  const { data: responseBody, status } = await axiosPrivate.post(
    `/dashboard/incoming/application/average-on-boarding-merchant`,
    {
      start_date,
      end_date,
    },
  );

  if (status !== 200) throw new Error(`[getDataOnBoardingMerchant] ${status}`);

  return { data: responseBody };
};

export { getDataOnBoardingMerchant };
