import { useQuery } from "@tanstack/react-query";
import { getListRole } from "services/api/private-routes";

const KEY_ROLES = "list-roles";

const useQueryRoles = () => {
  return useQuery([KEY_ROLES], getListRole, {
    placeholderData: { data: [], names: [] },
  });
};

export { useQueryRoles };
