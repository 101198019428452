/* eslint-disable react/jsx-filename-extension */
import { styled } from "@material-ui/core";
// import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// ICON
import iconList from "assets/icons/general/icon-list.svg";

// COMPONENT
import { ServerErrorBoundary } from "components/general";
import ListCommonPopover from "components/list/list-common-popover";
import TitleChart from "components/title/title-chart";
import TitleTable from "components/title/title-table";
import CardWrapper from "components/wrapper/wrapper-card";
import LoadingSpinner from "elements/loading/spinner";
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
// import { getDataTopIncomingContribution } from "services/api/private-routes/incoming-application/topIncomingContribution";
// import { dateUtility } from "utils/helpers/general/date-time";
import { useFetchDataTopIncomingContribution } from "./useFetchData";

const SpaceStyle = styled("div")({
  height: "14px",
});

const CardTopIncoming = () => {
  /* translations */
  const { t } = useTranslation();
  const [date] = useIncomingFilter();

  const translations = useMemo(
    () => ({
      titleCharts: t("INCOMING.TOP_INCOMING"),
      titleTable: {
        title1: t("INCOMING.PIC"),
        title2: t("INCOMING.NO_OF_INCOMING"),
      },
    }),
    [t],
  );

  const dataTopIncomingContribution = useFetchDataTopIncomingContribution(date);
  // useQuery(["data-top-incoming-contribution", { ...date }], () =>
  //   getDataTopIncomingContribution({
  //     startDate: dateUtility(date.start).isoSeparator(),
  //     endDate: dateUtility(date.end).isoSeparator(),
  //   }),
  // );

  const content = useMemo(() => {
    if (dataTopIncomingContribution?.isError)
      return (
        <ServerErrorBoundary
          onClick={dataTopIncomingContribution.refetch}
          title={dataTopIncomingContribution?.error?.message}
        />
      );
    if (dataTopIncomingContribution?.isLoading) return <LoadingSpinner height={250} />;
    if (!dataTopIncomingContribution?.isError && dataTopIncomingContribution?.isSuccess) {
      return (
        <>
          {dataTopIncomingContribution?.data?.data?.map((listSale, index) => (
            <ListCommonPopover
              key={listSale + Math.random()}
              number={index + 1}
              name={listSale.name}
              sum={listSale.sum}
              fontWeight={600}
              flex1={0.7}
              flex2={0.3}
            />
          ))}
        </>
      );
    }
    return null;
  }, [dataTopIncomingContribution]);

  return (
    <CardWrapper flexSize={0.7}>
      <TitleChart icon={iconList} title={translations.titleCharts} />
      <SpaceStyle />
      <TitleTable
        title1={translations.titleTable.title1}
        title2={translations.titleTable.title2}
        marginSizeLeft="33px"
      />
      {content}
    </CardWrapper>
  );
};

export default CardTopIncoming;
