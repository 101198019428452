import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const SalesVolumeIcon = ({ fill }) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7043 9.6739C20.3711 4.84282 16.5105 0.982313 11.6795 0.649079C11.6653 0.647865 11.6512 0.647461 11.6374 0.647461C11.2876 0.647461 11.0001 0.948342 11.0001 1.30382V10.3533H20.0495C20.4192 10.3533 20.7298 10.0427 20.7043 9.6739ZM12.9412 8.41214V2.81146C15.6734 3.51675 17.8366 5.67995 18.5419 8.41214H12.9412ZM9.05888 12.2945V2.69863C9.05888 2.34154 8.76852 2.04308 8.42234 2.04308C8.39403 2.04308 8.36572 2.0451 8.33701 2.04915C3.51805 2.72977 -0.16572 6.94009 0.00575014 11.9859C0.18086 17.1284 4.57721 21.3533 9.71079 21.3533C9.75042 21.3533 9.78884 21.3529 9.82848 21.3525C11.8667 21.327 13.75 20.6702 15.2985 19.5719C15.618 19.3454 15.639 18.8751 15.362 18.598L9.05888 12.2945ZM9.80461 19.4117L9.7112 19.4121C5.6404 19.4121 2.08442 15.9811 1.94611 11.92C1.82843 8.46633 3.97423 5.43447 7.11771 4.33326V13.0985L7.68631 13.6671L12.7908 18.7715C11.8542 19.18 10.8431 19.3984 9.80461 19.4117V19.4117ZM21.3445 12.2945H11.7482L18.1391 18.6854C18.2673 18.8136 18.4388 18.8799 18.609 18.8799C18.7635 18.8799 18.9172 18.8253 19.0365 18.7129C20.6016 17.2384 21.6781 15.2507 21.9939 13.0164C22.0481 12.6338 21.7307 12.2945 21.3445 12.2945V12.2945Z"
      fill={fill}
    />
  </SvgIcon>
);

SalesVolumeIcon.defaultProp = {
  fill: COLORS.WHITE,
};

SalesVolumeIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default SalesVolumeIcon;
