import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

// Colors
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const TypographyLegendNumber = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: COLORS.GRAY_PRIMARY,
  fontSize: SIZES.REGULAR_12,
});

const TextLegendNumber = ({ text, number }) => {
  return (
    <TypographyLegendNumber>
      {text} {typeof number === "undefined" ? number : `(${number})`}
    </TypographyLegendNumber>
  );
};

TextLegendNumber.defaultProps = {
  number: undefined,
};

TextLegendNumber.propTypes = {
  text: PropTypes.string.isRequired,
  number: PropTypes.number,
};

export default TextLegendNumber;
