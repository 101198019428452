import COLORS from "utils/constants/colors";
import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const statusTranlate = {
  approval: "Approve",
  incoming: "Incoming",
  process: "Process",
  done: "Deploy",
};

const getDailyChart = async ({
  startDate,
  endDate,
  salesArea,
  sales: salesNameCode,
  businessType: bussinesTypeCode,
  status: statusCode,
}) => {
  const { data: resDailyChart, status } = await axiosPrivate.post(ENDPOINT.API_DASHBOARD.INCOMING.DAILY_CHART, {
    start_date: startDate,
    end_date: endDate,
    sales_user_code: salesNameCode || "all",
    sales_area: salesArea || "all",
    business_type: bussinesTypeCode || "all",
    status: statusCode || "all",
  });

  if (status !== 200) throw new Error(`[getDailyChart] ${status}`);

  const colorsKey = {
    incoming: COLORS.BLUE_MEDIUM,
    approval: COLORS.GREEN_HARD,
    process: COLORS.SECONDARY_MEDIUM,
    done: COLORS.PURPLE_HARD,
  };

  const chart = resDailyChart.data
    .map(x => {
      return x.data.map(y => {
        return {
          date: y.date,
          batchDate: new Date(y.date.split("T")[0]).getDate(),
          amount: y.total,
          color: colorsKey[x.key],
        };
      });
    })
    .flat()
    .sort((a, b) => a.date - b.date);

  const legends = resDailyChart.data.map(x => {
    return {
      key: statusTranlate[x.key],
      color: colorsKey[x.key],
      total: x.total,
    };
  });

  return {
    chart,
    status: resDailyChart.data?.map(x => x.key),
    legends,
    rawData: resDailyChart.data,
  };
};

const getSelectSalesName = async () => {
  const { data: salesName, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.LIST_SELECT.INCOMING_SALES_NAME}`,
  );
  if (status !== 200) throw new Error(`[list-sales-name] ${status}`);
  let responseSalesName = salesName.data.map(item => {
    return {
      code: item.sales_user_code,
      name: item.sales_user_name,
    };
  });

  responseSalesName = [{ code: "all", name: "Select All" }, ...responseSalesName];
  return { responseSalesName };
};

const getSelectSalesArea = async () => {
  const { data: responseBody, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.LIST_SELECT.INCOMING_BUSINESS_AREA}`,
  );
  if (status !== 200) throw new Error(`[list-sales-area] ${status}`);
  const { data } = responseBody;
  if (!Array.isArray(data)) throw new Error(`[list-sales-area] Expected type array, got ${typeof data}`);
  let resBusinessArea = data.map(item => {
    return {
      code: item.businessAreaCode,
      name: item.businessArea,
    };
  });

  resBusinessArea = [{ code: "all", name: "SELECT ALL" }, ...resBusinessArea];
  return { resBusinessArea, rawData: responseBody };
};

const getListBusinessType = async () => {
  const { data: businessType, status } = await axiosPrivate.get(`${ENDPOINT.API_DASHBOARD.LIST_SELECT.BUSINESS_TYPE}`);
  if (status !== 200) throw new Error(`[list-business-type] ${status}`);
  let response = businessType.data.map(item => {
    return {
      code: item.businessCode,
      name: item.businessType,
    };
  });

  response = [{ code: "all", name: "Select All" }, ...response];
  return { response };
};

const getSeletStatus = async () => {
  const { data: statusName, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.LIST_SELECT.INCOMING_STATUS_NAME}`,
  );
  if (status !== 200) throw new Error(`[list-status-name] ${status}`);
  let resStatusName = statusName.data.map(item => {
    return {
      id: item.id,
      name: item.statusName,
    };
  });

  resStatusName = [{ id: "all", name: "Select All" }, ...resStatusName];
  return { resStatusName };
};

const getAvgIncoming = async ({ startDate, endDate }, signal) => {
  const { data: responseBody, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.INCOMING.AVERAGE_INCOMING}?startDate=${startDate}&endDate=${endDate}`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getAvgIncoming] ${status}`);
  return { data: responseBody.data };
};

const getAvgDeploy = async ({ startDate, endDate }, signal) => {
  const { data: responseBody, status } = await axiosPrivate.get(
    `${ENDPOINT.API_DASHBOARD.INCOMING.AVERAGE_DEPLOY}?startDate=${startDate}&endDate=${endDate}`,
    {
      signal,
    },
  );

  if (status !== 200) throw new Error(`[getAvgDeploy] ${status}`);

  const parser = responseBody.data.map(item => {
    return {
      name: item.name,
      avg: item.percen,
      sum: item.sum,
    };
  });

  return { responseBody, data: parser };
};

export {
  getDailyChart,
  getAvgIncoming,
  getAvgDeploy,
  getListBusinessType,
  getSelectSalesArea,
  getSelectSalesName,
  getSeletStatus,
};
