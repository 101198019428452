import { useQuery } from "@tanstack/react-query";
import { useFilterCalculation } from "pages/dashboard/dashboard-calculation-loss-profit/use-calculation-filter";
import { getExistingCalculation } from "services/api/private-routes/calculation-profit-and-loss";

const useQueryExistingCalculation = (options = {}) => {
  const [filter] = useFilterCalculation();
  return useQuery(
    ["existing-calculation", filter],
    () =>
      getExistingCalculation({
        brandingId: filter.brandingId,
        areaId: filter.area,
        period: filter.period,
        salesId: filter.sales,
      }),
    {
      enabled: !!filter.period && !!filter.brandingId,
      keepPreviousData: false,
      cacheTime: 0,
      staleTime: 0,
      ...options,
    },
  );
};

export { useQueryExistingCalculation };
