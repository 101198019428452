/* eslint-disable new-cap */
/* eslint-disable no-undef */
import { Grid } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useMutation, useQuery } from "@tanstack/react-query";
import html2canvas from "html2canvas";
import moment from "moment";
import React, { useState, useMemo, useEffect } from "react"; // useState // useEffect,
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import IconStateFailed from "assets/icons/popup/icon-state-failed.svg";
import IconStateSuccess from "assets/icons/popup/icon-state-success.svg";
import { ReactComponent as IconUpload } from "assets/icons/user-management/icon-upload.svg";
import { CardFilterProfitability } from "components/card/card-filter-profitability";
import CardTrbMtdChart from "components/card/card-profitability-mcc/card-trb-mtd-chart";
import { CardMtdChart } from "components/card/card-profitibility/card-mtd-chart";
import CardTopMerchantProfitibility from "components/card/card-top-merchant-profitibility";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupInformation from "components/popup/popup-information";
import PopupUpload from "components/popup/popup-upload";
import { Button } from "elements/button";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { ROLE, useAuth } from "hooks/useAuth";
import { uploadPdfTerminology } from "services/api/private-routes";
import {
  downloadPdf,
  getListDataTopMerchantTopBranding,
  getListDataTopMerchantBottomBranding,
  getListDataTopMerchantTopWatchlist,
} from "services/api/private-routes/dashboard-profitibility";
import { toogleCategoryProfitibility, toogleFilterProfitibility } from "utils/constants/mocks/calculation-profit-loss";
import { trimFileName } from "utils/helpers/general/trim-file-name";
import useStyles from "./styles";

const dateNow = new Date();
const INITIAL_FILTER = {
  toggle: toogleFilterProfitibility[0],
  date: new Date(dateNow.getFullYear(), dateNow.getMonth(), 1),
  category: "Profit",
};

const uploadMaxSize = 10240000; // 10mb

const DashboardProfitability = () => {
  const classes = useStyles();
  const { signal } = useAbortController();
  const { getRole } = useAuth();
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [openUpload, setOpenUpload] = useState(false);

  const year = filter?.date.getFullYear();
  const month = (filter?.date.getMonth() + 1).toString().padStart(2, "0");
  const formattedDate = `${year}-${month}`;

  const [uploadWarning, setUploadWarning] = useState("");
  const [submitUploadLoading, setSubmitUploadLoading] = useState(false);

  const [sidePopupData, setSidePopupData] = useState({
    data: [],
    open: false,
    icon: null,
    title: "",
    desc: "",
    btnTxt: "",
  });
  const [file, setFile] = useState(null);
  const { t } = useTranslation();
  const { active, setActive } = useCIMBLoading();

  const currentSelectDate = localStorage.getItem("selectedDate");

  useEffect(() => {
    setFilter(prevFilter => ({
      ...prevFilter,
      date: currentSelectDate ? new Date(currentSelectDate) : new Date(dateNow?.getFullYear(), dateNow?.getMonth(), 1),
    }));
  }, [currentSelectDate]);

  const translations = useMemo(
    () => ({
      general: {
        uploadTerminology: t("GENERAL.UPLOAD_TERMINOLOGY"),
        downloadPdf: t("GENERAL.DOWNLOAD_PDF"),
      },
    }),
    [t],
  );

  const removeFileHandler = () => setFile(null);

  const uploadHandler = event => {
    const fileTarget = event.target.files[0];
    const fileSize = fileTarget.size;
    if (fileSize > uploadMaxSize) {
      setUploadWarning("max upload file .pdf 10mb");
      return;
    }

    setUploadWarning("");
    if (fileTarget) setFile(fileTarget);
  };

  // UPLOAD PDF
  const submitUploadHandler = () => {
    if (!file) return;
    const formData = new FormData();
    formData.set("file", file);
    const typeCategory = filter?.category?.toLowerCase();
    mutationUploadTerminology.mutate({ formData, typeCategory });
  };

  const mutationUploadTerminology = useMutation(uploadPdfTerminology, {
    onMutate: () => {
      setActive(true);
      setSubmitUploadLoading(true);
      if (!active) {
        setActive(true);
      }
    },
    onSuccess: res => {
      setSubmitUploadLoading(false);
      if (res.invalid) {
        setSidePopupData({
          open: true,
          icon: IconStateFailed,
          title: t("USER_MANAGEMENT.UPLOAD_FAILED"),
          desc: "Upload failed, Please try again",
          btnTxt: t("GENERAL.TRY_AGAIN"),
        });
        return;
      }
      handleClosePopUp();
      setSidePopupData({
        open: true,
        icon: IconStateSuccess,
        title: t("USER_MANAGEMENT.UPLOAD_SUCCESS"),
        desc: "File has been successfully",
        btnTxt: t("GENERAL.CLOSE"),
      });
    },
    onSettled: () => {
      setActive(false);
      setSubmitUploadLoading(false);
    },
  });

  // button download & upload function
  const handlePrintButton = () => setOpenUpload(true);

  const handleClosePopupInformation = () => {
    setSidePopupData({ data: [], open: false });
  };

  const handleClosePopUp = () => {
    if (file) setFile(null);
    [setOpenUpload].forEach(x => x(false));
    setSidePopupData({ data: [], open: false });
  };

  const mutationDownloadPdf = useMutation(payload => downloadPdf(payload));

  function screenShootChart() {
    const getid = document.querySelector(filter?.category === "Profit" ? "#chart-mtd" : "#chart-mcc");
    return html2canvas(getid).then(res => {
      const tempcanvas = document.createElement("canvas");
      tempcanvas.width = window.innerWidth;
      tempcanvas.height = window.innerHeight;
      const context = tempcanvas.getContext("2d");
      context.drawImage(res, 0, 0, window.innerWidth, window.innerHeight);
      const link = document.createElement("a");
      link.href = res.toDataURL("image/jpg");
      return link.href;
    });
  }

  const donwloadPdf = async () => {
    const base64Image = await screenShootChart();

    mutationDownloadPdf.mutate({
      title: filter?.category.toLowerCase(),
      date: moment(filter?.date).format("YYYY-MM"),
      type: filter?.toggle?.toLowerCase(),
      base64: base64Image,
    });
  };

  const ChartContent = useMemo(() => {
    if (filter?.category === "Profit") {
      return (
        <div id="chart-mtd">
          <CardMtdChart dataFilter={filter} />
        </div>
      );
    }
    if (filter?.category === "MCC") {
      return (
        <div id="chart-mcc">
          <CardTrbMtdChart dataFilter={filter} />
        </div>
      );
    }
  }, [filter]);

  /* -------------------------------------------------------------------------- */
  /*                                    QUERY                                   */
  /* -------------------------------------------------------------------------- */

  const listDataTopMerchantTopBranding = useQuery(["list-data-top-merchant-top-branding", formattedDate], () =>
    getListDataTopMerchantTopBranding({ monthly: formattedDate }, signal),
  );

  const listDataTopMerchantBottomBranding = useQuery(["list-data-top-merchant-bottom-branding", formattedDate], () =>
    getListDataTopMerchantBottomBranding({ monthly: formattedDate }, signal),
  );

  const listDataTopMerchantTopWatchlist = useQuery(["list-data-top-merchant-top-watchlist", formattedDate], () =>
    getListDataTopMerchantTopWatchlist({ monthly: formattedDate }, signal),
  );

  return (
    <div className={classes.container}>
      <Grid container className={classes.gridWrapper}>
        <ToggleButtonGroup
          value={filter?.category}
          exclusive
          onChange={(_, val) => {
            if (val === null || val === filter?.category) return;
            localStorage.removeItem("selectedDate");
            setFilter({
              date: INITIAL_FILTER.date,
              toggle: INITIAL_FILTER.toggle,
              category: val,
            });
          }}
          className={classes.toggleButtonGroup}
        >
          {toogleCategoryProfitibility.map(name => (
            <ToggleButton className={classes.toggleButton} value={name} key={name}>
              {name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Grid className={classes.gridWrapper}>
          <Button
            startIcon={
              mutationDownloadPdf?.isLoading ? (
                <LoadingSpinner height={20} size={20} style={{ color: "white" }} />
              ) : (
                <IconDownload />
              )
            }
            variant="primary"
            onClick={donwloadPdf}
            defaultButton
            disabled={mutationDownloadPdf?.isLoading}
          >
            {translations.general.downloadPdf}
          </Button>
          {[ROLE.ADMINISTRATOR].includes(getRole().role) && (
            <Button startIcon={<IconUpload />} variant="primary" onClick={handlePrintButton} defaultButton>
              {translations.general.uploadTerminology}
            </Button>
          )}
        </Grid>
      </Grid>
      {/* Filter */}
      <CardFilterProfitability filter={filter} setFilter={setFilter} />
      {/* Content Chart */}
      {ChartContent}

      {/* Chart */}
      <Grid item className={classes.gridStyles}>
        <CardTopMerchantProfitibility
          listDataTopMerchantTopBranding={listDataTopMerchantTopBranding}
          listDataTopMerchantBottomBranding={listDataTopMerchantBottomBranding}
          listDataTopMerchantTopWatchlist={listDataTopMerchantTopWatchlist}
        />
      </Grid>

      {/* Upload */}
      <PopupUpload
        isOpen={openUpload}
        onCancel={handleClosePopUp}
        acceptFile=".pdf"
        onClose={handleClosePopUp}
        uploadButtonText={t("GENERAL.BROWSE_FILE")}
        submitButtonText={t("GENERAL.UPLOAD")}
        cancelButtonText={t("GENERAL.CANCEL")}
        title={t("GENERAL.UPLOAD_TERMINOLOGY")}
        description={t("GENERAL.UPLOAD_FILE")}
        placeholder={t("GENERAL.BROWSE_UPLOAD_FILE")}
        onUpload={uploadHandler}
        fileName={file?.name && trimFileName(file?.name)}
        onRemoveFile={removeFileHandler}
        onSubmit={submitUploadHandler}
        errorMessage={uploadWarning}
        isLoading={submitUploadLoading}
      />

      {sidePopupData.open && (
        <PopupInformation
          isOpen={sidePopupData.open}
          onClose={handleClosePopupInformation}
          onSubmit={handleClosePopupInformation}
          icon={sidePopupData.icon}
          title={sidePopupData.title}
          description={sidePopupData.desc}
          buttonText={sidePopupData.btnTxt}
        />
      )}
    </div>
  );
};

export { DashboardProfitability };
