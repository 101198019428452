import { makeStyles, Paper, styled } from "@material-ui/core";
import COLORS from "utils/constants/colors";

export const StyledPaper = styled(Paper, {
  name: "StyledPaper",
  slot: "Wrapper",
})({
  backgroundColor: `${COLORS.DARK_HARD}`,
  borderTopRightRadius: "12px",
  borderBottomRightRadius: "12px",
});

export const useStyles = makeStyles(() => ({
  wrapperSidebar: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    zIndex: "1110",
    transition: "0.5s",
  },
  menuButton: {
    "&.MuiListItem-button:hover": {
      color: COLORS.RED_HARD,
    },
  },
  menuButtonLogout: {
    alignItems: "center",
    color: COLORS.GRAY_PRIMARY,
    "&.MuiListItem-button:hover": {
      color: COLORS.RED_HARD,
    },
    margin: "0px 0 20px",
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuTop: {
    height: "90vh",
    maxHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuTopItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "0.2em",
    flexGrow: 1,
    flexShrink: 1,
    padding: "10px 0px 0px 0px",
    overflowY: "auto",
  },
  menuBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "10vh",
    maxHeight: "10vh",
  },
  menuItemUser: {
    color: COLORS.DARK_HARD,
    flexDirection: "row",
  },
  menuIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  menuIconDropdown: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  menuText: {
    marginLeft: "26px",
    display: "flex",
  },
  menuTextOpen: {
    visibility: "visible",
    opacity: 1,
    transition: "visibility 0s linear 250ms, opacity 250ms",
  },
  menuTextClose: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0s linear 250ms, opacity 250ms",
  },
  divider: {
    pt: 0,
    backgroundColor: COLORS.DARK_SOFT,
  },
  navLinkInactive: {
    textDecoration: "none",
    color: COLORS.GRAY_PRIMARY,
    "&:hover": {
      color: COLORS.RED_HARD,
    },
  },

  subnavLinkInactive: {
    textDecoration: "none",
    marginLeft: "59px",
    color: COLORS.GRAY_PRIMARY,
    "&:hover": {
      color: COLORS.RED_HARD,
    },
  },
  subnavLinkActive: {
    color: COLORS.RED_HARD,
    paddingLeft: "59px",
    fontWeight: "bold",
    borderLeft: `3px solid ${COLORS.PRIMARY_HARD}`,
    textDecoration: "none",
    "&.MuiListItemIcon": {
      color: COLORS.RED_HARD,
    },
  },
  navLinkActive: {
    color: COLORS.RED_HARD,
    fontWeight: "bold",
    borderLeft: `3px solid ${COLORS.PRIMARY_HARD}`,
    textDecoration: "none",
    "&.MuiListItemIcon": {
      color: COLORS.RED_HARD,
    },
  },
  navigation: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "5.5rem",
    maxWidth: "5.5rem",
    color: COLORS.GRAY_PRIMARY,
    background: COLORS.GRAY_ULTRASOFT,
    overflow: "hidden",
    variant: "outlined",
  },
  navigationOpen: {
    zIndex: 2,
    height: "100vh",
    width: "19rem",
    maxWidth: "19rem",
    transition: "all 0.3s ease-in",
  },
  navigationClose: {
    transition: "all 0.3s ease-in",
  },
  iconlogout: {
    marginLeft: "7px",
  },
  wrapperLogout: {
    width: "100%",
    alignSelf: "flex-start",
    background: COLORS.GRAY_ULTRASOFT,
    borderTop: `1px solid ${COLORS.DARK_SOFT}`,
    zIndex: "1111",
  },
}));
