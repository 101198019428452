import { makeStyles } from "@material-ui/core";
import React, { useState, useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// component
import Header from "components/header";
import Sidebar from "components/sidebar";
import { useAuth } from "hooks/useAuth";
import DevelopmentPage from "pages/development";
import { headerText } from "utils/constants/mocks/dashboard/incoming-application";
import { routes } from "utils/constants/paths";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingLeft: "8.5em",
    paddingTop: "8em",
    paddingRight: "2.5em",
    marginBottom: "10rem",
  },
  hideWrapper: {
    padding: "3rem 0 0 1rem",
    height: "100vh",
  },
}));

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const [open, setIsOpen] = useState(false);
  const pathNow = location.pathname.split("/")[1];
  const hideFeature = [
    // routes.DASHBOARD.INCOMING_APPLICATION,
  ];

  const header = useMemo(() => {
    return headerText.find(x => x.url === pathNow)?.text ?? "";
  }, [pathNow]);

  return isAuthenticated() ? (
    <div style={{ overflow: "auto", maxHeight: "100vh" }}>
      <Sidebar open={open} setIsOpen={setIsOpen} />
      <Header text={header} />
      {(hideFeature || []).includes(pathNow) ? (
        <div className={classes.hideWrapper}>
          <DevelopmentPage />
        </div>
      ) : (
        <div id="dashboard-layout" className={classes.wrapper}>
          <Outlet />
        </div>
      )}
    </div>
  ) : (
    <Navigate to={routes.AUTH.LOGIN} />
  );
};

export default DashboardLayout;
