import { makeStyles, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { TextFormLabel } from "elements/typhography/text-form-label";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  textField: {
    display: "flex",
    justifyContent: "center",
    height: "40px",
    paddingLeft: "10px",
    backgroundColor: ({ isInvalid }) => (isInvalid ? COLORS.WHITE : COLORS.GRAY_ULTRASOFT),
    fontSize: SIZES.REGULAR_14,
    color: COLORS.DARK_HARD,
    border: ({ isInvalid }) => `1px solid ${isInvalid ? COLORS.PRIMARY_DARK : COLORS.GRAY_ULTRASOFT}`,
    borderRadius: 10,
    "& .MuiInputBase-input": {
      lineHeight: "20px",
    },
  },
});

const Textfield = forwardRef(({ value, label, onChange, placeholder, isInvalid, name, onBlur, ...rest }, ref) => {
  const classes = useStyles({ isInvalid });
  return (
    <>
      <TextFormLabel>{label} :</TextFormLabel>
      <TextField
        {...rest}
        ref={ref}
        className={classes.textField}
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        type="text"
        fullWidth
        variant="standard"
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{ disableUnderline: true }}
        name={name}
      />
    </>
  );
});

Textfield.defaultProps = {
  isInvalid: false,
  onBlur: undefined,
};

Textfield.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
};

export default Textfield;
