import moment from "moment";
import qs from "qs";
import ENDPOINT from "utils/constants/endpoint";
import { axiosPrivate } from "../axiosPrivate";

const getDataOctoMerchantQrVolume = async ({ month, year }, signal) => {
  const query = qs.stringify({
    month: new Date(month).getMonth() + 1,
    year: moment(year).format("YYYY"),
  });
  const { data: dataResponse, status } = await axiosPrivate.get(
    `${ENDPOINT.BASEURL}/dashboard/portofolio/management/octo-merchant-qr-volume?${query}`,
    { signal },
  );

  if (status !== 200) throw new Error(`[getDataOctoMerchantQrVolume] ${status}`);

  const { data } = dataResponse;

  const dataOctoMerchant = Object.entries(data.octo_merchant || {}).reduce(
    (items, [, val]) => [
      ...items,
      {
        date: val.daybatch,
        value: "Octo Merchant",
        amount: val.amount * 1,
      },
    ],
    [],
  );

  const dataQrVolume = Object.entries(data.qr_volume || {}).reduce(
    (items, [, val]) => [
      ...items,
      {
        date: val.daybatch,
        value: "QR Volume",
        amount: val.amount * 1,
      },
    ],
    [],
  );

  const parsedData = [...dataOctoMerchant, ...dataQrVolume].sort((a, b) => a.date - b.date);
  return parsedData;
};

export { getDataOctoMerchantQrVolume };
