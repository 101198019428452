import { Box, Button, Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from "assets/icons/general/icon-download.svg";
import { ServerErrorBoundary } from "components/general";
import { DOWNLOAD_FEATURES, PopupDownload } from "components/popup/popup-download";
import ExecutiveSummaryTable from "components/table/table-executive-summary";
import ButtonContained from "elements/button/button-contained";
import CustomDeleteChip from "elements/chip/chip-delete";
import CustomSelectDropdown from "elements/input/select-custom";
import MultipleSelectCheckbox from "elements/input/select-multiple-checkbox";
import LoadingSpinner from "elements/loading/spinner";
import { useAbortController } from "hooks/useAbortController";
import { useAuth } from "hooks/useAuth";
import { downloadDataTableExecutiveSummary } from "services/api/private-routes/executive-summary/executive-summary-download";
import { getListDataTableExecutiveSummary } from "services/api/private-routes/executive-summary/get-data-table-executive-summary";
import { getListDrodownCategory } from "services/api/private-routes/executive-summary/get-list-dropdown";
import { getListDropdownYear } from "services/api/private-routes/executive-summary/get-list-dropdown-year";
import { getListYearHeader } from "services/api/private-routes/executive-summary/get-list-year-header";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { useStyles } from "./styled";

const styled = makeStyles({
  boxChip: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: "10px",
  },
  buttonReset: {
    width: "130px",
    height: "32px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "30px",
    borderColor: COLORS.PRIMARY_DARK,
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_DARK,
    border: "2px solid",
    marginTop: "-10px",
    marginStart: "15px",
    fontWeight: 500,
    fontSize: SIZES.REGULAR_14,
    textTransform: "initial",
    whiteSpace: "nowrap",
  },
  chipCustom: {
    marginLeft: "50px",
    marginBottom: "10px",
  },
});

const INITIAL_FILTER = {
  year: new Date().getFullYear(),
};

const CardExecutiveSummary = () => {
  const { getRole } = useAuth();
  const classes = useStyles();
  const style = styled();
  const { signal } = useAbortController();
  const [year, setYear] = useState(new Date().getFullYear());
  const [checkedFilterArea, setCheckedFilterArea] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [filterData, setFilterData] = useState(INITIAL_FILTER);
  const [popUpDownloadOpen, setPopUpDownloadOpen] = useState(false);
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      resetDefault: "Reset to Default",
      general: {
        print: t("GENERAL.PRINT"),
        download: t("GENERAL.DOWNLOAD_EXCEL"),
        allHeader: t("GENERAL.ALL_HEADER"),
        apply: t("GENERAL.APPLY"),
      },
    }),
    [t],
  );

  const listYearHeader = useQuery(["list-year-header"], () => getListYearHeader(year), { retry: 0 });
  const listDropdownYear = useQuery(["list-dropdown-year"], () => getListDropdownYear(), { retry: 0 });
  const listDataTableExecutiveSummary = useQuery(["list-data-table-executive-summary"], () =>
    getListDataTableExecutiveSummary(filterData, signal),
  );
  const listDrodownCategory = useQuery(["list-drodown-category"], () => getListDrodownCategory(), { retry: 0 });

  const handleDelete = useCallback(
    name => {
      setChipData(chipData.filter(string => string !== name));
    },
    [setChipData, chipData],
  );

  const resetFilter = () => {
    setChipData([]);
    setCheckedFilterArea(false);
  };

  const checkHandle = useCallback(
    ({ name }) => {
      const isRemoveAction = chipData.includes(name);
      setChipData(x => (isRemoveAction ? x.filter(y => y !== name) : [...x, name]));
    },
    [setChipData, chipData],
  );

  const checkedAllHandle = () => {
    if (checkedFilterArea) {
      setCheckedFilterArea(false);
      setChipData([]);
    } else {
      setCheckedFilterArea(true);
      setChipData(listDrodownCategory.data?.data.map(item => item.name));
    }
  };

  const onChangeDate = e => {
    if (e.target.value) {
      setYear(e.target.value);
      setFilterData({
        year: parseInt(e.target.value, e.target.value.length),
      });
    } else {
      setYear(new Date().getFullYear());
      setFilterData({
        year: parseInt(new Date().getFullYear(), new Date().getFullYear().length),
      });
    }
  };

  const TABLE_EXECUTIVE_SUMMARY = useMemo(() => {
    if (listDataTableExecutiveSummary.isFetching) return <LoadingSpinner height={650} />;
    if (listDataTableExecutiveSummary.isError)
      return (
        <ServerErrorBoundary
          onClick={() => listDataTableExecutiveSummary.refetch()}
          title={listDataTableExecutiveSummary.error?.message}
        />
      );

    if (!listDataTableExecutiveSummary.isError && listDataTableExecutiveSummary.isSuccess) {
      return (
        <ExecutiveSummaryTable
          filterData={chipData}
          tableHead={listYearHeader?.data?.data || []}
          data={listDataTableExecutiveSummary}
          year={year}
        />
      );
    }
  }, [listDataTableExecutiveSummary, chipData, listYearHeader, year]);

  /* ------------------------- DOWNLOAD EXCEL ------------------------ */
  const mutationDownloadExcelExecutiveSummary = useMutation(() => downloadDataTableExecutiveSummary(filterData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["download"]);
    },
  });

  const handleOnApply = () => {
    listDataTableExecutiveSummary.refetch();
    listYearHeader.refetch();
  };

  const CHIP_CONTENT = React.useMemo(() => {
    return (
      <Box className={classes.boxChip}>
        {chipData.map(name => (
          <CustomDeleteChip
            content={name}
            className={`${classes.chipCustom}, ${style.chipCustom}`}
            onDelete={() => handleDelete(name)}
          />
        ))}
        {chipData.length !== 0 && (
          <Button
            variant="outlined"
            className={classes.buttonReset}
            onClick={() => {
              resetFilter();
            }}
          >
            {translations.resetDefault}
          </Button>
        )}
      </Box>
    );
  }, [classes, chipData, translations, handleDelete, style]);

  return (
    <div>
      {!getRole().isInquiry && (
        <>
          <PopupDownload
            isOpen={popUpDownloadOpen}
            onClose={() => setPopUpDownloadOpen(false)}
            feature={DOWNLOAD_FEATURES.EXECUTIVE_SUMMARY}
            onSubmit={() => mutationDownloadExcelExecutiveSummary.mutate()}
          />

          <Box className={classes.boxTransparent}>
            <Button
              variant="contained"
              startIcon={<IconDownload />}
              className={classes.buttonExcel}
              onClick={() => setPopUpDownloadOpen(true)}
            >
              {translations.general.download}
            </Button>
          </Box>
        </>
      )}
      <Card className={classes.cardFilter}>
        <Grid container className={classes.grid}>
          <Grid container xs={11} item spacing={1} alignItems="center" direction="row">
            <Grid item>
              <MultipleSelectCheckbox
                content={translations.general.allHeader}
                arrayData={listDataTableExecutiveSummary.isLoading ? [] : listDrodownCategory?.data?.data}
                chipData={chipData}
                onChange={checkHandle}
                checkedAll={checkedFilterArea}
                onChooseAll={checkedAllHandle}
                isChooseAll
              />
            </Grid>
            <Grid item>
              <CustomSelectDropdown
                value={year}
                content={new Date().getFullYear()}
                arrayData={listDropdownYear?.data?.data || []}
                onChange={onChangeDate}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ButtonContained content="Apply" width="72px" onClick={handleOnApply} />
          </Grid>
        </Grid>
      </Card>
      <Box className={classes.boxChip}>{CHIP_CONTENT}</Box>
      <Card className={classes.cardTable}>{TABLE_EXECUTIVE_SUMMARY}</Card>
    </div>
  );
};

export default CardExecutiveSummary;
