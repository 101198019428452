import { makeStyles } from "@material-ui/core";
import { Axis, Chart, Legend, LineAdvance, Point, Tooltip } from "bizcharts";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useIncomingFilter } from "pages/dashboard/dashboard-incomming-app/use-filter-incoming";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
}));

const ChartLinePoint = ({ heightChart, colorChart, data, monthLabel }) => {
  const classes = useStyles();
  const [date] = useIncomingFilter();
  const label = {
    formatter(text) {
      return new Date(text.split("T")[0])?.getDate?.();
    },
  };

  const labelMonth = {
    formatter(text) {
      return moment(text.split("T")[0])?.format("MMMM");
    },
  };

  return (
    <div>
      <Chart
        padding={[20, 20, 60, 40]}
        autoFit
        height={heightChart}
        data={data || []}
        scale={{
          /** @type {import('bizcharts').ScaleConfig} */
          amount: { min: 0, type: "linear-strict" },
          /** @type {import('bizcharts').ScaleConfig} */
          date: {
            min: 0,
            max: !monthLabel ? (date.end - date.start) / (1000 * 3600 * 24) : undefined,
            nice: true,
            tickInterval: 1,
          },
        }}
      >
        <Point position="date*amount" color={colorChart} shape="circle" />
        <LineAdvance
          size={2}
          shape="line"
          position="date*amount"
          color={[
            "color",
            xVal => {
              return xVal;
            },
          ]}
          point={{
            size: 5,
            style: {
              lineWidth: 5,
            },
          }}
        />
        <Axis name="date" label={monthLabel ? labelMonth : label} />
        <Tooltip position="top">
          {(_, items) => {
            const [item] = items;
            return (
              <div className={classes.wrapper}>
                <div className={classes.datesWrapper}>{item.data.batchDate ?? "-"}</div>
                <div className={classes.valueWrapper}>{item.data.amount ?? "-"}</div>
              </div>
            );
          }}
        </Tooltip>
        <Legend visible={false} />
      </Chart>
    </div>
  );
};

ChartLinePoint.defaultProps = {
  heightChart: 400,
  colorChart: [],
  data: [],
  monthLabel: false,
};

ChartLinePoint.propTypes = {
  data: PropTypes.array,
  heightChart: PropTypes.number,
  colorChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  monthLabel: PropTypes.bool,
};

export default ChartLinePoint;
