import { styled, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const ImageWrapperLegend = styled("div")({
  marginRight: "15px",
  display: "flex",
  width: "15px",
  alignItems: "center",
});
const ImageComp = styled("img")({
  width: "15px",
  height: "15px",
});

const LegendWrapper = styled("div")({
  display: "flex",
  marginRight: "15px",
});

const LegendChartText = ({ icon, text }) => (
  <LegendWrapper>
    <ImageWrapperLegend>
      <ImageComp alt="incoming" src={icon} />
    </ImageWrapperLegend>
    <Typography>{text}</Typography>
  </LegendWrapper>
);

LegendChartText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default LegendChartText;
