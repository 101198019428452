import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as IconChevronDown } from "assets/icons/general/icon-chevron-down.svg";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  textField: {
    display: "flex",
    padding: "12px",
    justifyContent: "center",
    height: "40px",
    paddingLeft: "10px",
    backgroundColor: COLORS.GRAY_ULTRASOFT,
    fontSize: SIZES.REGULAR_14,
    color: COLORS.DARK_HARD,
    borderRadius: 10,
    "& .MuiInputBase-input": {
      lineHeight: "20px",
    },
    underline: {
      "&:before": {
        height: 0,
      },
    },
  },
}));

const AutoCompleteDropdown = ({ data, onChange, value, loading, key }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      key={key}
      id="combo-box-demo"
      data-testid="autocomplete"
      value={value}
      options={data}
      getOptionLabel={option => option?.name ?? ""}
      style={{ width: 200 }}
      onChange={(event, newVal) => onChange(event, newVal)}
      loading={loading}
      clearOnBlur={false}
      popupIcon={<IconChevronDown />}
      renderInput={params => {
        return (
          <TextField
            {...params}
            className={classes.textField}
            placeholder="All"
            variant="standard"
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            InputLabelProps={{ ...params.InputLabelProps, shrink: false }}
            SelectProps={{
              native: true,
            }}
          />
        );
      }}
    />
  );
};

AutoCompleteDropdown.defaultProps = {
  data: [],
  onChange: () => {},
  value: "",
  loading: false,
  key: "",
};

AutoCompleteDropdown.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  value: PropTypes.object,
  loading: PropTypes.bool,
  key: PropTypes.any,
};

export default AutoCompleteDropdown;
