/* eslint-disable no-unused-vars */
import { styled, Paper, Typography, Grid, Popover, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import iconPie from "assets/icons/chart/icon-chart-pie.svg";
import iconList from "assets/icons/general/icon-list.svg";
import CardMiniTopMerchant from "components/card/card-mini-top-merchant";
import { ServerErrorBoundary } from "components/general";
import TitleChart from "components/title/title-chart";
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { parseCurrency } from "utils/helpers/useFormater";

const CardTopMerchantStyle = styled("div")({
  backgroundColor: COLORS.WHITE,
  padding: "20px",
  borderRadius: "15px",
  border: "1px solid #E9F0FF",
});

const DivStyle = styled("div")({
  marginTop: "20px",
  display: "flex",
  flexDirection: "row",
});

const TextPopper = styled(Typography)({
  fontSize: SIZES.REGULAR_12,
  fontWeight: 500,
  color: COLORS.GRAY_PRIMARY,
});

const WrapperPopper = styled(Paper)({
  paddingTop: "15px",
  padding: "10px",
  textAlign: "center",
  fontSize: "12px",
  minWidth: "210px",
  overflow: "hidden",
});

const CardMiniTopMerchantStyle = styled("div")(({ marginLeft, marginRight }) => ({
  backgroundColor: COLORS.WHITE,
  padding: "20px",
  borderRadius: "15px",
  border: "1px solid #E9F0FF",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  marginLeft,
  marginRight,
}));

const useStyles = makeStyles(theme => ({
  popover: { pointerEvents: "none" },
}));

const CardTopMerchantProfitibility = ({
  listDataTopMerchantTopBranding,
  listDataTopMerchantBottomBranding,
  listDataTopMerchantTopWatchlist,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [listPoper, setListPoper] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const translation = useMemo(
    () => ({
      title: t("PROFITABILITY.TOP_MERCHANT"),
      label: {
        topBranding: t("PROFITABILITY.TOP_BRANDING"),
        bottomBranding: t("PROFITABILITY.BOTTOM_BRANDING"),
        topWatchlist: t("PROFITABILITY.TOP_WATCHLIST"),
        idrBio: t("GENERAL.IDR_BIO"),

        no_terminal_id: t("PROFITABILITY.NO_TERMINAL_ID"),
        no_marchant: t("PROFITABILITY.NO_MARCHANT"),
        total_sales_volume: t("PROFITABILITY.TOTAL_SALES_VOLUME"),
        total_cost: t("PROFITABILITY.TOTAL_COST"),
        total_revenue: t("PROFITABILITY.TOTAL_REVENUE"),
        total_profit: t("PROFITABILITY.TOTAL_PROFIT"),
      },
    }),
    [t],
  );

  const handleMouseEnter = useCallback(
    (evt, listCard) => {
      const formatDataDetail = Object.entries(listCard.detail_data).map(([keys, value]) => {
        const removeSpace = keys?.replace(/_/gi, " ");
        const formattingName = removeSpace?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter?.toUpperCase());
        return {
          name:
            formattingName === "Total Tid"
              ? translation.label.no_terminal_id
              : formattingName === "Total Revenue"
              ? translation.label.total_revenue
              : formattingName,
          value: value !== null ? value : 0,
        };
      });
      setListPoper(formatDataDetail);
      setAnchorEl(evt.currentTarget);
    },
    [setListPoper, setAnchorEl, translation],
  );

  const handleMouseLeave = useCallback(() => {
    setAnchorEl(null);
    setListPoper([]);
  }, [setListPoper]);

  /* -------------------------------------------------------------------------- */
  /*                                    RENDER                                  */
  /* -------------------------------------------------------------------------- */
  const CardMiniTopMerchantTopBranding = useMemo(() => {
    if (listDataTopMerchantTopBranding?.isFetching) {
      return (
        <CardMiniTopMerchantStyle marginLeft="0px" marginRight="10px">
          <LoadingSpinner height={348} />
        </CardMiniTopMerchantStyle>
      );
    }

    if (listDataTopMerchantTopBranding?.isError) {
      return (
        <CardMiniTopMerchantStyle marginLeft="0px" marginRight="2px">
          <ServerErrorBoundary
            onClick={listDataTopMerchantTopBranding?.refetch}
            title={listDataTopMerchantTopBranding?.error?.message}
          />
        </CardMiniTopMerchantStyle>
      );
    }

    if (listDataTopMerchantTopBranding?.data && !listDataTopMerchantTopBranding?.isFetching) {
      return (
        <CardMiniTopMerchant
          totalBio
          icon={iconList}
          title={translation.label.topBranding}
          titleIdr={translation.label.idrBio}
          list={listDataTopMerchantTopBranding.data}
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        />
      );
    }
  }, [listDataTopMerchantTopBranding, translation, handleMouseEnter, handleMouseLeave]);

  const CardMiniTopMerchantBottomBranding = useMemo(() => {
    if (listDataTopMerchantBottomBranding?.isFetching) {
      return (
        <CardMiniTopMerchantStyle marginLeft="10px" marginRight="10px">
          <LoadingSpinner height={348} />
        </CardMiniTopMerchantStyle>
      );
    }

    if (listDataTopMerchantBottomBranding?.isError) {
      return (
        <CardMiniTopMerchantStyle marginLeft="2px" marginRight="2px">
          <ServerErrorBoundary
            onClick={listDataTopMerchantBottomBranding?.refetch}
            title={listDataTopMerchantBottomBranding?.error?.message}
          />
        </CardMiniTopMerchantStyle>
      );
    }

    if (listDataTopMerchantBottomBranding.data && !listDataTopMerchantBottomBranding.isFetching) {
      return (
        <CardMiniTopMerchant
          totalBio
          icon={iconList}
          title={translation.label.bottomBranding}
          titleIdr={translation.label.idrBio}
          list={listDataTopMerchantBottomBranding.data}
          marginLeft="18px"
          marginRight="18px"
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        />
      );
    }
  }, [listDataTopMerchantBottomBranding, translation, handleMouseEnter, handleMouseLeave]);

  const CardMiniTopMerchantTopWatchlist = useMemo(() => {
    if (listDataTopMerchantTopWatchlist.isFetching) {
      return (
        <CardMiniTopMerchantStyle marginLeft="10px" marginRight="0px">
          <LoadingSpinner height={348} />
        </CardMiniTopMerchantStyle>
      );
    }

    if (listDataTopMerchantTopWatchlist.isError) {
      return (
        <CardMiniTopMerchantStyle marginLeft="0px" marginRight="0px">
          <ServerErrorBoundary
            onClick={listDataTopMerchantTopWatchlist?.refetch}
            title={listDataTopMerchantTopWatchlist?.error?.message}
          />
        </CardMiniTopMerchantStyle>
      );
    }

    if (listDataTopMerchantTopWatchlist.data && !listDataTopMerchantTopWatchlist.isFetching) {
      return (
        <CardMiniTopMerchant
          icon={iconList}
          title={translation.label.topWatchlist}
          list={listDataTopMerchantTopWatchlist.data}
          marginRight="18px"
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        />
      );
    }
  }, [listDataTopMerchantTopWatchlist, translation, handleMouseEnter, handleMouseLeave]);

  return (
    <CardTopMerchantStyle>
      <TitleChart icon={iconPie} title={translation.title} />
      <DivStyle>
        {/* UseMemo Card Mini Top Merchant Top Branding */}
        {CardMiniTopMerchantTopBranding}

        {/* UseMemo Card Mini Top Merchant Bottom Branding */}
        {CardMiniTopMerchantBottomBranding}

        {/* UseMemo Card Mini Top Wathclist */}
        {CardMiniTopMerchantTopWatchlist}
      </DivStyle>
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleMouseLeave}
        disableRestoreFocus
        disableScrollLock
      >
        <WrapperPopper>
          {listPoper.map(value => {
            return (
              <Grid container direction="row" justifyContent="space-between" spacing={4}>
                <Grid item>
                  <TextPopper>{value.name} :</TextPopper>
                </Grid>
                <Grid item>
                  <TextPopper>{parseCurrency(value.value)}</TextPopper>
                </Grid>
              </Grid>
            );
          })}
        </WrapperPopper>
      </Popover>
    </CardTopMerchantStyle>
  );
};

CardTopMerchantProfitibility.defaultProps = {
  listDataTopMerchantTopBranding: {},
  listDataTopMerchantBottomBranding: {},
  listDataTopMerchantTopWatchlist: {},
};

CardTopMerchantProfitibility.propTypes = {
  listDataTopMerchantTopBranding: PropTypes.object,
  listDataTopMerchantBottomBranding: PropTypes.object,
  listDataTopMerchantTopWatchlist: PropTypes.object,
};

export default CardTopMerchantProfitibility;
