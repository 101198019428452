import React from "react";
import { useTranslation } from "react-i18next";
import IconEmailSuccess from "assets/icons/popup/icon-mail-success.svg";
import CardVerification from "components/card/card-verification";

const VerificationSuccess = () => {
  const { t } = useTranslation();
  return (
    <div>
      <CardVerification
        title={t("REGISTER.VERIFICATION_SUCCESS")}
        description={t("REGISTER.VERIFICATION_SUCCESS_DESC")}
        icon={IconEmailSuccess}
      />
    </div>
  );
};

export default VerificationSuccess;
