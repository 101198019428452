import { makeStyles } from "@material-ui/core";
import { Axis, Chart, Interaction, Interval, Legend, Tooltip } from "bizcharts";
import PropTypes from "prop-types";
import React from "react";
// Component
import { ServerErrorBoundary } from "components/general";
import LegendChartBar from "components/legend/legend-chart-bar";
// Color
import LoadingSpinner from "elements/loading/spinner";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "8px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
  error: {
    height: "360px",
  },
}));

const BarChart = ({ data, legendView, heightChart, colors, labelCustom, positionName, axisName }) => {
  const classes = useStyles();

  const labelDefault = {
    formatter(items) {
      return items;
    },
  };

  if (data.isLoading) {
    return <LoadingSpinner height={350} />;
  }

  if (data.isError) {
    return <ServerErrorBoundary className={classes.error} onClick={() => data.refetch()} title={data.error?.message} />;
  }

  return (
    <div>
      <Chart
        padding={[30, 20, 50, 50]}
        autoFit
        scale={{ total_bio: { min: 0, type: "linear-strict" }, nice: true, type: "linear" }}
        height={heightChart}
        data={data.data}
        theme={{ maxColumnWidth: 40 }}
      >
        <Interaction type="element-active" />
        <Axis
          name="total_bio"
          grid={{
            align: "center",
            line: {
              type: "line",
              style: {
                lineWidth: 0,
              },
            },
          }}
        />
        <Axis name={axisName} label={data?.length !== 0 ? labelCustom : labelDefault} />
        <Tooltip position="bottom">
          {(items, title) => {
            return (
              <div className={classes.wrapper}>
                <div className={classes.valueWrapper}>{title.length !== 0 ? title[0].data?.total_bio : ""}</div>
              </div>
            );
          }}
        </Tooltip>
        <Interval position={positionName} color={data.length !== 0 ? colors : ""} />
        <Legend visible={false} />
      </Chart>
      {legendView === true ? <LegendChartBar /> : ""}
    </div>
  );
};

BarChart.defaultProps = {
  data: [],
  legendView: true,
  heightChart: 500,
  colors: [COLORS.GREEN_MEDIUM, COLORS.SECONDARY_MEDIUM, COLORS.PRIMARY_HARD],
  labelCustom: {},
  positionName: "",
  axisName: "",
  isLoading: false,
  isError: false,
};

BarChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  legendView: PropTypes.bool,
  heightChart: PropTypes.number,
  colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelCustom: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  positionName: PropTypes.string,
  axisName: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default BarChart;
