import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconMailSuccess from "assets/icons/popup/icon-mail-success.svg";
import FormForgotPassword from "components/form/form-forgot-password";
import { useCIMBLoading } from "components/loading/cimb-loading";
import PopupInformation from "components/popup/popup-information";
import useTimeOut from "hooks/useTimeOut";
import { postForgotPassword } from "services/auth-api";
import { routes } from "utils/constants/paths";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const { setActive } = useCIMBLoading();
  const [isEmailNotFound, setIsEmailNotFound] = useState(false);
  const [isEmailLocked, setIsEmailLocked] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const mutation = useMutation(postForgotPassword, {
    onSuccess: data => {
      data.success && setOpen(true);
      data.emailNotRegistered && setIsEmailNotFound(true);
      data.emailLocked && setIsEmailLocked(true);
    },
    onError: () => setIsEmailNotFound(true),
    onMutate: () => {
      setIsEmailLocked(false);
      setIsEmailNotFound(false);
      setActive(true);
    },
    onSettled: () => setActive(false),
  });

  useTimeOut({
    enable: open,
    onTimeOut: () => handleClose(),
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    navigate(routes.AUTH.PARSE_PREFIX(routes.AUTH.LOGIN));
  }, [setOpen, navigate]);

  const handleSubmitEmail = data => mutation.mutate(data);

  return (
    <div>
      <FormForgotPassword
        onSubmit={handleSubmitEmail}
        isEmailNotFound={isEmailNotFound}
        isEmailLocked={isEmailLocked}
      />
      <PopupInformation
        isOpen={open}
        onClose={handleClose}
        onSubmit={handleClose}
        icon={IconMailSuccess}
        title={t("DESCRIPTION.SUCCESSFULLY_SENT")}
        description={t("DESCRIPTION.PASSWORD_RESET_LINK_EMAIL")}
        buttonText={t("GENERAL.CLOSE")}
      />
    </div>
  );
};

export default ForgotPasswordPage;
