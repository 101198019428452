import { makeStyles } from "@material-ui/core";
import { Axis, Chart, Legend, LineAdvance, Tooltip, Point } from "bizcharts";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";
import { positiveProfitScaleCustomChart, negativeNotProfitScaleCustomChart } from "utils/helpers/useChartConfig";
import { thousandFormat } from "utils/helpers/useFormater";

const useStyles = makeStyles(() => ({
  wrapperLegend: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    paddingTop: "15px",
    padding: "10px",
    textAlign: "center",
    fontSize: "12px",
  },
  datesWrapper: {
    marginBottom: "5px",
    margintop: "5px",
    fontSize: SIZES.REGULAR_10,
    color: COLORS.GRAY_MEDIUM,
    fontWeight: 400,
  },
  valueWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
  arcoWrapper: {
    fontSize: SIZES.REGULAR_10,
    fontWeight: 600,
    color: COLORS.DARK_HARD,
  },
}));

const ChartProfitabilityCustom = ({
  data,
  customTooltip,
  handleClickPoint,
  positionLine,
  axisName,
  customLabelAxis,
  colors,
  pointValue,
  typeChart,
}) => {
  const classes = useStyles();

  const labelAxis = {
    formatter(text) {
      return text;
    },
  };

  const render = useMemo(() => {
    if (!Array.isArray(data)) return null;
    return data;
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <Chart
        padding={[50, 50, 50, 50]}
        autoFit
        height={300}
        data={render || []}
        scale={{
          total_bio: {
            min:
              typeChart === "notProfit"
                ? negativeNotProfitScaleCustomChart(render || [])
                : positiveProfitScaleCustomChart(render || []),
            type: "linear-strict",
          },
        }}
        onPointClick={evt => handleClickPoint(evt)}
      >
        {pointValue && (
          <Point
            position={positionLine}
            label={[
              pointValue,
              val => ({
                content: String(val).replace(".", ","),
              }),
            ]}
            size={0}
          />
        )}
        <Tooltip showCrosshairs={false} shared={false} />
        <Axis name={axisName} label={customLabelAxis ?? labelAxis} />
        <LineAdvance shape="smooth" size={4} point area position={positionLine} color={colors} />
        <Tooltip position="bottom" showCrosshairs={false}>
          {!customTooltip
            ? (_, items) => {
                return (
                  <div className={classes.wrapper}>
                    <div className={classes.datesWrapper}>{items[0]?.data.date ?? "-"}</div>
                    <div className={classes.valueWrapper}>{thousandFormat(items[0]?.data.total_bio) ?? "-"}</div>
                  </div>
                );
              }
            : customTooltip}
        </Tooltip>
        <Legend visible={false} />
      </Chart>
    </div>
  );
};

ChartProfitabilityCustom.defaultProps = {
  data: [],
  customTooltip: null,
  handleClickPoint: () => {},
  positionLine: "day*total_bio*month_name",
  colors: COLORS.BLUE_MEDIUM,
  axisName: "month_name",
  pointValue: "",
  typeChart: "",
};

ChartProfitabilityCustom.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  customTooltip: PropTypes.any,
  handleClickPoint: PropTypes.func,
  positionLine: PropTypes.string,
  customLabelAxis: PropTypes.any.isRequired,
  colors: PropTypes.string,
  axisName: PropTypes.string,
  pointValue: PropTypes.string,
  typeChart: PropTypes.string,
};

export default ChartProfitabilityCustom;
