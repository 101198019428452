import { IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useCallback, useMemo, forwardRef } from "react";
import IconEyeOff from "assets/icons/login/icon-eye-off.svg";
import IconEye from "assets/icons/login/icon-eye.svg";
import { TextFormLabel } from "elements/typhography/text-form-label";
import COLORS from "utils/constants/colors";
import { SIZES } from "utils/constants/fonts";

const useStyles = makeStyles({
  textField: {
    backgroundColor: ({ isInvalid }) => (isInvalid ? COLORS.WHITE : COLORS.GRAY_ULTRASOFT),
    fontSize: SIZES.REGULAR_14,
    color: COLORS.DARK_HARD,
    border: ({ isInvalid }) => `1px solid ${isInvalid ? COLORS.PRIMARY_DARK : COLORS.GRAY_ULTRASOFT}`,
    borderRadius: "10px",
    "& .MuiInputBase-input": {
      lineHeight: "20px",
      padding: "10px",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "10px",
    },
  },
});

const TextfieldPrivate = forwardRef(({ label, placeholder, isInvalid, name, ...rest }, ref) => {
  const classes = useStyles({ isInvalid });
  const [visible, setVisible] = useState(false);

  const switchVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const visibilityIcon = useMemo(
    () => (!visible ? <img src={IconEye} alt="visibility off" /> : <img src={IconEyeOff} alt="visibility on" />),
    [visible],
  );

  return (
    <div>
      <TextFormLabel>{label} :</TextFormLabel>
      <TextField
        ref={ref}
        className={classes.textField}
        name={name}
        placeholder={placeholder}
        type={visible ? "text" : "password"}
        variant="standard"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid="icon-change"
                aria-label="toggle password visibility"
                onClick={switchVisibility}
                edge="end"
              >
                {visibilityIcon}
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        {...rest}
      />
    </div>
  );
});

TextfieldPrivate.defaultProps = {
  isInvalid: false,
};

TextfieldPrivate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
};

export default TextfieldPrivate;
