import IconTopDebit from "assets/icons/dashboard/icon-top-debit.svg";
import IconTopOutlet from "assets/icons/dashboard/icon-top-outlet.svg";
import IconTopQR from "assets/icons/dashboard/icon-top-QR.svg";
import { orderingKeys } from "utils/helpers/general";

export const TRANSACTIONS_ALIASES = {
  merchant_name: "Merchant Name",
  profit: "Profit",
  trb: "TRB",
  status_edc: "Status EDC",
  sales: "Sales",
  mdr_revenue: "Revenue",
  interchange_fee: "Total Cost (Excl. EDC Cost)",
  edc_cost: "EDC Cost",
  total_profit: "Total Profit",
  mcc_group: "MCC Group",
  action: "Action",
};

export const KEYS_TRANSACTIONS_ALIASES = Object.keys(TRANSACTIONS_ALIASES);
export const VALUES_TRANSACTIONS_ALIASES = Object.values(TRANSACTIONS_ALIASES);

/**
 * Ordering keys transaction
 * @param {object} obj
 */
export const orderingKeysTransaction = obj => {
  return orderingKeys(obj, KEYS_TRANSACTIONS_ALIASES);
};

export const SUMMARY_OUTLET_ALIASES = {
  top_outlet: { title: "Top Outlet", icon: IconTopOutlet },
  top_debit_npg: { title: "Top Debit NPG", icon: IconTopDebit },
  top_qr: { title: "Top QR", icon: IconTopQR },
};

export const KEYS_SUMMARY_OUTLET_ALIASES = Object.keys(SUMMARY_OUTLET_ALIASES);

/**
 * Ordering keys transaction
 * @param {object} obj
 */
export const orderingKeysSummary = obj => {
  return orderingKeys(obj, KEYS_SUMMARY_OUTLET_ALIASES);
};

export function formatByMerchantBarChart(apiData) {
  const months = apiData.map(data => data.month_name?.trim());
  const dataChart = [];
  apiData.forEach(data => {
    data.data
      .sort((a, b) => a.label.localeCompare(b.label))
      .forEach(item => {
        dataChart.push({
          name: `${item.label}-${data.month_name?.trim()}`,
          months: data.month_name?.trim(),
          total_bio: item.total_bio,
        });
      });
  });

  return { months, dataChart };
}

export function formatBrandingTableByMerchant(apiData) {
  if (!apiData) return null;
  const { data, row_count: total_elements, total_page: total_pages, page } = apiData;
  const { pageNumber: page_number, pageSize: number_of_elements } = page;
  const { branding, details } = data;
  const contents = details.map(item => ({
    merchant_name: { title: item.merchant.merchant_name, options: item.tid },
    sales_volume: item.sales_volume.total,
    revenue: item.revenue.total,
    interchange_fee: item.interchange_fee.total,
    edc_cost: item.edc_cost.total,
    irf_cc_on_us: item.irf_cc_on_us.total,
    profit_merchant: item.profit,
    nama_sales: item.sales,
    status_edc: item.status_edc,
    total_profit: item.total_profit.total,
  }));

  return { branding, contents, number_of_elements, page_number, total_elements, total_pages };
}
