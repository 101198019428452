import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import COLORS from "utils/constants/colors";

const ChartBarStyle = styled("div")(({ marginsize }) => ({
  marginRight: marginsize,
}));

const getBgColor = value => {
  let color = COLORS.GREEN_HARD;
  if (value >= 8) {
    color = COLORS.GREEN_HARD;
  } else if (value >= 5) {
    color = COLORS.SECONDARY_MEDIUM;
  } else if (value < 5) {
    color = COLORS.RED_HARD;
  }
  return color;
};

const getBgColorShadow = value => {
  let color = COLORS.GREEN_ULTRASOFT;
  if (value >= 8) {
    color = COLORS.GREEN_ULTRASOFT;
  } else if (value >= 5) {
    color = COLORS.SECONDARY_ULTRASOFT;
  } else if (value < 5) {
    color = COLORS.RED_ULTRASOFT;
  }
  return color;
};

const BarTopStyle = styled("div")(({ percen }) => ({
  width: 140,
  height: 20,
  borderRadius: "4px",
  backgroundColor: getBgColorShadow(percen),
}));

const BarBottomStyle = styled("div")(({ percen }) => ({
  width: 14 * percen,
  height: 20,
  backgroundColor: getBgColor(percen),
  borderRadius: "4px",
  marginTop: -20,
}));

const ChartBar = ({ marginRight, percenNum }) => {
  return (
    <ChartBarStyle marginsize={marginRight}>
      <BarTopStyle percen={percenNum} />
      <BarBottomStyle percen={percenNum} />
    </ChartBarStyle>
  );
};

ChartBarStyle.propTypes = {
  marginRight: PropTypes.string,
};

BarTopStyle.propTypes = {
  rank: PropTypes.number,
};

BarBottomStyle.propTypes = {
  rank: PropTypes.number,
  percenNum: PropTypes.number,
};

ChartBar.defaultProps = {
  marginRight: "15px",
};

ChartBar.propTypes = {
  marginRight: PropTypes.string,
  percenNum: PropTypes.number.isRequired, // exp: (percenNum: 0.5) value 0.1, 0.2, 0.3, dst
};

export default ChartBar;
